import React, { useState, useContext } from 'react';
import { Form, Field, Formik } from 'formik';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { GlobalContext } from 'context/GlobalProvider';

import Button from 'components/UI/Antd/Button/Button';
import Menu from 'components/UI/Antd/Menu/Menu';
import ButtonWithAsync from 'components/UI/ButtonWithAsync/ButtonWithAsync';
import { AntInput } from 'components/UI/Antd/AntdInputWithFormik';

import { getSubscriptionSchema } from 'library/helpers/formUtils';

import {
  Wrapper,
  Title,
  SubscribedText
} from './FollowMenu.style.js';

const AsyncButton = ButtonWithAsync(Button);

const RenderSubscribeForm = props => {
  const {
    submitForm,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit} className='subscription-form'>
      <Field
        label={t('FOOTER_SUBSCRIBE_DESC')}
        component={AntInput}
        name='email'
        type='text'
        placeholder={t('FOOTER_SUBSCRIBE_PLACEHOLDER')}
      />
      <AsyncButton
        asyncAction={submitForm}
        className='subscribe-submit-button'
        htmlType='button'
        type='primary'
        size='large'
        label={t('FOOTER_CTA')}
      />
    </Form>
  );
};

const initialValues = { email: '' };

const FollowMenu = ({ menu, onLinkClick }) => {
  const { t } = useTranslation();
  const emailSchema = getSubscriptionSchema(i18n);
  const [ subscribed, setSubscribed ] = useState(false);
  const { subscribeGuest } = useContext(GlobalContext);

  const handleSubscribe = async (formProps, { setErrors, resetForm }) => {
    try {
      const { email } = formProps;
      const data = { email };
      const res = await subscribeGuest(data);
      if(res && res.subscribed) {
        setSubscribed(true);
        resetForm();
      } else {
        if(res && res.error_message ) {
          setErrors({ email: res.error_message });
        } else {
          setErrors({ email: "Couldn't subscribe user" });
        }
      }
    } catch (error) {
      console.error('Error subscribe', error);
    }
  };

  return (
    <Wrapper>
      <Title>{t('FOOTER_FOLLOW')}</Title>
      <Menu mode="horizontal" inlineCollapsed={ false }>
        {menu.map((item, index) => {
          const { url , icon, amplitude } = item;
          return (
            <Menu.Item key={index}>
              <a href={url || '#'} onClick={ () => onLinkClick(amplitude) } target='_blank'>
                <ReactSVG src={ icon }/>
              </a>
            </Menu.Item>
          )
        })}
      </Menu>
      {subscribed ? (
        <SubscribedText>{t('FOOTER_SUBSCRIBE_SUCCESS')}</SubscribedText>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubscribe}
          validationSchema={emailSchema}
        >
          {props => (
            <RenderSubscribeForm {...props} />
          )}
        </Formik>
      )}
    </Wrapper>
  );
};

export default FollowMenu;
