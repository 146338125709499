import React, { useState, useContext } from 'react';
import moment from 'moment';
import memoize from 'lodash/memoize';
import { AuthContext } from 'context/AuthProvider';
import { dateSortDesc } from 'library/helpers/utils';
import messagesService from 'services/messagesService.js';
import reservationService from 'services/reservation.js';
import AbsenceReportBanner from 'components/UI/AbsenceReportBanner/AbsenceReportBanner';
import absenceReportService from 'services/absenceReport.js';
export const ReservationContext = React.createContext({});

const ReservationProvider = ({ children }) => {
  const { loggedIn, refreshData } =
    useContext(AuthContext);
  const [loadingReservations, setLoadingReservations] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [completed, setCompleted] = useState([]);

  const [reservation, setReservation] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [messages, setMessages] = useState([]);
  const [read, setRead] = useState([]);
  const [unread, setUnread] = useState([]);
  const [ARBannerVisible, setARBannerVisible] = useState(false);
  const [messagesAndStatuses, setMessagesAndStatuses] = useState([]);
  const [usePopeyeBalance, setUsePopeyeBalance] = useState(false);

  const onOpenARBanner = () => {
    setARBannerVisible(true);
  };

  const onCloseARBanner = async () => {
    setARBannerVisible(false);
  };

  const onClickARBanner = async reservation_id => {
    setARBannerVisible(false);
    await absenceReportService.activate_auto_absence({
      reservation_id: reservation_id || reservation.id
    });
    await refreshData({ parameters: ['boater'] });
  };

  const getReservations = async () => {
    setLoadingReservations(true);
    try {
      const reservations = await reservationService.getall();
      const allReservations = [];
      if(reservations) {
        Object.keys(reservations).forEach(key => {
          reservations[key].forEach(reservation => {
            allReservations.push(reservation)
          });
        })
      }
      const sortedReservations = sortReservations(allReservations);
      setReservations(sortedReservations);
      const currentDayMoment = moment().startOf('day');
      setCompleted(
        sortedReservations.filter(res => {
          const { status_code } = res.statuses[res.statuses.length - 1];
          return (
            status_code === 'CANCELED_BY_MARINA' ||
            status_code === 'CANCELED_BY_BOATER' ||
            status_code === 'EXPIRED_NO_MARINA_RESPONSE' ||
            status_code === 'EXPIRED_NO_BOATER_RESPONSE' ||
            currentDayMoment.diff(moment(res.end_date), 'days') >= 0
          );
        })
      );
      setUpcoming(
        sortedReservations.filter(res => {
          const { status_code } = res.statuses[res.statuses.length - 1];
          return (
            status_code !== 'CANCELED_BY_MARINA' &&
            status_code !== 'CANCELED_BY_BOATER' &&
            status_code !== 'EXPIRED_NO_BOATER_RESPONSE' &&
            status_code !== 'EXPIRED_NO_MARINA_RESPONSE' &&
            currentDayMoment.diff(moment(res.end_date), 'days') < 0
          );
        })
      );
      setLoadingReservations(false);
      return sortedReservations;
    } catch (err) {
      setLoadingReservations(false);
      console.error(err);
    }
  };

  const _sortAndSetMessagesAndStatuses = (messages, statuses) => {
    const sortedMessages = messages.sort(dateSortDesc);
    const sortedReadMessages = messages
      .filter(
        message =>
          message.sender === 'BOATER' ||
          (message.receiver === 'BOATER' && !!message.read_by_receiver === true)
      )
      .sort(dateSortDesc);
    const sortedUnreadMessages = sortedMessages
      .filter(
        message =>
          message.receiver === 'BOATER' && !!message.read_by_receiver === false
      )
      .sort(dateSortDesc);
    const lastReadMessage = sortedReadMessages.length
      ? sortedReadMessages[sortedReadMessages.length - 1]
      : null;
    let statusesBeforeLastReadMessage;
    let statusesAfterLastReadMessage;
    if (lastReadMessage) {
      if (sortedReadMessages.length === messages.length) {
        statusesBeforeLastReadMessage = statuses;
        statusesAfterLastReadMessage = [];
      } else {
        statusesBeforeLastReadMessage = statuses.filter(status => {
          return (
            moment(status.created_at).diff(moment(lastReadMessage.created_at)) <
            0
          );
        });
        statusesAfterLastReadMessage = statuses.filter(status => {
          return (
            moment(status.created_at).diff(
              moment(lastReadMessage.created_at)
            ) >= 0
          );
        });
      }
    } else {
      if (sortedUnreadMessages.length) {
        statusesBeforeLastReadMessage = statuses.filter(status => {
          return (
            moment(status.created_at).diff(
              moment(sortedUnreadMessages[0].created_at)
            ) < 0
          );
        });
        statusesAfterLastReadMessage = statuses.filter(status => {
          return (
            moment(status.created_at).diff(
              moment(sortedUnreadMessages[0].created_at)
            ) >= 0
          );
        });
      } else {
        statusesBeforeLastReadMessage = statuses;
        statusesAfterLastReadMessage = [];
      }
    }
    setRead(
      [...sortedReadMessages, ...statusesBeforeLastReadMessage].sort(
        dateSortDesc
      )
    );
    setUnread(
      [...sortedUnreadMessages, ...statusesAfterLastReadMessage].sort(
        dateSortDesc
      )
    );
    setMessagesAndStatuses([...messages, ...statuses].sort(dateSortDesc));
  };

  const getReservationAndMessages = async id => {
    try {
      const res = await reservationService.get(id);
      const { statuses: stats, marina_id } = res;
      const mesgs = await messagesService.getAllMarina(marina_id);
      setReservation(res);
      setStatuses(stats);
      setMessages(mesgs);
      _sortAndSetMessagesAndStatuses(mesgs, stats);
      return res;
    } catch (err) {
      console.error(err);
    }
  };

  const updateReservation = async data => {
    try {
      const res = await reservationService.update(data);
      return Promise.resolve(res);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const updateReservationPayment = async data => {
    try {
      const res = await reservationService.updatePayment(data);
      return Promise.resolve(res);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const resubmitReservation = async data => {
    try {
      const res = await reservationService.resubmit(data);
      return Promise.resolve(res);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const sendMessage = async data => {
    try {
      await messagesService.create({
        ...data,
        reservation_id: reservation.id,
        marina_id: reservation.marina_id
      });
      await readMessages();
    } catch (err) {
      console.error(err);
    }
  };

  const readMessages = async () => {
    if (unread.length) {
      try {
        let options = {};
        if (reservation) {
          options.marina_id = reservation.marina_id;
        }
        await messagesService.read({
          message_ids: unread.map(({ id }) => id),
          ...options
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const clearReservationContext = () => {
    setReservation(null);
    setStatuses([]);
    setMessages([]);
    setRead([]);
    setUnread([]);
    setMessagesAndStatuses([]);
  };

  const sortReservations = reservations => {
    return reservations.sort((a, b) => {
      const aLastStatus = a.statuses.length - 1;
      const bLastStatus = b.statuses.length - 1;
      const keyA = new Date(a.statuses[aLastStatus].updated_at);
      const keyB = new Date(b.statuses[bLastStatus].updated_at);
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  };

  const calculateReservationPrice = async data => {
    try {
      let res = {};
      if (loggedIn) {
        res = await reservationService.calculatePrice(data);
      } else {
        res = await reservationService.calculatePriceForGuest(data);
      }
      return Promise.resolve(res);
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  };

  const memoized_calculateReservationPrice = memoize(
    calculateReservationPrice,
    (...args) => JSON.stringify(args)
  );

  return (
    <ReservationContext.Provider
      value={{
        getReservations,
        loadingReservations,
        reservations,
        upcoming,
        completed,
        getReservationAndMessages,
        reservation,
        statuses,
        messagesAndStatuses,
        read,
        unread,
        sendMessage,
        readMessages,
        updateReservation,
        clearReservationContext,
        updateReservationPayment,
        resubmitReservation,
        memoized_calculateReservationPrice,
        onOpenARBanner,
        usePopeyeBalance,
        setUsePopeyeBalance
      }}
    >
      {children}
      <AbsenceReportBanner
        details={{
          headline: 'AUTO_ABSENCE_HEADLINE',
          body: 'AUTO_ABSENCE_BODY',
          cta_title: 'AUTO_ABSENCE_CTA',
          description_first: 'AUTO_ABSENCE_BANNER_DESCRIPTION_FIRST',
          description_first_mobile:
            'AUTO_ABSENCE_BANNER_DESCRIPTION_FIRST_MOBILE',
          description_second: 'AUTO_ABSENCE_BANNER_DESCRIPTION_SECOND',
          description_second_mobile: 'AUTO_ABSENCE_BANNER_DESCRIPTION_MOBILE'
        }}
        noLink
        visible={ARBannerVisible}
        onClose={onCloseARBanner}
        onClick={onClickARBanner}
      />
    </ReservationContext.Provider>
  );
};

export default ReservationProvider;
