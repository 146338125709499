import React, { useEffect } from 'react';
import { Spin } from 'antd';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { SpinnerWrapper } from './Spinner.style';

const spinIcon = <LoadingOutlined style={{ fontSize: 34, color: '#194AB9' }} />;

const Spinner = ({
  className = 'spinner',
  indicator = spinIcon,
  tip = 'Loading...',
  spinning = false
}) => {
  useEffect(() => {
    const scrollY = document.body.style.top;
    if (spinning) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [spinning]);

  if (!spinning) return null;

  return (
    <SpinnerWrapper>
      <Spin indicator={indicator} tip={tip} wrapperClassName={className} />
    </SpinnerWrapper>
  );
};

export default Spinner;
