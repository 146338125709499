export const Variant = {
    Filled: 'filled',
    Outlined: 'outlined',
    Link: 'link',
    Naked: 'naked'
};

export const IconAlign = {
    Left: 'left',
    Right: 'right'
}
