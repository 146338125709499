import React, { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from 'context/AuthProvider';

import Menu from 'components/UI/Antd/Menu/Menu';
import Divider from 'components/UI/Antd/Divider/Divider';

import { PROFILE_PAGE, SETTINGS_PAGE } from 'settings/constant';

import { AvatarWrapper } from './ProfileMenu.style';

import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { removeIdentification } from 'library/helpers/amplitude';
import useCookie from 'library/hooks/useCookie';
import { getStateFromUrl } from 'library/helpers/url_handler';

const ProfileMenu = ({ location, history, match }) => {
  const { logOut, user } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  // for amplitude purpose.
  const { removeCookie } = useCookie();

  const handleDropdown = () => {
    setState(!state);
    const searchParams = getStateFromUrl(location);
    if (!isEmpty(searchParams) && searchParams.origin && searchParams.origin === 'transeuropemarinas.com') {
      history.replace({
        pathname: `/${match.params.locale}`,
        search: ''
      });
    }
  };

  const closeDropdown = () => {
    setState(false);
  };

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));

  const handleLogout = async () => {
    await logOut();
    setTimeout(() => history.push(`/${match.params.locale}`), 400);
    removeIdentification();
    removeCookie('campaign');
  };

  const getUserAvatar = () => {
    const { first_name, last_name } = user;
    return first_name && last_name
      ? `${first_name.charAt(0).toUpperCase()}${last_name
          .charAt(0)
          .toUpperCase()}`
      : '--';
  };

  return (
    <div
      className='dropdown-menu-item'
      ref={dropdownRef}
      data-test-id='test-boater-icon'
    >
      <AvatarWrapper onClick={handleDropdown}>{getUserAvatar()}</AvatarWrapper>
      {/* ToDo: selected menu item indicate */}
      <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
        <Menu.Item onClick={closeDropdown} key='0'>
          <NavLink to={`${match.url}${PROFILE_PAGE}`}>
            {t('PROFILE_MENU_ITEM')}
          </NavLink>
        </Menu.Item>
        <Menu.Item onClick={closeDropdown} key='1'>
          <NavLink to={`${match.url}${SETTINGS_PAGE}`}>
            {t('SETTINGS_MENU_ITEM')}
          </NavLink>
        </Menu.Item>
        <Menu.Item key='2'>
          <a
            href='https://pickapier-boater.zendesk.com/hc/en-us/sections/360004381880-FAQ'
            target='_blank'
            onClick={closeDropdown}
          >
            {t('FAQ_MENU_ITEM')}
          </a>
        </Menu.Item>
        <Menu.Item className='menu-divider' key='3'>
          <Divider />
        </Menu.Item>
        <Menu.Item key='4'>
          <button className='logout-btn' onClick={handleLogout}>
            {t('LOG_OUT_MENU_ITEM')}
          </button>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(ProfileMenu);
