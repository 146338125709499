import config from './firebaseServiceConfig';
import firebase from 'firebase/app';
import moment from 'moment';
import fileExtension from 'file-extension';
import { v4 as uuidv4 } from 'uuid';
import { post } from './api';

class firebaseService {
  zeroPad(num, places) {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  importMethods(arr) {
    Object.keys(arr).forEach(key => (this[key] = arr[key].bind(this)));
  }

  timeStampToMoment = function (timeStampDate) {
    if (timeStampDate instanceof moment) return timeStampDate;
    return moment(timeStampDate.toDate());
  };

  init(success) {
    if (Object.entries(config).length === 0 && config.constructor === Object) {
      if (process.env.NODE_ENV === 'development') {
        console.warn(
          'Missing Firebase Configuration at src/app/services/firebaseService/firebaseServiceConfig.js'
        );
      }
      success(false);
      return;
    }

    if (firebase.apps.length) {
      return;
    }
    firebase.initializeApp(config);

    this.updates = {
      message: moment(),
      notification: moment(),
      reservation: moment(),
      boater: moment()
    };
    this.logged_in = false;
    const _this = this;
    import('firebase/auth')
      .then(() => {
        _this.auth = firebase.auth();
        this.GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
        _this.FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
        success(true);
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/auth:', error);
      });

    import('firebase/analytics')
      .then(() => {
        _this.analytics = firebase.analytics();
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/analytics:', error);
      });
    import('firebase/performance')
      .then(() => {
        // this.performance = firebase.performance();
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/performance:', error);
      });
  }

  listenUserUpdates = callback => {
    const _this = this;
    import('firebase/firestore')
      .then(() => {
        _this.db = firebase.firestore();
        _this.FieldValue = firebase.firestore.FieldValue;
        _this.Timestamp = firebase.firestore.Timestamp;
        _this.userUpdatesListener = _this.db
          .collection('boater_updates')
          .doc(_this.auth.currentUser.uid)
          .onSnapshot(docSnapshot => {
            if (docSnapshot.exists) {
              const data = docSnapshot.data();
              const updates = { ..._this.updates };
              if (data.message)
                updates.message = _this.timeStampToMoment(data.message);
              if (data.notification)
                updates.notification = _this.timeStampToMoment(
                  data.notification
                );
              if (data.reservation)
                updates.reservation = _this.timeStampToMoment(data.reservation);
              if (data.boater)
                updates.boater = _this.timeStampToMoment(data.boater);
              _this.updates = updates;
              console.log('realtime updated');
            }
            return callback(_this.updates);
          });
      })
      .catch(error => {
        console.error('Unable to lazy-load firebase/firestore:', error);
      });
  };

  // Change password flow
  // 1. Re-authenticate with old password + email
  // 2. Change password
  changePassword = async function (oldPassword, newPassword) {
    if (!this.auth || !newPassword) {
      return;
    }
    const user = this.auth.currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    );
    await user.reauthenticateWithCredential(credential);

    // await user.reauthenticateWithCredential()
    return await user.updatePassword(newPassword);
  };

  sendPasswordResetEmail = async function (email) {
    return await post('/public/reset_password', { email });
  };

  // File methods
  uploadFile = async (file, progressHandler, formattedFileName = null) => {
    
    if (!this.storage) {
      await import('firebase/storage');
    }

    const boater_id = !!this.auth.currentUser && this.auth.currentUser.uid;
    const original_name = file.name;
    const ext = fileExtension(file.name);
    const file_name = formattedFileName
      ? formattedFileName
      : `${uuidv4()}.${ext}`;
    let file_path = `IB/${file_name}`;
    if(boater_id) file_path = `attachments/${boater_id}/${file_name}`;
    // Create file metadata including the content type
    const metadata = {
      contentType: file.type
    };

    // Upload file and metadata to the object 'documents/file.name'
    const uploadTask = firebase
      .storage()
      .ref()
      .child(file_path)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // Return progress
        progressHandler({
          status: 'PROCESSING',
          progress
        });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }
      },
      function (error) {
        // A full list of error codes is available at
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            console.log("User doesn't have permission to access the object");
            break;

          case 'storage/canceled':
            // User canceled the upload
            console.log("User canceled the upload")
            break;

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            console.log("Unknown error occurred, inspect error.serverResponse")
            break;
        }
        progressHandler({
          status: 'ERROR'
        });
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        if(boater_id){
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          
            return progressHandler({
            status: 'DONE',
            url: downloadURL,
            name: original_name,
            file_path,
            file_type: file.type,
            boater_id
          });
          
          
        });
        }
        else{
          return progressHandler({
            status: 'DONE',
            name: original_name,
            file_path,
            file_type: file.type,
          });
        }
        
      }
    );
  };

  getFileUrl = async file_path => {
    try {
      if (!this.storage) {
        await import('firebase/storage');
      }
      const url = await firebase
        .storage()
        .ref()
        .child(file_path)
        .getDownloadURL();
      return url;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  deleteFile = async (file) =>{
    try{
      if (!this.storage) {
        await import('firebase/storage');
      }
      const name = file.name;
      const deleted = await firebase.storage().ref().child(file.file_path).delete();
      console.log('File deleted successfully:', name);
    }catch (error) {
      console.error(error);
      console.error('Error deleting file:', error);
    }
  }

  onAuthStateChanged = callback => {
    if (!this.auth) {
      return;
    }
    this.auth.onAuthStateChanged(callback);
    this.auth.onIdTokenChanged(user => {
      if (user && user.uid) {
        this.analytics.setUserId(user.uid);
      }
    });
  };

  signOut = () => {
    if (!this.auth) {
      return;
    }
    if (typeof this.userUpdatesListener === 'function')
      this.userUpdatesListener();
    this.auth.signOut();
  };

  sendEmailVerification = async (pathname, search) => {
    let url = `${process.env.REACT_APP_DOMAIN}${pathname}`;
    if (search) {
      url = `${url}${search}&email_confirmed=true`;
    } else {
      url = `${url}?email_confirmed=true`;
    }
    return await post('/boater/send_email_verification', { url });
  };
}

const instance = new firebaseService();

export default instance;
