import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MdMoreVert } from 'react-icons/md';

import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';

import Button from 'components/UI/Antd/Button/Button';
import {
  NotificationItem,
  NotificationBody,
  NotificationContent,
  NotificationSubject,
  NotificationDate,
  ActionDropdownOuter,
  ActionsDropdown
} from './Notifications.style';

const Notification = ({ item, opened, onClick, onUnread, onDelete }) => {
  const { t } = useTranslation();
  const [actionsOpened, setActionsOpened] = useState(false);

  useEffect(() => {
    if (!opened) {
      closeAction();
    }
  }, [opened]);

  const closeAction = () => setActionsOpened(false);

  const onToggleActions = e => {
    e.stopPropagation();
    setActionsOpened(!actionsOpened);
  };

  const onUnreadClick = e => {
    e.stopPropagation();
    closeAction();
    onUnread(item);
  };

  const onDeleteClick = e => {
    e.stopPropagation();
    closeAction();
    onDelete(item);
  };

  const onOuterClick = e => {
    e.stopPropagation();
    closeAction();
  };

  const getNotificationIcon = (iconType = 'INFO') => {
    const icons = {
      APPROVED: <CheckCircleFilled style={{ color: '#00BB5D' }} />,
      UPDATED: <InfoCircleFilled style={{ color: '#194AB9' }} />,
      INFO: <InfoCircleFilled style={{ color: '#194AB9' }} />,
      INITIATED: <InfoCircleFilled style={{ color: '#194AB9' }} />,
      WARNING: <ExclamationCircleFilled style={{ color: '#F29100' }} />,
      CANCELED: <CloseCircleFilled style={{ color: '#F9503D' }} />,
      DECLINED: <CloseCircleFilled style={{ color: '#F9503D' }} />,
      ACCEPTED: <CheckCircleFilled style={{ color: '#00BB5D' }} />,
      INSTANT_BOOK: <CheckCircleFilled style={{ color: '#00BB5D' }} />
    };
    return icons[iconType];
  };

  const getInfoTitle = (subject, name) => {
    switch (subject) {
      case 'MARINA_UPDATED_BOATER':
        return t('NTF41_MARINA_UPDATES_BOATER', { marina_name: name });
      case 'MARINA_UPDATED_VESSEL':
        return t('NTF41_MARINA_UPDATES_VESSEL', { marina_name: name });
      default:
        return t('MARINA_SENT_YOU_MESSAGE', { name });
    }
  };

  const getUpdatedTitle = (text, subject, name) => {
    if (text === 'payment_expired') {
      return t('PAYMENT_EXPIRED_NOTIFICATION', { marina_name: name });
    } else {
      if (subject === 'ABSENCE_REPORT') {
        return t('ABSENCE_UPDATED_BY_MARINA', { marina_name: name });
      } else {
        return t('MARINA_SENT_YOU_PRICE', { name });
      }
    }
  };

  const getNotificationTitle = ({ type = 'INFO', subject, text }) => {
    let parcedObj = {};
    let name = '';
    try {
      parcedObj = item.details;
    } catch (error) {
      parcedObj = {};
    }

    const keys = Object.keys(parcedObj);

    if (
      keys.length &&
      (keys.indexOf('marina_name') !== -1 || keys.indexOf('sender_name') !== -1)
    ) {
      name = parcedObj.marina_name
        ? parcedObj.marina_name
        : parcedObj.sender_name;
    } else {
      name = 'Marina';
    }

    const titles = {
      INITIATED: t('REQUEST_SENT_TO_MARINA', { name }),
      UPDATED: getUpdatedTitle(text, subject, name),
      APPROVED: t('MARINA_APPROVED_REQUEST', { name }),
      CANCELED: t('MARINA_CANCELED_REQUEST', { name }),
      ACCEPTED:
        subject === 'HOME_MARINA_VERIFICATION'
          ? t('MARINA_VERIFICATION_REQUEST_ACCEPTED', { name })
          : t('MEMBERSHIP_REQUEST_ACCEPTED', { name }),
      DECLINED:
        subject === 'HOME_MARINA_VERIFICATION'
          ? t('MARINA_VERIFICATION_REQUEST_DECLINED', { name })
          : t('MEMBERSHIP_REQUEST_DECLINED', { name }),
      INFO: getInfoTitle(subject, name),
      INSTANT_BOOK: t('MARINA_APPROVED_REQUEST', { name }),
      REMOVED: t('NTF49', {
        admin_name: parcedObj.admin_name,
        vessel_name: parcedObj.vessel_name
      })
    };

    return titles[type] || name;
  };

  const getText = () => {
    if (item.text !== 'NEW_MESSAGE_RECEIVED') {
      return null;
    } else {
      let parcedObj = {};
      try {
        parcedObj = item.details;
      } catch (error) {
        parcedObj = {};
      }
      const text = parcedObj.message_text;
      return (
        !!text && (
          <NotificationContent>
            {text.length > 30 ? `${text.substring(0, 30)}...` : text}
          </NotificationContent>
        )
      );
    }
  };

  return (
    <NotificationItem onClick={() => onClick(item)}>
      <NotificationBody
        className={`notification-body ${item.view_at ? '' : 'unviewed'} ${item.read_at ? 'readed' : ''
          }`}
      >
        {item.read_at ? (
          <Button
            className='actions_button'
            htmlType='button'
            onClick={onToggleActions}
            type='link'
          >
            <MdMoreVert />
          </Button>
        ) : (
          <div />
        )}
        <ActionDropdownOuter
          onClick={onOuterClick}
          className={actionsOpened ? 'opened' : ''}
        >
          <ActionsDropdown className={actionsOpened ? 'opened' : ''}>
            <Button
              className='action_button'
              htmlType='button'
              onClick={onUnreadClick}
              type='link'
            >
              {t('MARK_AS_UNREAD')}
            </Button>
            <Button
              className='action_button'
              htmlType='button'
              onClick={onDeleteClick}
              type='link'
            >
              {t('DELETE_MESSAGE')}
            </Button>
          </ActionsDropdown>
        </ActionDropdownOuter>
        <NotificationContent>
          {getNotificationIcon(item.type)}
          <NotificationSubject className='title'>
            {getNotificationTitle(item)}
          </NotificationSubject>
        </NotificationContent>
        {getText()}
        <NotificationDate className='date'>
          {moment(item.created_at).format('MMM DD, HH:mm')}
        </NotificationDate>
      </NotificationBody>
    </NotificationItem>
  );
};

export default Notification;
