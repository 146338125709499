import isEmpty from 'lodash/isEmpty';
import {
  LISTING_POSTS_PAGE,
  MARINA_PAGE,
  PROFILE_PAGE,
  AGENT_VESSEL_PAGE,
  ABSENCE_REPORTS_PAGE,
  RESERVATIONS_PAGE,
  RESERVATION_PAGE,
  POPEYE_PAGE
} from 'settings/constant';
import {
  DISCOVER,
  HOME_MARINA,
  MY_TRIPS,
  MORE,
  POPEYE
} from 'container/Layout/MobileMenu/MobileMenu';


export const isItemActive = (item, state, pathname, match, params ) => {
  const {
    homeDrawerOpened,
    tripsDrawerOpened,
    moreDrawerOpened,
    popeyeRequestDrawerOpened
  } = state;
  const {
    activeGuestItem,
    isARModalShown,
    searchParams,
    vesselsWithHomeMarina,
    getMarinaTextId,
    marinas
  } = params;
  switch (item) {
    case DISCOVER:
      const { guestMode } = params;
      if (guestMode) {
        if (activeGuestItem) {
          return false;
        } else {
          return !moreDrawerOpened;
        }
      } else {
        return (
          (pathname === `/${match.params.locale}` ||
            pathname === `${match.url}${LISTING_POSTS_PAGE}` ||
            (pathname.includes(MARINA_PAGE) &&
              !vesselsWithHomeMarina.some(
                ({ home_marina_id }) => {
                  const textId = getMarinaTextId(marinas.find(({ id }) => id === home_marina_id));
                  return pathname === `${match.url}${MARINA_PAGE}/${textId}`;
                }
              ))) &&
          !homeDrawerOpened &&
          !tripsDrawerOpened &&
          !moreDrawerOpened &&
          !popeyeRequestDrawerOpened &&
          !isARModalShown
        );
      }
    case HOME_MARINA:
      return (
        ((activeGuestItem && activeGuestItem === HOME_MARINA) ||
          homeDrawerOpened ||
          isARModalShown ||
          vesselsWithHomeMarina.some(
            ({ home_marina_id }) => {
              const textId = getMarinaTextId(marinas.find(({ id }) => id === home_marina_id));
              return pathname === `${match.url}${MARINA_PAGE}/${textId}`;
            }
          ) ||
          (pathname === `${match.url}${PROFILE_PAGE}${AGENT_VESSEL_PAGE}` &&
            !isEmpty(searchParams) &&
            searchParams.hmv_request) ||
          (pathname.includes(`${ABSENCE_REPORTS_PAGE}`) &&
            !isEmpty(searchParams) &&
            searchParams.open_report)) &&
        !moreDrawerOpened &&
        !popeyeRequestDrawerOpened &&
        !tripsDrawerOpened
      );
    case MY_TRIPS:
      return (
        tripsDrawerOpened ||
        (((activeGuestItem && activeGuestItem === MY_TRIPS) ||
          pathname.includes(`${RESERVATIONS_PAGE}`) ||
          pathname.includes(`${RESERVATION_PAGE}`) ||
          pathname.includes(`${ABSENCE_REPORTS_PAGE}`)) &&
          !(!isEmpty(searchParams) && searchParams.open_report) &&
          !homeDrawerOpened &&
          !moreDrawerOpened &&
          !popeyeRequestDrawerOpened &&
          !isARModalShown
        )
      );
    case POPEYE:
      return (
        popeyeRequestDrawerOpened || (
          (activeGuestItem && activeGuestItem === POPEYE) ||
          pathname.includes(`${POPEYE_PAGE}`) &&
          !homeDrawerOpened &&
          !tripsDrawerOpened &&
          !moreDrawerOpened &&
          !isARModalShown
        )
      );
    default:
      return false;
  }
};
