import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import isNil from 'lodash/isNil';
import { AuthContext } from 'context/AuthProvider';
import { PopeyeContext } from 'context/PopeyeProvider';
import { EmailVerificationContext } from 'context/EmailVerificationProvider';
import { POPEYE_PAGE } from 'settings/constant';
import { POPEYE } from './MobileMenu';

import { isItemActive } from 'library/helpers/bottomNavigation';

import popeyeIcon from 'assets/images/pickapier/popeye.svg';
import popeyeHoverIcon from 'assets/images/pickapier/popeye-hovered.svg';
import { MobileMenuTitle } from './MobileMenu.style';

const PopeyeMobileMenu = ({
  location,
  match,
  state,
  dispatch,
  history,
  isARModalShown,
  activeGuestItem,
  setActiveGuestItem,
  vesselsWithHomeMarina,
  guestMode,
  onHomeClick
}) => {
  const { pathname } = location;
  const { loggedIn, user } = useContext(AuthContext);
  const { isPopeyeMember, isPreSignatureStatus } = useContext(PopeyeContext);
  const { onVerify } = useContext(EmailVerificationContext);
  const {
    popeyeRequestDrawerOpened,
    authDrawerOpened
  } = state;

  const onPopeyeMenuItemClick = e => {
    e.preventDefault();
    onHomeClick();
    if (!loggedIn || !user) {
      dispatch({
        type: popeyeRequestDrawerOpened ? 'HIDE_POPEYE_REQUEST_DRAWER' : 'OPEN_POPEYE_REQUEST_DRAWER'
      });
      if (!popeyeRequestDrawerOpened) {
        setActiveGuestItem(POPEYE);
      } else {
        setActiveGuestItem(null);
      }
    } else {
      if (isPopeyeMember || isPreSignatureStatus) {
        // go to Popeye pages
        history.push(`${match.url}${POPEYE_PAGE}`);
      } else {
        if(user.emailVerified) {
          // display or hide request drawer
          dispatch({
            type: popeyeRequestDrawerOpened
              ? 'HIDE_POPEYE_REQUEST_DRAWER'
              : 'OPEN_POPEYE_REQUEST_DRAWER'
          });
        } else {
          // request email verification
          onVerify({ force: true });
        }
      }
    }
  };

  const popeyeItemActive = isItemActive(POPEYE, state, pathname, match, {
    guestMode,
    activeGuestItem,
    isARModalShown,
    vesselsWithHomeMarina
  });

  return (
    <NavLink
      isActive={() => false}
      className={
        popeyeItemActive ? 'mobile-nav-link active' : 'mobile-nav-link'
      }
      to={`/${match.params.locale}${POPEYE_PAGE}`}
      onClick={onPopeyeMenuItemClick}
    >
      <ReactSVG
        className={`bottom-nav-icon with-halo ${
          popeyeItemActive ? '' : 'hidden'
        }`}
        src={popeyeHoverIcon}
      />
      <ReactSVG
        className={`bottom-nav-icon with-halo ${
          popeyeItemActive ? 'hidden' : ''
        }`}
        src={popeyeIcon}
      />
      <MobileMenuTitle className='mobile-menu-title'>Popeye®</MobileMenuTitle>
    </NavLink>
  );
};

export default PopeyeMobileMenu;
