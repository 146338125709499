import React from 'react';
import { IoMdArrowBack } from 'react-icons/io';
import {
  MobileSubMenuWrapper,
  MobileSubMenuHeader,
  MobileSubMenuHeadline
} from './MobileSubMenu.style';

const MobileSubMenu = ({ title, visible, onClose, children }) => {
  if (!visible) {
    return null;
  } else {
    return (
      <MobileSubMenuWrapper>
        <MobileSubMenuHeader>
          <button onClick={onClose}>
            <IoMdArrowBack />
          </button>
          <MobileSubMenuHeadline>{title}</MobileSubMenuHeadline>
        </MobileSubMenuHeader>
        {children}
      </MobileSubMenuWrapper>
    );
  }
};

export default MobileSubMenu;
