import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const RightMenuItemInfoStyles = css`
  display: flex;
  flex-grow: 1;
  align-items: center;
  position: relative;
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  width: 100%;
  height: 52px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 999999;
  background-color: ${themeGet('color.1', '#ffffff')};
  box-shadow: 0 1px 4px 1px ${themeGet('boxShadow.1', 'rgba(0, 0, 0, 0.16')};
  padding: 0 15px;
  .mobile-menu-item {
    max-width: 20%;
    flex-grow: 1;
  }
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
  @media only screen and (max-width: 991px) {
    padding: 0;
    ${props => props.hidden ? 'display: none;' : ''}
  }
`;

export const MobileMenuItem = styled.div`
  max-width: 25%;
  > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.active {
      .mobile-menu-title {
        color: ${themeGet('primary.0', '#194AB9')};
        font-weight: 700;
      }
    }
    .bottom-nav-icon {
      position: relative;
      &.hidden {
        display: none;
      }
      &.with-halo {
        top: -2px;
        &:after {
          box-shadow: 0 -8px 5px -7px rgb(0 0 0 / 16%);
          content: '';
          position: absolute;
          z-index: -1;
          height: 40px;
          width: 40px;
          top: -8px;
          left: -8px;
          border-radius: 50%;
          background-color: ${themeGet('color.1', '#ffffff')};
        }
      }
    }
  }
`;

export const MobileMenuTitle = styled.p`
  color: ${themeGet('text.12', '#828282')};
  margin-bottom: 0;
  font-size: 12px;
  line-height: 10px;
  font-weight: 500;
  white-space: nowrap;
`;

export const DrawerBodyWrapper = styled.div`
  .ant-menu {
    li {
      &.ant-menu-item {
        height: auto;
        margin: 0;
        padding: 16px 32px;
        border-bottom: 1px solid ${themeGet('border.5', '#e8eaec')};
        &:active,
        &.ant-menu-item-selected {
          background-color: ${themeGet('color.1', '#ffffff')};
          a {
            color: ${themeGet('primary.0', '#194AB9')};
            svg {
              g,
              path {
                stroke: ${themeGet('primary.0', '#194AB9')};
                &.white {
                  stroke: ${themeGet('color.1', '#ffffff')};
                }
              }
              circle {
                fill: ${themeGet('primary.0', '#194AB9')};
              }
            }
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        font-size: 15px;
        color: ${themeGet('text.4', '#323232')};
        &:hover {
          color: ${themeGet('primary.0', '#194AB9')};
          svg {
            g,
            path {
              stroke: ${themeGet('primary.0', '#194AB9')};
              &.white {
                stroke: ${themeGet('color.1', '#ffffff')};
              }
            }
            circle {
              fill: ${themeGet('primary.0', '#194AB9')};
            }
          }
        }
      }
    }
  }
  .mobile-menu-icon {
    height: 24px;
    width: 24px;
    margin-right: 17px;
  }
`;

export const DrawerFooter = styled.div`
  height: 52px;
`;

export const DrawerMoreBodyWrapper = styled.div`
  height: calc(100% - 52px);
  overflow-y: scroll;
  padding: 9px 0 0;
  .ant-menu {
    li {
      &.ant-menu-item {
        height: 60px;
        margin: 0;
        padding: 8px 20px 8px 36px;
        border-bottom: 1px solid ${themeGet('border.5', '#e8eaec')};
        &:active,
        &.ant-menu-item-selected {
          background-color: ${themeGet('color.1', '#ffffff')};
        }
      }
      a {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 44px;
        color: ${themeGet('text.7', '#444444')};
        .chevron-right {
          margin-left: auto;
        }
        &:hover {
          color: ${themeGet('primary.0', '#194AB9')};
        }
      }
    }
  }
`;

export const MobieSignupBannerWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 35px;
`;

export const ProfileInfo = styled.div`
  padding: 16px 20px 18px 36px;
  display: flex;
  align-items: center;
`;

export const Avatar = styled.div`
  height: 52px;
  width: 52px;
  border-radius: 26px;
  background-color: ${themeGet('color.23', '#66bdff')};
  text-align: center;
  line-height: 52px;
  font-weight: bold;
  color: ${themeGet('color.1', '#ffffff')};
  font-size: 21px;
  cursor: pointer;
  margin-right: 16px;
`;

export const UserInfo = styled.div`
  font-size: 18px;
  color: ${themeGet('text.7', '#444444')};
`;

export const MobileMenuSection = styled.div``;

export const MenuSectionTitle = styled.h5`
  margin-bottom: 0;
  padding: 8px 20px 8px 36px;
  background-color: ${themeGet('color.22', '#fafafa')};
  color: ${themeGet('text.3', '#8d8d8d')};
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const IconWrapper = styled.div`
  ${RightMenuItemInfoStyles};
  top: 3px;
  .crown-icon {
    margin-left: auto;
  }
`;

export const SelectedLanguage = styled.div`
  ${RightMenuItemInfoStyles};
  top: 1px;
  justify-content: flex-end;
  font-size: 16px;
`;
