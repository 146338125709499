import React, { Fragment } from 'react';
import {
  Wrapper,
  Divider,
  Menu,
  Item
} from './BottomMenu.style.js';

const BottomMenu = ({ menu, onLinkClick }) => {
  return (
    <Wrapper>
      <Menu>
        {menu.map((item, index) => {
          const { title , link, amplitude } = item;
          return (
            <Item key={index}>
              <Divider first={!index} />
              <a href={link || '#'} onClick={ () => onLinkClick(amplitude) } target='_blank'>
                {title}
              </a>
            </Item>
          )
        })}
      </Menu>
    </Wrapper>
  );
};

export default BottomMenu;
