import { post, postWithResult, get } from './api';

export default {
  createGuest: async function (data) {
    return await post('/guest/create_reservation', data);
  },
  getall: async function () {
    return await get('/reservation/getall');
  },
  create: async function (data) {
    return await post('/reservation/create', data);
  },
  get: async function (id) {
    return await get('/reservation/get/' + id);
  },
  update: async function (data) {
    return await post('/reservation/update', data);
  },
  createInstant: async function (data) {
    return await post('/reservation/instant_book', data);
  },
  updatePayment: async function (data) {
    return await post('/reservation/update_payment', data);
  },
  resubmit: async function (data) {
    return await post('/reservation/resubmit', data);
  },
  calculatePrice: async function (data) {
    return await post('/reservation/calculate_price', data);
  },
  calculatePriceForGuest: async function (data) {
    return await post('/guest/calculate_reservation_price', data);
  },
  getReservationProposal: async function (data) {
    return await postWithResult('/public/instant_booking/proposal', data);
  },
  updateGuestPayment: async function(data) {
    return await post('/public/instant_booking/guest_update_payment', data);
  },
  extendAvailability: async function(data) {
    return await postWithResult('/public/instant_booking/extend_availability', data);
  },
  releaseBerth: async function(data) {
    return await postWithResult('/public/instant_booking/release_proposal_berth', data);
  },
  createWithResult: async function (data) {
    return await postWithResult('/reservation/create', data);
  },
  createGuestWithResult: async function (data) {
    return await postWithResult('/guest/create_reservation', data);
  },
  updatePaymentWithResult: async function (data) {
    return await postWithResult('/reservation/update_payment', data);
  },
  updateGuestPaymentWithResult: async function(data) {
    return await postWithResult('/public/instant_booking/guest_update_payment', data);
  },
};
