import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  padding: 16px 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
`;

export const Title = styled.h4`
  color: ${themeGet('text.4', '#323232')};
  font-size: 15px;
  font-weight: bold;
  margin-left: 15px;
`;

export const SignUpInfo = styled.div`
  padding: 0 18px;
  margin-bottom: 15px;
  a {
    display: block;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    padding: 8px 0;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#194AB9')};
    transition: opacity 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const LoginInfo = styled.div`
  text-align: center;
  color: ${themeGet('text.3', '#8d8d8d')};
  a {
    color: ${themeGet('primary.0', '#194AB9')};
  }
`;
