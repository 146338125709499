import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalProvider';
import { Formik, Field, Form } from 'formik';
import i18n from '../../i18n';
import Row from 'components/UI/Antd/Grid/Row';
import Col from 'components/UI/Antd/Grid/Col';
import Button from 'components/UI/Antd/Button/Button';
import Divider from 'components/UI/Antd/Divider/Divider';
import { AntInput } from 'components/UI/Antd/AntdInputWithFormik';
import AntdCheckboxWithFormik from 'components/UI/Antd/AntdCheckboxWithFormik';
import ButtonWithAsync from 'components/UI/ButtonWithAsync/ButtonWithAsync';
import { getExtendedSignupSchema } from 'library/helpers/formUtils';
import {
  Wrapper,
  AuthButtons,
  FieldWrapper,
  SwitchWrapper,
  Label,
  ErrorLabel,
  BottomInfo
} from './AuthForms.style';
// icons
import googleIcon from 'assets/images/google-icon.svg';
import facebookIcon from 'assets/images/facebook-icon.svg';

const AsyncButton = ButtonWithAsync(Button);

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirmPassword: '',
  termsAndConditions: false
};

const RenderSignUpForm = ({
  values,
  submitCount,
  submitForm,
  error,
  signUpCtaLabel
}) => {
  const { t } = useTranslation();
  const { termsOfServiceLink, privacyPolicyLink } = useContext(GlobalContext);
  return (
    <Form>
      <Row gutter={10}>
        <Col xs={12}>
          <Field
            className='input-field'
            component={AntInput}
            name='first_name'
            type='text'
            size='large'
            placeholder={t('FIRST_NAME')}
            submitCount={submitCount}
            value={values.first_name}
            hasFeedback
          />
        </Col>
        <Col xs={12}>
          <Field
            className='input-field'
            component={AntInput}
            name='last_name'
            type='text'
            size='large'
            placeholder={t('LAST_NAME')}
            submitCount={submitCount}
            value={values.last_name}
            hasFeedback
          />
        </Col>
      </Row>

      <Field
        className='input-field'
        component={AntInput}
        name='email'
        type='text'
        size='large'
        placeholder={t('YOUR_EMAIL_PLACEHOLDER')}
        submitCount={submitCount}
        value={values.email}
        hasFeedback
      />
      <Field
        className='input-field'
        component={AntInput}
        name='password'
        type='password'
        size='large'
        placeholder={t('SIGN_IN_PASSWORD_PLACEHOLDER')}
        submitCount={submitCount}
        value={values.password}
        hasFeedback
      />
      <Field
        className='input-field'
        component={AntInput}
        name='confirmPassword'
        type='password'
        size='large'
        placeholder={t('CONFIRM_PASSWORD_PLACEHOLDER')}
        submitCount={submitCount}
        value={values.confirmPassword}
        hasFeedback
      />

      <FieldWrapper>
        <SwitchWrapper>
          <Field
            className='terms-field'
            component={AntdCheckboxWithFormik}
            name='termsAndConditions'
            checked={values.termsAndConditions}
          >
            <Label>
              <a
                href={termsOfServiceLink || '#'}
                target='_blank'
                onClick={e => e.stopPropagation()}
              >
                {t('TERMS_OF_USE_LINK')}
              </a>
            </Label>
            <Label className='and-field'>
              {" " + t('AND') + " "}
            </Label>
            <Label>
              <a
                href={privacyPolicyLink || '#'}
                target='_blank'
                onClick={e => e.stopPropagation()}
              >
                {t('PRIVACY_POLICY_LINK')}
              </a>
            </Label>
          </Field>
        </SwitchWrapper>
      </FieldWrapper>

      {error && <ErrorLabel>{error.message}</ErrorLabel>}

      <AsyncButton
        asyncAction={submitForm}
        className='signup-btn'
        htmlType='button'
        type='primary'
        size='large'
        label={signUpCtaLabel ? signUpCtaLabel : t('SIGN_UP_BUTTON')}
      />
    </Form>
  );
};

const SignUpForm = ({
  handleSubmit,
  error,
  googleBtnLoading,
  googleAuthAction,
  facebookBtnLoading,
  facebookAuthAction,
  onSignInLinkClick,
  signUpCtaLabel,
  loginText,
  loginLinkLabel
}) => {
  const { t } = useTranslation();
  const extendedSignupSchema = getExtendedSignupSchema(i18n);
  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={extendedSignupSchema}
      >
        {props => <RenderSignUpForm {...{ ...props, error, signUpCtaLabel }} />}
      </Formik>

      <Divider>{t('OR_SIGN_UP_WITH')}</Divider>

      <AuthButtons>
        <Row gutter={20}>
          <Col xs={12}>
            <Button
              loading={googleBtnLoading}
              className='google-btn'
              type='primary'
              size='large'
              onClick={googleAuthAction}
            >
              <img src={googleIcon} /> Google
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              loading={facebookBtnLoading}
              className='facebook-btn'
              type='primary'
              size='large'
              onClick={facebookAuthAction}
            >
              <img src={facebookIcon} /> Facebook
            </Button>
          </Col>
        </Row>
      </AuthButtons>
      <BottomInfo>
        <span>{loginText ? loginText : t('SIGN_UP_FOOTER')}</span>
        <span>{'  '}</span>
        <span style={{ cursor: 'pointer' }}>
          <Link className='bottom-link' to={'#'} onClick={onSignInLinkClick}>
            {loginLinkLabel ? loginLinkLabel : t('LOGIN')}
          </Link>
        </span>
      </BottomInfo>
    </Wrapper>
  );
};

export default SignUpForm;
