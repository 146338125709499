export const InstantBookingAdditionalRequirement = {
    FirstName: 'first_name',
    LastName: 'last_name',
    EmailAddress: 'email_address',
    VesselName: 'vessel_name',
    VesselType: 'vessel_type',
    VesselHullType: 'vessel_hull_type',
    PrivateCommercial: 'private_or_commercial',
    DateOfBirth: 'date_of_birth',
    Country: 'country_of_residence',
    Phone: 'phone',
    Address: 'address',
    PostalCode: 'postal_code',
    HomeMarinaName: 'home_marina_name',
    WaterElectricity: 'water_electricity',
    Insurance: 'vessel_insurance',
    License: 'vessel_license',
    Photo:'vessel_photo',
    SkipperId: 'skipper_id',
    VesselHullType: 'vessel_hull_type',
    VesselType: 'vessel_type',
    LiveAboard: 'live_aboard'
}
