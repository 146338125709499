import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const MenuDropdownItemWrapperStyles = css`
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
`;


export const NavbarInnerContainer = styled.nav`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 36px;
  @media only screen and (max-width: 991px) {
    padding: 0px;
    margin: 0px;
  }
`

export const NavbarRightItemsContainer = styled.nav`
  display: flex;
  padding: 0px;
  margin: 0px;
  height: 100%;
  align-items: center;
`

export const NavbarLeftItemsContainer = styled.nav`
  display: flex;
  padding: 0px;
  margin: 0px;
  height: 100%;
  align-items: center;
`

const NavbarWrapper = styled.nav`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 37px 0 25px;
  @media only screen and (max-width: 1600px) {
    padding: 0 24px;
  }
  @media only screen and (max-width: 991px) {
    height: 52px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 16px;
  }

  &.default {
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  .search_close_button {
    display: none;
    color: ${themeGet('color.24', '#8D9AA4')};
    border: 0;
    padding: 0;
    margin-top: 4px;
    width: 16px;
    &:focus {
      outline: none;
    }
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }

  &.transparent {
    position: fixed;
    z-index: 10001;
    @media only screen and (max-width: 991px) {
      position: absolute;
    }
    .nav-link {
      color: ${themeGet('color.1', '#ffffff')};
      &.active {
        border-bottom: 0px;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    min-width: 160px;
    &.hide {
      opacity: 0;
      visibility: hidden;
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
    .ant-menu-item {
      line-height: 24px;
      margin: 0;
      height: auto;
      padding: 10px 20px;
      &.ant-menu-item-selected {
        background-color: ${themeGet('color.1', '#ffffff')};
      }
      &.menu-divider {
        padding: 4px 12px 0;
        .ant-divider {
          margin: 1px;
        }
      }
      > a {
        color: ${themeGet('text.4', '#323232')};
        height: 24px;
        line-height: 24px;
        font-size: 15px;
        display: flex;
        &:hover {
          color: ${themeGet('primary.0', '#194AB9')};
        }
      }
      .logout-btn {
        color: ${themeGet('text.4', '#323232')};
        font-size: 15px;
        border: 0;
        cursor: pointer;
        padding: 0 0 4px;
        width: 100%;
        text-align: left;
        background-color: transparent;
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('primary.0', '#194AB9')};
        }
        &:focus {
          outline: none;
        }
      }
    }
    &.with-icons {
      right: 50%;
      margin-right: -110px;
      min-width: 220px;
      .ant-menu-item {
        padding: 24px;
        border-bottom: 1px solid ${themeGet('border.6', '#f0f0f0')};
        &.ant-menu-item-disabled {
          cursor: pointer;
          border-bottom: 1px solid ${themeGet('border.6', '#f0f0f0')} !important;
          > a {
            .dropdown-menu-icon {
              svg {
                g {
                  stroke: ${themeGet('color.19', '#bfbfbf')};
                }
              }
            }
          }
        }
        &.ant-menu-item-selected {
          background-color: ${themeGet('color.1', '#ffffff')};
          > a {
            color: ${themeGet('primary.0', '#194AB9')};
            svg {
              g,
              path {
                stroke: ${themeGet('primary.0', '#194AB9')};
                &.white {
                  stroke: ${themeGet('color.1', '#ffffff')};
                }
              }
              circle {
                fill: ${themeGet('primary.0', '#194AB9')};
              }
            }
          }
        }
        &:last-child {
          border-bottom: 1px solid transparent;
        }
        > a {
          display: flex;
          .dropdown-menu-icon {
            width: 26px;
            margin-right: 16px;
            &.add-absence {
              margin-top: 3px;
            }
            &.connect-icon {
              margin-top: 4px;
              margin-right: 11px;
              margin-left: 5px;
            }
            &.request-icon {
              margin-top: -1px;
            }
          }
          &:hover {
            svg {
              g,
              path {
                stroke: ${themeGet('primary.0', '#194AB9')};
                &.white {
                  stroke: ${themeGet('color.1', '#ffffff')};
                }
              }
              circle {
                fill: ${themeGet('primary.0', '#194AB9')};
              }
            }
            p {
              color: ${themeGet('primary.0', '#194AB9')};
            }
          }
        }
      }
    }
  }
`;

export const LogoArea = styled.div`
  margin-left: 8px;
  margin-top: 3px;
  @media only screen and (max-width: 991px) {
    margin-left: 0;
    margin-top: 0;
  }
`;

export const MainMenu = styled.div`
  display: flex;
  height: 34px;
  margin-left: 38px;
  @media only screen and (max-width: 1200px) {
    margin-left: 18px;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const MainMenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 8px;
  .dropdown-menu-item {
    ${MenuDropdownItemWrapperStyles};
  }
  .nav-link {
    white-space: nowrap;
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 17px;
    font-whight: 600;
    line-height: 20.4px;
    color: ${themeGet('text.13', '#121a2c')};
    padding: 12px 12px;
    border-radius: 5px;
    .search-label {
      margin-left: 10px;
    }
    &.vacancy {
      font-weight: 600;
      color: ${themeGet('color.1', '#ffffff')};
      background-color: ${themeGet('primary.0', '#00BB5D')};
      height: auto;
      padding: 12px;
      border-radius: 5px;
      &:hover {
        background-color: ${themeGet('primary.2')};
      }
    }
    &:hover {
      background-color: rgba(242, 242, 242);
    }
    &.active {
      border-bottom: 0px;
    }
    i {
      margin-left: 2px;
      font-size: 12px;
    }
  }
  .navbar-search-item {
    a {
      padding: 7px 12px;
    }
  }
  &.transparent {
    .dropdown-menu-item > .nav-link,
    .navbar-search-item > .nav-link,
    > .nav-link {
      color: ${themeGet('color.1', '#ffffff')};
      &.vacancy {
        font-weight: 600;
        color: ${themeGet('text.13', '#121a2c')};
        background-color: ${themeGet('color.1', '#00BB5D')};
        height: auto;
        padding: 12px;
        border-radius: 5px;
        &:hover {
          background-color: rgba(242, 242, 242);
        }
      }
      &:hover {
        background-color: rgba(242, 242, 242, 0.1);
      }
    }
  }
`;

export const AdditionalMenu = styled.div`
  margin-right: 12px;
  margin-left: 12px;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  ${({ searchVisibility }) => {
    return searchVisibility
      ? css`
          @media only screen and (max-width: 991px) {
            display: none;
          }
        `
      : css``;
  }}
  &.transparent {
    .additional-menu-wrapper {
      background-color: transparent;
      @media only screen and (max-width: 991px) {
        border: 1px solid transparent;
        background-color: transparent;
      }
      .additional-menu-trigger {
        &:hover {
          background-color: rgba(242, 242, 242, 0.1);
        }
      }
    }
    .additional-menu-trigger {
      color: ${themeGet('color.1', '#ffffff')};
      svg {
        .affect-class {
          &.fill {
            fill: ${themeGet('color.1', '#ffffff')};
          }
          &.stroke {
            stroke: ${themeGet('color.1', '#ffffff')};
          }
        }
      }
      &:hover {
        color: ${themeGet('color.1', '#ffffff')};
        .additional-menu-trigger-icon {
          svg {
            .affect-class {
              &.fill {
                fill: ${themeGet('color.1')};
              }
              &.stroke {
                stroke: ${themeGet('color.1')};
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    margin-right: 0;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

export const AdditionalMenuWrapper = styled.div`
  height: 48px;
  background-color: ${themeGet('color.1', '#ffffff')};
  display: flex;
  align-items: center;
  @media only screen and (max-width: 991px) {
    border: 1px solid transparent;
    background-color: transparent;
    height: 100%;
    .dropdown-menu-item {
      &.memberships-dropdown-menu,
      &.languages-dropdown-menu {
        margin-left: 10px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .dropdown-menu-item {
      &.languages-dropdown-menu {
        margin-left: 0;
      }
    }
  }
`;

export const AuthenticaionMenu = styled.div`
  height: 100%;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  .ant-spin {
    color: ${themeGet('color.26', '#b3cbe1')} !important;
    &.transparent {
      color: ${themeGet('color.26', '#b3cbe1')} !important;
    }
  }
`;
export const AuthMenuWrapper = styled.div`
  margin-right: 7px;
  margin-left: 8px;
  height: 100%;
  ul.ant-menu {
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 0;
    background: transparent;
    li {
      height: auto;
      margin-top: 8px;
      margin-bottom: 8px;
      padding: 0;
      &:active {
        background-color: transparent;
      }
      a {
        display: flex;
        height: 44px;
        width: 74px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        color: ${themeGet('color.1', '#ffffff')};
        font-size: 17px;
        line-height: 20.4px;
        border-radius: 4px;
        transition: color 0.2s ease-in-out;
      }
      &:first-child {
        a {
          color: ${themeGet('text.13', '#121a2c')};
          border: 1px solid;
          border-color: ${themeGet('text.13', '#121a2c')};
          transition: opacity 0.2s ease;
          margin-right: 10px;
          &:hover {
            background-color: rgba(242, 242, 242);
          }
        }
      }
      &:last-child {
        a {
          width: 80px;
          color: ${themeGet('color.1', '#ffffff')};
          background-color: ${themeGet('primary.0', '#66bdff')};
          border: 1px solid;
          border-color: ${themeGet('primary.0', '#66bdff')};
          transition: opacity 0.2s ease;
          &:hover {
            background-color: ${themeGet('primary.2')};
          }
        }
      }
    }
  }
  &.transparent {
    ul.ant-menu {
      li:first-child {
        a {
          color: ${themeGet('text.13', '#121a2c')};
          background-color: ${themeGet('color.1', '#ffffff')};
          border: 0px;
          &:hover {
            background-color: rgba(242, 242, 242);
          }
        }
      }
      li:last-child {
        a {
          color: ${themeGet('color.1', '#ffffff')};
          background-color: transparent;
          border: 1px solid;
          border-color: ${themeGet('color.1', '#ffffff')};
          &:hover {
            background-color: rgba(242, 242, 242, 0.1);
          }
        }
      }
    }
  }
`;

export const ProfileMenuWrapper = styled.div`
  height: 100%;
  .dropdown-menu-item {
    ${MenuDropdownItemWrapperStyles};
  }
`;

export const Trigger = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${themeGet('text.13', '#121a2c')};
  transition: all 0.3s ease;
  margin-right: 15px;
  .chevron-down {
    margin-left: 5px;
    margin-top: 1px;
  }
  &:hover {
    color: ${themeGet('primary.0', '#194AB9')};
    .additional-menu-trigger-icon {
      svg {
        .affect-class {
          &.fill {
            fill: ${themeGet('text.13', '#121a2c')};
          }
          &.stroke {
            stroke: ${themeGet('text.13', '#121a2c')};
          }
        }
      }
    }
  }
  .additional-menu-trigger-icon {
    svg {
        &.fill {
          fill: ${themeGet('text.13', '#121a2c')};
        }
        &.stroke {
          stroke: ${themeGet('text.13', '#121a2c')};
        }
    }
  }
  &.transparent {
    &:hover {
      color: ${themeGet('color.26', '#b3cbe1')};
      .additional-menu-trigger-icon {
        svg {
          .affect-class {
            &.fill {
              fill: ${themeGet('color.26', '#b3cbe1')};
            }
            &.stroke {
              stroke: ${themeGet('color.26', '#b3cbe1')};
            }
          }
        }
      }
    }
  }
`;

export const IconWrapper = styled.div`
  position: relative;
  top: 3px;
`;

export const EnvBanner = styled.div`
  margin-right: 10px;
  margin-left: 10px;
  color: ${themeGet('color.1', '#ffffff')};
  padding: 5px 40px;
  font-size: 20px;
  font-weight: bold;
  background-color: ${themeGet('success.0', '#00BB5D')};
  border-radius: 3px;

  @media only screen and (max-width: 1200px) {
    padding: 5px 20px;
  }

  @media only screen and (max-width: 991px) {
    margin-left: 20px;
    font-size: 18px;
    padding: 0 30px;
  }
`;

export const HeaderSearchBarContainer = styled.div`
  display: none;
`;


export default NavbarWrapper;
