import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { I18nextProvider } from 'react-i18next';
import {Provider as StoreProvider} from "react-redux";
import theme from 'themes/default.theme';
import GlobalStyles from 'assets/style/Global.style';
import CookieProvider from 'context/CookieProvider';
import LayoutProvider from 'context/LayoutProvider';

import EmailVerificationProvider from 'context/EmailVerificationProvider';
import GlobalProvider from 'context/GlobalProvider';
import AuthProvider from 'context/AuthProvider';
import ReservationProvider from 'context/ReservationProvider';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import i18n from './i18n';
import Routes from './router';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'antd/dist/antd.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import firebaseService from 'services/firebaseService.js';
import errorhandler from 'services/errorHandler';
import * as serviceWorker from './serviceWorker';
import Helmet from './helmet';
import AbsenceReportProvider from './context/AbsenceReportProvider';
import { localeOptions } from 'settings/locales';
import StackTrace from 'stacktrace-js';
import PopeyeProvider from 'context/PopeyeProvider';
import store from './store'

import hotjar, { startTrackingHotjar } from 'services/hotjar'; // do not remove importing starts the tracker
import pixel, { startTrackingPixel } from 'services/facebookPixel'; // do not remove importing starts the tracker

startTrackingHotjar();
startTrackingPixel();

console.log(`%c   ⛵ Pick a Pier Boaters - %cv.${process.env.REACT_APP_VERSION} ⛵   `,
    'color: #194AB9; background: white;', 'color: #194AB9; background: white; font-weight: bold;');

const langsRegxp = localeOptions.join('|');
class ErrorBoundary extends React.Component {
  state = {
    errorMessage: ''
  };
  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }
  componentDidCatch(error, info) {
    try {
      this.logErrorToServices(error.toString(), info.componentStack);

      const trace = stackFrames => {
        let errorStackTrace = stackFrames
          .map(sf => {
            return '# ' + sf.toString();
          })
          .slice(0, 10)
          .join('\n');

        errorhandler.sendSlackCrashReport({
          url: window.location.href,
          email:
            firebaseService.auth && firebaseService.auth.currentUser
              ? firebaseService.auth.currentUser.email
              : 'Guest',
          errorStackTrace: this.state.errorMessage + '\n\n' + errorStackTrace,
          agent: navigator.userAgent
        });
      };

      StackTrace.fromError(error)
        .then(trace)
        .catch(err =>
          errorhandler.sendCrashErrorMessage({
            url: window.location.href,
            email:
              firebaseService.auth && firebaseService.auth.currentUser
                ? firebaseService.auth.currentUser.email
                : 'Guest',
            errorStackTrace: this.state.errorMessage + '\n\n' + err.message,
            agent: navigator.userAgent
          })
        );

      const errorDetails = {
        error: error.toString(),
        info: info.componentStack.split('\n').slice(0, 2).join('\n'),
        id:
          firebaseService.auth && firebaseService.auth.currentUser
            ? firebaseService.auth.currentUser.uid
            : null
      };
      // firebaseService.analytics && firebaseService.analytics.logEvent('boater_crash', errorDetails)
    } catch (error) {
      console.error('cannot log crash: ', error);
    }
  }
  // A fake logging service 😬
  logErrorToServices = console.log;

  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <div style={{ padding: 20 }}>
          <br />
          <br />
          <p>
            Oops! An error occured. Please try{' '}
            <a href='#' onClick={() => window.location.reload()}>
              refreshing the page{' '}
            </a>{' '}
            or going back to the <a href='/'>main page</a>
          </p>
          <p>
            <a
              href={
                'data:text/plain;charset=utf-8,' +
                encodeURIComponent(this.state.errorMessage)
              }
              download='error.log'
            >
              Download error log
            </a>
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ErrorBoundary>
          <I18nextProvider i18n={i18n}>
            <StoreProvider store={store}>
            <AuthProvider>
              <GlobalProvider>
                <LayoutProvider>
                  <Helmet />
                  <ReservationProvider>
                    <AbsenceReportProvider>
                      <CookieProvider>
                        <EmailVerificationProvider>
                          <ScrollToTop />
                          <PopeyeProvider>
                              <Routes />
                          </PopeyeProvider>
                        </EmailVerificationProvider>
                      </CookieProvider>
                    </AbsenceReportProvider>
                  </ReservationProvider>
                </LayoutProvider>
              </GlobalProvider>
            </AuthProvider>
            </StoreProvider>
          </I18nextProvider>
        </ErrorBoundary>
    </ThemeProvider>
  );
};

const BasicComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`/:locale(${langsRegxp})`} component={App} />
        <Route path='/' component={App} />
      </Switch>
    </BrowserRouter>
  );
};

const rootElement = document.getElementById('root');

// if (rootElement.hasChildNodes()) {
// Array.prototype.forEach.call(document.querySelectorAll('link[rel=stylesheet]'), function(element){
//   try{
//     element.href.endsWith('chunk.css') && element.parentNode.removeChild(element);

//   }catch(err){}
// });

// //or this is similar
// var elements = document.querySelectorAll('link[rel=stylesheet]');
// for(var i=0;i<elements.length;i++){
//   elements[i].parentNode.removeChild(elements[i]);
// }
// ReactDOM.hydrate(<BasicComponent />, rootElement);
// } else {
ReactDOM.render(<BasicComponent />, rootElement);
// }

serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     registration.unregister().then(() => {
//     setTimeout(() => {
//       try {
//         if (!window.location.href.endsWith('registration')) {
//           window.alert('new version detected, reloading...')
//           window.location.reload()
//         }
//       } catch (err) {
//         console.error(err)
//       }
//     }, 200);
//   })
//  },
//  onSuccess: registration => {
//    console.info('service worker on success state')
//    console.log(registration)
//   },
// });
