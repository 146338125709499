import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Menu from 'components/UI/Antd/Menu/Menu';
import { LOGIN_PAGE, REGISTRATION_PAGE } from 'settings/constant';

const AuthMenu = ({ className, match, location }) => {
  const { t } = useTranslation();

  return (
    <Menu className={className}>
      <Menu.Item key='0'>
        <NavLink
          to={{
            pathname: `${match.url}${LOGIN_PAGE}`,
            state: { from: location.pathname, ref: 'header' }
          }}
        >
          {t('LOGIN')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key='1'>
        <NavLink
          to={{
            pathname: `${match.url}${REGISTRATION_PAGE}`,
            state: { from: location.pathname, ref: 'header' }
          }}
        >
          {t('SIGN_UP_BUTTON')}
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(AuthMenu);
