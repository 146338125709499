import React, { useContext, useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import i18n from '../../i18n';

import { AuthContext } from 'context/AuthProvider';
import { EmailVerificationContext } from 'context/EmailVerificationProvider';
import { PopeyeContext } from 'context/PopeyeProvider';

import Spinner from 'components/Spinner/Spinner';
import RenderEmailVerificationForm from 'components/EmailVerification/RenderEmailVerificationForm';
import { getStateFromUrl, setStateToUrl } from 'library/helpers/url_handler';
import boaterService from 'services/boater.js';
import firebaseService from 'services/firebaseService.js';
import {
  PROFILE_PAGE,
  AGENT_MEMBERSHIPS,
  ABSENCE_REPORTS_PAGE,
  POPEYE_WELCOME_PAGE
} from 'settings/constant';
import useAmplitude from 'library/hooks/useAmplitude';

const initialValues = {
  digit_0: '',
  digit_1: '',
  digit_2: '',
  digit_3: ''
};

export default withRouter(({ history, match, location, onCloseModal }) => {
  const { t } = useTranslation();
  const searchParams = getStateFromUrl(location);

  const {
    signUp,
    loggedIn,
    emailVerified,
    waitForUserLoggedIn,
    refreshData,
    user
  } = useContext(AuthContext);
  const { activatePopeye } = useContext(PopeyeContext);
  const { setEmailConfirmed, modalCloseCallback } = useContext(
    EmailVerificationContext
  );

  const [error, setError] = useState('');
  const [isVerificationProcess, setIsVerificationProcess] = useState(false);
  const [verified, setVerified] = useState(false);
  const { sendToAmplitude } = useAmplitude();

  useEffect(() => {
    const init = async () => {
      let pathname = `/${match.params.locale}`;
      if (searchParams.type === 'new_customer') {
        pathname = `/${match.params.locale}${PROFILE_PAGE}${AGENT_MEMBERSHIPS}`;
        const search = setStateToUrl({ connect_membership: true });
        setTimeout(() => history.push({ pathname, search }), 400);
      } else if (searchParams.type === 'absence_inquiry') {
        pathname = `/${match.params.locale}${ABSENCE_REPORTS_PAGE}`;
        const search = setStateToUrl({ open_report: true });
        setTimeout(() => history.push({ pathname, search }), 400);
      } else {
        setTimeout(() => history.push(`/${match.params.locale}${POPEYE_WELCOME_PAGE}`), 400);
      }
    };
    if (
      loggedIn &&
      (emailVerified || verified) &&
      isEmpty(modalCloseCallback)
    ) {
      init();
    }
  }, [loggedIn, emailVerified, verified]);

  useEffect(() => {
    return () => {
      setIsVerificationProcess(false);
    };
  }, []);

  const handleSubmit = async (
    formProps,
    { setSubmitting, setErrors, setStatus, resetForm }
  ) => {
    const code = `${formProps.digit_0}${formProps.digit_1}${formProps.digit_2}${formProps.digit_3}`;
    setIsVerificationProcess(true);
    setSubmitting(true);
    const result = await boaterService.verify_email({ code });
    setSubmitting(false);
    setIsVerificationProcess(false);
    if (!result || result.error || result.error_type === 'UNAUTHORIZED') {
      resetForm({});
      setError(true);
    } else {
      if (onCloseModal) {
        onCloseModal();
      }
      Cookies.set(encodeURIComponent(user.email), 'verified', {
        expires: 365 * 10
      });
      localStorage.setItem('emailVerifiedDate', new Date());
      setVerified(true);
      setEmailConfirmed(true);
      await activatePopeye();
      await refreshData({ email_verification: true, parameters: ['boater'] });
      sendToAmplitude({
        eventProperties: {},
        eventName: 'email_verified'
      });
      if (!isEmpty(modalCloseCallback) && modalCloseCallback.cb) {
        modalCloseCallback.cb();
      }
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={{ ...initialValues, ...searchParams }}
        onSubmit={handleSubmit}
      >
        {props => <RenderEmailVerificationForm {...{ ...props, error }} />}
      </Formik>
      <Spinner
        spinning={isVerificationProcess}
        tip={t('SIGNING_UP_SPINNER_TEXT')}
      />
    </Fragment>
  );
});
