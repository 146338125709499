import { post, get } from './api';

export default {
  invite: async function (data) {
    return await post('/boater/popeye-invite', data);
  },
  activate: async function (data) {
    return await post('/boater/popeye-activate', data);
  },
  order: async function (data) {
    return await post('/popeye/order-welcome-kit', data);
  },
  statistic: async function (data) {
    return await get('/loyalty/stats', data);
  },
  history: async function (data) {
    return await get('/loyalty/log', data);
  },
  allLive: async function () {
    return await get('/loyalty/live_promotions');
  },
  marinaPromotions: async function (data) {
    return await post('/loyalty/get_marina_promotions', data);
  },
  allmarinasPromotions: async function () {
    return await get('/loyalty/get_all_promotions');
  }
};
