import { createSlice } from "@reduxjs/toolkit";
import { AlertSeverity } from '../../../settings/enums';

const initialState = {
    ready: false,
    loading: false,
    background: true,
    redirectUrlProvided: false,
    iframe: false
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        initialize(state) {
            state.ready = false;
            state.loading = true;
        },
        initializeForMarina(state) {
            state.ready = false;
            state.loading = true;
        },
        initializeSuccess(state) {
            state.ready = true;
            state.loading = false;
        },
        initializeFailed(state, action) {
            state.ready = true;
            state.loading = false;
            state.message = {message: action.payload.message, severity: AlertSeverity.Error};
        },
        setEnableBackground(state, action) {
            state.background = action.payload;
            sessionStorage.setItem('background', `${action.payload}`);
        },
        setMarinaId(state, action) {
            state.marinaId = action.payload;
            sessionStorage.setItem('marinaId', `${action.payload}`);
        },
        setRedirectUrl(state, action) {
            state.redirectUrl = action.payload;
            sessionStorage.setItem('redirectUrl', action.payload);
        },
        setRedirectUrlProvided(state, action) {
            state.redirectUrlProvided = action.payload;
            sessionStorage.setItem('redirectUrlProvided', action.payload);
        },
        setMarina(state, action) {
            state.marina = action.payload;
            sessionStorage.setItem('marina', JSON.stringify(action.payload));
        },
        setMarinas(state, action) {
            state.marinas = action.payload;
            sessionStorage.setItem('marinas', JSON.stringify(action.payload));
        },
        setMarinaData(state, action) {
            state.marinaData = action.payload;
            sessionStorage.setItem('marinaData', JSON.stringify(action.payload));
        },
        setAppLoading(state, action) {
            state.loading = action.payload;
        },
        setAppReady(state, action) {
            state.ready = action.payload;
        },
        setIframe(state, action) {
            state.iframe = action.payload;
        }
    }
});

export const appActions = appSlice.actions;

export const appReducer = appSlice.reducer;

export default appSlice;
