import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import find from 'lodash/find';
import moment from 'moment';
import i18n from '../../../i18n';
import { AuthContext } from 'context/AuthProvider';
import { GlobalContext } from 'context/GlobalProvider';
import { PopeyeContext } from 'context/PopeyeProvider';
import Modal from 'components/UI/Antd/Modal/Modal';
import RenderAbsenceReportForm from './RenderAbsenceReportForm';
import absenceReportService from 'services/absenceReport';
import { getStateFromUrl } from 'library/helpers/url_handler';
import {
  getAbsenceReportSchema,
  getAbsenceReportSchemaEdit
} from 'library/helpers/formUtils';
import { getUpdatedFieldsLabels } from 'library/helpers/amplitude';
import { message } from 'antd';
import useAmplitude from 'library/hooks/useAmplitude';
import ReactPixel from 'services/facebookPixel';
import { generateID, isUnverifiedMarina } from 'library/helpers/utils';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog'

const checkEqual = (initValues, newValues) => {
  let initClone = { ...initValues };
  let newClone = {
    ...newValues,
    departure_date: moment(newValues.departure_date).format('YYYY-MM-DD'),
    return_date: moment(newValues.return_date).format('YYYY-MM-DD')
  };

  delete initValues.completed;
  delete initValues.home_marina_id;
  delete initValues.deleted;
  delete initClone.id;
  delete newClone.absence_report_id;
  delete newClone.home_marina_id;

  const initKeys = Object.keys(initClone);
  const newKeys = Object.keys(newClone);
  for (let key of initKeys) {
    if (initClone[key] !== newClone[key]) {
      return false;
    }
  }
  return true;
};

const AbsenceReportModal = ({
  className,
  visible,
  editValues,
  onCancel,
  location,
  afterSubmit,
  popeye
}) => {
  const { t } = useTranslation();
  const absenceReportSchema = getAbsenceReportSchema(i18n);
  const absenceReportSchemaEdit = getAbsenceReportSchemaEdit(i18n);

  const {
    user,
    getStatisticsForBoater,
    selectBoaterVessel,
    selectedBoaterVessel
  } = useContext(AuthContext);
  const { homeMarinas } = useContext(GlobalContext);
  const { isPopeyeMember, isPreSignatureStatus } = useContext(PopeyeContext);

  const [options, setOptions] = useState(
    homeMarinas.reduce((obj, { home_marina_id, home_marina_name }) => {
      if (home_marina_id) {
        return {
          ...obj,
          [home_marina_id]: home_marina_name
        };
      }

      // generateID
      const id = generateID(home_marina_name);

      return {
        ...obj,
        [id]: home_marina_name
      };
    }, {})
  );
  const [changed, setChanged] = useState(false)
  const [showOnCloseDialog, setShowOnCloseDialog] = useState(false)
  const isEdit = !isEmpty(editValues);

  // initial data
  const { vessels } = user;
  const currentVessel = vessels.filter(
    ({ id }) => id === selectedBoaterVessel
  )[0];
  const homeMarinaVerified = currentVessel?.home_marina_verified ? currentVessel?.home_marina_verified : false;

  const reasons = {
    '': '',
    INTERNATIONAL_VACATION: t('INTERNATIONAL_VACATION_REASON'),
    DOMESTIC_VACATION: t('DOMESTIC_VACATION_REASON'),
    MAINTENANCE: t('MAINTENANCE_REASON'),
    OTHER: t('OTHER_REASON')
  };

  // vessels
  const vesselOptions = vessels.map(({ id, name }) => ({
    label: name || t('VESSEL_NAME_UNNAMED'),
    value: id
  }));

  const getInitialHomeMarinaId = () => {
    if (isEdit) {
      if (editValues?.home_marina_id) return editValues?.home_marina_id;
      else if (editValues?.unverified_marina_name)
        return generateID(editValues?.unverified_marina_name);
    }

    if (currentVessel?.home_marina_id) return currentVessel?.home_marina_id;
    else if (currentVessel?.home_marina_name)
      return generateID(currentVessel?.home_marina_name);

    return null;
  };

  const initialValues = {
    home_marina_id: getInitialHomeMarinaId(),
    unverified_marina_name: isEdit
      ? editValues?.unverified_marina_name
      : isNil(currentVessel?.home_marina_id) && currentVessel?.home_marina_name,
    vessel_id: currentVessel?.id,
    reason: isEdit ? editValues.reason : '',
    destination: isEdit ? editValues.destination : '',
    lat: isEdit ? editValues.lat : null,
    lng: isEdit ? editValues.lng : null,
    departure_date: isEdit ? editValues.departure_date : '',
    return_date: isEdit ? editValues.return_date : ''
  };
  // states
  const [values, setValues] = useState({});
  const [selected, setSelected] = useState(null);
  const [focusedDateInput, setFocusedDateInput] = useState(null);
  const [startDateInvalid, setStartDateInvalid] = useState(false);
  const [endDateInvalid, setEndDateInvalid] = useState(false);
  const [absenceDate, setAbsenceDate] = useState({
    setStartDate: null,
    setEndDate: null
  });
  const { sendToAmplitude } = useAmplitude();

  useEffect(() => {
    const {
      destination,
      reason,
      departure_date,
      return_date,
      lat,
      lng,
      vessel_id
    } = editValues;
    /**
     * On auto absence report - it shouldn't appear in the list as reason.
     */
    setValues({
      ...initialValues,
      reason: reason && reason !== 'AUTO_ABSENCE_REPORT' ? reason : '',
      destination: destination || '',
      lat: lat || null,
      lng: lng || null
    });
    setSelected(currentVessel?.home_marina_id);
    if (departure_date && return_date) {
      setAbsenceDate({
        setStartDate: moment.utc(departure_date, 'YYYY-MM-DD'),
        setEndDate: moment.utc(return_date, 'YYYY-MM-DD'),
        departure_date,
        return_date
      });
    }
  }, [user, editValues, visible, user.vessels]);

  const onChangeDate = setDateValue => {
    if (
      setDateValue.setStartDate !== null &&
      setDateValue.setEndDate !== null
    ) {
      setFocusedDateInput(null);
    }
    setStartDateInvalid(false);
    setEndDateInvalid(false);
    const departure_date = moment(
      setDateValue.setStartDate,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD');
    const return_date = moment(setDateValue.setEndDate, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    setAbsenceDate({ ...setDateValue, departure_date, return_date });
    setChanged(true);
  };

  const isDateValid = () => {
    if (!absenceDate.setStartDate) {
      setStartDateInvalid(true);
      setFocusedDateInput('startDate');
      return false;
    }
    if (!absenceDate.setEndDate) {
      setEndDateInvalid(true);
      setFocusedDateInput('endDate');
      return false;
    }
    return true;
  };

  const onSubmit = async (formProps, { setErrors, resetForm }) => {
    if (!isDateValid()) {
      return false;
    }

    const { reason, destination, home_marina_id, vessel_id, lat, lng } =
      formProps;

    let home_marina = {};
    let unverified_marina_name;
    const _isUnverifiedMarina = isUnverifiedMarina(home_marina_id);
    if (_isUnverifiedMarina) {
      unverified_marina_name = `${home_marina_id}`.split('_')[1];
      home_marina = { unverified_marina_name };
    } else {
      home_marina = { home_marina_id };
    }
    let data = {
      departure_date: moment(absenceDate.setStartDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      return_date: moment(absenceDate.setEndDate, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      reason,
      destination,
      geo_location: lat && lng ? { lat, lng } : {},
      ...home_marina,
      vessel_id
    };

    if (isEdit) {
      data = { ...data, absence_report_id: editValues.id };
    }

    const recievedFormValues = pick(editValues, [
      'reason',
      'destination',
      'departure_date',
      'return_date'
    ]);
    const editedFormValues = pick(data, [
      'reason',
      'destination',
      'departure_date',
      'return_date'
    ]);

    if (isEdit && checkEqual(recievedFormValues, editedFormValues)) {
      onClose();
    } else {
      try {
        const method = isEdit ? 'update' : 'create';
        const res = await absenceReportService[method](data);
        if (res && res.error_code && res.error_code === 'VACANCY_COLLISION') {
          message.error(t('DUPLICATE_VACANCY_ERROR'));
          return Promise.reject();
        } else if (res && res.error_type) {
          message.error(t('ERROR_UPDATING_DETAILS'));
          return Promise.reject();
        } else {
          // to open the result dialog
          afterSubmit({
            home_marina_id,
            unverified_marina_name:
              _isUnverifiedMarina && unverified_marina_name,
            isEdit,
            is_popeye_member: isPopeyeMember || isPreSignatureStatus,
            res
          });
          // reset
          resetForm();
          // Amplitude event
          const marina = find(homeMarinas, {
            id: _isUnverifiedMarina ? home_marina_id : +home_marina_id
          });
          getStatisticsForBoater().then(statistics => {
            const boaterProperties = { boater: user, statistics };

            const total_nights = moment(data.return_date).diff(
              data.departure_date,
              'days'
            );
            let absenceInformation = {};

            const today = moment().format('YYYY-MM-DD');

            switch (method) {
              case 'create':
                let source;
                if (
                  location.search &&
                  location.search.includes('absence_inquiry_id')
                ) {
                  source = 'marina_inquiry';
                } else {
                  source = 'manual';
                }

                absenceInformation = {
                  absence_report_id: res.id,
                  total_nights,
                  popeye_points: isPopeyeMember || isPreSignatureStatus
                    ? res?.popeye?.number_of_points
                    : null,
                  reason: data.reason ? data.reason : '-',
                  destination: data.destination ? data.destination : '-',
                  marina_name: options[home_marina_id],
                  marina_country: !_isUnverifiedMarina ? marina.country : '-',
                  days_before_start: moment(data.departure_date).diff(
                    today,
                    'days'
                  ),
                  source
                };
                break;
              case 'update':
                absenceInformation = {
                  // absence_report_id: editValues.absence_report_id,
                  absence_report_id: res.id,
                  total_nights,
                  popeye_points: isPopeyeMember || isPreSignatureStatus
                    ? res?.popeye?.number_of_points
                    : null,
                  reason: data.reason ? data.reason : '-',
                  destination: data.destination ? data.destination : '-',
                  marina_name: options[home_marina_id],
                  marina_country: marina.country ? marina.country : '',
                  updated_fields: getUpdatedFieldsLabels({
                    data,
                    initialValues,
                    action: 'updated_absence_report'
                  }),
                  days_before_start: moment(data.departure_date).diff(
                    today,
                    'days'
                  )
                };
            }

            const amplitudeEventName =
              method === 'create' ? 'vacancy_created' : 'vacancy_updated';
            sendToAmplitude({
              boaterProperties,
              eventProperties: absenceInformation,
              eventName: amplitudeEventName
            });

            try {
              ReactPixel.trackCustom('cSubmitAbsenceReport'); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
            } catch (e) {
              console.error(e);
            }
          });

          // reset data
          setAbsenceDate({
            setStartDate: null,
            setEndDate: null
          });
          setValues(initialValues);
          setSelected(null);
          return Promise.resolve();
        }
      } catch (err) {
        console.error(err);
        return Promise.reject();
      }
    }
  };

  const onClose = () => {
    // reset data
    setAbsenceDate({
      setStartDate: null,
      setEndDate: null
    });
    setValues(initialValues);
    setShowOnCloseDialog(false);
    setChanged(false);
    // close modal
    onCancel();
  };

  const handleClose = () => {
    if (changed || (editValues.destination && editValues.destination !== '')) {
      setShowOnCloseDialog(true);
    } else {
      onClose();
    }
  }

  const onChangeVessel = (vessel_id, formProps) => {
    selectBoaterVessel(vessel_id);
    const currentVessel = vessels.find(({ id }) => id === vessel_id);
    formProps.setFieldValue('home_marina_id', currentVessel.home_marina_id);
    setSelected(currentVessel.home_marina_id);
    setChanged(true);
  };

  const handleAddNewHomeMarina = newHomeMarina => {
    const newOptions = { ...options };
    newOptions[`_${newHomeMarina}`] = newHomeMarina;
    setOptions(newOptions);
    setChanged(true);
  };

  useEffect(() => {
    if (!isNil(location)) {
      const state = getStateFromUrl(location);
      const { absence_inquiry_id, vessel_id } = state;
      const currentVessel = vessels.find(({ id }) => id === vessel_id);
      if (!isNil(currentVessel)) {
        const { absence_inquiries } = currentVessel;

        if (!isEmpty(absence_inquiries)) {
          const absence_inquiry = find(absence_inquiries, {
            id: absence_inquiry_id
          });

          if (!isNil(absence_inquiry)) {
            const { desired_departure_date, marina_id } = absence_inquiry;
            if (
              !isNil(currentVessel) &&
              currentVessel.home_marina_id === marina_id
            ) {
              selectBoaterVessel(vessel_id);
              setAbsenceDate({
                setStartDate: moment.utc(desired_departure_date, 'YYYY-MM-DD'),
                setEndDate: null
              });
            }
          }
        }
      }
    }
  }, [location]);

  const hasDifferentHomeMarina = currentVessel?.home_marina_id
    ? currentVessel?.home_marina_id !== initialValues?.home_marina_id
    : currentVessel?.home_marina_name
      ? currentVessel?.home_marina_name !== initialValues?.unverified_marina_name
      : true;

  return (
    <>
      <Modal
        wrapClassName={ `absence-report-modal ${className}` }
        width={ 450 }
        title={
          isEdit
            ? editValues.completed || editValues.deleted
              ? t('VIEW_ABSENCE_REPORT_TITLE')
              : t('EDIT_ABSENCE_REPORT_TITLE')
            : t('NEW_ABSENCE_REPORT_TITLE')
        }
        visible={ visible }
        centered={ true }
        onCancel={ handleClose }
        footer={ null }
        destroyOnClose={ true }
        maskClosable={ true }
        closeIcon={ <MdClose size={ 24 } /> }
        zIndex={ 10001 }
      >
        <Formik
          enableReinitialize
          initialValues={ values }
          onSubmit={ onSubmit }
          validationSchema={
            isEdit ? absenceReportSchemaEdit : absenceReportSchema
          }
        >
          { props => (
            <RenderAbsenceReportForm
              { ...props }
              user={ user }
              isEdit={ isEdit }
              deleted={ editValues.deleted }
              completed={ editValues.completed }
              onChangeDate={ onChangeDate }
              focusedDateInput={ focusedDateInput }
              startDateInvalid={ startDateInvalid }
              endDateInvalid={ endDateInvalid }
              date={ absenceDate }
              options={ options }
              vesselOptions={ vesselOptions }
              reasons={ reasons }
              initialValues={ initialValues }
              onSelect={ id => setSelected(id) }
              onChange={ value => { setSelected(null); setChanged(true) } }
              onChangeVessel={ id => onChangeVessel(id, props) }
              homeMarinaVerified={ homeMarinaVerified }
              onAddNewHomeMarina={ handleAddNewHomeMarina }
              hasDifferentHomeMarina={ hasDifferentHomeMarina }
              setChanged={ setChanged }
            />
          ) }
        </Formik>
      </Modal>

      <ConfirmDialog
        visible={ showOnCloseDialog }
        description={ t('CLOSE_POPUP_BODY') }
        onClose={ () => setShowOnCloseDialog(false) }
        onConfirm={ () => onClose() }
        title={ t('CLOSE_POPUP_TITLE') }
        confirmLabel={ t('CLOSE_POPUP_CONFIRM') }
        cancelLabel={ t('CLOSE_POPUP_CANCEL') }
      />
    </>
  );
};

export default AbsenceReportModal;
