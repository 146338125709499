import React, { Fragment, useRef, useEffect } from 'react';
import _ from 'lodash';
import boaterService from 'services/boater';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'formik';
import { AntInput } from 'components/UI/Antd/AntdInputWithFormik';
import ButtonWithAsync from 'components/UI/ButtonWithAsync/ButtonWithAsync';
import Button from 'components/UI/Antd/Button/Button';
import FormWrapper, {
  ErrorLabel,
  Resend,
  CodeFieldsWrapper,
  CodeFieldsInner,
  FieldWrapper
} from './EmailVerificationForm.style';
import { Link } from 'react-router-dom';
import { message } from 'antd';

const AsyncButton = ButtonWithAsync(Button);

const RenderEmailVerificationForm = props => {
  const { t } = useTranslation();
  const { values, submitCount, submitForm, error } = props;
  const digit0Ref = useRef(null);
  const digit1Ref = useRef(null);
  const digit2Ref = useRef(null);
  const digit3Ref = useRef(null);

  useEffect(() => {
    if (digit0Ref.current) {
      digit0Ref.current.focus()
    }
  }, []);

  const onChange = (ref, val) => {
    if (val) {
      ref.current.focus();
    }
  };

  const resendEmail = async () => {
    try {
      const result = await boaterService.send_email_verification();
      message.success(t('FORGOT_SENDMAIL_SUCCESS'));
    } catch (err) {
      message.error('Send email failed');
    }
  };

  const pressPhoneNumberKeyCondition = code =>
    (code < 48 || code > 57) && code !== 8 && code !== 43 && code !== 45;
  const handlePhoneNumberKeyPress = (e, value, ref )=> {
    if (pressPhoneNumberKeyCondition(e.keyCode)) {
      e.preventDefault();
    } else if ((!value || value === '') && e.keyCode === 8 && ref) { 
      ref.current.focus();
    }
  };

  return (
    <FormWrapper>
      <Form>
        <CodeFieldsWrapper>
          <CodeFieldsInner>
            <FieldWrapper className={error ? 'error' : ''}>
              <Field
                className='input-field'
                type='text'
                component={AntInput}
                name='digit_0'
                innerRef={digit0Ref}
                onKeyDown={e => handlePhoneNumberKeyPress(e, values.digit_0)}
                onInputChange={val => onChange(digit1Ref, val)}
                maxLength={1}
                pattern='[+-]?\d+(?:[.,]\d+)?'
                submitCount={submitCount}
              />
            </FieldWrapper>

            <FieldWrapper className={error ? 'error' : ''}>
              <Field
                className='input-field'
                type='text'
                component={AntInput}
                name='digit_1'
                innerRef={digit1Ref}
                onKeyDown={e => handlePhoneNumberKeyPress(e, values.digit_1, digit0Ref)}
                onInputChange={val => onChange(digit2Ref, val)}
                maxLength={1}
                pattern='[+-]?\d+(?:[.,]\d+)?'
                size='small'
                submitCount={submitCount}
                value={values.digit_1}
              />
            </FieldWrapper>

            <FieldWrapper className={error ? 'error' : ''}>
              <Field
                className='input-field'
                type='text'
                component={AntInput}
                name='digit_2'
                maxLength={1}
                innerRef={digit2Ref}
                onKeyDown={e => handlePhoneNumberKeyPress(e, values.digit_2, digit1Ref)}
                onInputChange={val => onChange(digit3Ref, val)}
                pattern='[+-]?\d+(?:[.,]\d+)?'
                submitCount={submitCount}
                value={values.digit_2}
              />
            </FieldWrapper>

            <FieldWrapper className={error ? 'error' : ''}>
              <Field
                className='input-field'
                type='text'
                component={AntInput}
                name='digit_3'
                maxLength={1}
                innerRef={digit3Ref}
                onKeyDown={e => handlePhoneNumberKeyPress(e, values.digit_3, digit2Ref)}
                pattern='[+-]?\d+(?:[.,]\d+)?'
                submitCount={submitCount}
                value={values.digit_3}
              />
            </FieldWrapper>
          </CodeFieldsInner>
          {error && <ErrorLabel>{t('VERIFICATION_CODE_ERROR')}</ErrorLabel>}
        </CodeFieldsWrapper>
        <AsyncButton
          asyncAction={submitForm}
          className='submit-btn'
          htmlType='button'
          type='primary'
          size='large'
          label={<Fragment>{t('SIGN_UP_CODE_SUBMIT')}</Fragment>}
        />
        <Resend>
          {!error && <span>{t('EMAIL_NOT_RECEIVED')}. </span>}
          <Link
            onClick={() => {
              resendEmail();
            }}
          >
            {t('RESEND')}
          </Link>
        </Resend>
      </Form>
    </FormWrapper>
  );
};

export default RenderEmailVerificationForm;
