import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${({ lineThrough }) => lineThrough ? css`text-decoration: line-through;` : css``};
  .promotion-icon {
    position: relative;
    top: -5px;
    height: 12px;
  }
`;

export default Wrapper;
