import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { GlobalContext } from 'context/GlobalProvider';
import { getStateFromUrl, setStateToUrl } from 'library/helpers/url_handler';
import { LISTING_POSTS_PAGE } from 'settings/constant';

const HelmetWrapper = ({ location, match }) => {
  const { t } = useTranslation();
  const { numOfNotifs, subSectionTitle, metaDescription, reviewSchema } =
    useContext(GlobalContext);
  const searchParams = getStateFromUrl(location);

  const [documentTitle, setDocumentTitle] = useState('');
  const [canonicalLink, setCacnonicalLink] = useState('');

  useEffect(() => {
    let title = t('BOATER_APP_META_TITLE');
    if (numOfNotifs) {
      title = `(${numOfNotifs}) ${title}`;
    }

    if (subSectionTitle) title = `${title} - ${subSectionTitle}`;
    setDocumentTitle(title);
  }, [numOfNotifs, subSectionTitle, reviewSchema]);

  // set canonical link
  useEffect(() => {
    let canonPathname = `${process.env.REACT_APP_BOATER_URL}${location.pathname}`;

    if (location.pathname.includes(LISTING_POSTS_PAGE)) {
      // marinas search and listing
      if (!isEmpty(searchParams) && searchParams.country) {
        const canonSearch = setStateToUrl({ country: searchParams.country });
        canonPathname = `${process.env.REACT_APP_BOATER_URL}${location.pathname}${canonSearch}`;
      }
    }

    if (canonPathname.endsWith('/')) canonPathname = canonPathname.slice(0, -1);
    setCacnonicalLink(canonPathname);
  }, [location, match]);

  return (
    <Helmet>
      <title>{documentTitle}</title>
      {reviewSchema && 
        (<script type="application/ld+json">
          {JSON.stringify(reviewSchema)}
        </script>)}
      <meta
        name='description'
        content={metaDescription ? metaDescription : t('BOATER_APP_META_DESCRIPTION')}
      />
      <link rel='canonical' href={canonicalLink} />
      <h1 style={{display: 'none'}}>{documentTitle}</h1>
    </Helmet>
  );
};
export default withRouter(HelmetWrapper);
