import React, { useState, useEffect, useContext, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import * as Cookies from 'js-cookie';
import moment from 'moment';
import { AuthContext } from 'context/AuthProvider';
import { setStateToUrl, getStateFromUrl } from 'library/helpers/url_handler';
import firebaseService from 'services/firebaseService.js';
import { message } from 'antd';

export const EmailVerificationContext = React.createContext({});

message.config({
  top: 100,
  duration: 7,
  maxCount: 1
});

// set the timeout for firebase requests
const FBaseTimeout = 60000;

const EmailVerificationProvider = ({ children, match, location, history }) => {
  const { loggedIn, user, refreshData, waitForCurrentUserReload } =
    useContext(AuthContext);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [lastSendTime, setLastSendTime] = useState(null);
  const [searchParams, setSearchParams] = useState('');
  const [modalCloseCallback, setModalCloseCallback] = useState({});

  useEffect(() => {
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, []);

  const onStorageChange = async e => {
    if (e.key !== 'emailVerifiedDate') {
      return false;
    }
    const { newValue, timeStamp } = e;
    await refreshData({ email_verification: true });
    init();
  };

  const init = useCallback(async () => {
    if (
      loggedIn &&
      user &&
      firebaseService.auth &&
      firebaseService.auth.currentUser
    ) {
      await firebaseService.auth.currentUser.reload();
      await waitForCurrentUserReload();

      const { emailVerified } = firebaseService.auth.currentUser;
      const emailCookieValue = Cookies.get(encodeURIComponent(user.email));
      const emailVErificationBannerHidden = Cookies.get(
        `${encodeURIComponent(user.email)}_verification_hidden`
      );
      const emailVErificationBannerClosed = Cookies.get(
        `${encodeURIComponent(user.email)}_verification_closed`
      );
      const emailConfirmedUrlState = getStateFromUrl(location).email_confirmed;

      // reset
      setBannerVisible(false);
      setEmailSent(false);
      setEmailConfirmed(false);

      if (emailVerified) {
        setEmailConfirmed(true);
        if (emailVErificationBannerClosed) {
          // hide the success email verification banner
          setBannerVisible(false);
        } else {
          Cookies.set(encodeURIComponent(user.email), 'verified', {
            expires: 365 * 10
          });
          setEmailSent(false);
          if (emailConfirmedUrlState) {
            // display the success email verification banner
            setBannerVisible(true);
            // cross tab verification
            localStorage.setItem('emailVerifiedDate', new Date());
          }
        }
        // clear email verification search parameters
        const searchQueryParameters = getStateFromUrl(location);
        if (
          searchQueryParameters &&
          searchQueryParameters.email_confirmed &&
          searchQueryParameters.firebase_id
        ) {
          let newSearchQuery = { ...searchQueryParameters };
          delete newSearchQuery.email_confirmed;
          delete newSearchQuery.firebase_id;
          history.replace({
            pathname: location.pathname,
            search: setStateToUrl(newSearchQuery)
          });
        }
      } else {
        if (emailVErificationBannerHidden) {
          setBannerVisible(false);
        } else {
          // display the default email verification banner
          setBannerVisible(true);
          if (emailCookieValue === 'sent') {
            // display the email verification banner with Recend button
            setEmailSent(true);
          }
        }
      }
    }
  }, [loggedIn, user]);

  useEffect(() => {
    init();
  }, [loggedIn, user]);

  const onVerify = ({
    showModal = true,
    additionalSearch,
    force = false,
    currentUser = user
  } = {}) => {
    const { pathname } = location;
    let { search } = location;
    if (emailSent && showModal && !force) {
      setModalVisible(true);
    } else {
      if (additionalSearch) {
        if (search) {
          const joinedSearchQuery = {
            ...getStateFromUrl({ search }),
            ...getStateFromUrl({ search: additionalSearch })
          };
          search = setStateToUrl(joinedSearchQuery);
        } else {
          search = additionalSearch;
        }
        setSearchParams(search);
      }
      if (showModal) {
        setModalVisible(true);
      }
      const currentTime = moment();
      if (!lastSendTime || currentTime.diff(lastSendTime) > FBaseTimeout) {
        const additionalSearchParams = search || searchParams;
        firebaseService
          .sendEmailVerification(pathname, additionalSearchParams)
          .then(function () {
            setLastSendTime(currentTime);
            setEmailSent(true);
            Cookies.set(encodeURIComponent(currentUser.email), 'sent', {
              expires: 365 * 10
            });
          })
          .catch(function (error) {
            message.error('error sending email');
            console.error(error);
          });
      }
    }
  };

  const onDecline = () => {
    setBannerVisible(false);
    Cookies.set(`${encodeURIComponent(user.email)}_verification_hidden`, true, {
      expires: 1
    });
  };

  const onCloseBanner = () => {
    setBannerVisible(false);
    Cookies.set(`${encodeURIComponent(user.email)}_verification_closed`, true, {
      expires: 365 * 10
    });
  };

  const onCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <EmailVerificationContext.Provider
      value={{
        bannerVisible,
        modalVisible,
        setModalVisible,
        emailSent,
        emailConfirmed,
        onVerify,
        onDecline,
        onCloseBanner,
        onCloseModal,
        setEmailConfirmed,
        setModalCloseCallback,
        modalCloseCallback
      }}
    >
      {children}
    </EmailVerificationContext.Provider>
  );
};

export default withRouter(EmailVerificationProvider);
