import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withScriptjs } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import forEach from 'lodash/forEach';
import Switch from 'components/UI/Antd/Switch/Switch';
import { Form } from 'antd';

const {
  StandaloneSearchBox
} = require('react-google-maps/lib/components/places/StandaloneSearchBox');
const FormItem = Form.Item;

// fixed value from google maps API
const predictionElementHeight = 51;
const predictionDropDownoffset = 10;

const GoogleMapSearch = compose(
  withProps({
    googleMapURL: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />
  }),
  withScriptjs
)(({ updateValue, defaultValue, placeholder, disabled }) => {
  const { t } = useTranslation();
  const SearchBoxRef = useRef(null);
  const SearchBoxInputRef = useRef(null);

  const [locationInput, setLocationInput] = useState({
    searchedLocation: defaultValue
  });

  const service = new window.google.maps.places.AutocompleteService();

  useEffect(() => {
    return () => {
      // clear dom (remove all pac-containers)
      const conts = document.getElementsByClassName('pac-container');
      if (conts.length) {
        forEach(conts, c => {
          c.remove();
        });
      }
    };
  }, []);

  const displaySuggestions = (predictions, status) => {
    if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
      return;
    }
    // set dropdown position to top
    if (predictions.length) {
      const conts = document.getElementsByClassName('pac-container');
      if (conts.length) {
        forEach(conts, c => {
          c.style.marginTop = `-${
            SearchBoxInputRef.current.getBoundingClientRect().height +
            predictionDropDownoffset +
            predictionElementHeight * predictions.length
          }px`;
        });
      }
    }
  };

  const handleOnChange = event => {
    // when input change
    event.stopPropagation();
    if (event.which === '13') {
      event.preventDefault();
    }

    const { value } = event.target || {};

    if (value) {
      service.getQueryPredictions({ input: value }, displaySuggestions);
    }

    updateValue(value, null);
    setLocationInput({ searchedLocation: event.target.value });
  };

  const onPlacesChanged = () => {
    // when element is selected
    const places = SearchBoxRef.current.getPlaces();
    if (
      places.length &&
      SearchBoxInputRef.current &&
      SearchBoxInputRef.current.value
    ) {
      setLocationInput({ searchedLocation: places[0].name });
      const {
        geometry: { location }
      } = places[0];
      updateValue(SearchBoxInputRef.current.value, location);
    }
  };

  const onFocus = event => {
    // addjust dropdown when input focus
    const { value } = event.target || {};
    if (value) {
      service.getQueryPredictions({ input: value }, displaySuggestions);
    }
  };

  return (
    <StandaloneSearchBox ref={SearchBoxRef} onPlacesChanged={onPlacesChanged}>
      <input
        ref={SearchBoxInputRef}
        className='ant-input google-search-custom'
        type='text'
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={handleOnChange}
        onFocus={onFocus}
      />
    </StandaloneSearchBox>
  );
});

const AntdGoogleMapSearchWithFormik = ({
  label,
  field,
  labelCol = { span: 24 },
  form,
  ...props
}) => {
  return (
    <div className='field-container'>
      <FormItem labelCol={labelCol} label={label} colon={false}>
        <GoogleMapSearch
          {...props}
          defaultValue={field.value}
          updateValue={(value, location) => {
            const lat = location ? location.lat() : null;
            const lng = location ? location.lng() : null;
            form.setFieldValue(field.name, value);
            form.setFieldValue('lat', lat);
            form.setFieldValue('lng', lng);
          }}
        />
      </FormItem>
    </div>
  );
};

export default AntdGoogleMapSearchWithFormik;
