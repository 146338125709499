import styled from 'styled-components';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 154px;
    height: 132px;
  }
`;

export const Centerized = styled.div`
  @media only screen and (max-width: 991px) {
    max-width: 384px;
  }
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  button {
    margin-top: 45px;
    height: 47px;
  }
  span {
    align-self: flex-start;
  }
`;
