import {get} from './api';

const paths = {
    GET_MARINAS: '/public/marinas',
    GET_MARINA_DATA: '/public/marinas_data?language=all',
    GET_MARINA:'/public/marina/get/'
};

const getMarinas = () => get(paths.GET_MARINAS);

const getMarinasData = () => get(paths.GET_MARINA_DATA);

const getMarina = (marina_id) => get(paths.GET_MARINA + marina_id);

const marina = { getMarinas, getMarinasData, getMarina };

export default marina;
