import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import {
  Wrapper,
  TitleWrapper,
  Title,
  SignUpInfo,
  LoginInfo
} from './MobieSignupBanner.style';
import { LOGIN_PAGE, REGISTRATION_PAGE } from 'settings/constant';
import wheelIcon from 'assets/images/pickapier/wheel.svg';

const MobieSignupBanner = ({ closeDrawer, match }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <TitleWrapper>
        <ReactSVG src={wheelIcon} />
        <Title>{t('BOTTOM_POPUP_SIGNUP_HEADER')}</Title>
      </TitleWrapper>
      <SignUpInfo>
        <Link to={`${match.url}${REGISTRATION_PAGE}`} onClick={closeDrawer}>
          {t('BOTTOM_POPUP_SIGNUP_CTA')}
        </Link>
      </SignUpInfo>
      <LoginInfo>
        {t('BOTTOM_POPUP_SIGNUP_LOGIN_TEXT')}{' '}
        <Link
          className='link'
          to={`${match.url}${LOGIN_PAGE}`}
          onClick={closeDrawer}
        >
          {t('BOTTOM_POPUP_SIGNUP_LOGIN_LINK')}
        </Link>
      </LoginInfo>
    </Wrapper>
  );
};

export default withRouter(MobieSignupBanner);
