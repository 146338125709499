import React, { useState, useContext, useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { LayoutContext } from 'context/LayoutProvider';
import { GlobalContext, getMarinaTextId } from 'context/GlobalProvider';
import Button from 'components/UI/Antd/Button/Button';
import { AutoComplete } from 'antd';
import { MdClose } from 'react-icons/md';
import { setStateToUrl } from 'library/helpers/url_handler';
import { MARINA_PAGE, HOME_PAGE, DISCOVER_PAGE } from 'settings/constant';
import { regionsList } from 'settings/regions';
import { FormWrapper, ComponentWrapper, OrtWrapper } from './SearchForm.style';
import useWindowSize from 'library/hooks/useWindowSize';
// import MapAutoComplete from 'components/Map/MapAutoComplete';
// import { MapDataHelper } from 'components/Map/MapLocationBox';

const SearchForm = ({
  location,
  history,
  match,
  mobileMode
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const inputRef = useRef();

  const [{ searchVisibility, homeSearchActive }, dispatch] = useContext(LayoutContext);
  const { marinas, countries } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        if (searchVisibility || homeSearchActive) {
          if (location.pathname === match.url) {
            window.scrollTo({ top: 0, block: 'center', behavior: 'smooth' });
          }
          inputRef.current.focus();
        } else {
          inputRef.current.blur();
        }
      }
    }, 200);
    if (searchVisibility) {
      dispatch({ type: 'HIDE_MOBILE_MENU' });
    } else {
      dispatch({ type: 'SHOW_MOBILE_MENU' });
    }
  }, [searchVisibility, homeSearchActive, location.pathname, match.url, dispatch]);

  // states
  const [marinasAndCountries, setMarinasAndCountries] = useState([]);
  const [placeValue, setPlaceValue] = useState(null);
  const [placeText, setPlaceText] = useState(null);
  // const [ mapValue, setMapValue ] = useState([]);
  const [disableSearch, setDisableSearch] = useState(false); // By default false, cause 'anywhere' is selected by default
  const [searchOpened, setSearchOpened] = useState(false);

  useEffect(() => {
    setMarinasAndCountries([
      { value: 'anywhere', text: t('ANYWHERE'), label: t('ANYWHERE') },
      ...countries.map(c => ({
        value: c.name, text: c.name, label: (
          <OrtWrapper>
            <span className='ort-label'> {t(c.name)}</span>
            <span className='ort-type'>Country</span>
          </OrtWrapper>
        )

      })),
      ...marinas.map(m => ({
        value: m.name, text: m.name, label: (
          <OrtWrapper>
            <span className='ort-label'> {t(m.name)}</span>
            <span className='ort-type'>Marina</span>
          </OrtWrapper>
        )
      })),
      ...regionsList.map(r => ({
        value: r.name, text: t(r.title), label: (
          <OrtWrapper>
          <span className='ort-label'> {t(r.title)}</span>
          <span className='ort-type'>Region</span>
        </OrtWrapper>
        )
      }))
    ]);
  }, [marinas, countries, t]);

  // const updatevalueFunc = event => {
  //   const { searchedPlaceAPIData } = event;
  //   if (!isEmpty(searchedPlaceAPIData)) {
  //     setMapValue(searchedPlaceAPIData);
  //   }
  // };

  const onChangePlace = (value='', option={}) => {
    if (option?.text) {
      setPlaceText(option.text);
      setPlaceValue(value);
    } else {
      setPlaceValue(value);
      setPlaceText(value);
    }
    /**
     * Until an item is selected - disable search
     */
    const matchedOption = marinasAndCountries.find(option => option.value.toUpperCase() === value.toUpperCase());

    if (matchedOption) {
      setPlaceValue(matchedOption.value);
      setDisableSearch(false);
    } else {
      setDisableSearch(true);
    }

  };

  const goToSearchPage = (selectedValue) => {
    dispatch({ type: 'HIDE_TOP_SEARCHBAR' });
    dispatch({ type: 'SHOW_MOBILE_MENU' });

    // let tempPlace = [];
    // const mapData = mapValue ? MapDataHelper(mapValue) : [];
    // mapData &&
    //   mapData.map((singleMapData, i) => {
    //     return tempPlace.push({
    //       formattedAddress: singleMapData ? singleMapData.formattedAddress : '',
    //       lat: singleMapData ? singleMapData.lat.toFixed(3) : null,
    //       lng: singleMapData ? singleMapData.lng.toFixed(3) : null,
    //     });
    //   });
    // const place = tempPlace ? tempPlace[0] : {};

    const query = {};

    let pathname = `${match.url}${MARINA_PAGE}`;

    const desiredPlace = selectedValue? selectedValue : (placeValue || 'anywhere');
    const value = selectedValue? selectedValue : placeValue;

    if (
      desiredPlace === 'anywhere' ||
      countries.find(country => value === country.name)
    ) {
      // if the country has been selected or nothing was selected
      query.country = desiredPlace;
      pathname = `${match.url}${DISCOVER_PAGE}`;
    } else if (marinas.find(m => m.name === value)) {
      // if the marina has been selected
      const textId = getMarinaTextId(marinas.find(m => m.name === value));
      pathname += `/${textId}`;
    } else if (regionsList.find(r => r.name === value)) {
      // if a region has been selected
      query.region_key = desiredPlace;
      pathname = `${match.url}${DISCOVER_PAGE}`;
    }

    setPlaceValue(null);
    setPlaceText(null);
    const search = setStateToUrl(query);

    history.push({
      pathname,
      search,
      state: {
        from: location.pathname,
        ref: location.pathname === HOME_PAGE ? 'begin_here' : 'top_search',
        type: location.pathname?.split('/').length > 2 ? 'header' : 'begin here',
      }
    });
  };

  const onSubmit = event => {
    event.preventDefault();
    if(disableSearch) return;
    goToSearchPage();
  }

  const handleSelectPlace = (selectedValue) => {
    if (width < 992) {
      goToSearchPage(selectedValue);
    } else {
      setDisableSearch(false);
    }
  };

  const onFocus = () => {
    setSearchOpened(true);
    if (location.pathname === match.url) {
      dispatch({ type: 'HIDE_MOBILE_MENU' });
    }
  };

  const onBlur = () => {
    setSearchOpened(false);
    if (location.pathname === match.url) {
      dispatch({ type: 'DEACTIVATE_HOME_SEARCHBAR' });
      dispatch({ type: 'SHOW_MOBILE_MENU' });
    }
  };

  return (
    <FormWrapper className='search-form-wrapper' mobileMode={mobileMode} onSubmit={onSubmit}>

      <ComponentWrapper mobileMode={mobileMode} searchOpened={searchOpened}>
        <FiSearch className='search-icon' />
        <AutoComplete
          allowClear={mobileMode}
          clearIcon={<MdClose size={22} style={{ color: '#121a2c' }} />}
          dropdownClassName='search-autocomplete-dropdown'
          listHeight={isMobile && location.pathname !== match.url ? 130 : 256}
          placeholder={width < 992 ? t('HEADER_MOBILE_SEARCH_PLACEHOLDER') : t('HOMEPAGE_SEARCH_PLACEHOLDER')}
          value={placeText || ''}
          options={marinasAndCountries.filter(item => !!item)}
          filterOption={(inputValue, option) => {
            return (
              option.text?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            );
          }}
          onChange={onChangePlace}
          getPopupContainer={trigger => trigger.parentElement}
          ref={inputRef}
          onFocus={onFocus}
          onBlur={onBlur}
          onSelect={handleSelectPlace}
          dropdownAlign={
            {
              points: ['tl', 'bl'],
              offset: [0, isMobile ? 10 : 28],
              overflow: {
                adjustX: 0,
                adjustY: 0
              }
            }
          }
        />
      </ComponentWrapper>

      <Button
        type='primary'
        htmlType='submit'
        size='large'
        disabled={disableSearch}
      >
        {t('RESERVE')}
      </Button>
    </FormWrapper>
  );
};

export default withRouter(SearchForm);
