import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Copyright = styled.p`
  display: ${({ mobileOnly }) => ( mobileOnly ? 'none': 'block')};
  padding-top: ${({ paddingTop }) => `${paddingTop}px`};
  margin-bottom: 0;
  padding-right: 12px;
  padding-left: 12px;
  width: ${({ align }) => align.desktop === 'center' ? '100%' : 'auto' };
  font-size: 12px;
  line-height: 1.67;
  color: ${({ mode }) => mode === 'dark' ? themeGet('text.12', '#828282') : themeGet('color.1', '#ffffff')};
  text-align: ${({ align }) => align.desktop };
  @media only screen and (max-width: 991px) {
    display: block;
    text-align: ${({ align }) => align.mobile };
  }
`
