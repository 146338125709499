import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const LogoArea = styled.div`
  a {
    width: 170px;
    height: 44px;
    @media only screen and (max-width: 991px) {
      height: 34px;
      width: 34px;
    }
  }

  img {
    width: 170px;
    height: 44px;
    @media only screen and (max-width: 991px) {
      height: 34px;
      width: 34px;
    }
  }

  &.auth-logo {
    img {
      width: 153px;
      height: 39px;
      @media only screen and (max-width: 991px) {
        width: 138px;
        height: 35px;
      }
    }
  }

  &.footer-logo {
    img {
      width: 101px;
      height: 75px;
    }
  }
`;

export default LogoArea;
