import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const FormWrapper = styled.div`
  @media only screen and (max-width: 991px) {
    flex-grow: 1;
    max-width: 384px;
  }
  .field-container {
    height: 100%;
  }
`;

export const FieldWrapper = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 12px;
  border: solid 3px ${themeGet('color.31', '#2eb7a9')};
  margin: 0 5px;
  &.error {
    border-color: ${themeGet('error.0', '#F9503D')};
  }
  @media only screen and (max-width: 991px) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (max-width: 480px) {
    width: 42px;
    height: 42px;
  }
  .ant-form-item {
    width: 66px;
    height: 66px;
    margin-bottom: 0;
    @media only screen and (max-width: 991px) {
      width: 54px;
      height: 54px;
    }
    @media only screen and (max-width: 480px) {
      width: 36px;
      height: 36px;
    }
  }
  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-input {
    height: 66px;
    @media only screen and (max-width: 991px) {
      height: 54px;
    }
    @media only screen and (max-width: 480px) {
      height: 36px;
    }
  }
  .ant-form-item-control-input-content {
    max-height: 66px;
    @media only screen and (max-width: 991px) {
      max-height: 54px;
    }
    @media only screen and (max-width: 480px) {
      max-height: 36px;
    }
  }
  .ant-input {
    padding: 0;
    border: 0;
    border-radius: 12px;
    text-align: center;
    font-size: 32px;
    color: ${themeGet('text.0', '#2C2C2C')};
    @media only screen and (max-width: 991px) {
      font-size: 28px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
    }
    &:focus {
      outline: none;
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:hover {
      border-color: none;
    }
  }
`;

export const Resend = styled.div`
  text-align: left;
  margin-top: 130px;
  .download-terms-link {
    margin-left: 5px;
  }
  span {
    font-size: 14px;
    color: ${themeGet('text.3', '#8d8d8d')};
  }
  a {
    color: ${themeGet('primary.0', '#194AB9')};
    font-size: 14px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const ErrorLabel = styled.span`
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  color: ${themeGet('text.5', '#f5222d')};
  line-height: 1;
`;

export const CodeFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

export const CodeFieldsInner = styled.div`
  display: flex;
  margin-bottom: 14px;
`;

export default FormWrapper;
