import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { ListingLoadMoreStyles } from 'container/Listing/Listing.style';

export const ARFormWrapper = styled.div`
  .ant-select {
    &.ant-select-single {
      .ant-select-selector {
        height: 47px;
        .ant-select-selection-search-input {
          height: 45px;
          &[disabled] {
            cursor: default;
          }
        }
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 45px;
        }
      }
    }
    &.ant-select-disabled {
      .ant-select-selector {
        cursor: default;
      }
    }
    &:not(.ant-select-disabled):hover .ant-select-selector,
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border-color: ${themeGet('primary.0', '#194AB9')};
      box-shadow: none;
    }
  }

  .ant-form-item-control-input-content {
    input.ant-input {
      height: 47px;
      &[disabled],
      &.ant-input-disabled {
        cursor: default;
      }
    }
    .ant-input:hover,
    .ant-input:focus,
    .ant-input-focused {
      border-color: ${themeGet('primary.0', '#194AB9')};
      box-shadow: none;
    }
  }

  button.ant-btn {
    margin-top: 6px;
  }

  label {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    @media only screen and (max-width: 575px) {
      height: auto;
    }
  }

  .submit-button {
    width: 100%;
    height: 47px;
    font-size: 15px;
    font-weight: 700;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#194AB9')};
    border: 0;
    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  @media only screen and (max-width: 991px) {
    .field-container {
      .ant-form-item {
        margin-bottom: 8px;
      }
    }
    button.submit-button {
      margin-top: 12px;
    }
  }
`;

export const SuccessDialogActions = styled.div`
  button {
    height: 47px;
    font-size: 15px;
    font-weight: 700;
    padding-left: 28px;
    padding-right: 28px;
    min-width: 167px;
  }
`;

export const EmptyList = styled.div`
  text-align: center;
`;

export const ListWrapper = styled.div`
  .loadmore_wrapper {
    ${ListingLoadMoreStyles}
  }
  .grid_wrapper {
    gap: 40px !important;
  }
`;

export const NotFound = styled.div`
  button {
    margin-left: 10px;
    color: ${themeGet('primary.0', '#194AB9')};
    background-color: transparent;
    font-size: 16px;
    outline: none;
    border: 0;
    padding: 0;
  }
`;

export const FieldWrapperWithNote = styled.div`
  position: relative;
  .ant-form-item {
    margin-bottom: 4px;
    .field-with-icon {
      input.ant-select-selection-search-input {
        padding-left: 32px;
      }
    }
  }
  .anticon {
    position: absolute;
    top: 56px;
    left: 16px;
    font-size: 15px;
    @media only screen and (max-width: 575px) {
      top: 44px;
    }
  }
`;
