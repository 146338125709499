import styled, { css } from "styled-components";
import {Variant} from "./constants";
import themeGet from "@styled-system/theme-get";

const getColor = (variant, disabled) => {
    switch (variant) {
        case Variant.Outlined:
            return themeGet('color.1', '#FFFFFF');
        case Variant.Link:
        case Variant.Naked:
            return 'transparent';
        case Variant.Filled:
        default:
            return disabled ? 'rgba(25, 74, 185, 0.3)' : themeGet('primary.0', '#194AB9');
    }
};

const getHoverColor = (variant, disabled) => {
    switch (variant) {
        case Variant.Link:
        case Variant.Naked:
            return disabled ? 'transparent' : themeGet('color.32', '#f2f2f2');
        case Variant.Outlined:
            return disabled ? themeGet('color.1', '#FFFFFF') : themeGet('color.32', '#f2f2f2');
        case Variant.Filled:
        default:
            return disabled ? 'rgba(25, 74, 185, 0.3)' : themeGet('color.39', '#0036B1');
    }
};

const getVerticalPadding = (medium, icon, mobile) => {
    if(medium){
        if(icon) {
            return mobile ? 1.6 :6;
        }
        return mobile? 2.533 : 9.5;
    }
    if(icon) {
        return mobile ? 2.666 : 10;
    }
    return mobile ? 3.2 : 12;
}

const getBorderColor = (variant, isHovered, isDisabled) => {
    switch (variant) {
        case Variant.Outlined:
            return isDisabled ? themeGet('border.11', '#bdbdbd')
                : themeGet('text.13', '#121a2c');
        case Variant.Link:
        case Variant.Naked:
            return 'none';
        case Variant.Filled:
        default:
            return !isHovered || isDisabled ? 'none' : themeGet('primary.0', '#194AB9');
    }
}

const getTextColor = (variant, isDisabled) => {
    if(!variant || variant === Variant.Filled) {
        return themeGet('color.1', '#FFFFFF');
    }
    if(isDisabled) {
        return themeGet('border.11', '#bdbdbd');
    }
    return themeGet('text.13', '#121a2c');
}

const getColorFilter = (variant, isDisabled) => {
    if(!variant || variant === Variant.Filled) {
        return 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(171deg) brightness(103%) contrast(101%)';
    }
    if(isDisabled) {
        return 'brightness(0) saturate(100%) invert(84%) sepia(0%) saturate(1155%) hue-rotate(152deg) brightness(95%) contrast(80%)';
    }
    return 'brightness(0) saturate(100%) invert(7%) sepia(8%) saturate(5801%) hue-rotate(187deg) brightness(93%) contrast(93%)';
}

export const StyledButton = styled.button`
  all: unset;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  gap: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-decoration: ${props => props.variant === Variant.Link ? 'underline' : 'none'};
  font-size: ${props => props.medium ? 14 : 17}px;
  color: ${props => getTextColor(props.variant, props.disabled)};
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  padding: ${props => getVerticalPadding(props.medium, props.icon)}px 12px;
  background-color: ${props => getColor(props.variant, props.disabled)};
  border: 1px solid ${props => getBorderColor(props.variant, false, props.disabled)};  
  :hover {
    background-color: ${props => getHoverColor(props.variant, props.disabled)};
    border: 1px solid ${props => getBorderColor(props.variant, false, props.disabled)};
    @media only screen and (max-width: 991px) {
      border: 0.266vw solid ${props => getBorderColor(props.variant, false, props.disabled)};
    }
  }
  @media only screen and (max-width: 991px) {
    ${({ adaptive }) => adaptive && css`
        border-radius: 1.066vw;
        gap: 2.133vw;
        font-size: ${props => props.medium ? 3.733 : 4.533}vw;
        padding: ${props => getVerticalPadding(props.medium, props.icon, true)}vw 3.2vw;
        border: 0.266vw solid ${props => getBorderColor(props.variant, false, props.disabled)};  
    `}
  }
  ${props => props.fillWidth ? 'width: 100%;' : ''}
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
  filter: ${props => getColorFilter(props.variant, props.disabled)};
`;
