import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/UI/Antd/Drawer/Drawer';
import Button from 'components/UI/Antd/Button/Button';
import PopeyeRequestForm from 'components/PopeyeRequestForm/PopeyeRequestForm';

import { Body, Label } from './PopeyeRequestDrawer.style';

const PopeyeRequestDrawer = ({ opened, onClose }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      className='popeye-request-drawer'
      placement='right'
      onClose={onClose}
      visible={opened}
      width='493px'
      zIndex={999998}
      closable={false}
      height='100%'
      width='100%'
      bodyStyle={{ padding: 0, height: '100%', width: '100%' }}
    >
      <Body>
        <Button
          className='popeye-request-back-button'
          htmlType='button'
          onClick={onClose}
          type='link'
        >
          <IoIosArrowBack />
          <Label>{t('POPEYE_BACK_BUTTON')}</Label>
        </Button>
        <PopeyeRequestForm onClose={onClose} />
      </Body>
    </Drawer>
  );
};

export default PopeyeRequestDrawer;
