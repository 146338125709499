import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const AvatarWrapper = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: ${themeGet('color.23', '#66bdff')};
  text-align: center;
  line-height: 48px;
  font-weight: bold;
  color: ${themeGet('color.1', '#ffffff')};
  font-size: 19px;
  cursor: pointer;
`;
