import styled from 'styled-components';


export const StyledHeader = styled.div`
  padding-top: 0px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const ImageWrapper = styled.div`
  position: relative;
`;

export const StyledTitle = styled.h3`
  text-align: center;
  margin-top: 8px;
  font-size: 25px;
  font-weight: bold;
  padding: 0 20px;
`;

export const CTAstyledButton = styled.div`
  width: 175px;
  height: 47px;
  margin: 25px auto;
  padding: 14px 50px 15px;
  border-radius: 4px;
  background-color: #4071e2;
  color: #fff;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
`;

export const CTADeclineButton = styled.div`
  text-align: center;
  padding: 0 20px 25px;
  color: #7e7e7e;
  cursor: pointer;
  text-decoration: underline;
`;

export const StyledBody = styled.div`
  max-width: 530px;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 18px;
  color: #1a1a1b;
`;