import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from 'context/AuthProvider';
import { GlobalContext, getMarinaTextId } from 'context/GlobalProvider';
import Drawer from 'components/UI/Antd/Drawer/Drawer';
import Menu from 'components/UI/Antd/Menu/Menu';
import AbsenceReportModal from 'container/Agent/AbsenceReports/AbsenceReportModal';
import { setStateToUrl, getStateFromUrl } from 'library/helpers/url_handler';
import { isItemActive } from 'library/helpers/bottomNavigation';
import {
  PROFILE_PAGE,
  AGENT_VESSEL_PAGE,
  ABSENCE_REPORTS_PAGE,
  MARINA_PAGE,
  MESSAGES_PAGE
} from 'settings/constant';
import { HOME_MARINA } from './MobileMenu';
import {
  MobileMenuTitle,
  DrawerBodyWrapper,
  DrawerFooter
} from './MobileMenu.style';
// icons
import homeMarinaIcon from 'assets/images/pickapier/home-marina.svg';
import homeMarinaHoverIcon from 'assets/images/pickapier/home-marina-hovered.svg';
import addAbsenceIcon from 'assets/images/pickapier/add-absence.svg';
import chatIcon from 'assets/images/pickapier/chat.svg';

const HomeMarinaMobileMenu = ({
  location,
  match,
  state,
  dispatch,
  history,
  isARModalShown,
  setIsARModalShown,
  activeGuestItem,
  setActiveGuestItem,
  vesselsWithHomeMarina
}) => {
  const { pathname } = location;
  const searchParams = getStateFromUrl(location);
  const { t } = useTranslation();
  const { loggedIn, user, selectBoaterVessel, selectedBoaterVessel } =
    useContext(AuthContext);
  const { marinas } = useContext(GlobalContext);

  const { homeDrawerOpened, authDrawerOpened } = state;

  const toggleBottomMenu = (e, prevent) => {
    if (prevent) {
      e.preventDefault();
    }
    dispatch({ type: 'HIDE_TRIPS_DRAWER' });
    dispatch({ type: 'HIDE_MORE_DRAWER' });
    dispatch({ type: 'HIDE_POPEYE_REQUEST_DRAWER' });
    if (!loggedIn || !user) {
      dispatch({
        type: authDrawerOpened ? 'HIDE_AUTH_DRAWER' : 'OPEN_AUTH_DRAWER'
      });
      if (!authDrawerOpened) {
        setActiveGuestItem(HOME_MARINA);
      } else {
        setActiveGuestItem(null);
      }
    } else {
      dispatch({
        type: homeDrawerOpened ? 'HIDE_HOME_DRAWER' : 'OPEN_HOME_DRAWER'
      });
    }
  };

  const onNotifyOpen = (e, prevent) => {
    toggleBottomMenu(e, prevent);
    setIsARModalShown(true);
  };

  const afterArSubmit = () => {
    setIsARModalShown(false);
    setTimeout(() => {
      // workaround: fix the strange bug from ant design
      // in some reason body gets overflow:hidden after popup closing:
      // https://github.com/ant-design/ant-design/issues/21539
      document.body.style.overflow = '';
      history.push({ pathname: `${match.url}${ABSENCE_REPORTS_PAGE}` });
    }, 500);
  };

  const onARmodalCancel = () => {
    setIsARModalShown(false);
    // workaround: fix the strange bug from ant design
    // in some reason body gets overflow:hidden after popup closing:
    // https://github.com/ant-design/ant-design/issues/21539
    setTimeout(() => {
      document.body.style.overflow = '';
    }, 500);
  };

  const onClickChatHandler = e => {
    toggleBottomMenu(e, true);

    let path, search;
    if (vesselsWithHomeMarina.length) {
      // check if the already selected vessel has home marina
      const selectedVesselWithHomeMarina = vesselsWithHomeMarina.filter(
        ({ id }) => id === selectedBoaterVessel
      );
      if (selectedVesselWithHomeMarina.length) {
        const textId = getMarinaTextId(marinas.find(({ id }) => id === selectedVesselWithHomeMarina[0].home_marina_id));
        path = `${match.url}${MARINA_PAGE}/${textId}`;
      } else {
        // select the first vessel with home marina
        selectBoaterVessel(vesselsWithHomeMarina[0].id);
        const textId = getMarinaTextId(marinas.find(({ id }) => id === vesselsWithHomeMarina[0].home_marina_id));
        path = `${match.url}${MARINA_PAGE}/${textId}`;
      }
      search = setStateToUrl({ open_messages: true });
    } else {
      path = `${match.url}${PROFILE_PAGE}${AGENT_VESSEL_PAGE}`;
      search = setStateToUrl({
        vessel_id: selectedBoaterVessel,
        hmv_request: true
      });
    }
    setTimeout(() => {
      history.push({
        pathname: path,
        search
      });
    }, 300);
  };

  const activeBottomItem = isItemActive(HOME_MARINA, state, pathname, match, {
    activeGuestItem,
    isARModalShown,
    searchParams,
    vesselsWithHomeMarina,
    getMarinaTextId,
    marinas
  });

  const isActiveMenu = vesselsWithHomeMarina.some(
    ({ home_marina_id }) => {
      const textId = getMarinaTextId(marinas.find(({ id }) => id === home_marina_id));
      return `${location.pathname}${location.search}` ===
        `${match.url}${MARINA_PAGE}/${textId}?open_messages=true`;
    }
  );

  return (
    <Fragment>
      <a
        onClick={e => toggleBottomMenu(e, true)}
        className={
          activeBottomItem ? 'mobile-nav-link active' : 'mobile-nav-link'
        }
      >
        <ReactSVG
          className={`bottom-nav-icon ${activeBottomItem ? '' : 'hidden'}`}
          src={homeMarinaHoverIcon}
        />
        <ReactSVG
          className={`bottom-nav-icon ${activeBottomItem ? 'hidden' : ''}`}
          src={homeMarinaIcon}
        />
        <MobileMenuTitle className='mobile-menu-title'>
          {t('BOTTOM_NAV_VACANCY')}
        </MobileMenuTitle>
      </a>
      <Drawer
        placement='bottom'
        closable={false}
        onClose={toggleBottomMenu}
        zIndex={999998}
        className='mobile-bottom-menu'
        visible={homeDrawerOpened}
        height={196}
        bodyStyle={{ padding: 0 }}
      >
        <DrawerBodyWrapper>
          <Menu selectable={false}>
            <Menu.Item key='1'>
              <NavLink
                isActive={() => false}
                to={pathname}
                onClick={e => onNotifyOpen(e, true)}
              >
                <ReactSVG src={addAbsenceIcon} className='mobile-menu-icon' />
                {t('BOTTOM_NAV_HOME_MARINA_ABSENCE')}
              </NavLink>
            </Menu.Item>

            <Menu.Item
              className={isActiveMenu ? 'ant-menu-item-selected' : ''}
              key='2'
            >
              <NavLink
                isActive={() => false}
                to={{ pathname: match.url }}
                onClick={onClickChatHandler}
              >
                <ReactSVG src={chatIcon} className='mobile-menu-icon' />
                {t('BOTTOM_NAV_HOME_MARINA_CHAT')}
              </NavLink>
            </Menu.Item>
          </Menu>
        </DrawerBodyWrapper>
        <DrawerFooter />
      </Drawer>

      {!!loggedIn && !!user && (
        <AbsenceReportModal
          visible={isARModalShown}
          onCancel={onARmodalCancel}
          afterSubmit={afterArSubmit}
          editValues={{}}
        />
      )}
    </Fragment>
  );
};

export default HomeMarinaMobileMenu;
