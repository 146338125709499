import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import { GlobalContext, getMarinaTextId } from 'context/GlobalProvider';
import Drawer from 'components/UI/Antd/Drawer/Drawer';
import MyTripsMobileMenu from './MyTripsMobileMenu';
import HomeMarinaMobileMenu from './HomeMarinaMobileMenu';
import MoreMobileMenu from './MoreMobileMenu';
import PopeyeMobileMenu from './PopeyeMobileMenu';
import MobieSignupBanner from './MobieSignupBanner/MobieSignupBanner';
import { isItemActive } from 'library/helpers/bottomNavigation';
import { Wrapper, MobileMenuItem, MobileMenuTitle } from './MobileMenu.style';
// icons
import discoverIcon from 'assets/images/pickapier/discover.svg';
import discoverHoverIcon from 'assets/images/pickapier/discover-hovered.svg';

// bottom nav items
export const DISCOVER = 'discover';
export const HOME_MARINA = 'home_marina';
export const POPEYE = 'popeye';
export const MY_TRIPS = 'my_trips';
export const MORE = 'more';

const MobileMenu = ({ location, history, match }) => {
  const { t } = useTranslation();
  const { pathname } = location;
  const [state, dispatch] = useContext(LayoutContext);
  const { loggedIn, user } = useContext(AuthContext);
  const { marinas } = useContext(GlobalContext);

  const guestMode = !loggedIn || !user;

  const vesselsWithHomeMarina = user && user.vessels && user.vessels.length
    ? user.vessels.filter(v => v.home_marina_id)
    : [];

  const [isARModalShown, setIsARModalShown] = useState(false);
  const [activeGuestItem, setActiveGuestItem] = useState(null);

  const onHomeClick = () => {
    dispatch({ type: 'HIDE_HOME_DRAWER' });
    dispatch({ type: 'HIDE_TRIPS_DRAWER' });
    dispatch({ type: 'HIDE_MORE_DRAWER' });
    dispatch({ type: 'HIDE_AUTH_DRAWER' });
  };

  const closeSignupBanner = () => {
    setActiveGuestItem(null);
    dispatch({ type: 'HIDE_AUTH_DRAWER' });
  };

  const attrs = { location, state, dispatch, history, match };

  const discoverItemActive = isItemActive(DISCOVER, state, pathname, match, {
    guestMode,
    activeGuestItem,
    isARModalShown,
    vesselsWithHomeMarina,
    marinas,
    getMarinaTextId
  });

  return (
    <Wrapper hidden={state.mobileMenuHidden}>
      <MobileMenuItem className='mobile-menu-item'>
        <NavLink
          exact
          isActive={() => false}
          className={
            discoverItemActive ? 'mobile-nav-link active' : 'mobile-nav-link'
          }
          to={`/${match.params.locale}`}
          onClick={onHomeClick}
        >
          <ReactSVG
            className={`bottom-nav-icon ${discoverItemActive ? '' : 'hidden'}`}
            src={discoverHoverIcon}
          />
          <ReactSVG
            className={`bottom-nav-icon ${discoverItemActive ? 'hidden' : ''}`}
            src={discoverIcon}
          />
          <MobileMenuTitle className='mobile-menu-title'>
            {t('BOTTOM_NAV_DISCOVER')}
          </MobileMenuTitle>
        </NavLink>
      </MobileMenuItem>

      <MobileMenuItem className='mobile-menu-item'>
        <HomeMarinaMobileMenu
          {...attrs}
          isARModalShown={isARModalShown}
          setIsARModalShown={setIsARModalShown}
          activeGuestItem={activeGuestItem}
          setActiveGuestItem={setActiveGuestItem}
          vesselsWithHomeMarina={vesselsWithHomeMarina}
        />
      </MobileMenuItem>

      <MobileMenuItem className='mobile-menu-item'>
        <PopeyeMobileMenu
          {...attrs}
          isARModalShown={isARModalShown}
          activeGuestItem={activeGuestItem}
          setActiveGuestItem={setActiveGuestItem}
          vesselsWithHomeMarina={vesselsWithHomeMarina}
          guestMode={guestMode}
          onHomeClick={onHomeClick}
        />
      </MobileMenuItem>

      <MobileMenuItem className='mobile-menu-item'>
        <MyTripsMobileMenu
          {...attrs}
          isARModalShown={isARModalShown}
          activeGuestItem={activeGuestItem}
          setActiveGuestItem={setActiveGuestItem}
        />
      </MobileMenuItem>

      <MobileMenuItem className='mobile-menu-item'>
        <MoreMobileMenu {...attrs} />
      </MobileMenuItem>

      <Drawer
        placement='bottom'
        closable={false}
        onClose={closeSignupBanner}
        zIndex={999998}
        className='mobile-bottom-menu'
        visible={state.authDrawerOpened}
        height={224}
        bodyStyle={{ padding: 0 }}
      >
        <MobieSignupBanner closeDrawer={closeSignupBanner} />
      </Drawer>
    </Wrapper>
  );
};

export default withRouter(MobileMenu);
