import React from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'components/UI/Antd/Modal/Modal';
import PopeyeRequestForm from 'components/PopeyeRequestForm/PopeyeRequestForm';
import { ModalBody } from './PopeyeRequestModal.style';

const PopeyeRequestModal = ({ visible, onClose }) => {
  return (
    <Modal
      zIndex={10001}
      width={673}
      visible={visible}
      centered={true}
      onCancel={onClose}
      wrapClassName={`popeye-request-modal`}
      closeIcon={<MdClose size={30} style={{ color: '#000000' }} />}
      footer={null}
    >
      <ModalBody>
        <PopeyeRequestForm onClose={onClose} />
      </ModalBody>
    </Modal>
  );
};

export default PopeyeRequestModal;
