import React, { Fragment } from 'react';
import { MdClose } from 'react-icons/md';
import Modal from '../Antd/Modal/Modal';
import Result from '../Antd/Result/Result';
import emailVerifyLogo from 'assets/images/pickapier/verify-email.png';
import { IconWrapper, Centerized } from './ConfirmEmailDialog.style';

/*
 * ConfirmEmailDialog
 *
 * @param {boolean}  visible
 * @param {boolean}  [closable=true]
 * @param {Function} [onCancel=()=>{}]
 * @param {Node}     [footer=null]
 * @param {string}   [title]
 * @param {string}   [subtitle]
 * @param {Node}     [actions]     Node or Array of nodes
 * @param {Node}     [children]    Node or Array of nodes
 */

export default function ConfirmEmailDialog({
  visible,
  closable,
  onCancel = () => {},
  footer = null,
  title,
  subtitle,
  actions,
  children,
  className
}) {
  return (
    <Fragment>
      <Modal
        zIndex={10001}
        visible={visible}
        bodyStyle={{ padding: footer ? '10px 0 0' : '10px 0 20px' }}
        closeIcon={<MdClose size={24} />}
        centered={true}
        width={642}
        footer={footer}
        closable={closable}
        onCancel={onCancel}
        wrapClassName={`confirm-email-modal ${className || ''}`}
      >
        <Result icon={<></>} title={title} subTitle={subtitle} extra={actions}>
          <Centerized>{children}</Centerized>
        </Result>
      </Modal>
    </Fragment>
  );
}
