import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const EmailVerificationBody = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  transition: all 0.3s ease;
  padding: 2px 40px 1px;
  background-color: ${({ success }) => {
    return success ? '#06d6a0;' : '#3677a9';
  }};
  @media only screen and (max-width: 767px) {
    padding: 2px 25px;
  }
  ${({ visible }) => {
    return visible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `;
  }}
  ${({ headerType }) => {
    return headerType === 'transparent'
      ? css`
          top: 72px;
        `
      : css``;
  }}
  @media only screen and (max-width: 991px) {
    ${({ headerType }) => {
      return headerType === 'transparent'
        ? css`
            top: 52px;
          `
        : css``;
    }}
  }
`;

export const EmailVerificationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet('color.1', '#ffffff')};
  button {
    margin-top: 1px;
    padding-left: 3px;
    padding-right: 3px;
    color: ${themeGet('color.1', '#ffffff')};
    transition: opacity 0.3s ease;
    span {
      text-decoration: underline;
    }
    &:hover,
    &:focus {
      color: ${themeGet('color.1', '#ffffff')};
      opacity: 0.8;
    }
    @media only screen and (max-width: 480px) {
      font-size: 13px;
      line-height: 20px;
      height: 22px;
    }
  }
  p {
    margin-right: 7px;
    margin-bottom: 0;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 767px) {
      align-items: start;
      margin-right: 0;
    }
    > svg {
      margin-right: 10px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 13px;
    }
  }
  .confirm_email_button {
    font-weight: bold;
  }
  button.confirm_email_button,
  button.remind_later_button {
    color: ${themeGet('color.1', '#ffffff')};
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const EmailVerificationActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
