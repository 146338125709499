import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';

import i18n from '../../i18n';

import PopeyeCopyright from 'components/PopeyeCopyright/PopeyeCopyright';
import { AntInput } from 'components/UI/Antd/AntdInputWithFormik';
import Button from 'components/UI/Antd/Button/Button';
import Spinner from 'components/Spinner/Spinner';
import {
  createSpreadsheetReference,
  appendSpreadsheet
} from 'services/googleSheet';

import popeyeWaitingPic from 'assets/images/popeye_waiting.png';

import {
  Wrapper,
  Title,
  Description,
  Image,
  PopeyeLink
} from './PopeyeRequestForm.style';

const HOST_DEV = "http://localhost:3001";
const HOST_TEST = "https://popeye-test.pickapier.com";
const HOST_PROD = "https://popeyesailclub.com";

const PopeyeRequestForm = ({ match, onClose }) => {
  const { t } = useTranslation();
  const { loggedIn, user } = useContext(AuthContext);
  const [ link, setLink ] = useState(null);

  useEffect(() => {
    const hosts = {
      DEVELOPMENT: HOST_DEV,
      TEST: HOST_TEST,
      PRODUCTION: HOST_PROD
    };
    const host = hosts[process.env.REACT_APP_FIREBASE_ENV] || HOST_PROD;
    let link;
    if(user && loggedIn) {
      const { first_name, last_name, email } = user;
      link = `${host}/#/${match.params.locale}/signup?origin=PAP&first_name=${first_name}&last_name=${last_name}&email=${email}`
    } else {
      link = `${host}/#/${match.params.locale}/signup`;
    }
    setLink(link)
  }, [user, loggedIn, match]);

  return (
    <Wrapper>
      <Title>{t('POPEYE_REQUEST_INVITE_POPUP_TITLE')}</Title>
      <Description>{t('POPEYE_REQUEST_INVITE_POPUP_DESC')}</Description>
      <Image>
        <img src={popeyeWaitingPic} alt='Popeye request waiting' />
      </Image>
      <PopeyeLink target='_blank' href={link}>{t('POPEYE_REQUEST_INVITE_POPUP_CTA')}</PopeyeLink>
      <PopeyeCopyright paddingTop={42} />
    </Wrapper>
  );
};

export default withRouter(PopeyeRequestForm);
