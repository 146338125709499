import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { GlobalContext } from 'context/GlobalProvider';
import Menu from 'components/UI/Antd/Menu/Menu';

const MobileHelpMenu = () => {
  const { t } = useTranslation();
  const { termsOfServiceLink, privacyPolicyLink } = useContext(GlobalContext);
  return (
    <Menu className='mobile-submenu' mode='inline' inlineIndent={0}>
      <Menu.Item key='1'>
        <a
          href='https://pickapier-boater.zendesk.com/hc/en-us/sections/360004381880-FAQ'
          target='_blank'
        >
          {t('FAQ_MENU_ITEM')}
        </a>
      </Menu.Item>
      <Menu.Item key='2'>
        <a href={termsOfServiceLink || '#'} target='_blank'>
          {t('TERMS_OF_USE_MENU_ITEM')}
        </a>
      </Menu.Item>
      <Menu.Item key='3'>
        <a href={privacyPolicyLink || '#'} target='_blank'>
          {t('PRIVACY_POLICY_MENU_ITEM')}
        </a>
      </Menu.Item>
      <Menu.Item key='4'>
        <a href='https://gdpr-rep.eu/q/17740657' target='_blank'>
          {t('GDPR_COMPLIANCE_MENU_ITEM')}
        </a>
      </Menu.Item>
    </Menu>
  );
};

export default MobileHelpMenu;
