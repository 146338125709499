import React from 'react';
import FooterMenu from './components/FooterMenu/FooterMenu';
import FollowMenu from './components/FollowMenu/FollowMenu';
import BottomMenu from './components/BottomMenu/BottomMenu';
import FooterWrapper, {
  Top,
  Bottom,
  Copyright
} from './Footer.style';

const Footer = ({ logo, menu, social, bottomMenu, copyright, onLinkClick }) => {
  return (
    <FooterWrapper id='footer'>
      <Top>
        {logo && logo}
        <FooterMenu menu={ menu } onLinkClick={ onLinkClick } />
        <FollowMenu menu={ social } onLinkClick={ onLinkClick } />
      </Top>
      <Bottom>
        {copyright && <Copyright>{copyright}</Copyright>}
        <BottomMenu menu={ bottomMenu } onLinkClick={ onLinkClick } />
      </Bottom>
    </FooterWrapper>
  );
};

export default Footer;
