import axios from 'axios';
import qs from 'qs';
import firebaseService from 'services/firebaseService.js';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json'; // for all requests
export const get = async (url, query) => {
  if (!query) query = '';
  try {
    const response = await call('get', `${url}`, {
      params: {
        ...query
      }
    }); //?${query}`)
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response && error.response.data ? error.response.data : error;
  }
};

export const post = async (url, data, csrf_token) => {
  try {
    const response = await call('post', `${url}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    return error.response && error.response.data ? error.response.data : error;
  }
};

export const postWithResult = async (url, data) => {
  try {
    const response = await call('post', `${url}`, data);
    return {
      data: response.data,
      code: response.status,
      success: response.status >= 200 && response.status <= 400
    };
  } catch (error) {
    console.error(error);
    return {
      data: error.response && error.response.data ? error.response.data : error,
      code: (error.response && error.response.status) || -1,
      success: false
    };
  }
};

async function call(func, url, data) {
  let idToken;
  const headers = {
    'Content-Type': 'application/json'
  };
  if (firebaseService.auth && firebaseService.auth.currentUser) {
    idToken = await firebaseService.auth.currentUser.getIdToken(true);
    headers['Authorization'] = idToken;
  }
  const options = {
    method: func,
    withCredentials: true,
    headers,
    data: func === 'post' ? data : qs.stringify(data),
    params: func === 'get' ? data.params : '', //qs.stringify(data),
    url:
      (process.env.NODE_ENV === 'production'
        ? navigator.userAgent == 'ReactSnap'
          ? 'http://localhost:4001'
          : process.env.REACT_APP_API_ENDPOINT
        : '/api') + url //+ (func === "post" ? '/' : '')
  };
  return axios(options);
}
