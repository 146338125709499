export const selectReservationStartDate = (state) => state.reservation.startDate;

export const selectReservationEndDate = (state) => state.reservation.endDate;

export const selectVesselLength = (state) => state.reservation.vesselLength;

export const selectVesselBeam = (state) => state.reservation.vesselBeam;

export const selectVesselDraft = (state) => state.reservation.vesselDraft;

export const selectUnits = (state) => state.reservation.units;

export const selectVesselType = (state) => state.reservation.vesselType;

export const selectHullType = (state) => state.reservation.hullType;

export const selectNatureOfTravel = (state) => state.reservation.natureOfTravel;

export const selectRedeemNights = (state) => state.reservation.redeemNights;

export const selectLiveAboard = (state) => state.reservation.liveAboard;

export const selectReservationProposal = (state) => state.reservation.proposal;

export const selectReservationTimerStartTime = (state) => state.reservation.timerStart;

export const selectReservationSecondsLeft = (state) => state.reservation.secondsLeft;

export const selectReservationTimerExtended = (state) => state.reservation.timerExtended;

export const selectBoaterFirstName = (state) => state.reservation.firstName;

export const selectBoaterLastName = (state) => state.reservation.lastName;

export const selectBoaterPhone = (state) => state.reservation.phone;

export const selectBoaterAddress = (state) => state.reservation.address;

export const selectBoaterAddressLine2 = (state) => state.reservation.addressLineTwo;

export const selectPostalCode = (state) => state.reservation.postCode;

export const selectCountry = (state) => state.reservation.country;

export const selectBoaterMail = (state) => state.reservation.mail;

export const selectVesselName = (state) => state.reservation.vesselName;

export const selectNotes = (state) => state.reservation.notes;

export const selectReservationDetails = (state) => state.reservation.reservationDetails;

export const selectForceLogin = (state) => state.reservation.forceLogin;

export const selectPayment = (state) => state.reservation.reservationDetails?.payment;

export const selectFinished = (state) => state.reservation.finished

export const selectVessel = (state) => state.reservation.vessel;

export const selectVesselsSameDimensions = (state) => state.reservation.vesselsSameDimensions;

export const selectIsNewVessel = (state) => state.reservation.isNewVessel;

export const selectHomeMarinaId = (state) => state.reservation.homeMarinaId;

export const selectHomeMarinaName = (state) => state.reservation.homeMarinaName;

export const selectWaterElectricity = (state) => state.reservation.needWaterElectricity;

export const selectPasseportEscales = (state) => state.reservation.passeportEscales;

export const selectIsCardError = (state) => state.reservation.cardError;

export const selectForceTimer = (state) => state.reservation.forceTimer;

export const selectContractId = (state) => state.reservation.contract_id;

export const selectError = (state) => state.reservation.error;

export const selectPromotions = (state) => state.reservation.promotions;

export const selectIsLoggedInStep1 = (state) => state.reservation.isLoggedInStep1;

export const selectIsRequest = (state) => state.reservation.isRequest;

export const selectVesselPhoto = (state) => state.reservation.vesselPhoto;

export const selectSkiperId = (state) => state.reservation.skiperId;

export const selectVesselLicense = (state) => state.reservation.vesselLicense;

export const selectVesselInsurance = (state) => state.reservation.vesselInsurance;

export const selectAttachment_Ids = (state) => state.reservation.attachment_ids;

export const selectCalculatedPrice = (state) => state.reservation.calculatedPrice;
