import { GoogleSpreadsheet } from 'google-spreadsheet';

const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

export const createSpreadsheetReference = ({ spreadsheetId }) => {
  return new GoogleSpreadsheet(spreadsheetId);
};

export const appendSpreadsheet = async ({ doc, newRow, sheetId }) => {
  try {
    await doc.useServiceAccountAuth({
      client_email: CLIENT_EMAIL,
      private_key: PRIVATE_KEY
    });
    // loads document properties and worksheets
    await doc.loadInfo();

    const sheet = doc.sheetsById[sheetId];
    await sheet.addRow(newRow);
  } catch (e) {
    console.error(e);
  }
};
