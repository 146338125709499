import { hotjar } from 'react-hotjar';

if (process.env.REACT_APP_HOTJAR_ID) {
    try {
        hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, 6);
    } catch (e) {
        console.error(e)
    }
}

export const startTrackingHotjar = () => {} 

export default hotjar;