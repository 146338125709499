import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CookieConcent from 'components/UI/CookieConcent/CookieConcent';
import * as Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import { HOME_PAGE } from 'settings/constant';

export const CookieContext = React.createContext({});

const CookieProvider = ({ children, location }) => {
  const [accepted, setAccepted] = useState(null);
  const [visible, setVisible] = useState(false);
  const [cookieParams, setCookieParams] = useState([]);

  const isIframe = window.location.pathname?.includes('/iframe');

  useEffect(() => {
    const campaignFromSearch = new URLSearchParams(window.location.search).get(
      'campaign'
    );
    const originFromSearch = new URLSearchParams(window.location.search).get(
      'origin'
    );

    const campaignValue = !isEmpty(campaignFromSearch)
    ? campaignFromSearch
    : !isEmpty(originFromSearch)
    ? originFromSearch
    : '';

    if (!isEmpty(campaignValue)) {

      setCookieParams([...cookieParams, {key: 'campaign', value: campaignValue}])
    }

    const acceptCookies = Cookies.get('accepts-cookies');

    if (acceptCookies === undefined) {
      setVisible(true);
    } else {
      setAccepted(acceptCookies);
      if (acceptCookies === 'accepted') {
        Cookies.set('accepts-cookies', 'accepted', { expires: 365 * 10 });
        setCookieParamsFromState();
      } else {
        Cookies.set('accepts-cookies', 'declined');
      }
    }
  }, []);

  const onAccept = () => {
    Cookies.set('accepts-cookies', 'accepted', { expires: 365 * 10 });
    setAccepted('accepted');
    setCookieParamsFromState();
    setVisible(false);
  };
  const onDecline = () => {
    Cookies.set('accepts-cookies', 'declined');
    setAccepted('declined');
    setVisible(false);
  };
  const onClose = () => {
    setVisible(false);
  };

  const setCookieParamsFromState = () => {
    cookieParams.forEach(param => {
      Cookies.set(param.key, param.value, { expires: 365 * 10 });
    })
  };

  return (
    <CookieContext.Provider
      value={{
        accepted,
        onAccept,
        onDecline,
        onClose
      }}
    >
      <CookieConcent visible={!isIframe && visible} />
      {children}
    </CookieContext.Provider>
  );
};

export default withRouter(CookieProvider);
