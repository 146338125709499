import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  .ant-menu {
    border-bottom: none;
    margin-bottom: 12px;
    .ant-menu-item {
      padding: 0;
      margin-right: 38px;
      margin-left: 0;
      &.ant-menu-item-selected {
        background-color: transparent;
      }
      &.ant-menu-item-active {
        border-color: transparent;
      }
      &:after {
        display: none;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .subscription-form {
    display: flex;
    max-width: 100%;
    .field-container {
      width: 288px;
      max-width: 100%;
    }
    .ant-form-item-label {
      padding-bottom: 4px;
      label {
        color: ${themeGet('text.13')};
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
      }
    }
    .ant-input,
    .ant-input.ant-input-status-error,
    .ant-input:hover {
      width: 100%;
      padding: 10px 16px;
      color: ${themeGet('text.12')};
      font-size: 17px;
      line-height: 24px;
      background-color: ${themeGet('color.32')} !important;
      border: none;
      box-shadow: none !important;
    }
  }
  .subscribe-submit-button {
    height: 44px;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    border-radius: 4px;
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 36px;
    margin-left: 8px;
  }
  @media only screen and (max-width: 1440px) {
    .subscription-form {
      .field-container {
        width: 220px;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .ant-menu {
      margin-bottom: 0;
      .ant-menu-item {
        height: 42px;
      }
    }
    .subscription-form {
      .field-container {
        width: 100%;
      }
    }
  }
`;

export const Title = styled.h5`
  color: ${themeGet('text.13')};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 37px;
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 14px;
  }
`;

export const SubscribedText = styled.p`
  color: ${themeGet('text.13')};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 0;
`;