import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ::selection {
    background: ${themeGet('primary.0', '#194AB9')};
    color: ${themeGet('color.1', '#ffffff')};
  }

  // #root:after {
  //   content: "testing only";
  //   position: fixed;
  //   width: 100%;
  //   height: 21px;
  //   background: #ffb800;
  //   top: -5px;
  //   // left: -2px;
  //   text-align: center;
  //   font-size: 13px;
  //   font-family: sans-serif;
  //   text-transform: uppercase;
  //   font-weight: bold;
  //   color: #fff;
  //   line-height: 27px;
  //   -ms-transform: rotate(-45deg);
  //   /* -webkit-transform: rotate(-45deg); */
  //   /* -webkit-transform: rotate(-45deg); */
  //   // -ms-transform: rotate(-45deg);
  //   /* transform: rotate(-45deg); */
  //   z-index: 100000;
  // }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Lato', sans-serif;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
    background-color: ${themeGet('color.1', '#ffffff')};

    main.ant-layout-content {
      flex: 1 0 auto;
      min-height: 1px;
      position: relative;
    }
  }

  .hotel-submission-form {
    .ant-progress-outer {
      position: relative;
      z-index: 9;
      left: 0;
      top: 20px;
    }
    .gm-style {
      > input[type="text"] {
        left: 9px !important;
        top: 46px !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .hotel-form-location {
      .ant-card-head-title {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700;
        color: ${themeGet('text.0', '#2C2C2C')};
      }
      .ant-card-body p {
        display: flex;
        color: ${themeGet('text.2', '#777777')};
        justify-content: flex-start;
        strong {
          color: ${themeGet('text.0', '#2C2C2C')};
          width: 30%;
        }
      }
    }
  }
  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-arrow{
        display: none;
      }
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('text.0', '#2C2C2C')};
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('text.0', '#2C2C2C')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#194AB9')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('primary.0', '#194AB9')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#194AB9')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('text.0', '#2C2C2C')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#2C2C2C')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
    &.marina-verification-tooltip,
    &.vessel-not-etitable-field-tooltip {
      max-width: none;
      &.is-mobile {
        width: 250px;
        min-height: 48px;
        .ant-tooltip-inner {
          width: 250px;
          min-height: 48px;
        }
      }
    }
  }

  .DateRangePicker_picker {
    z-index: 1000000 !important;
  }
  .ant-message {
    z-index: 1000000 !important;
  }

  .ant-calendar-picker-container {
    z-index: 1000000 !important;
  }

  .ant-select-dropdown {
    z-index: 1000000 !important;
    .ant-select-item-option {
      padding: 12px 12px;
      line-height: 1;
      min-height: 30px;
      &.ant-select-item-option-selected {
        background-color: #fafafa;
      }
      &.ant-select-item-option-active {
        background-color: rgba(0, 132, 137, 0.1);
      }
    }
  }

  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  .ant-dropdown {
    &.social_share_menu {
      z-index: 9999 !important;
      
      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet('text.0', '#2C2C2C')};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet('color.2', '#F7F7F7')};
          }
        }
      }
    }
  }

  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 121px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
            padding-bottom: 74px;
          }
        }
      }
    }
    &.wizard-drawer,
    &.incentive-drawer,
    &.messaging-drawer,
    &.popeye-request-drawer {
      .ant-drawer-content-wrapper {
        max-width: 100%;
      }
      .ant-drawer-header {
        padding: 0;
        border-bottom: 0;
      }
      .ant-drawer-body {
        padding: 0;
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }

  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 6px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > img {
        width: 100%;
        height: 134px;
        object-fit: cover;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex !important;
      background-color: ${themeGet('color.1', '#ffffff')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
    }
  }

  .ant-modal-wrap {
    &.image_gallery_modal {
      .ant-modal {
        top: 20px;
        padding-bottom: 0;
        @media only screen and (max-width: 767px) {
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-content{
          box-shadow: none;
          padding: 0;
          background: transparent;
          .ant-modal-body {
            padding: 0;
            margin: 0 auto; 
            position: relative;
          }
        }
        .image_gallery_close {
          background: ${themeGet('color.1', '#ffffff')};
          padding: 2px 0 0;
          border: 0;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 10px;
          right: 10px;
          border-radius: 50%;
          &:focus,
          &:hover {
            outline: none;
          }
          .anticon {
            font-size: 26px;
          }
        }
      }
    }
  }

  .quantity {
    button.btn {
      border: 1px solid ${themeGet('primary.0', '#194AB9')};
      svg {
        fill: ${themeGet('primary.0', '#194AB9')};
      }
      &:hover {
        background-color: ${themeGet('primary.0', '#194AB9')};
        svg {
          fill: #ffffff;
        }
      }
    }
    input[type="number"],
    input[type="number"].qnt-input {
      color: ${themeGet('text.0', '#2C2C2C')};
    }
  }

  .pac-container {
    border: 0;
    z-index: 99999;
    padding: 5px 0;
    margin-top: 0;
    border-radius: 3px;
    box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    @media only screen and (max-width: 991px) {
      margin-top: 0;
    }
    &::after {
      display: none;
    }
    .pac-item {
      border-top: 0;
      line-height: 30px;
      padding: 10px 14px;
      cursor: pointer;
      font-size: 13px;
      color: ${themeGet('text.1', '#909090')};
      .pac-icon {
        margin-top: 6px;
      }
      .pac-item-query {
        font-size: 15px;
        font-weight: 600;
        color: ${themeGet('text.0', '#2C2C2C')};  
        .pac-matched {
          font-weight: 700;
          color: ${themeGet('color.0', '#000000')};
        }
      } 
      &:hover {
        background-color: ${themeGet('color.2', '#F7F7F7')};
      }
    }
  }

  .reservation_modal {
    .ant-modal {
      width: 100% !important;
      max-width: 450px;
      padding: 0 15px;
      @media only screen and (max-width: 991px) {
        top: 50px;
      }
    }
    .ant-modal-content {
      box-shadow: none;
      .ant-modal-body {
        padding: 0;
        position: relative;
        .reservation_sidebar {
          box-shadow: 0 1px 10px rgba(0,0,0,0.16);
          header {
            position: relative;
            padding-bottom: 20px;
            border-color: ${themeGet('border.0', '#EBEBEB')};
            @media only screen and (max-width: 375px) {
              padding-top: 30px;
            }
          }
          .DateInput__small {
            width: 112px;
          }
          input {
            padding: 0 18px;
          }
          .DateRangePickerInput {
            input {
              padding: 0 9px;
            }
          }
          footer {
            border-color: ${themeGet('border.0', '#EBEBEB')};
          }
          &.instant_booking {
            header {
              padding-bottom: 0;
              @media only screen and (max-width: 375px) {
                padding-top: 0;
              }
            }
          }
        }
        > button.close {
          border: 0;
          padding: 0;
          top: 15px;
          right: 15px;
          height: auto;
          line-height: 1;
          position: absolute;
          font-size: 32px;
          background-color: transparent;
          color: ${themeGet('text.1', '#909090')};
          transition: all 0.3s ease;
          @media only screen and (max-width: 375px) {
            top: 10px;
            right: 10px;
            font-size: 25px;
          }
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          &::after {
            display: none;
          }
        }
      }
    }
    &.instant_booking {
      .ant-modal-body {
        > button.close {
          color: ${themeGet('color.1', '#ffffff')};
          &:hover,
          &:focus {
            outline: none;
            color: ${themeGet('color.21', '#eeeeee')};
          }
        }
      }
    }
  }

  .edit-reservation-confirm-modal {
    .ant-modal-header {
      background-color: ${themeGet('color.40', '#F2F2F2')};
    }
    .ant-modal-close {
      top: 7px;
    }
  }

  .confirm-modal {
    .ant-modal-header {
      background-color: ${themeGet('color.40', '#F2F2F2')};
    }
    .ant-modal-close {
      top: 7px;
    }
  }

  .reservation_sidebar {
    padding: 0 !important;
    background-color: ${themeGet('color.1', '#ffffff')};
    header {
      position: relative;
      padding-bottom: 27px;
      margin-bottom: 29px;
      padding: 25px 30px 26px 30px;
      border-bottom: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 25px 15px 26px 15px;
      }
      a {
        &:hover {
          color: #31b8bd;
        }
      }
    }

    p {
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      font-weight: 400;
      a {
        color: ${themeGet('primary.0', '#194AB9')};
        font-weight: 700;
        &:hover,
        &:focus {
          outline: 0;
          text-decoration: underline;
        }
      }

      &.reservation_price_disclaimer {
        color: ${themeGet('text.1', '#909090')};
      }
    }

    footer {
      padding: 25px 30px 28px 30px;
      margin-top: 29px;
      border-top: 1px solid ${themeGet('border.0', '#EBEBEB')};
      @media only screen and (max-width: 375px) {
        padding: 20px 15px 25px 15px;
      }

      p {
        margin-bottom: 0;
      }
    }

    &.instant_booking {
      header {
        padding: 0;
        margin-bottom: 7px;
      }
    }
  }

  .grid_column .placeholder {
    max-width: 100%;
  }

  .result-modal {
    .ant-modal-close {
      right: 8px;
      top: 14px;
    }
    .ant-modal-close-x {
      width: 34px;
      height: 30px;
      line-height: 30px;
    }
    .ant-modal-footer {
      border-top: 0;
      padding-top: 0;
    }
    .ant-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      &.ant-result-info {
        .ant-result-icon {
          .anticon.anticon-exclamation-circle {
            color: ${themeGet('primary.0', '#194AB9')};
          }
        }
      }
    }
    .ant-result-content {
      padding: 0;
      background-color: transparent;
    }
    .ant-result-icon {
      margin-bottom: 13px;
      .anticon {
        font-size: 59px;
      }
    }
    .ant-result-extra {
      margin-top: 20px;
    }
    .ant-result-title {
      color: ${themeGet('text.8', '#6a6a6a')};
      font-weight: bold;
      font-size: 25px;
      margin-top: 7px;
      margin-bottom: 10px;
      line-height: 30px;
      @media only screen and (max-width: 767px) {
        font-size: 22px;
      }
    }
    .ant-result-subtitle {
      color: ${themeGet('text.3', '#8d8d8d')};
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    .success-reservation-result {
      .ant-result-title {
        margin-top: 10px;
        max-width: 510px;
      }
    }

    .success-ar-result {
      .ant-result {
        padding: 63px 32px 54px;
        @media only screen and (max-width: 767px) {
          padding: 48px 32px;
        }
      }
      .ant-result-title {
        max-width: 475px;
        @media only screen and (max-width: 767px) {
          max-width: 420px;
        }
      }
    }

    .success-connect-result,
    .success-request-result {
      .ant-result-title {
        max-width: 536px;
        @media only screen and (max-width: 767px) {
          max-width: 470px;
        }
      }
    }

    .success-profile-update-result,
    .info-required-info-missing-result,
    .info-home-marina-reservation-result,
    .info-connect-result,
    .info-request-result {
      .ant-result {
        padding: 89px 32px 50px;
        @media only screen and (max-width: 767px) {
          padding: 48px 32px;
        }
      }
      .ant-result-title {
        margin-top: 2px;
      }
      .ant-result-extra {
        margin-top: 40px;
      }
    }

    .success-hmv-sent-result {
      .ant-result {
        padding: 78px 32px 48px;
        @media only screen and (max-width: 767px) {
          padding: 48px 32px;
        }
      }
      .ant-result-title {
        max-width: 560px;
        @media only screen and (max-width: 767px) {
          max-width: 490px;
        }
      }
      .ant-result-extra {
        margin-top: 28px;
      }
    }

    .info-cancel-hmv-result {
      .ant-result {
        padding: 68px 32px 70px;
        @media only screen and (max-width: 767px) {
          padding: 48px 32px;
        }
      }
      .ant-result-extra {
        margin-top: 36px;
      }
    }

    .info-reservation-result {
      .ant-result-subtitle {
        max-width: 500px;
      }
    }
  }

  .member-modal {
    &.moved-down {
      .ant-modal {
        padding-top: 100px;
      }
    }
    .ant-modal {
      padding-top: 50px;
    }
    .ant-modal-close {
      top: 1px;
      right: -2px;
    }
    .ant-modal-content {
      background-color: transparent;
    }
  }

  .confirm-email-modal,
  .request-membership-result,
  .connect-membership-result,
  .reached-limit-modal {
    .ant-modal-close {
      right: 8px;
      top: 14px;
    }
    .ant-modal-close-x {
      width: 34px;
      height: 30px;
      line-height: 30px;
    }
    .ant-modal-footer {
      border-top: 0;
      padding-top: 0;
    }
    .ant-result-content {
      padding: 0;
      background-color: transparent;
    }
    .ant-result {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .ant-result-icon {
      margin-bottom: 26px;
    }
    .ant-result-title {
      color: ${themeGet('text.0', '#2C2C2C')};
      font-weight: bold;
      font-size: 25px;
      margin-bottom: 23px;
      line-height: 30px;
      @media only screen and (max-width: 1200px) {
        font-size: 22px;
        line-height: 26px;
      }
    }
    .ant-result-subtitle {
      color: #8d8d8d;
      max-width: 400px;
      p {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 21px;
        @media only screen and (max-width: 1200px) {
          font-size: 14px;
          line-height: 20px;
        }
        span {
          color: ${themeGet('primary.0', '#194AB9')};
          font-weight: bold;
        }
        b {
          display: block;
          color: ${themeGet('text.0', '#2C2C2C')};
          font-size: 16px;
        }
      }
    }
    .ant-result-extra {
      margin-top: 27px;
      @media only screen and (max-width: 1200px) {
        margin-top: 20px;
      }
      button {
        font-weight: bold;
        height: 47px;
        min-width: 211px;
        font-size: 15px;
        @media only screen and (max-width: 1200px) {
          min-width: 111px;
          font-size: 14px;
        }
      }
    }
  }

  .confirm-email-modal {
    .ant-result-title {
      font-size: 32px;
      line-height: 42px;
    }
    .ant-result-subtitle {
      p {
        font-size: 18px;
        @media only screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
    .ant-result-content {
      margin-top: 0;
    }
  }

  .reached-limit-modal {
    .ant-modal-close {
      right: 17px;
      top: 19px;
    }
    .ant-result-icon {
      margin-bottom: 19px;
    }
    .ant-result-title {
      max-width: 410px;
      margin-bottom: 17px;
    }
    .ant-result-subtitle {
      max-width: 100%;
      p {
        font-size: 20px;
        font-weight: bold;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    .ant-result-extra {
      margin-top: 38px;
      margin-bottom: 27px;
      @media only screen and (max-width: 767px) {
        margin-top: 27px;
        margin-bottom: 0;
      }
      button {
        @media only screen and (max-width: 1200px) {
          min-width: 200px;
          font-size: 14px;
        }
      }
    }
  }

  .ant-btn.disabled,
  .ant-btn[disabled] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }

  .dialog-modal {
    .ant-modal {
      padding-bottom: 0;
    }
    .ant-modal-footer {
      margin: 0 30px;
      padding: 10px 0;
    }
  }

  .confirm-modal {
    .ant-modal-title {
      padding-left: 6px;
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      color: ${themeGet('text.2', '#4a4a4a')};
    }
    .ant-modal-body {
      padding: 30px;
      @media only screen and (max-width: 480px) {
        padding: 24px;
      }
    }
    .ant-modal-footer {
      padding: 15px 30px 18px;
      @media only screen and (max-width: 480px) {
        padding: 15px 24px 18px;
      }
    }
  }

  .reservation-summary-modal {
    .ant-modal-title{
      color: ${themeGet('text.2', '#4a4a4a')};
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
    }
    .ant-modal-header {
      border-bottom-width: 7px;
    }
    &.wizard-mode {
      .ant-modal-header {
        border-bottom-width: 0;
      }
    }
  }

  .documents-preview-modal {
    .ant-modal-title {
      padding-left: 6px;
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      color: ${themeGet('text.2', '#4a4a4a')};
    }
    .ant-modal-close {
      top: 7px;
    }
  }

  .share-tooltip-overlay,
  .membership-menu-tooltip,
  .date-picker-tooltip {
    z-index: 99999;
  }

  .documents-reservation-modal {
    .ant-modal {
      @media only screen and (max-width: 1200px) {
        max-width: calc(100vw - 16px);
      }
    }
  }

  .absence-report-banner {
    .ant-modal {
      @media only screen and (max-width: 991px) {
        margin-bottom: 41px;
      }
    }
    .ant-modal-content {
      border-radius: 16px;
    }
    .ant-modal-close {
      top: 2px;
      right: -2px;
    }
    .ant-modal-header {
      border-radius: 16px 16px 0 0;
      text-align: center;
      background-color: ${themeGet('color.25', '#495e6e')};
      padding: 57px 24px 47px;
      @media only screen and (max-width: 991px) {
        background-color: ${themeGet('color.1', '#ffffff')};
        padding: 24px;
        border-bottom: 1px solid transparent;
      }
      .ant-modal-title {
        font-size: 25px;
        color: ${themeGet('color.1', '#ffffff')};
        font-weight: 700;
        line-height: 30px;
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
    }
  }

  .auth-modal {
    .ant-modal {
      padding-bottom: 0;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-header {
      background-color: ${themeGet('color.25', '#495e6e')};
      border-radius: 8px 8px 0 0;
    }
    .ant-modal-close {
      top: 5px;
      .ant-modal-close-x {
        width: 44px;
      }
    }
    .ant-modal-body {
      padding: 20px 24px;
    }
  }

  .popeye-request-modal {
    .ant-modal {
      padding-bottom: 0;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-body {
      padding: 88px 88px 24px;
    }
    .ant-modal-close {
      top: 14px;
      right: 6px;
    }
  }
  .popeye-signature-modal {
    .ant-modal {
      padding-bottom: 0;
    }
    .ant-modal-body {
      padding: 24px 24px 30px;
    }
    .ant-modal-content {
      border-radius: 8px;
    }
    .ant-modal-close {
      top: 8px;
      right: 10px;
    }
    .ant-modal-close-x {
      width: 30px;
      height: 30px;
    }
  }

  .message-link {
    padding: 7px 24px 7px 12px;
    border: 2px solid ${themeGet('primary.0', '#194AB9')};
    color: ${themeGet('primary.0', '#194AB9')};
    border-radius: 4px;
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    transition: 0.2s linear;
    &:hover {
      color: ${themeGet('primary.0', '#194AB9')};
      opacity: 0.8;
    }
    .message-icon {
      width: 21px;
      height: 21px;
      margin-right: 3px;
    }
    @media only screen and (max-width: 991px) {
      padding: 7px 12px;
      .message-icon {
        margin-right: 5px;
      }
    }
    @media only screen and (max-width: 767px) {
      .message-icon {
        display: none
      }
    }
  }

  .message-link-reservation {
    padding: 12px 24px 12px 24px;
    border-radius: 4px;
    border: solid 1px ${themeGet('primary.0', '#194AB9')};
    color: ${themeGet('primary.0', '#194AB9')};
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: 0.2s linear;
    &:hover {
      color: ${themeGet('primary.0', '#194AB9')};
      opacity: 0.8;
    }
    .message-icon {
      width: 21px;
      height: 21px;
      margin-right: 6px;
    }
    @media only screen and (max-width: 991px) {
      padding: 7px 12px;
      .message-icon {
        margin-right: 5px;
      }
    }
    @media only screen and (max-width: 767px) {
      .message-icon {
        display: none
      }
    }
  }

  .birthday-date-picker {
    &.birthday-date-picker-input-hidden {
      .ant-calendar-input-wrap {
        display: none;
      }
    }
    .ant-picker-today-btn,
    .ant-picker-header-view button:hover {
      color: ${themeGet('primary.0', '#194AB9')};
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: ${themeGet('primary.0', '#194AB9')};
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: ${themeGet('primary.0', '#194AB9')};
    }
  }

  span.required-asterisk {
    color: ${themeGet('color.17', '#ff495c')};
  }
  
  .absence-report-modal {
    .ant-modal-header {
      border-bottom-color: transparent;
      padding: 16px 20px;
    }
    .ant-modal-title {
      font-size: 25px;
      line-height: 28px;
      font-weight: 700;
    }
    .ant-modal-close {
      top: 10px;
    }
    .ant-modal-body {
      padding: 14px 38px 40px;
    }
    @media only screen and (max-width: 991px) {
      .ant-modal {
        padding-bottom: 50px;
      }
      .ant-modal-header {
        padding: 16px 38px;
      }
      .ant-modal-title {
        font-size: 22px;
        line-height: 26px;
      }
      .ant-modal-body {
        padding: 14px 38px 20px;
      }
    }
  }
  
  .discover-sticky-wrapper {
    width: 100%;
    .sticky-inner-wrapper {
      width: 100%;
      border-bottom: 1px solid ${themeGet('border.10', '#828282')};
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .header-sticky-wrapper,
  .alert-sticky-wrapper,
  .filter-sticky-wrapper {
    .sticky-inner-wrapper {
      transition: 0.2s linear;
    }
  }

  .ant-avatar {
    &.ant-avatar-image {
      &.contained {
        > img {
          object-fit: contain;
        }
      }
    }
  }

  .custom_radio {
    .ant-radio-checked .ant-radio-inner {
      border-color: ${themeGet('primary.0', '#194AB9')} !important;
    }
    .ant-radio-checked .ant-radio-inner:after {
      background-color: ${themeGet('primary.0', '#194AB9')};
    }
    .ant-radio:hover .ant-radio-inner {
      border-color: ${themeGet('primary.0', '#194AB9')};
    }
    .ant-radio-checked .ant-radio-inner:focus {
      border-color: ${themeGet('primary.0', '#194AB9')};
    }
    .ant-radio-checked::after {
      border-color: ${themeGet('primary.0', '#194AB9')};
    }
    &.partners_radio {
      span.ant-radio {
        margin-top: 3px;
      }
    }
  }

  .ant-switch {
    &.ant-switch-checked {
      background-color: ${themeGet('primary.0', '#194AB9')};
    }
  }

  button.ant-btn {
    &.ant-btn-link {
      color: ${themeGet('primary.0', '#194AB9')};
    }
    &.ant-btn-primary {
      border: 0;
      background-color: ${themeGet('primary.0', '#194AB9')};
    }
    &.ant-btn-link,
    &.ant-btn-primary {
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .ant-progress {
    .ant-progress-inner {
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: ${themeGet('primary.0', '#194AB9')};
      }
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${themeGet('primary.0', '#194AB9')};
    }
  }

  .ant-checkbox {
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${themeGet('primary.0', '#194AB9')};
        border-color: ${themeGet('primary.0', '#194AB9')};
      }
      &:after {
        border-color: ${themeGet('primary.0', '#194AB9')};
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: ${themeGet('primary.0', '#194AB9')};
      }
    }
  }

  .route-loader {
    .ant-spin-dot-item {
      background-color: ${themeGet('primary.0', '#194AB9')};
    }
  }

  .ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
    @media only screen and (max-width: 767px) {
      .ant-menu-item a:hover,
      .ant-menu-item-selected a,
      .ant-menu-item-selected a:hover {
        color: ${themeGet('primary.0', '#194AB9')};
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent;
      }
    }
  }
  
  .CalendarDay__selected {
    background: ${themeGet('color.32')};
    color: ${themeGet('color.16')};
    border-color: ${themeGet('color.32')};
    :hover {
      background: ${themeGet('color.34')};
      color: ${themeGet('color.16')};
      border-color: ${themeGet('color.34')};
    }
  }
  .CalendarDay__hovered {
    background: ${themeGet('color.32')};
    color: ${themeGet('color.16')};
    border-color: ${themeGet('color.32')};
    :hover {
      background: ${themeGet('color.34')};
      color: ${themeGet('color.16')};
      border-color: ${themeGet('color.34')};
    }
  }  
  .CalendarDay__selected_span {
    background: ${themeGet('color.32')};
    color: ${themeGet('color.16')};
    border-color: ${themeGet('color.32')};
    :hover {
      background: ${themeGet('color.34')};
      color: ${themeGet('color.16')};
      border-color: ${themeGet('color.34')};
    }
  }
  .CalendarDay__hovered_span {
    background: ${themeGet('color.32')};
    color: ${themeGet('color.16')};
    border-color: ${themeGet('color.32')};
    :hover {
      background: ${themeGet('color.34')};
      color: ${themeGet('color.16')};
      border-color: ${themeGet('color.34')};
    }
  }
  .ant-tooltip-arrow {
    opacity: 0;
  }
  .price-marker {
    width: initial !important;
    overflow: visible !important; 
  }
  .reservations-wrapper {
    padding: 0 !important;
    margin: 35px 40px 0 40px !important;
    @media only screen and (max-width: 480px) {
      margin: 4vw 4vw 0 4vw !important;
    }
  }
  .date_picker {
    margin-bottom: 0;
    &.error {
      .DateInput_input {
        &::placeholder {
          color: #F9503D !important;
        }
      }
    }
    &.disabled {
      .DateInput_input {
        &::placeholder {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
    > label {
      margin-bottom: 0;
      width: 100%;
    }
    .DateRangePicker {
      width: 100%;
      input,
      .DateRangePickerInput {
        border: 0;
        width: 100%;
        height: 54px;
        display: flex;
        padding: 0 9px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 3px;
        align-items: center;
        justify-content: space-between;
        color: #2C2C2C;
        background-color: #F7F7F7;
        &::placeholder {
          font-size: 15px;
          color: #2C2C2C;
        }
        .DateInput {
          width: auto;
          .DateInput_fang {
            top: 46px !important;
          }
        }
        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
        &.DateRangePickerInput__showClearDates {
          padding: 0 22px 0 9px;
        }
      }
      .DateRangePicker_picker {
        top: 56px !important;
        .DayPicker {
          width: 316px !important;
          .DayPicker_focusRegion {
            .DayPicker_transitionContainer {
              width: 316px !important;
              .CalendarMonth_caption {
                font-size: 16px;
                color: #2C2C2C;
              }
            }
          }
        }
      }
      .DateRangePickerInput_clearDates {
        outline: none;
        width: 25px;
        height: 25px;
        padding: 0;
        transition: background-color 0.2s ease;
        border-radius: 50%;
        .DateRangePickerInput_clearDates_svg__small {
          position: relative;
          top: -2px;
        }
      }
    }
  }
`;

export default GlobalStyle;
