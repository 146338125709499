import React from 'react';
import { ReactSVG } from 'react-svg';
import { Trans, useTranslation } from 'react-i18next';
import Modal from 'components/UI/Antd/Modal/Modal';
import HMVpopupHeader from 'assets/images/HMVpopupHeader.svg';
import {
  CTADeclineButton,
  CTAstyledButton,
  StyledBody,
  StyledHeader,
  StyledTitle,
  ImageWrapper
} from './HMVpopup.style';

import { sanitizeTranslation } from 'library/helpers/translationHelpers';

const HMVpopup = ({ visible, onClose, history, locale, marinaName }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      zIndex={10001}
      width={576}
      centered={true}
      bodyStyle={{ padding: 0 }}
      footer={null}
      closable={false}
    >
      <StyledHeader>
        <ImageWrapper>
          <ReactSVG src={HMVpopupHeader} />
        </ImageWrapper>
        <StyledTitle>
          {t('AUTO_VACANCY_POPUP_HEADER', {
            marina_name: marinaName
          })}
        </StyledTitle>
      </StyledHeader>
      <StyledBody>
        {sanitizeTranslation(
          t('AUTO_VACANCY_POPUP_BODY', { marina_name: marinaName })
        )}
      </StyledBody>
      <CTAstyledButton onClick={onClose}>
        {t('AUTO_VACANCY_POPUP_CTA')}
      </CTAstyledButton>
      <CTADeclineButton
        onClick={() => {
          onClose();
          history.push(`/${locale}/profile`);
        }}
      >
        {t('AUTO_VACANCY_POPUP_NOT_CTA')}
      </CTADeclineButton>
    </Modal>
  );
};

export default HMVpopup;
