import React, { useContext, useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import Modal from 'components/UI/Antd/Modal/Modal';
import SignUpForm from 'components/AuthForms/SignUpForm';
import SignInForm from 'components/AuthForms/SignInForm';
import Spinner from 'components/Spinner/Spinner';
import {
  AuthModalHeader,
  AuthModalTitle,
  AuthModalBody,
  FormWrapper
} from './AuthModal.style';

import { EMAIL_VERIFICATION_PAGE } from 'settings/constant';

// icons
import wheelWhiteIcon from 'assets/images/pickapier/wheel-white.svg';

import useWindowSize from 'library/hooks/useWindowSize';
import useAmplitude from 'library/hooks/useAmplitude';

const AuthModalTest = ({ title, visible, onClose, history, match }) => {
  const { t } = useTranslation();
  const {
    loggedIn,
    signInFacebookAccount,
    signInGoogleAccount,
    signUp,
    signIn,
    waitForUserLoggedIn
  } = useContext(AuthContext);
  const { height } = useWindowSize();

  const [isAuthProcess, setIsAuthProcess] = useState(false);
  const [isSignUpAuthMode, setIsSignUpAuthMode] = useState(true);
  const [signupError, setSignupError] = useState('');
  const [loginError, setLoginError] = useState('');
  const [loaders, setLoaders] = useState({
    facebookBtnLoading: false,
    googleBtnLoading: false
  });
  const [className, setClassName] = useState('');

  const { sendToAmplitude } = useAmplitude();

  useEffect(() => {
    const className =
      height < 830
        ? `small ${isSignUpAuthMode ? 'signup' : 'login'}`
        : `${isSignUpAuthMode ? 'signup' : 'login'}`;
    setClassName(className);
  }, [height, isSignUpAuthMode]);

  const resetAuthState = () => {
    onClose();
    setTimeout(() => {
      setIsAuthProcess(false);
      setIsSignUpAuthMode(true);
    }, 500);
  };

  const onCancelAuth = () => {
    resetAuthState();
  };

  const handleSignupSubmit = async formProps => {
    const { first_name, last_name, email } = formProps;
    setSignupError('');
    if (!loggedIn) {
      setIsAuthProcess(true);

      try {
        const boater = await signUp({ ...formProps, onError: setSignupError });
        await waitForUserLoggedIn();
        resetAuthState();

        // Amplitude event
        const boaterDetailsForAmplitude = {
          first_name,
          last_name,
          email
        };
        const eventProperties = {
          source: 'header',
          type: 'email',
          email: email
        };

        sendToAmplitude({
          identificationData: { boater },
          boaterProperties: {
            boater: boaterDetailsForAmplitude,
            initialize_properties: true
          },
          eventProperties,
          eventName: 'signup'
        });

        setTimeout(
          () =>
            history.push({
              pathname: `/${match.params.locale}${EMAIL_VERIFICATION_PAGE}`,
            }),
          500
        );
      } catch (error) {
        setIsAuthProcess(false);
      }
    }
  };

  const handleLoginSubmit = async formProps => {
    setLoginError('');
    if (!loggedIn) {
      setIsAuthProcess(true);
      try {
        await signIn({ ...formProps, onError: setLoginError });
        await waitForUserLoggedIn();
        resetAuthState();

        // Amplitude event
        const eventProperties = {
          source: 'header'
        };

        sendToAmplitude({
          eventProperties,
          eventName: 'login'
        });
      } catch (error) {
        setIsAuthProcess(false);
      }
    }
  };

  const googleAuthAction = async isSignUp => {
    setLoaders({ googleBtnLoading: true });
    setIsAuthProcess(true);
    const boater = await signInGoogleAccount({
      onError: console.log,
      isSignUp
    });
    setLoaders({ googleBtnLoading: false });
    resetAuthState();

    const eventProperties = {
      source: 'header',
      email: isSignUp ? boater.email : '-'
    };
    if (isSignUp) {
      eventProperties.type = 'google';
    }

    sendToAmplitude({
      identificationData: { boater },
      eventProperties,
      eventName: isSignUp ? 'signup' : 'login'
    });
  };

  const facebookAuthAction = async isSignUp => {
    setLoaders({ facebookBtnLoading: true });
    setIsAuthProcess(true);
    const boater = await signInFacebookAccount({
      onError: console.log,
      isSignUp
    });
    setLoaders({ facebookBtnLoading: false });
    resetAuthState();

    //Amplitude event
    const eventProperties = {
      source: 'header',
      email: isSignUp ? boater.email : '-'
    };

    if (isSignUp) {
      eventProperties.type = 'facebook';
    }

    sendToAmplitude({
      identificationData: { boater },
      eventProperties,
      eventName: isSignUp ? 'signup' : 'login'
    });
  };

  return (
    <Modal
      zIndex={10001}
      width={596}
      title={
        <AuthModalHeader className={className}>
          <ReactSVG className='wheel-icon' src={wheelWhiteIcon} />
          <AuthModalTitle>{title}</AuthModalTitle>
        </AuthModalHeader>
      }
      visible={visible}
      centered={true}
      onCancel={onCancelAuth}
      wrapClassName={`auth-modal ${className}`}
      closeIcon={<MdClose size={24} style={{ color: '#ffffff' }} />}
      footer={null}
    >
      <AuthModalBody>
        <FormWrapper>
          {isSignUpAuthMode ? (
            <SignUpForm
              handleSubmit={handleSignupSubmit}
              error={signupError}
              googleBtnLoading={loaders.googleBtnLoading}
              googleAuthAction={() => googleAuthAction(true)}
              facebookBtnLoading={loaders.facebookBtnLoading}
              facebookAuthAction={() => facebookAuthAction(true)}
              onSignInLinkClick={e => {
                e.preventDefault();
                setIsSignUpAuthMode(false);
              }}
              signUpCtaLabel={t('POPUP_SIGNUP_CTA')}
              loginText={t('POPUP_SIGNUP_LOGIN_TEXT')}
              loginLinkLabel={t('POPUP_SIGNUP_LOGIN_LINK')}
            />
          ) : (
            <SignInForm
              handleSubmit={handleLoginSubmit}
              error={loginError}
              googleBtnLoading={loaders.googleBtnLoading}
              googleAuthAction={() => googleAuthAction(false)}
              facebookBtnLoading={loaders.facebookBtnLoading}
              facebookAuthAction={() => facebookAuthAction(false)}
              onSignUpLinkClick={e => {
                e.preventDefault();
                setIsSignUpAuthMode(true);
              }}
            />
          )}
        </FormWrapper>
        <Spinner
          spinning={isAuthProcess}
          tip={
            isSignUpAuthMode
              ? t('SIGNING_UP_SPINNER_TEXT')
              : t('SIGNING_IN_SPINNER_TEXT')
          }
        />
      </AuthModalBody>
    </Modal>
  );
};

export default withRouter(AuthModalTest);
