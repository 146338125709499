import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const AuthModalHeader = styled.div`
  padding-top: 12px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.small {
    &.signup {
      padding: 5px 0;
      .wheel-icon {
        display: none;
      }
      h3 {
        font-size: 22px;
        margin: 0;
      }
    }
  }
`;

export const AuthModalTitle = styled.h3`
  margin-top: 8px;
  font-size: 25px;
  font-weight: bold;
  color: ${themeGet('color.1', '#ffffff')};
`;

export const AuthModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormWrapper = styled.div`
  padding-top: 10px;
  max-width: 384px;
  width: 100%;
  .ant-divider {
    .ant-divider-inner-text {
      font-size: 15px;
      color: ${themeGet('text.2', '#777777')};
    }
  }
  .ant-form-item {
    margin-bottom: 30px;
    .ant-form-item-control-wrapper {
      .ant-form-item-control {
        .ant-form-item-children {
          .input-field {
            width: 100%;
            border-radius: 2px;
            border: 1px solid ${themeGet('border.3', '#E6E6E6')};
            height: 47px;
            padding: 6px 20px;
            color: ${themeGet('text.0', '#2C2C2C')};
            font-size: 14px;
            &::placeholder {
              color: ${themeGet('text.3', '#8d8d8d')};
            }
            &:focus {
              box-shadow: none;
            }
          }
        }
        .ant-form-item-explain {
          position: absolute;
        }
      }
    }
  }
  .signin-btn,
  .signup-btn {
    height: 47px;
    width: 100%;
    border-radius: 2px;
    font-weight: bold;
  }
  form.error {
    .ant-form-item {
      margin-bottom: 10px;
    }
    .ant-alert {
      margin-bottom: 19px;
    }
  }
`;
