import { useState, useCallback } from 'react';
import {
  amplitudeLogEvent,
  addIdentification,
  updateUserProperties
} from 'library/helpers/amplitude';
import isEmpty from 'lodash/isEmpty';
import useCookie from './useCookie';

const useAmplitude = () => {
  const [campaignParam, setCampaignParam] = useState('');
  const [resolutionParam, setResolutionParam] = useState('');

  const { getCookie } = useCookie();

  // Add required parameter for each SEASON (depends on cookies):

  /* 
    As required by the product team:
      There could be only one param campaign/origin in searchParams.
      Either of them should be the value for the campaign param 
        which sent to the amplitude
  */
  if (isEmpty(campaignParam)) {
      const campaignFromCookie = getCookie('campaign');

      if (!isEmpty(campaignFromCookie)) {
        setCampaignParam(campaignFromCookie);
      }
  }

  // Add required parameters for each EVENT:

  if (isEmpty(resolutionParam)) {
    const screenResolution = window
      ? `${window.innerWidth}X${window.innerHeight}`
      : `unable-to-provide-resolution`;
    setResolutionParam(screenResolution);
  }

  // Return function for send amplitude event
  const sendToAmplitude = useCallback(
    details => {
      const acceptCookies = getCookie('accepts-cookies');

      if (acceptCookies !== 'accepted') {
        return;
      }

      const {
        boaterProperties,
        eventProperties,
        identificationData,
        eventName
      } = details;
    
      const eventPropertiesExtended = {
        ...eventProperties,
        resolution: resolutionParam,
        campaign: eventProperties && eventProperties.campaign ? eventProperties.campaign : campaignParam
      };
      // Not every event requires identification/update boater properties, therefore returning an empty promise.
      const emptyPromise = () => new Promise(() => {});
      const identify = !isEmpty(identificationData)
        ? addIdentification
        : emptyPromise;

      const updateBoaterProperties = !isEmpty(boaterProperties)
        ? updateUserProperties
        : emptyPromise;

      identify(identificationData).then(
        updateBoaterProperties(boaterProperties).then(
          amplitudeLogEvent(eventName, eventPropertiesExtended)
        )
      );
    },
    [resolutionParam, campaignParam]
  );
  return {
    sendToAmplitude
  };
};

export default useAmplitude;
