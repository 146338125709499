import React from 'react';
import { Checkbox, Form } from 'antd';

const FormItem = Form.Item;

const AntdCheckboxWithFormik = ({ label, field, form, children, ...props }) => {
  const { touched, errors } = form;
  return (
    <div className='field-container'>
      <FormItem
        label={label}
        help={
          touched[field.name] && errors[field.name] ? errors[field.name] : false
        }
        validateStatus={
          touched[field.name] && errors[field.name] ? 'error' : 'success'
        }
      >
        <Checkbox
          {...props}
          onChange={e => form.setFieldValue(field.name, e.target.checked)}
        >
          {children}
        </Checkbox>
      </FormItem>
    </div>
  );
};

export default AntdCheckboxWithFormik;
