import React from 'react';
import { MdClose } from 'react-icons/md';
import Modal from 'components/UI/Antd/Modal/Modal';
import { ModalBody } from './Modal.style';

const MyModal = ({ visible, onClose, width, children }) => {
  return (
    <Modal
      zIndex={10001}
      width={width || 673}
      visible={visible}
      centered={true}
      onCancel={onClose}
      wrapClassName={``}
      closeIcon={<MdClose size={30} style={{ color: '#000000' }} />}
      footer={null}
    >
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default MyModal;
