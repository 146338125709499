export const selectAppLoading = (state) => state.app.loading;

export const selectAppMessage = (state) => state.app.message;

export const selectEnableBackground = (state) => state.app.background;

export const selectMarinaId = (state) => state.app.marinaId;

export const selectRedirectUrl = (state) => state.app.redirectUrl;

export const selectRedirectUrlProvided = (state) => state.app.redirectUrlProvided;

export const selectMarina = (state) => state.app.marina;

export const selectMarinas = (state) => state.app.marinas;

export const selectMarinaData = (state) => state.app.marinaData;

export const selectReady = (state) => state.app.ready;

export const selectIframe = (state) => state.app.iframe;
