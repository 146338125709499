import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {AuthContext} from './AuthProvider';
import popeyeService from 'services/popeye';
import isNil from 'lodash/isNil';
import PopeyeRequestModal from 'components/UI/PopeyeRequestModal/PopeyeRequestModal';
import firebaseService from 'services/firebaseService.js'

export const PopeyeContext = React.createContext({});

const getAllPromotions = async () => {
  try {
    return await popeyeService.allmarinasPromotions();
  } catch (err) {
    console.error(err);
  }
};

const activatePopeye = async signatureData => {
  try {
    if (!(firebaseService.auth && firebaseService.auth.currentUser)) throw { error: 'NO_BOATER_AUTHORIZED' };
    return await popeyeService.activate({
      ...signatureData
    });
  } catch (err) {
    console.error(err);
  }
};

const getLivePromotions = async () => {
  try {
    return await popeyeService.allLive();
  } catch (err) {
    console.error(err);
  }
};

const PopeyeProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const [popeyeLoyaltyStatus, setPopeyeLoyaltyStatus] = useState(null);
  const [visibleRequest, setVisibleRequest] = useState(false);
  const [promotions, setPromotions] = useState(undefined);

  useEffect(() => {
    getAllPromotions().then(promotions => {
      setPromotions(promotions);
    })
  }, []);

  useEffect(() => {
    if (!isNil(user)) {
      setPopeyeLoyaltyStatus(user.popeye_loyalty_status);
    }
  }, [user]);

  const getMarinaPromotions = useCallback(async id => {
    try {
      if (isNil(user)) throw { error: 'NO_BOATER_AUTHORIZED' };
      return await popeyeService.marinaPromotions({marina_id: id});
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const getPopeyeStatistic = useCallback(async () => {
    try {
      if (isNil(user)) throw { error: 'NO_BOATER_AUTHORIZED' };
      return await popeyeService.statistic();
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const getPopeyeHistory = useCallback(async () => {
    try {
      if (isNil(user)) throw { error: 'NO_BOATER_AUTHORIZED' };
      return await popeyeService.history();
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  const value = useMemo(() => ({
    popeyeLoyaltyStatus,
    isPreSignatureStatus: popeyeLoyaltyStatus === 'PENDING_SIGNATURE',
    isPopeyeMember: popeyeLoyaltyStatus === 'ACTIVE',
    activatePopeye,
    getPopeyeStatistic,
    getPopeyeHistory,
    setVisibleRequest,
    getLivePromotions,
    getMarinaPromotions,
    getAllPromotions,
    promotions
  }), [promotions, popeyeLoyaltyStatus]);

  return (
    <PopeyeContext.Provider value={value}>
      {children}
      <PopeyeRequestModal
        visible={visibleRequest}
        onClose={() => setVisibleRequest(false)}
      />
    </PopeyeContext.Provider>
  );
};

export default PopeyeProvider;
