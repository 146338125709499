import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { isMobile } from 'react-device-detect';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import { GlobalContext, getMarinaTextId } from 'context/GlobalProvider';

import Button from 'components/UI/Antd/Button/Button';
import Badge from 'components/UI/Antd/Badge/Badge';
import Notification from './Notification';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { dateSortAsc, dateSortDesc } from 'library/helpers/utils';
import { setStateToUrl } from 'library/helpers/url_handler';
import notificationsService from 'services/notificationsService';
import useWindowSize from 'library/hooks/useWindowSize';
import {
  RESERVATION_PAGE,
  PROFILE_PAGE,
  AGENT_MEMBERSHIPS,
  AGENT_VESSEL_PAGE,
  MARINA_PAGE,
  MESSAGES_PAGE,
  ABSENCE_REPORTS_PAGE
} from 'settings/constant';
import {
  Trigger,
  NotificationsDropdown,
  NotificationsListHeader,
  NotificationsListBody,
  NotificationsList,
  NotificationsListFooter,
  EmptyNotifications,
  NewMessagesCounter
} from './Notifications.style';
import messagesService from 'services/messagesService';
// icons
import bellIcon from 'assets/images/pickapier/alarm-bell.svg';
import bellBadgeIcon from 'assets/images/pickapier/alarm-bell-badge.svg';

const UPDATE_DATE_FORMAT = 'MMM DD, HH:mm:ss';

const Notifications = ({ history, location, match, onAuth }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { updates, loggedIn, user, selectBoaterVessel } =
    useContext(AuthContext);
  const { marinas, setNumOfNotifs } = useContext(GlobalContext);
  const [state, dispatch] = useContext(LayoutContext);

  const [notifications, setNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [displayedNotifications, setDisplayedNotifications] = useState([]);
  const [displayAllNotifications, setDisplayAllNotifications] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const notificationUpdate = updates.notification
    ? updates.notification.format(UPDATE_DATE_FORMAT)
    : null;

  useEffect(() => {
    if (notificationUpdate && loggedIn && !isEmpty(user)) {
      getNotifications();
    }
  }, [notificationUpdate, updates, loggedIn, user]);

  useEffect(() => {
    if (!loggedIn) {
      // reset states after logout
      setNotifications([]);
      setNewNotifications([]);
      setNotificationsOpened(false);
      setDisplayedNotifications([]);
      setDisplayAllNotifications(false);
    }
  }, [loggedIn]);

  useEffect(() => {
    if (width <= 480 && notificationsOpened) {
      setTimeout(() => {
        // setTimeout need to correct position recalculation when swithing immediately frmom one droprown to another
        const { scrollY } = window;
        document.body.style.top = `-${scrollY}px`;
        document.body.style.position = 'fixed';
      }, 100);
    } else {
      if (document.body.style && document.body.style.position === 'fixed') {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  }, [width, notificationsOpened]);

  useEffect(() => {
    const newNotificationsCount = [...newNotifications].length;
    setNumOfNotifs(newNotificationsCount);
  }, [newNotifications]);

  const getNotifications = async () => {
    if (isFetching) {
      return false;
    }
    setIsFetching(true);
    const res = await notificationsService.get();
    let sorted = [];
    if (res && !res.error_type) {
      sorted = res.sort(dateSortAsc);
    }
    const notifications = sorted.filter(item => item.type !== 'INITIATED');
    setNotifications(notifications);
    setDisplayedNotifications(
      displayAllNotifications ? notifications : notifications.slice(0, 4)
    );
    setNewNotifications(notifications.filter(item => !item.read_at));
    setIsFetching(false);
  };

  const readAll = async arr => {
    const notificationsToRead = arr.filter(({ read_at }) => !read_at);
    if (notificationsToRead.length) {
      await notificationsService.read({
        notification_ids: notificationsToRead.map(({ id }) => id)
      });
      getNotifications();
    }
  };

  const readNotification = async item => {
    await notificationsService.read({ notification_ids: [item.id] });
    getNotifications();
  };

  const unreadNotification = async item => {
    await notificationsService.unread({ notification_ids: [item.id] });
    getNotifications();
  };

  const deleteNotification = async item => {
    await notificationsService.delete({ notification_ids: [item.id] });
    getNotifications();
  };

  const viewNotifications = async notification_ids => {
    await notificationsService.view({ notification_ids });
  };

  const triggerViewNotifications = async () => {
    if (newNotifications.length) {
      await viewNotifications(newNotifications.map(({ id }) => id));
      getNotifications();
    }
  };

  const toggleNotifications = () => {
    if (!loggedIn || !user) {
      if (width > 991) {
        onAuth();
      } else {
        dispatch({
          type: state.authDrawerOpened ? 'HIDE_AUTH_DRAWER' : 'OPEN_AUTH_DRAWER'
        });
      }
    } else {
      if (notificationsOpened) {
        setDisplayAllNotifications(false);
        setDisplayedNotifications(notifications.slice(0, 4));
        triggerViewNotifications();
      }
      setNotificationsOpened(!notificationsOpened);
    }
  };

  const closeNotifications = () => {
    if (notificationsOpened) {
      setNotificationsOpened(false);
      setDisplayAllNotifications(false);
      setDisplayedNotifications(notifications.slice(0, 4));
      triggerViewNotifications();
    }
  };

  const showAllNotifications = () => {
    setDisplayAllNotifications(true);
    setDisplayedNotifications(notifications);
  };

  const notificationDDRef = useRef(null);
  useOnClickOutside(notificationDDRef, closeNotifications);

  const onClickNotification = async item => {
    if (!item.read_at) {
      await readNotification(item);
    }

    let parcedObj = {};
    try {
      parcedObj = item.details;
    } catch (error) {
      parcedObj = {};
    }

    const keys = Object.keys(parcedObj);

    if (keys.length && keys.indexOf('membership_request_id') !== -1) {
      setTimeout(() => {
        history.push({
          pathname: `${match.url}${PROFILE_PAGE}${AGENT_MEMBERSHIPS}`
        });
      }, 400);
      return false;
    }

    if (keys.length && keys.indexOf('marina_request_id') !== -1) {
      let search;
      let pathname;
      let state = { ref: 'notification' };
      const textId = getMarinaTextId(marinas.find(({ id }) => id === parcedObj.sender_id));
      if (isMobile || width < 768) {
        search = { open_messages: true };
        pathname = `${match.url}${MARINA_PAGE}/${textId}`;
      } else {
        pathname = `${match.url}${MARINA_PAGE}/${textId}${MESSAGES_PAGE}`;
      }

      const vesselFormThisMarina = user.vessels.filter(
        ({ home_marina_id }) => home_marina_id === parcedObj.sender_id
      );

      if (vesselFormThisMarina.length) {
        selectBoaterVessel(vesselFormThisMarina[0].id);
      }
      setTimeout(() => {
        history.push({
          pathname,
          search: setStateToUrl(search),
          state
        });
      }, 400);
      return false;
    }

    if (keys.length && keys.indexOf('reservation_id') !== -1) {
      const search = item.text === 'NEW_MESSAGE_RECEIVED' && {
        reservation_tab: 'chat'
      };
      setTimeout(() => {
        history.push({
          pathname: `${match.url}${RESERVATION_PAGE}/${parcedObj.reservation_id}`,
          state: { ref: 'notification' },
          search: setStateToUrl(search)
        });
      }, 400);
      return false;
    }

    if (
      item.subject === 'MESSAGE' &&
      keys.length &&
      keys.indexOf('sender_id') !== -1
    ) {
      // if this is the direct message from marina
      const search = isMobile || width < 768 ? { open_messages: true } : null;
      const textId = getMarinaTextId(marinas.find(({ id }) => id === parcedObj.sender_id));
      const pathname =
        isMobile || width < 768
          ? `${match.url}${MARINA_PAGE}/${textId}`
          : `${match.url}${MARINA_PAGE}/${textId}${MESSAGES_PAGE}`;

      const vesselFormThisMarina = user.vessels.filter(
        ({ home_marina_id }) => home_marina_id === parcedObj.sender_id
      );

      if (vesselFormThisMarina.length) {
        selectBoaterVessel(vesselFormThisMarina[0].id);
      }
      setTimeout(() => {
        history.push({
          pathname,
          search: setStateToUrl(search)
        });
      }, 400);
      return false;
    }

    if (
      item.subject === 'ABSENCE_REPORT' &&
      keys.length &&
      keys.indexOf('absence_report_id') !== -1 &&
      keys.indexOf('vessel_id') !== -1
    ) {
      // if marina edits the absence report
      const vesselFormThisAR = user.vessels.find(
        ({ id }) => id === item.details.vessel_id
      );

      if (vesselFormThisAR) {
        selectBoaterVessel(vesselFormThisAR.id);
      } else {
        return false;
      }

      setTimeout(() => {
        history.push({
          pathname: `${match.url}${ABSENCE_REPORTS_PAGE}`,
          search: setStateToUrl({ absence_id: item.details.absence_report_id })
        });
      }, 400);
      return false;
    }

    if (
      item.subject === 'MARINA_UPDATED_VESSEL' &&
      keys.length &&
      keys.indexOf('vessel_id') !== -1
    ) {
      // if marina updates the vessel
      setTimeout(() => {
        history.push({
          pathname: `${match.url}${PROFILE_PAGE}${AGENT_VESSEL_PAGE}`,
          search: setStateToUrl({ vessel_id: item.details.vessel_id })
        });
      }, 400);
      return false;
    }

    if (
      item.subject === 'MARINA_UPDATED_BOATER' &&
      keys.length &&
      keys.indexOf('sender_id') !== -1
    ) {
      // if marina updates the boater profile
      setTimeout(() => {
        history.push({
          pathname: `${match.url}${PROFILE_PAGE}`
        });
      }, 400);
      return false;
    }

    if (item.subject === 'REMOVE_PARTNER') {
      // if this is the direct message from marina
      setTimeout(() => {
        history.push({
          pathname: `${match.url}${PROFILE_PAGE}${AGENT_VESSEL_PAGE}`
        });
      }, 400);
      return false;
    }
  };

  return (
    <div className='dropdown-menu-item' ref={notificationDDRef}>
      <Trigger
        className='additional-menu-trigger'
        onClick={toggleNotifications}
      >
        {loggedIn && user && newNotifications.length ? (
          <Badge
            size='small'
            style={{ backgroundColor: '#ff495c' }}
            count={newNotifications.length}
          >
            <ReactSVG
              className='additional-menu-trigger-icon'
              src={bellBadgeIcon}
            />
          </Badge>
        ) : (
          <ReactSVG className='additional-menu-trigger-icon' src={bellIcon} />
        )}
      </Trigger>

      <NotificationsDropdown
        className={`${notificationsOpened ? 'active' : 'hide'}`}
      >
        {!!notifications.length && (
          <NotificationsListHeader
            className={`${newNotifications.length ? 'unviewed' : ''}`}
          >
            <Button
              className='read_all'
              htmlType='button'
              onClick={() => readAll(notifications)}
              type='link'
            >
              {t('MARK_ALL_AS_READ')}
            </Button>
            {!!newNotifications.length && (
              <NewMessagesCounter>
                {newNotifications.length > 1
                  ? t('NEW_NOTIFICATIONS', { number: newNotifications.length })
                  : t('NEW_NOTIFICATION', { number: newNotifications.length })}
              </NewMessagesCounter>
            )}
          </NotificationsListHeader>
        )}
        <NotificationsListBody
          displayedAll={displayAllNotifications}
          isEmpty={!notifications.length}
        >
          {displayedNotifications.length ? (
            <NotificationsList>
              {displayedNotifications.map(item => (
                <Notification
                  key={item.id}
                  item={item}
                  opened={notificationsOpened}
                  onClick={() => {
                    onClickNotification(item);
                    closeNotifications();
                  }}
                  onUnread={unreadNotification}
                  onDelete={deleteNotification}
                />
              ))}
            </NotificationsList>
          ) : (
            <EmptyNotifications>
              {t('THERE_ARE_NO_NOTIFICATIONS_YET')}
            </EmptyNotifications>
          )}
        </NotificationsListBody>
        {!!(
          !displayAllNotifications &&
          notifications.length &&
          notifications.length > 4
        ) && (
          <NotificationsListFooter>
            <Link
              onClick={showAllNotifications}
              className='link'
              to={location.pathname}
            >
              {t('VIEW_ALL_NOTIFICATIONS')}
            </Link>
          </NotificationsListFooter>
        )}
      </NotificationsDropdown>
    </div>
  );
};

export default withRouter(Notifications);
