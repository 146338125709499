import moment, {isMoment} from 'moment';
import i18n from "i18next";

export const formatLocalizedDate = (date, hideYear = false) => new Intl.DateTimeFormat(i18n.language, {
    month: 'short', day: 'numeric', year: hideYear ? undefined : 'numeric'
}).format(isMoment(date) ? date.toDate() : new Date(date));

export const formatLocalizedDateRange = (startDate, endDate, hideYear = false) => {
    const start = moment(startDate);
    const end = moment(endDate);
    if(hideYear) {
        end.set('year', start.get('year'));
    }
    return new Intl.DateTimeFormat(i18n.language, {
        month: 'short', day: 'numeric', year: hideYear ? undefined : 'numeric'
    }).formatRange(start.toDate(), end.toDate());
}
