import React, { useContext } from 'react';
import { Route, Redirect, Switch, withRouter, useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';
import { AuthContext } from './context/AuthProvider';
import { PopeyeContext } from './context/PopeyeProvider';
import Layout from './container/Layout/Layout';
import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  COUNTRIES_LISTING_PAGE,
  MARINA_PAGE,
  PROFILE_PAGE,
  SETTINGS_PAGE,
  PRICING_PLAN_PAGE,
  RESERVATIONS_PAGE,
  RESERVATION_PAGE,
  ABSENCE_REPORTS_PAGE,
  DEMO_MAP_VIEW,
  POPEYE_SUCCESS_SCREEN,
  EMAIL_VERIFICATION_PAGE,
  POPEYE_PAGE,
  POPEYE_WELCOME_PAGE, DISCOVER_PAGE, IFRAME
} from './settings/constant';
import { Spin } from 'antd';

/**
 *
 * Public Routes
 *
 */
function Loading({}) {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spin className='route-loader' size='large' />
    </div>
  );
}

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ './container/Home/Home'),
      loading: Loading,
      modules: ['Home']
    }),
    exact: true
  },
  {
    path: IFRAME,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Reservation" */ './container/Reservation/Reservation'),
      loading: Loading,
      modules: ['Reservation']
    })
  },
  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignIn" */ './container/SignIn/SignIn'),
      loading: Loading,
      modules: ['SignIn']
    })
  },
  {
    path: REGISTRATION_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "SignUp" */ './container/SignUp/SignUp'),
      loading: Loading,
      modules: ['SignUp']
    })
  },
  {
    path: FORGET_PASSWORD_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "ForgetPassWord" */ './container/ForgetPassWord/ForgetPassWord'
        ),
      loading: Loading,
      modules: ['ForgetPassWord']
    })
  },
  {
    path: `${MARINA_PAGE}/:id`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ './container/SinglePage/SinglePageView'
        ),
      loading: Loading,
      modules: ['SinglePageView']
    })
  },
  {
    path: LISTING_POSTS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ './container/Listing/Listing'),
      loading: Loading,
      modules: ['Listing']
    })
  },
  {
    path: COUNTRIES_LISTING_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Countries" */ './container/CountriesPage/CountriesPage'
        ),
      loading: Loading,
      modules: ['CountriesPage']
    })
  },
  {
    path: DISCOVER_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Discover" */ './container/Discover/Discover'
        ),
      loading: Loading,
      modules: ['Discover']
    })
  },
  // {
  //   path: AGENT_PROFILE_PAGE,
  //   component: Loadable({
  //     loader: () =>
  //       import(
  //         /* webpackChunkName: "AgentDetailsViewPage" */ './container/Agent/AccountDetails/AgentDetailsViewPage'
  //       ),
  //     loading: Loading,
  //     modules: ['AgentDetailsViewPage'],
  //   }),
  // },
  {
    path: PRICING_PLAN_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ './container/Pricing/Pricing'),
      loading: Loading,
      modules: ['Pricing']
    })
  },
  {
    path: DEMO_MAP_VIEW,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "Listing" */ './container/Listing/DemoListingMapView'
        ),
      loading: Loading,
      modules: ['DemoListingMapView']
    })
  }
];

/**
 *
 * Protected Route Component
 *
 */

const EmailVerification = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EmailVerification" */ './container/EmailVerification/EmailVerification'
    ),
  loading: Loading,
  modules: ['EmailVerification']
});

const ProfilePage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProfilePage" */ './container/Agent/Profile/ProfilePage'
    ),
  loading: Loading,
  modules: ['ProfilePage']
});

const SettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SettingsPage" */ './container/Agent/Settings/SettingsPage'
    ),
  loading: Loading,
  modules: ['SettingsPage']
});

const ReservationPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ReservationPage" */ './container/Agent/Reservation/ReservationPage'
    ),
  loading: Loading,
  modules: ['ReservationPage']
});

const ReservationsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ReservationsPage" */ './container/Agent/Reservations/ReservationsPage'
    ),
  loading: Loading,
  modules: ['ReservationsPage']
});

const AbsenceReportsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AbsenceReportsPage" */ './container/Agent/AbsenceReports/AbsenceReportsPage'
    ),
  loading: Loading,
  modules: ['AbsenceReportsPage']
});

const PopeyePage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PopeyePage" */ './container/Agent/Popeye/PopeyePage'
    ),
  loading: Loading,
  modules: ['PopeyePage']
});

const PopeyeWelcomePage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PopeyeWelcomePage" */ './container/Agent/PopeyeWelcome/PopeyeWelcome'
    ),
  loading: Loading,
  modules: ['PopeyeWelcome']
});

const PopeyeSuccessScreen = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "PopeyeSuccessScreen" */ './container/Agent/Popeye/PopeyeSuccessScreen/PopeyeSuccessScreen'
    ),
  loading: Loading,
  modules: ['PopeyeSuccessScreen']
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ './container/404/404'),
  loading: Loading,
  modules: ['NotFound']
});

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn, waitingForAuth } = useContext(AuthContext);
  if (waitingForAuth) return <Loading />;
  return (
    <Route
      render={props => {
        return loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/${props.match.params.locale}${LOGIN_PAGE}`,
              state: { from: props.location.pathname }
            }}
          />
        );
      }}
      {...rest}
    />
  );
};

/**
 *
 * Marketing site redirects (the next paths will be redirected to the marketing site)
 *
 */
const externalRedirects = [
  'for-marinas/',
  'for-boaters/',
  'for-the-ocean/',
  'schedule-a-demo/',
  'contact/',
  'about/',
  'press/',
  'blog/',
  'career/'
];

/**
 *
 * Overall Router Component
 *
 */
const Routes = ({ match }) => {
  const { pathname } = useLocation();

  return (
    <Layout>
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        {routes.map(({ path, component, exact = false }) => (
          <Route
            key={path}
            path={match.path + path}
            exact={exact}
            component={component}
          />
        ))}
        <ProtectedRoute
          path={match.path + EMAIL_VERIFICATION_PAGE}
          component={EmailVerification}
        />
        <ProtectedRoute
          path={match.path + PROFILE_PAGE}
          component={ProfilePage}
        />
        <ProtectedRoute
          path={match.path + SETTINGS_PAGE}
          component={SettingsPage}
        />
        <ProtectedRoute
          path={match.path + RESERVATIONS_PAGE}
          component={ReservationsPage}
        />
        <ProtectedRoute
          path={`${match.path + RESERVATION_PAGE}/:id`}
          component={ReservationPage}
        />
        <ProtectedRoute
          path={match.path + ABSENCE_REPORTS_PAGE}
          component={AbsenceReportsPage}
        />
        <ProtectedRoute
          path={match.path + POPEYE_SUCCESS_SCREEN}
          component={PopeyeSuccessScreen}
        />
        <ProtectedRoute
          path={match.path + POPEYE_PAGE}
          component={PopeyePage}
        />
        <ProtectedRoute
          path={match.path + POPEYE_WELCOME_PAGE}
          component={PopeyeWelcomePage}
        />

        {externalRedirects.map(path => (
          <Route key={path} path={match.path + path} component={() => {
            window.location.href = `${process.env.REACT_APP_MARKET_URL}/${path}`;
            return null;
          }}/>
        ))}

        <Route path='*' component={NotFound}></Route>
      </Switch>
    </Layout>
  );
};

export default withRouter(Routes);
