import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const BackButtonStyles = css`
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: 38px;
  line-height: 1;
  height: auto;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: ${themeGet('text.1', '#909090')};
  transition: all 0.3s ease;

  &:hover,
  &:focus {
    outline: 0;
    color: ${themeGet('text.0', '#2C2C2C')};
  }
`;

export const MobileSubMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${themeGet('color.1', '#ffffff')};
  .mobile-submenu {
    li {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &.ant-menu-item-active {
        background-color: transparent;
        a {
          color: ${themeGet('primary.0', '#194AB9')};
        }
      }
      &.ant-menu-item-selected {
        background-color: transparent;
        &:after {
          display: none;
        }
        a {
          color: ${themeGet('primary.0', '#194AB9')};
        }
      }
      &.ant-menu-item-disabled {
        cursor: default;
        > a {
          pointer-events: none;
          .membership-mobile-menu-action {
            color: ${themeGet('text.1', '#909090')};
          }
        }
      }
      &.ant-menu-item {
        &:hover {
          a {
            color: ${themeGet('primary.0', '#194AB9')};
          }
        }
      }
      > a {
        padding-left: 70px;
        font-size: 16px;
        display: flex;
        align-items: center;
        img {
          &.flag {
            width: 20px;
            height: 10px;
            margin-right: 14px;
          }
          &.card {
            width: 41px;
            height: 27px;
          }
          &.membership-conect,
          &.membership-request {
            margin-right: 10px;
          }
        }
        &:hover {
          .membership-mobile-menu-label,
          .membership-mobile-menu-action {
            color: ${themeGet('primary.0', '#194AB9')};
          }
        }
        img {
          &.trips-reservation,
          &.trips-absence {
            width: 24px;
            height: 24px;
            margin-right: 15px;
          }
        }
      }
    }
    ul.memberships-list-menu {
      padding: 20px 0;
      border-top: 1px solid ${themeGet('border.1', '#e8e8e8')};
      li {
        margin-bottom: 22px;
      }
    }
  }
`;

export const MobileSubMenuHeader = styled.div`
  position: relative;
  padding: 27px 55px 20px;
  button {
    ${BackButtonStyles}
    font-size: 24px;
    position: absolute;
    top: 27px;
    left: 27px;
  }
`;

export const MobileSubMenuHeadline = styled.h3`
  font-size: 16px;
  margin-bottom: 0;
  text-align: center;
  ${themeGet('text.0', '#2c2c2c')};
`;
