import i18n from 'i18next';
import deLocale from 'moment/locale/de';
import esLocale from 'moment/locale/es';
import frLocale from 'moment/locale/fr';
import itLocale from 'moment/locale/it';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { localeOptions } from 'settings/locales';
import moment from "moment";
//import availableLanguages from 'common-pickapier/translations/availableLanguages'; DOESNT WORK FOR SOME REASON
export const availableLanguages = ['en', 'fr', 'de', 'es', 'it'];

let arr = window.location.href.split('/')
let lang =
  (availableLanguages.includes(arr[arr.length-1]) ? arr[arr.length-1] : undefined) ||
  navigator.language.split('-')[0] ||
  navigator.userLanguage;
if (!availableLanguages.includes(lang)) {
  lang = 'en';
}

const resources = {
  en: require(`common-pickapier/translations/en`),
};

if (lang !== 'en') {
  resources[lang] = require(`common-pickapier/translations/${lang}`)
}

if (process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION') {
  availableLanguages.push('xx');
  resources['xx'] = { translation: {}}
  Object.keys(resources['en']['translation']).forEach(key => {
    resources['xx']['translation'][key] = key
  })
}

i18n
  // .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lang,
    fallbackLng: 'en',
    whitelist: localeOptions,
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    escapeInterpolation: false,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true
    }
  });

i18n.on('languageChanged', lngKey => {
  moment.locale(lngKey, [deLocale, esLocale, frLocale, itLocale]);
  if (!resources[lngKey]) {
    import(`common-pickapier/translations/${lngKey}`).then(lang => {
      resources[lngKey] = lang;
      i18n.addResources(lngKey, null, lang);
      i18n.changeLanguage(lngKey);
    });
  }
});

export default i18n;
