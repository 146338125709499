import { isMobile } from 'react-device-detect';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

export const URL_DATE_FORMAT = 'DD/MM/YYYY'

export const getStatus = status => {
  const { action_required, status_code } = status;
  const statusKeys = {
    BOATER: 'PENDING',
    MARINA: 'WAITING'
  };
  if (action_required === 'FINISHED' || status_code === 'WAITING_LIST') {
    return status_code;
  } else {
    return statusKeys[action_required];
  }
};

export const getCurrencySymbol = (currency = 'EUR') => {
  return getSymbolFromCurrency(currency);
};

export const getCurrencyCode = (currency = 'EUR') => {
  return currency;
};

export const formatPrice = (value, locale = 'en', currency = 'EUR') => {
  return parseFloat(value).toLocaleString(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code'
  });
};

export const dateSortDesc = (a, b) => {
  const keyA = new Date(a.created_at);
  const keyB = new Date(b.created_at);
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

export const dateSortAsc = (a, b) => {
  const keyA = new Date(a.created_at);
  const keyB = new Date(b.created_at);
  if (keyA > keyB) return -1;
  if (keyA < keyB) return 1;
  return 0;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const scrollToErrorField = (desktop = false) => {
  if (!desktop && !isMobile) return;
  setTimeout(() => {
    const errorElements = document.getElementsByClassName('has-error');
    if (!isEmpty(errorElements)) {
      errorElements[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
      setTimeout(() => {
        if (
          isMobile &&
          !isEmpty(errorElements) &&
          errorElements[0].offsetHeight > window.screen.height / 2
        ) {
          window.scrollTo({
            top: document.documentElement.scrollTop + 200,
            behavior: 'smooth'
          });
        }
      }, 1000);
      const inputEl =
        errorElements[0].querySelector('.ant-input') ||
        errorElements[0].querySelector('.ant-select');
      if (inputEl) {
        inputEl.focus();
      }
    }
  }, 0);
};

export const onDownload = (e, path, name) => {
  e.preventDefault();
  const url = path || null;
  const getFile = (filePath, cb) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', filePath);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      if (xhr.status && xhr.status === 200) {
        // cb(xhr.response)
        var url = window.URL.createObjectURL(xhr.response);
        var a = document.createElement('a');
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    };
    xhr.send();
  };
  getFile(url);
};

export const isBeforeDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
};

export const isInclusivelyAfterDay = (a, b) => {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
};

export const isBlockedDayInAdvance = (day, limits) => {
  return (
    limits &&
    limits.max_days_before &&
    !isBeforeDay(day, moment().add(limits.max_days_before, 'days'))
  );
};

export const isBlockedMinDaysInAdvance = (day, limits) => {
  return (
    limits &&
    limits.min_days_before &&
    isBeforeDay(day, moment().add(limits.min_days_before, 'days'))
  );
};

export const getOutsideRangeByLimits = (
  day,
  limits,
  startDate,
  endDate,
  withBlockBeforeToday,
  focusedInput
) => {
  const blockAllDaysBeforeToday = !isInclusivelyAfterDay(day, moment());
  const blockAfterMaxNightsLimitByStart =
    startDate &&
    limits &&
    limits.max_nights &&
    isInclusivelyAfterDay(
      day,
      moment(startDate).startOf('day').add(limits.max_nights + 1, 'days')
    );
  const blockAfterMaxNightsLimitByEnd =
    focusedInput !== 'endDate' &&
    endDate &&
    startDate &&
    limits &&
    limits.max_nights &&
    moment(endDate).diff(moment(startDate), 'days') > limits.max_nights;

  const blockDaysInAdvance =
    focusedInput !== 'endDate' && isBlockedDayInAdvance(day, limits);

  const blockMinDaysInAdvance = isBlockedMinDaysInAdvance(day, limits);

  return (
    (withBlockBeforeToday && blockAllDaysBeforeToday) ||
    blockAfterMaxNightsLimitByStart ||
    blockAfterMaxNightsLimitByEnd ||
    blockDaysInAdvance ||
    blockMinDaysInAdvance
  );
};

export const generateID = name => {
  if (name) return `_${name}`;

  return '_' + Math.random().toString(36).substr(2, 9);
};

export const isUnverifiedMarina = marinaName => {
  return `${marinaName}`.startsWith('_');
};

const businessToolsLabels = {
  platform_features: 'MARKETING_EMAILS_CATEGORY_1',
  destination_offers: 'MARKETING_EMAILS_CATEGORY_2',
  special_promotions: 'MARKETING_EMAILS_CATEGORY_3',
  community_tips: 'MARKETING_EMAILS_CATEGORY_4'
};

export const getBusinessToolLabel = businessTool => {
  return businessToolsLabels[businessTool];
};
