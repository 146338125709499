const prodConfig = {
  apiKey: 'AIzaSyBPZhN9_rae-qaAQ8GIv6OJHNpHDdkkJds',
  authDomain: 'pickapier-prod.firebaseapp.com',
  databaseURL: 'https://pickapier-prod.firebaseio.com',
  projectId: 'pickapier-prod',
  storageBucket: 'pickapier-prod.appspot.com',
  messagingSenderId: '402013370209',
  appId: '1:402013370209:web:38bfd82c8b29c590747720',
  measurementId: 'G-RLPDRJR5FG'
};

const testConfig = {
  apiKey: 'AIzaSyCn-KmsIPYClIQ0XissjTZhIt8SbxEEN9Y',
  authDomain: 'pickapier-widget.firebaseapp.com',
  databaseURL: 'https://pickapier-widget.firebaseio.com',
  projectId: 'pickapier-widget',
  storageBucket: 'pickapier-widget.appspot.com',
  messagingSenderId: '324326721782',
  appId: '1:324326721782:web:254a32d8b53d57b91fc4d7',
  measurementId: 'G-J9R1YDB6QG'
};

const demoConfig = {
  apiKey: 'AIzaSyCnQ_y2beWV0V8D5cain1g2pH1B-XWr8QE',
  authDomain: 'pickapier-demo.firebaseapp.com',
  databaseURL: 'https://pickapier-demo.firebaseio.com',
  projectId: 'pickapier-demo',
  storageBucket: 'pickapier-demo.appspot.com',
  messagingSenderId: '597352381155',
  appId: '1:597352381155:web:1e2da1f5eb0d44fabee3d6',
  measurementId: 'G-J7GJW0C61Y'
};

const devConfig = {
  apiKey: 'AIzaSyCn-KmsIPYClIQ0XissjTZhIt8SbxEEN9Y',
  authDomain: 'pickapier-widget.firebaseapp.com',
  databaseURL: 'https://pickapier-widget.firebaseio.com',
  projectId: 'pickapier-widget',
  storageBucket: 'pickapier-widget.appspot.com',
  messagingSenderId: '324326721782',
  appId: '1:324326721782:web:254a32d8b53d57b91fc4d7',
  measurementId: 'G-J9R1YDB6QG'
};

let config;

switch (process.env.REACT_APP_FIREBASE_ENV) {
  case 'PRODUCTION':
    config = prodConfig;
    break;
  case 'TEST':
    config = testConfig;
    break;
  case 'DEMO':
    config = demoConfig;
    break;
  default:
    config = devConfig;
    break;
}

export default config;
