import { post, get } from './api';

export default {
  update: async function (data) {
    return await post('/boater/update', data);
  },
  marina_update: async function (data) {
    return await post('/boater/home_marina_update', data);
  },
  marina_request_update: async function (data) {
    return await post('/marina_request/update', data);
  },
  stats: async function () {
    return await get('/boater/statistics');
  },
  email_preferences_update: async function (data) {
    return await post('/boater/email_preferences/update', data);
  },
  get_update: async function (data) {
    return await post('/boater/get_update', data);
  },
  send_email_verification: async function (data) {
    return await post('/boater/send_email_verification', data);
  },
  verify_email: async function (data) {
    return await post('/boater/verify_email', data);
  },
  get_boater: async function () {
    return await get('/boater/get');
  }
};
