import React, { useState, useEffect } from 'react';

const ButtonWithAsync = Component => {
  const WithStateComponent = ({
    asyncAction,
    htmlType,
    type,
    label,
    ...props
  }) => {
    const [actionDone, setActionDone] = useState(true);

    useEffect(() => {
      let isSubscribed = true;
      if (!actionDone) {
        try {
          asyncAction()
            .then(() => {
              if (isSubscribed) {
                setActionDone(true);
              }
            })
            .catch(err => {
              console.error(err);
              if (isSubscribed) {
                setActionDone(true);
              }
            });
        } catch (err) {
          console.error(err);
          if (isSubscribed) {
            setActionDone(true);
          }
        }
      }
      return () => (isSubscribed = false);
    }, [actionDone]);

    const handleClick = e => {
      if (!actionDone) {
        e.preventDefault();
      } else {
        setActionDone(false);
      }
    };

    return (
      <Component
        {...props}
        onClick={handleClick}
        htmlType={htmlType}
        type={type}
      >
        {label}
      </Component>
    );
  };

  return props => <WithStateComponent {...props} />;
};

export default ButtonWithAsync;
