import temLogo from 'assets/images/tem-logo-for-popup.png';
import igyLogo from 'assets/images/igy_logo.svg';
import BFLogo from 'assets/images/bflogo.png';
import greekMarinasLogo from 'assets/images/associations_logos/greekmarinas.png';
import dMarinLogo from 'assets/images/associations_logos/dmarin.png';
import tyhaLogo from 'assets/images/associations_logos/tyha.png';
import boatfolkLogo from 'assets/images/associations_logos/boatfolk.png';
import aciLogo from 'assets/images/associations_logos/aci.png';
import seturLogo from 'assets/images/associations_logos/setur.png';
import moment from 'moment';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import React from 'react';

export const isDemoMode = () => {
  return !!process.env.REACT_APP_DEMO_MODE;
};

const associations = {
  TEM: {
    reservationDiscountJSX: (
      <span>
        You get <mark>20% off price</mark> on your TransEuropeMarinas membership
      </span>
    )
  },
  BFG: {
    reservationDiscountJSX: (
      <span>
        You get <mark>20% off price</mark> on your Blue Flag membership
      </span>
    )
  },
  IGY: {
    reservationDiscountJSX: (
      <span>
        You get <mark>20% off price</mark> on your Island Global Yachting membership
      </span>
    )
  }
};

export const getMembershipTypes = details => {
  if (details && details.namesOnly) {
    return [
      'TransEurope Marinas',
      'Blue Flag',
      'Island Global Yachting',
      'Greek Marinas Association',
      'D-Marin',
      'TYHA',
      'Boatfolk',
      'ACI',
      'Setur Marinas'
    ];
  }

  return [
    {
      id: 'TEM',
      imgSrc: temLogo,
      title: 'TransEurope Marinas'
    },
    {
      id: 'BFG',
      imgSrc: BFLogo,
      title: 'Blue Flag',
      style: {
        height: '65px'
      }
    },
    {
      id: "IGY",
      imgSrc: igyLogo,
      title: 'Island Global Yachting'
    },
    {
      id: 'GMA',
      imgSrc: greekMarinasLogo,
      title: 'Greek Marinas Association',
      style: {
        height: '65px'
      }
    },
    {
      id: 'DMN',
      imgSrc: dMarinLogo,
      title: 'D-Marin',
      style: {
        height: '96px',
        marginTop: '-15px'
      }
    },
    {
      id: 'TYHA',
      imgSrc: tyhaLogo,
      title: 'TYHA',
      style: {
        marginTop: '-10px',
        height: '81px'
      }
    },
    // {
    //   id: "GA",
    //   imgSrc: '',
    //   title: 'Georgia Marinas'
    // },
    {
      id: 'BLF',
      imgSrc: boatfolkLogo,
      title: 'Boatfolk',
      style: {
        height: '67px',
        marginTop: '-5px'
      }
    },
    {
      id: 'ACI',
      imgSrc: aciLogo,
      title: 'ACI',
      style: {
        height: '73px',
        marginTop: '-10px'
      }
    },
    {
      id: 'STR',
      imgSrc: seturLogo,
      title: 'Setur Marinas',
      style: {
        marginTop: '10px',
        height: '36px'
      }
    }
    // {
    //   id: "ACI",
    //   imgSrc: '',
    //   title: 'Adriatic Croatia International Club'
    // },
    // {
    //   id: "HISWA",
    //   imgSrc: '',
    //   title: 'HISWA TE WATER'
    // },
  ];
};

export const getRatingComponents = () => {
  const numberOfReviews = Math.round(Math.random() * 500);
  // const rating = Math.round(1 + Math.random() * 4);
  const rating = 5;

  return { numberOfReviews, rating };
};

export const getReviews = () => {
  return [
    {
      reviewAuthorFirstName: 'Danny',
      reviewAuthorLastName: 'Kingston',
      reviewText:
        'We always come to the marina, and have a blast! Pleasant staff and what a view!',
      reviewTitle: 'Great stay!',
      reviewDate: moment(),
      reviewAuthorPic: {
        url: 'https://media-cdn.tripadvisor.com/media/photo-s/10/ac/bc/e8/pic-2.jpg'
      },
      reviewFields: ''
    },
    {
      reviewAuthorFirstName: 'Susana',
      reviewAuthorLastName: 'Hamburg',
      reviewText:
        'Located pretty close to the caves, nice folk, a bit pricy restaurant but good burgers',
      reviewTitle: 'Nothing to write home about',
      reviewDate: moment().subtract(30, 'days'),
      reviewAuthorPic: {
        url: 'https://www.islands.com/resizer/aQlPmIbW6MYJGLOITjHKCal9gQM=/760x570/cloudfront-us-east-1.images.arcpublishing.com/bonnier/6FMRLA4SH5FCRBH3GZCOHMM5J4.jpg'
      },
      reviewFields: ''
    },
    {
      reviewAuthorFirstName: 'Daniel',
      reviewAuthorLastName: 'Stue',
      reviewText: 'Cheers mate',
      reviewTitle: 'Thanks Daniel, we really enjoyed visiting, the Flanders',
      reviewDate: moment().subtract(365, 'days'),
      reviewAuthorPic: {
        url: 'https://cdn.britannica.com/25/123125-050-8E6C8227/rowboat.jpg'
      },
      reviewFields: ''
    }
  ];
};

export const getReservationDiscountJSX = details => {
  const { marina } = details;
  if (isEmpty(marina)) return false;

  const { association } = marina;
  const JSX = associations[association.code]
    ? associations[association.code].reservationDiscountJSX
    : null;
  return JSX;
};

export const getGeoLocationForFirstMarinaInMap = () => {
  const lat = 39.0471829;
  const lng = 1.3727072;

  return { lat, lng };
};

export const getCodeOfTheFirstMarinaInMap = () => {
  return 'BFM';
};
