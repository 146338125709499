import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const ButtonStyles = css`
  border-radius: 4px;
  height: 47px;
  font-size: 15px;
  font-weight: 700;
  padding-left: 28px;
  padding-right: 28px;
  line-height: 1;
`;

export const Wrapper = styled.div`
  padding: 0 30px;
  @media only screen and (max-width: 375px) {
    padding: 0 15px;
  }

  .ant-select {
    &.requested_redeem_nights_amount {
      /* border: 1px solid red; */
      .ant-select-arrow {
        display: flex;
        width: 280px;
        align-items: center;
        right: 0px;
        @media only screen and (max-width: 480px) {
          margin-right: 20px;
        }
      }
      .ant-select-selection-item,
      .ant-select .ant-select-selector .ant-select-selection-search-input {
        padding-left: 30px;
        padding-top: 2px;
      }
    }
  }
`;

export const FieldWrapper = styled.div`
  margin-bottom: 30px;

  @media only screen and (max-width: 375px) {
    margin-bottom: 25px;
  }

  label {
    font-size: 15px;
    color: ${themeGet('text.0', '#2C2C2C')};
    text-transform: capitalize;
    margin-bottom: 7px;
    display: block;
  }

  /* date picker component style */
  .date_picker {
    margin-bottom: 0;
    &.error {
      .DateInput_input {
        &::placeholder {
          color: ${themeGet('error.0', '#F9503D')}!important;
        }
      }
    }
    &.disabled {
      .DateInput_input {
        &::placeholder {
          color: rgba(0, 0, 0, 0.25) !important;
        }
      }
    }
    > label {
      margin-bottom: 0;
      width: 100%;
    }
    .DateRangePicker {
      width: 100%;
      input,
      .DateRangePickerInput {
        border: 0;
        width: 100%;
        height: 54px;
        display: flex;
        padding: 0 9px;
        font-size: 15px;
        font-weight: 400;
        border-radius: 3px;
        align-items: center;
        justify-content: space-between;
        color: ${themeGet('text.0', '#2C2C2C')};
        background-color: ${themeGet('color.2', '#F7F7F7')};
        &::placeholder {
          font-size: 15px;
          color: ${themeGet('text.0', '#2C2C2C')};
        }
        .DateInput {
          width: auto;
          .DateInput_fang {
            top: 46px !important;
          }
        }
        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
        &.DateRangePickerInput__showClearDates {
          padding: 0 22px 0 9px;
        }
      }
      .DateRangePicker_picker {
        top: 56px !important;
        .DayPicker {
          width: 316px !important;
          .DayPicker_focusRegion {
            .DayPicker_transitionContainer {
              width: 316px !important;
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#2C2C2C')};
              }
            }
          }
        }
      }
      .DateRangePickerInput_clearDates {
        outline: none;
        width: 25px;
        height: 25px;
        padding: 0;
        transition: background-color 0.2s ease;
        border-radius: 50%;
        .DateRangePickerInput_clearDates_svg__small {
          position: relative;
          top: -2px;
        }
      }
    }
  }

  /* inputs components style */
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-has-error {
    .ant-input {
      background-color: ${themeGet('color.2', '#F7F7F7')};
      &:focus,
      &:active {
        border-color: transparent;
        box-shadow: none;
      }
      &:hover {
        background-color: ${themeGet('color.2', '#F7F7F7')};
      }
    }
    .ant-select .ant-select-selector {
      background-color: ${themeGet('color.2', '#F7F7F7')} !important;
      box-shadow: none !important;
    }
  }
  .ant-input,
  .ant-select .ant-select-selector {
    border-radius: 0;
    border: 0;
    background-color: ${themeGet('color.2', '#F7F7F7')};
    height: 50px;
    &:focus,
    &:active {
      border-color: transparent;
      box-shadow: none;
    }
  }
  .ant-input {
    padding-left: 18px;
  }
  .ant-select {
    .ant-select-selector {
      padding-left: 9px;
      .ant-select-selection-item,
      .ant-select-selection-search-input {
        height: 50px;
        line-height: 50px;
      }
      .ant-select-selection-placeholder {
        line-height: 50px;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .link {
    font-size: 15px;
    font-weight: 700;
  }

  &.absence-report-date-picker-wrapper {
    > label {
      text-transform: none;
    }
    .date_picker {
      .DateRangePicker {
        .DateRangePickerInput,
        input {
          height: 47px;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 8px;
    }
  }
`;

export const FieldsRow = styled.div`
  display: flex;
`;

export const FormActionArea = styled.div`
  button.ant-btn-primary {
    width: 100%;
    height: 47px;
    color: ${themeGet('color.1', '#ffffff')};
    font-size: 15px;
    font-weight: 700;
    border-color: ${themeGet('primary.0', '#194AB9')};
    background-color: ${themeGet('primary.0', '#194AB9')};
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: none;
      opacity: 0.93;
    }
  }
`;

export const SuccessDialogActions = styled.div`
  button {
    ${ButtonStyles};
    min-width: 167px;
  }
`;

export const Info = styled.p`
  margin-bottom: 7px;
  &.additional-info {
    margin-top: 7px;
    margin-bottom: 0;
  }
`;

export const MessagingFormHeader = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${themeGet('border.0', '#EBEBEB')};
  margin-bottom: 20px;
  h2 {
    font-size: 25px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    color: ${themeGet('color.0', '#2c2c2c')};
    span {
      font-size: 15px;
      margin: 0;
      font-weight: 400;
    }
  }
  button.ant-btn-primary {
    width: auto;
  }
  &.instant_booking_header {
    flex-direction: column;
    align-items: center;
    padding: 0 0 10px;
    .form-action-area {
      width: 100%;
      margin-bottom: 10px;
      button.ant-btn-primary {
        width: 100%;
      }
    }
    p.instant_booking_info {
      margin-bottom: 0;
      font-size: 12px;
    }
  }
`;

export const DownloadButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 3px;
  .download_tariff_button {
    margin-left: auto;
    margin-right: 18px;
  }
`;

export const DateError = styled.div`
  color: ${themeGet('text.5', '#f5222d')};
`;

export const Error = styled.div`
  color: ${themeGet('text.5', '#f5222d')};
  margin-top: 10px;
  margin-bottom: 0;
`;

export const DemoMembershipSection = styled.div`
  margin: 21px 0 0;
  font-family: Lato;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: 21px;
  color: #5f5f5e;

  mark {
    font-weight: bold;
    color: #00aa8b;
    background-color: transparent;
  }
`;

export const SelectActions = styled.div`
  .add_vessel {
    font-size: 15px;
    font-weight: bold;
    color: ${themeGet('primary.0', '#194AB9')};
  }
`;

export const NoReservationHeader = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
`;

export const StillInterested = styled.div`
  height: 24px;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 15px;
`;

export const RedeemNightsExceeded = styled.span`
  color: #194AB9;
  line-height: 30px;
`;

export const ErrorLabel = styled.span`
  color: ${themeGet('text.5', '#f5222d')};
  line-height: 1;
`;

export const ReservationWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
  .total-price-label {
    font-size: 14px;
  }
`;

export const TotalPriceValue = styled.span`
  font-size: 33px;
  line-height: 1;
`;

export const StrikeThroughPrice = styled.div`
  display: inline-block;
  color: #828282;
  font-size: 21px;
  font-weight: bold;
  line-height: 1.2;
  position: relative;
  &:after {
    content: '';
    top: 50%;
    left: 0;
    position: absolute;
    background-color: #eb5757;
    width: 100%;
    height: 2px;
  }
`;

export const PriceContainer = styled.div`
  margin-bottom: ${({ withMargin }) => (withMargin ? '16px' : '0')};
`;

export const PromotionEstimationList = styled.div`
  ${props => props.centerAndFill ? 'width: 100%; display: flex; align-items: center; justify-content: center;': ''}
  .estimation-icon {
    margin-right: 8px;
    height: 30px;
  }
`;

export const PromotionEstimation = styled.div`
  ${props => props.centerAndFill ? 'width: 100%;' : '' }
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 7px 8px;
  background-color: #DAF9DD;
  border-radius: 4px;
  margin-top:8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const PromotionEstimationText = styled.span`
  font-size: 14px;
  line-height: 1.6;
  color: #333333;
`;

export const ProdAlertMessageTitle = styled.div`
  font-size: 24px;
  color: red;
  font-weight: bold;
  padding: 20px 0px;
`;
export const ProdAlertMessageBody = styled.p`
  font-size: 18px;
  // text-align: center;
`;

export const ProdAlertMessageFooter = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  button.ant-btn {
    border: none;
    margin-right: 10px;
    &:hover {
    }
  }
`;
