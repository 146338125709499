import * as ReduxToolkit from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import reducers from './reducers';
import sagas from './sagas';
const {configureStore} = ReduxToolkit;

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
});

sagaMiddleware.run(sagas);

export default store;
