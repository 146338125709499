import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Headline = styled.div`
  font-size: 21px;
  font-weight: bold;
  line-height: 25px;
  color: ${themeGet('text.13', '#121A2C')};
`;

export const Body = styled.div`
  padding: 24px 24px 32px;
  font-size: 14px;
  line-height: 17px;
  color: ${themeGet('text.13', '#121A2C')};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 6px 0;
`;
