import { post, get } from './api';

export default {
  get: async function () {
    return await get('/notification/getall');
  },
  view: async function (data) {
    return await post('/notification/view', data);
  },
  read: async function (data) {
    return await post('/notification/read', data);
  },
  unread: async function (data) {
    return await post('/notification/unread', data);
  },
  delete: async function (data) {
    return await post('/notification/delete', data);
  }
};
