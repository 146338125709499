import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Field, Form } from 'formik';
import HtmlLabel from 'components/UI/HtmlLabel/HtmlLabel';
import DateRangePickerBox from 'components/UI/DatePicker/ReactDates';
import ButtonWithAsync from 'components/UI/ButtonWithAsync/ButtonWithAsync';
import {
  AntInput,
  AntSelect,
  AntAutoComplete
} from 'components/UI/Antd/AntdInputWithFormik';
import AntdGoogleMapSearchWithFormik from 'components/UI/Antd/AntdGoogleMapSearchWithFormik';
import Button from 'components/UI/Antd/Button/Button';

import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import ClockCircleFilled from '@ant-design/icons/ClockCircleFilled';
import HomeFilled from '@ant-design/icons/HomeFilled';

import { FieldWrapper } from 'container/SinglePage/Reservation/Reservation.style';
import {
  ARFormWrapper,
  NotFound,
  FieldWrapperWithNote
} from './AbsenceReports.style';
import isEqual from 'lodash/isEqual';
import { isUnverifiedMarina } from 'library/helpers/utils';
import MyModal from 'components/UI/Modal/Modal.js';
import ProdAlertMessage from 'container/SinglePage/Reservation/ProdAlertMessage';

const AsyncButton = ButtonWithAsync(Button);

const RenderAbsenceReportForm = ({
  user,
  isEdit,
  deleted,
  completed,
  onChangeDate,
  focusedDateInput,
  startDateInvalid,
  endDateInvalid,
  submitForm,
  initialValues,
  vesselOptions,
  values,
  date,
  options,
  reasons,
  submitCount,
  onSelect,
  onChange,
  onChangeVessel,
  homeMarinaVerified,
  hasDifferentHomeMarina,
  onAddNewHomeMarina
}) => {
  const { t } = useTranslation();

  const calendarItem = {
    separator: '/',
    format: 'DD/MM/YYYY',
    locale: 'en'
  };

  const [hasChangedForm, setHasChangedForm] = useState(false);
  const [newHomeMarina, setNewHomeMarina] = useState(null);
  const [openProdAlert, setOpenProdAlert] = useState(false);

  useEffect(() => {
    const valuesWithDates = {
      ...values,
      departure_date: date.departure_date,
      return_date: date.return_date
    };
    /**
     * Auto absence report is not in the dropdown,
     * therefore the initial value AUTO_ABSENCE_REPORT will never be the
     * same as value.reason which can be none or a dropdown item.
     */
    const initialValuesCopy = { ...initialValues };
    if (initialValuesCopy.reason === 'AUTO_ABSENCE_REPORT')
      initialValuesCopy.reason = '';
    if (!isEqual(initialValuesCopy, valuesWithDates)) {
      setHasChangedForm(true);
    } else {
      setHasChangedForm(false);
    }
  }, [values, initialValues, date]);

  return (
    <ARFormWrapper>
      <Form>
        <FieldWrapper className='absence-report-date-picker-wrapper'>
          <HtmlLabel htmlFor='dates' content={t('LEAVING_DATE_RANGE_LABEL')} />
          <Field
            render={() => (
              <DateRangePickerBox
                disabled={
                  deleted || completed || (isEdit && hasDifferentHomeMarina)
                }
                className={startDateInvalid || endDateInvalid ? 'error' : ''}
                item={calendarItem}
                startDatePlaceholderText={t('ABSENCE_START_DATE')}
                endDatePlaceholderText={t('ABSENCE_END_DATE')}
                startDateId='absence-start'
                endDateId='absence-end'
                startDate={date.setStartDate}
                endDate={date.setEndDate}
                updateSearchData={onChangeDate}
                showClearDates={true}
                small={true}
                numberOfMonths={1}
                style={{ maxWidth: '300px' }}
                focusedInput={focusedDateInput}
                updatable={true}
                withPortal={isMobile}
                readOnly={isMobile}
              />
            )}
            name='dates'
          />
        </FieldWrapper>

        <Field
          disabled={isEdit || deleted || completed}
          component={AntSelect}
          name='vessel_id'
          label={t('VESSEL_NAME_LABEL')}
          value={values.vessel_id}
          options={vesselOptions}
          tokenSeparators={[',']}
          submitCount={submitCount}
          onSelect={onChangeVessel}
        />

        <FieldWrapperWithNote>
          <Field
            className={
              (initialValues.home_marina_id && !hasDifferentHomeMarina) ||
              deleted
                ? 'field-with-icon'
                : ''
            }
            disabled={
              isEdit ||
              Boolean(initialValues.home_marina_id) ||
              Boolean(initialValues.unverified_marina_name) ||
              deleted ||
              completed
            }
            component={AntAutoComplete}
            name='home_marina_id'
            label={t('ABSENCE_HOME_MARINA_LABEL')}
            placeholder={t('ABSENCE_HOME_MARINA_PLACEHOLDER')}
            value={
              values.home_marina_id
                ? isUnverifiedMarina(values.home_marina_id)
                  ? `${values.home_marina_id}`.split('_')[1]
                  : options[values.home_marina_id]
                : null
            }
            selectOptions={options}
            filterOption={(inputValue, option) =>
              option.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            onSelect={onSelect}
            onChange={value => {
              setNewHomeMarina(value);
              onChange();
            }}
            notFoundContent={
              <NotFound>
                {t('NO_RESULTS_FOUND_SEARCH_AGAIN_OR_SELECT')}
                <button
                  onClick={e => {
                    e.preventDefault();
                    onAddNewHomeMarina(newHomeMarina);
                  }}
                >
                  {t('ADD')}
                </button>
              </NotFound>
            }
          />
          {deleted ? (
            <CloseCircleFilled style={{ color: '#F9503D', fontSize: '15px' }} />
          ) : (
            <Fragment>
              {initialValues.home_marina_id && !hasDifferentHomeMarina && (
                <Fragment>
                  {homeMarinaVerified ? (
                    <HomeFilled
                      style={{
                        color: '#194AB9',
                        fontSize: '15px'
                      }}
                    />
                  ) : (
                    <ClockCircleFilled
                      style={{
                        color: '#194AB9',
                        fontSize: '15px'
                      }}
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </FieldWrapperWithNote>

        <Field
          disabled={deleted || completed || (isEdit && hasDifferentHomeMarina)}
          component={AntSelect}
          name='reason'
          label={t('REASON_LABEL')}
          defaultValue={values.reason}
          selectOptions={reasons}
          tokenSeparators={[',']}
          submitCount={submitCount}
        />

        <Field
          disabled={deleted || completed || (isEdit && hasDifferentHomeMarina)}
          component={AntdGoogleMapSearchWithFormik}
          name='destination'
          label={t('ABSENCE_REPORT_BOATER_FORM_DESTINATION_Q')}
          placeholder={
            deleted || completed || hasDifferentHomeMarina
              ? ''
              : t('ABSENCE_REPORT_BOATER_FORM_DESTINATION_PLACEHOLDER')
          }
          submitCount={submitCount}
        />

        {!deleted && !completed && (
          <AsyncButton
            asyncAction={() => {
              process.env.REACT_APP_FIREBASE_ENV === 'PRODUCTION' &&
              user?.email.includes('@pickapier')
                ? setOpenProdAlert(true)
                : submitForm();
            }}
            disabled={!hasChangedForm || (isEdit && hasDifferentHomeMarina)}
            className='submit-button'
            htmlType='button'
            type='primary'
            label={
              isEdit
                ? t('EDIT_ABSENCE_REPORT_SUBMIT')
                : t('NEW_ABSENCE_REPORT_SUBMIT')
            }
          />
        )}
      </Form>
      <MyModal onClose={() => setOpenProdAlert(false)} visible={openProdAlert}>
        <ProdAlertMessage
          handleClose={() => setOpenProdAlert(false)}
          handleSubmit={() => {
            setOpenProdAlert(false);
            submitForm();
          }}
        />
      </MyModal>
    </ARFormWrapper>
  );
};

export default RenderAbsenceReportForm;
