// **************** ROUTE CONSTANT START **************************
// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const COUNTRIES_LISTING_PAGE = '/countries';
export const MARINA_PAGE = '/marina';
export const DISCOVER_PAGE = '/discover';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/agent-profile';
export const AGENT_PROFILE_EDIT_PAGE = '/edit';
export const AGENT_VESSEL_PAGE = '/vessel';
export const AGENT_IMAGE_EDIT_PAGE = '/change-image';
export const AGENT_PROFILE_DELETE = '/delete';
export const AGENT_PROFILE_FAVOURITE = '/favourite-post';
export const PROFILE_PAGE = '/profile';
export const SETTINGS_PAGE = '/account-settings';
export const DOCUMENTS_PAGE = '/documents';
export const PAYMENT_PAGE = '/payment';
export const MESSAGES_PAGE = '/messaging';
export const RESERVATIONS_PAGE = '/reservations';
export const COMPLETED = '/completed';
export const RESERVATION_PAGE = '/reservation';
export const AGENT_MEMBERSHIPS = '/memberships';
export const EMAIL_VERIFICATION = '/email-verification';
export const EMAIL_PREFERENCES = '/email-preferences';
export const ABSENCE_REPORTS_PAGE = '/absence-report';
export const PAST_REPORTS = '/past';

// Popeye
export const POPEYE_WELCOME_PAGE = '/popeye-welcome';
export const POPEYE_PAGE = '/popeye';
export const POPEYE_SUCCESS_SCREEN = '/popeye/success-screen';
export const POPEYE_ABOUT = '/info';
export const POPEYE_INVITE = '/invite';
export const POPEYE_WELCOME_KIT = '/welcome-kit';
export const POPEYE_INFO = '/membership-info';
export const POPEYE_CERTIFICATE = '/certificate';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const NOT_FOUND_PAGE = '/not-found';

// Login / Registration Page
export const LOGIN_PAGE = '/login';
export const REGISTRATION_PAGE = '/registration';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';
export const EMAIL_VERIFICATION_PAGE = '/email-verification';
export const IFRAME = '/iframe';
// **************** ROUTE CONSTANT END **************************

// Demo mode
export const DEMO_MAP_VIEW = '/map-view';
