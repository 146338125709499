import React, { useState, useContext, Fragment, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash/isNil';

import { EmailVerificationContext } from 'context/EmailVerificationProvider';
import { PopeyeContext } from 'context/PopeyeProvider';

import { POPEYE_PAGE } from 'settings/constant';

const PopeyeMenu = ({ match, location, history, loggedIn, user, onAuth }) => {
  const { t } = useTranslation();
  const { isPopeyeMember, isPreSignatureStatus, setVisibleRequest } =
    useContext(PopeyeContext);

  const { onVerify } = useContext(EmailVerificationContext);

  const onClickHandler = e => {
    e.preventDefault();
    if (!loggedIn || !user) {
      // display login popup
      onAuth();
    } else {
      if (isPopeyeMember || isPreSignatureStatus) {
        if(user.emailVerified) {
          // go to Popeye pages
          history.push(`${match.url}${POPEYE_PAGE}`);
        } else {
          onVerify({ force: true });
        }
      } else {
        if(user.emailVerified) {
          // display request popup
          setVisibleRequest(true);
        } else {
          // request email verification
          onVerify({ force: true });
        }
      }
    }
  };

  return (
    <Fragment>
      <NavLink
        className='nav-link'
        to={`${match.url}${POPEYE_PAGE}`}
        onClick={onClickHandler}
      >
        {t('NAVBAR_POPEYE_LABEL')}
      </NavLink>
    </Fragment>
  );
};

export default withRouter(PopeyeMenu);
