import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 27px;
  line-height: 1.2;
  margin-bottom: 24px;
  font-weight: bold;
  color: ${themeGet('text.11', '#333333')};
  text-align: center;
`;

export const Description = styled.p`
  font-size: 17px;
  line-height: 1.4;
  margin-bottom: 40px;
  color: ${themeGet('text.11', '#333333')};
  text-align: center;
`;

export const Image = styled.div`
  margin-bottom: 40px;
  img {
    width: 319px;
    height: 51px;
  }
`;

export const PopeyeLink = styled.a`
  display: block;
  background-color: ${themeGet('primary.0', '#194AB9')};
  color: ${themeGet('color.1', '#ffffff')};
  text-align: center;
  line-height: 44px;
  height: 44px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  transition: all 0.3s ease;
  &:hover {
    color: ${themeGet('color.1', '#ffffff')};
    opacity: 0.9;
  }
`
