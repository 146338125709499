import React, { Fragment } from 'react';
import Button from 'components/UI/Antd/Button/Button';
import ButtonWithAsync from 'components/UI/ButtonWithAsync/ButtonWithAsync';

import {
  ProdAlertMessageTitle,
  ProdAlertMessageBody,
  ProdAlertMessageFooter
} from './Reservation.style.js';
const AsyncButton = ButtonWithAsync(Button);

const ProdAlertMessage = ({ handleClose, handleSubmit }) => {
  return (
    <Fragment>
      <ProdAlertMessageTitle>**REAL MARINA**</ProdAlertMessageTitle>
      <ProdAlertMessageBody>
        Pick a Pier user, you are in PROD and about to trigger an event to a
        REAL marina. If this isn't coordinated with the marina, please cancel
        and go to TEST or DEMO environments.
      </ProdAlertMessageBody>
      <ProdAlertMessageFooter>
        <Button onClick={handleClose}>{'Cancel'}</Button>
        <AsyncButton
          asyncAction={handleSubmit}
          className='submit-button'
          htmlType='button'
          type='primary'
          label={'Continue'}
        />
      </ProdAlertMessageFooter>
    </Fragment>
  );
};

export default ProdAlertMessage;
