import React, { Fragment, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { FiChevronRight } from 'react-icons/fi';
import i18n from 'i18n';
import { AuthContext } from 'context/AuthProvider';
import Drawer from 'components/UI/Antd/Drawer/Drawer';
import Menu from 'components/UI/Antd/Menu/Menu';
import MobileSubMenu from 'components/MobileSubMenu/MobileSubMenu';
import MobieSignupBanner from './MobieSignupBanner/MobieSignupBanner';
import MobileLanguageMenu from './MobileLanguageMenu';
import MobileHelpMenu from './MobileHelpMenu';
import {
  MobileMenuTitle,
  DrawerMoreBodyWrapper,
  DrawerFooter,
  MobieSignupBannerWrapper,
  ProfileInfo,
  Avatar,
  UserInfo,
  MobileMenuSection,
  MenuSectionTitle,
  IconWrapper,
  SelectedLanguage
} from './MobileMenu.style';
import {
  PROFILE_PAGE,
  AGENT_MEMBERSHIPS,
  SETTINGS_PAGE
} from 'settings/constant';
import locales from 'settings/locales';
// icons
import moreIcon from 'assets/images/pickapier/more.svg';
import moreHoverIcon from 'assets/images/pickapier/more-hovered.svg';
import crownCheckIcon from 'assets/images/pickapier/mobile-crown-check.svg';
import crownDotIcon from 'assets/images/pickapier/mobile-crown-dot.svg';

const MoreMobileMenu = ({ location, match, state, dispatch, history }) => {
  const { pathname } = location;
  const { t } = useTranslation();
  const { loggedIn, user, logOut } = useContext(AuthContext);
  const [mobileLanguageOpened, setMobileLanguageOpened] = useState(false);
  const [mobileHelpOpened, setMobileHelpOpened] = useState(false);

  const { moreDrawerOpened, homeDrawerOpened } = state;

  const toggleBottomMenu = (e, prevent) => {
    if (prevent) {
      e.preventDefault();
    }
    dispatch({ type: 'HIDE_HOME_DRAWER' });
    dispatch({ type: 'HIDE_TRIPS_DRAWER' });
    dispatch({ type: 'HIDE_AUTH_DRAWER' });
    dispatch({ type: 'HIDE_POPEYE_REQUEST_DRAWER' });
    dispatch({
      type: moreDrawerOpened ? 'HIDE_MORE_DRAWER' : 'OPEN_MORE_DRAWER'
    });
  };

  const onOpenLanguageMenu = e => {
    e.preventDefault();
    setMobileLanguageOpened(true);
  };
  const onCloseLanguageMenu = () => {
    setMobileLanguageOpened(false);
  };
  const onOpenHelpMenu = e => {
    e.preventDefault();
    setMobileHelpOpened(true);
  };
  const onCloseHelpMenu = () => {
    setMobileHelpOpened(false);
  };

  const onSupportClick = e => {
    e.preventDefault();
    window.zE && window.zE.activate();
  };

  const onLogoutClick = e => {
    e.preventDefault();
    toggleBottomMenu();
    logOut();
    setTimeout(() => history.push(`/${match.params.locale}`), 400);
  };

  const getAvatarText = () => {
    const { first_name, last_name } = user;
    return first_name && last_name
      ? `${first_name.charAt(0).toUpperCase()}${last_name
          .charAt(0)
          .toUpperCase()}`
      : '--';
  };

  const getUserInfo = () => {
    const { first_name, last_name } = user;
    return first_name && last_name ? `${first_name} ${last_name}` : '--';
  };

  const renderIcon = () => {
    const { vessels } = user;
    const allMemberships = [].concat.apply(
      [],
      vessels && vessels.length ? vessels.map(({ memberships }) => memberships || []) : []
    );
    if (allMemberships.length) {
      return (
        <IconWrapper>
          <ReactSVG className='crown-icon' src={crownCheckIcon} />
        </IconWrapper>
      );
    } else {
      return (
        <IconWrapper>
          <ReactSVG className='crown-icon' src={crownDotIcon} />
        </IconWrapper>
      );
    }
  };

  return (
    <Fragment>
      <a
        className={
          moreDrawerOpened ? 'mobile-nav-link active' : 'mobile-nav-link'
        }
        onClick={e => toggleBottomMenu(e, true)}
      >
        <ReactSVG
          className={`bottom-nav-icon ${moreDrawerOpened ? '' : 'hidden'}`}
          src={moreHoverIcon}
        />
        <ReactSVG
          className={`bottom-nav-icon ${moreDrawerOpened ? 'hidden' : ''}`}
          src={moreIcon}
        />
        <MobileMenuTitle className={'mobile-menu-title'}>
          {t('BOTTOM_NAV_MORE')}
        </MobileMenuTitle>
      </a>
      <Drawer
        placement='bottom'
        closable={false}
        maskClosable={false}
        zIndex={999998}
        className='mobile-bottom-menu'
        visible={moreDrawerOpened}
        height='100%'
        bodyStyle={{ padding: 0, height: '100%' }}
      >
        <DrawerMoreBodyWrapper>
          {loggedIn && user ? (
            <ProfileInfo>
              <Avatar>{getAvatarText()}</Avatar>
              <UserInfo>{getUserInfo()}</UserInfo>
            </ProfileInfo>
          ) : (
            <MobieSignupBannerWrapper>
              <MobieSignupBanner closeDrawer={toggleBottomMenu} />
            </MobieSignupBannerWrapper>
          )}

          {!!loggedIn && !!user && (
            <MobileMenuSection>
              <MenuSectionTitle>
                {t('BOTTOM_NAV_MORE_MY_ACCOUNT')}
              </MenuSectionTitle>
              <Menu>
                <Menu.Item key='1'>
                  <NavLink
                    isActive={() => false}
                    to={`${match.url}${PROFILE_PAGE}`}
                    onClick={toggleBottomMenu}
                  >
                    {t('BOTTOM_NAV_MORE_MY_PROFILE')}
                    <FiChevronRight size={16} className='chevron-right' />
                  </NavLink>
                </Menu.Item>
                <Menu.Item key='2'>
                  <NavLink
                    isActive={() => false}
                    to={`${match.url}${PROFILE_PAGE}${AGENT_MEMBERSHIPS}`}
                    onClick={toggleBottomMenu}
                  >
                    {t('BOTTOM_NAV_MORE_MEMBERSHIPS')}
                    {renderIcon()}
                    <FiChevronRight size={16} className='chevron-right' />
                  </NavLink>
                </Menu.Item>
              </Menu>
            </MobileMenuSection>
          )}

          <MobileMenuSection>
            <MenuSectionTitle>{t('BOTTOM_NAV_MORE_SETTINGS')}</MenuSectionTitle>
            <Menu>
              <Menu.Item key='3'>
                <NavLink
                  isActive={() => false}
                  to={pathname}
                  onClick={onOpenLanguageMenu}
                >
                  {t('BOTTOM_NAV_MORE_LANGUAGE')}
                  {!!locales[i18n.language] && (
                    <SelectedLanguage>
                      {locales[i18n.language].label}
                    </SelectedLanguage>
                  )}
                  <FiChevronRight size={16} className='chevron-right' />
                </NavLink>
              </Menu.Item>
              {loggedIn && user && (
                <Menu.Item key='4'>
                  <NavLink
                    isActive={() => false}
                    to={`${match.url}${SETTINGS_PAGE}`}
                    onClick={toggleBottomMenu}
                  >
                    {t('BOTTOM_NAV_MORE_CHANGE_PASSWORD')}
                    <FiChevronRight size={16} className='chevron-right' />
                  </NavLink>
                </Menu.Item>
              )}
            </Menu>
          </MobileMenuSection>

          <MobileMenuSection>
            <MenuSectionTitle>{t('BOTTOM_NAV_MORE_SUPPORT')}</MenuSectionTitle>
            <Menu>
              <Menu.Item key='5'>
                <NavLink
                  isActive={() => false}
                  to={pathname}
                  onClick={onOpenHelpMenu}
                >
                  {t('BOTTOM_NAV_MORE_HELP')}
                  <FiChevronRight size={16} className='chevron-right' />
                </NavLink>
              </Menu.Item>
              <Menu.Item key='6'>
                <NavLink
                  isActive={() => false}
                  to={pathname}
                  onClick={onSupportClick}
                >
                  {t('BOTTOM_NAV_MORE_CONTACT_SUPPORT')}
                  <FiChevronRight size={16} className='chevron-right' />
                </NavLink>
              </Menu.Item>
            </Menu>
          </MobileMenuSection>

          {!!loggedIn && !!user && (
            <MobileMenuSection>
              <MenuSectionTitle></MenuSectionTitle>
              <Menu>
                <Menu.Item key='7'>
                  <NavLink
                    isActive={() => false}
                    to={pathname}
                    onClick={onLogoutClick}
                  >
                    {t('BOTTOM_NAV_MORE_LOG_OUT')}
                    <FiChevronRight size={16} className='chevron-right' />
                  </NavLink>
                </Menu.Item>
              </Menu>
            </MobileMenuSection>
          )}
        </DrawerMoreBodyWrapper>
        <DrawerFooter />
      </Drawer>

      <MobileSubMenu
        title={t('LANGUAGES_MENU_ITEM')}
        visible={mobileLanguageOpened}
        onClose={onCloseLanguageMenu}
      >
        <MobileLanguageMenu onClose={onCloseLanguageMenu} />
      </MobileSubMenu>

      <MobileSubMenu
        title={t('HELP_MENU_ITEM')}
        visible={mobileHelpOpened}
        onClose={onCloseHelpMenu}
      >
        <MobileHelpMenu />
      </MobileSubMenu>
    </Fragment>
  );
};

export default MoreMobileMenu;
