import React from 'react';
import { withRouter } from 'react-router-dom';
import { Trigger } from './Notifications/Notifications.style'
import { LOGIN_PAGE } from 'settings/constant';
import { ReactSVG } from 'react-svg';
import customer from 'assets/images/pickapier/customer.svg';

const GuestProfile = ({ match, location, history }) => {
  const onClickHandler = e => {
    e.preventDefault();
    history.push(`${match.url}${LOGIN_PAGE}`);
  };

  return (
    <Trigger
      className='additional-menu-trigger'
      onClick={onClickHandler}
    >
      <ReactSVG className='additional-menu-trigger-icon' src={customer} />
    </Trigger>
  );
};

export default withRouter(GuestProfile);
