import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { AuthContext } from 'context/AuthProvider';
import AbsenceReportBanner from 'components/UI/AbsenceReportBanner/AbsenceReportBanner';
import absenceReportService from 'services/absenceReport';

export const AbsenceReportContext = React.createContext({});

const AbsenceReportProvider = ({ children }) => {
  const { updates } = useContext(AuthContext);

  const [loadingAbsenceReports, setLoadingAbsenceReports] = useState(false);

  const [absenceReports, setAbsenceReports] = useState([]);
  const [allAbsenceReports, setAllAbsenceReports] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [past, setPast] = useState([]);
  const [ARBannerVisible, setARBannerVisible] = useState(false);

  const notificationUpdate = updates.notification;

  const getAbsenceReports = async () => {
    setLoadingAbsenceReports(true);
    try {
        const result = await absenceReportService.getall();
        const sortedResult = [];
        Object.keys(result || {}).forEach(key => {
            result[key].forEach(item => sortedResult.push(item));
        });
        sortedResult.sort((a, b) =>
            moment(a.departure_date, 'YYYY-MM-DD').diff(
                moment(b.departure_date, 'YYYY-MM-DD')
            )
        );
        setAbsenceReports(sortedResult);
        setLoadingAbsenceReports(false);
      setPast(
        sortedResult
          .filter(
            ar =>
              ar.deleted || moment().diff(moment(ar.return_date), 'days') > 0
          )
          .map(ar => ({ ...ar, completed: true }))
      );
      setUpcoming(
        sortedResult
          .filter(
            ar =>
              !ar.deleted && moment().diff(moment(ar.return_date), 'days') <= 0
          )
          .map(ar => ({ ...ar, completed: false }))
      );
      setAllAbsenceReports([].concat.apply([], Object.values(result)));
    } catch (err) {
      setLoadingAbsenceReports(false);
      console.error(err);
    }
  };

  useEffect(() => {
    if (notificationUpdate) {
      getAbsenceReports();
    }
  }, [notificationUpdate]);

  const onOpenARBanner = () => {
    setARBannerVisible(true);
  };

  const onCloseARBanner = () => {
    setARBannerVisible(false);
  };

  return (
    <AbsenceReportContext.Provider
      value={{
        getAbsenceReports,
        loadingAbsenceReports,
        absenceReports,
        upcoming,
        past,
        onOpenARBanner,
        onCloseARBanner,
        allAbsenceReports
      }}
    >
      {children}
      <AbsenceReportBanner
        visible={ARBannerVisible}
        onClose={onCloseARBanner}
      />
    </AbsenceReportContext.Provider>
  );
};

export default AbsenceReportProvider;
