import {combineReducers} from "@reduxjs/toolkit";
import {appReducer} from './slices/app/appSlice';
import {authReducer} from './slices/auth/authSlice';
import {reservationReducer} from './slices/reservation/reservationSlice';

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    reservation: reservationReducer
});

export default rootReducer;
