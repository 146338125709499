import React, {createContext, useContext, useMemo, useReducer} from 'react';
export const LayoutContext = createContext();

const initialState = {
  searchVisibility: false,
  homeSearchActive: false,
  homeDrawerOpened: false,
  tripsDrawerOpened: false,
  moreDrawerOpened: false,
  authDrawerOpened: false,
  mobileHeaderHidden: false,
  popeyeRequestDrawerOpened: false,
  popeyePledges: {
    pledgeIndex: null,
    loading: false
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'SHOW_TOP_SEARCHBAR':
      return {
        ...state,
        searchVisibility: true
      };
    case 'HIDE_TOP_SEARCHBAR':
      return {
        ...state,
        searchVisibility: false
      };
    case 'ACTIVATE_HOME_SEARCHBAR':
      return {
        ...state,
        homeSearchActive: true
      };
    case 'DEACTIVATE_HOME_SEARCHBAR':
      return {
        ...state,
        homeSearchActive: false
      };
    case 'OPEN_HOME_DRAWER':
      return {
        ...state,
        homeDrawerOpened: true
      };
    case 'OPEN_TRIPS_DRAWER':
      return {
        ...state,
        tripsDrawerOpened: true
      };
    case 'OPEN_MORE_DRAWER':
      return {
        ...state,
        moreDrawerOpened: true
      };
    case 'OPEN_AUTH_DRAWER':
      return {
        ...state,
        authDrawerOpened: true
      };
    case 'HIDE_HOME_DRAWER':
      return {
        ...state,
        homeDrawerOpened: false
      };
    case 'HIDE_TRIPS_DRAWER':
      return {
        ...state,
        tripsDrawerOpened: false
      };
    case 'HIDE_MORE_DRAWER':
      return {
        ...state,
        moreDrawerOpened: false
      };
    case 'HIDE_AUTH_DRAWER':
      return {
        ...state,
        authDrawerOpened: false
      };
    case 'HIDE_MOBILE_MENU':
      return {
        ...state,
        mobileMenuHidden: true
      };
    case 'SHOW_MOBILE_MENU':
      return {
        ...state,
        mobileMenuHidden: false
      };
    case 'HIDE_MOBILE_HEADER':
      return {
        ...state,
        mobileHeaderHidden: true
      };
    case 'SHOW_MOBILE_HEADER':
      return {
        ...state,
        mobileHeaderHidden: false
      };
    case 'OPEN_POPEYE_REQUEST_DRAWER':
      return {
        ...state,
        popeyeRequestDrawerOpened: true
      };
    case 'HIDE_POPEYE_REQUEST_DRAWER':
      return {
        ...state,
        popeyeRequestDrawerOpened: false
      };
    case 'ININTIATE_PLEDGE_INDEX':
      return {
        ...state,
        popeyePledges: {
          ...state.popeyePledges,
          pledgeIndex: 0
        }
      };
    case 'INCREASE_PLEDGE_INDEX':
      return {
        ...state,
        popeyePledges: {
          ...state.popeyePledges,
          pledgeIndex: ++state.popeyePledges.pledgeIndex
        }
      };
    case 'DECREASE_PLEDGE_INDEX':
      return {
        ...state,
        popeyePledges: {
          ...state.popeyePledges,
          pledgeIndex: --state.popeyePledges.pledgeIndex
        }
      };
    case 'RESET_PLEDGE_INDEX':
      return {
        ...state,
        popeyePledges: {
          ...state.popeyePledges,
          pledgeIndex: null
        }
      };
    default:
      return state;
  }
}

export default function LayoutProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ([state, dispatch]), [state, dispatch]);

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  );
}

export const useStateValue = () => useContext(LayoutContext);
