import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    user: undefined,
    boater: undefined,
    loginError: undefined
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        init(state) {},
        initSuccess(state, action) {},
        initFailure(state) {},
        loginWithUsername(state, action) {
            state.loginError = undefined;
        },
        loginWithGoogle(state, action) {
            state.loginError = undefined;
        },
        loginWithFacebook(state, action) {
            state.loginError = undefined;
        },
        loginSuccess(state, action) {
            state.user = action.payload.user;
            state.boater = action.payload.boater;
            state.isLoggedIn = true;
            sessionStorage.setItem('user', JSON.stringify(action.payload.user));
            sessionStorage.setItem('boater', JSON.stringify(action.payload.boater));
            sessionStorage.setItem('isLoggedIn', JSON.stringify(true));
        },
        loginFailure(state, action) {
            state.loginError = action.payload;
        },
        setLoginError(state, action) {
            state.loginError = action.payload;
        },
        setUser(state, action) {
            state.user = action.payload;
            sessionStorage.setItem('user', JSON.stringify(action.payload));
        },
        setBoater(state, action) {
            state.boater = action.payload;
            sessionStorage.setItem('boater', JSON.stringify(action.payload));
        },
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
            sessionStorage.setItem('isLoggedIn', JSON.stringify(action.payload));
        }
    }
});

export const authActions = authSlice.actions;

export const authReducer = authSlice.reducer;

export default authSlice;
