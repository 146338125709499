import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const HeaderWrapper = styled.header`
  width: 100%;
  .sticky-outer-wrapper {
    &.isHeaderSticky {
      nav {
        background-color: ${themeGet('color.1', '#ffffff')};
        @media only screen and (max-width: 991px) {
          background-color: ${themeGet('color.40', '#F2F2F2')};
        }
      }
      .nav-link {
        color: ${themeGet('text.13', '#121a2c')};
        &:hover {
          background-color: rgba(242, 242, 242);
        }
        &.vacancy {
          background-color: ${themeGet('primary.0', '#194AB9')};
          color: ${themeGet('color.1', '#ffffff')};
          &:hover {
            background-color: ${themeGet('primary.2', '#194AB9')}
          }
        }
      }
      .header-search-Bar-container {
        @media only screen and (max-width: 991px) {
          height: 52px;
          display: block;
          width: 100%;
          font-size: 18px;
        }
      }
      .env-banner,
      .header-navbar-logo {
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }
      .additional-menu-wrapper {
        background-color: transparent;
        @media only screen and (max-width: 991px) {
          border: 1px solid transparent;
          background-color: transparent;
        }
      }
      .additional-menu-trigger {
        color: ${themeGet('text.13', '#121a2c')};
        .additional-menu-trigger-icon {
          svg {
            .affect-class {
              &.fill {
                fill: ${themeGet('text.13', '#121a2c')};
              }
              &.stroke {
                stroke: ${themeGet('text.13', '#121a2c')};
              }
            }
          }
        }
        &:hover {
          background-color: rgba(242, 242, 242)
        }
      }
      .auth-menu-wrapper {
        ul.ant-menu {
          li:first-child {
            a {
              color: ${themeGet('text.13', '#121a2c')};
              &:hover {
                color: ${themeGet('primary.0', '#194AB9')};
              }
            }
          }
        }
        &.transparent {
          ul.ant-menu {
            li:first-child {
              a {
                color: ${themeGet('text.13', '#121a2c')};
                background-color: ${themeGet('color.1', '#ffffff')};
                border: 1px solid;
                border-color: ${themeGet('text.13', '#121a2c')};
                &:hover {
                  background-color: rgba(242, 242, 242);
                }
              }
            }
            li:last-child {
              a {
                color: ${themeGet('color.1', '#ffffff')};
                background-color: ${themeGet('primary.0', '#ffffff')};
                border: 0px;
                &:hover {
                  background-color: ${themeGet('primary.2')};
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MarinaMobileHeaderWrapper = styled.div`
  background-color: ${themeGet('color.1', '#ffffff')};
  padding: 16px 1px;
  position: relative;
  z-index: 2;
  .back_button {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
`;

export const BackButtonTitle = styled.span`
  margin-left: 7px;
  font-size: 15px;
`;

export default HeaderWrapper;
