import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Sticky from 'react-stickynode';
import { withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';
import { EmailVerificationContext } from 'context/EmailVerificationProvider';
import EmailVerificationBanner from 'components/UI/EmailVerificationBanner/EmailVerificationBanner';
import Navbar from 'components/Navbar/Navbar';
import Logo from 'components/UI/Logo/Logo';
import AuthModal from 'components/UI/AuthModal/AuthModal';
import AuthMenu from './AuthMenu';
import MyTripsMenu from './MyTripsMenu';
import PopeyeMenu from './PopeyeMenu';
import GuestProfile from './GuestProfile';
import MarinaMobileHeader from './MarinaMobileHeader';
import LanguageMenu from './LanguageMenu/LanguageMenu';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import NavbarSearch from './NavbarSearch/NavbarSearch';
import Notifications from './Notifications/Notifications';
import useWindowSize from 'library/hooks/useWindowSize';
import boater from 'services/boater.js';
import HeaderWrapper from './Header.style';
import { MARINA_PAGE } from 'settings/constant';
// icons
import logoHorizontal from 'assets/images/pickapier/logo_horizontal.svg';
import logoHorizontalWhite from 'assets/images/pickapier/logo_horizontal_white.svg';
import circleLogo from 'assets/images/pickapier/logo_circle.svg';
import circleLogoWhite from 'assets/images/pickapier/logo_circle_white.svg';
import HMVpopup from 'components/UI/HMVpopup/HMVpopup';
import {DISCOVER_PAGE} from "../../../settings/constant";

export default withRouter(function Header({ history, location, match }) {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const { loggedIn, user, homeMarinaVerified, setHomeMarinaVerified } =
    useContext(AuthContext);
  const [, dispatch] = useContext(LayoutContext);
  const { pathname } = location;
  const { bannerVisible } = useContext(EmailVerificationContext);

  const [sticked, setSticked] = useState(false);
  const [authVisible, setAuthVisible] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const handleStateChange = status => {
    setSticked(status.status === Sticky.STATUS_FIXED);
  };

  const bannerVisibility = bannerVisible && loggedIn;
  const isMarinaPage = pathname.includes(MARINA_PAGE);
  const isDiscoverPage = pathname.includes(DISCOVER_PAGE);

  const headerType =
    pathname === match.url && !bannerVisibility ? 'transparent' : 'default';
  let logoSrc = '';
  if (width > 991) {
    logoSrc =
      headerType === 'transparent' && !sticked
        ? logoHorizontalWhite
        : logoHorizontal;
  } else {
    logoSrc =
      headerType === 'transparent' && !sticked ? circleLogoWhite : circleLogo;
  }

  if(width < 992 && isDiscoverPage) {
    return <></>;
  }

  return (
    <HeaderWrapper>
      <Sticky
        top={enabled ? 0 : width < 992 && isMarinaPage ? -64 : -52}
        innerZ={10001}
        activeClass='isHeaderSticky'
        className='header-sticky-wrapper'
        onStateChange={handleStateChange}
      >
        {width < 992 && isMarinaPage ? (
          <MarinaMobileHeader history={history} match={match} />
        ) : (
          <Navbar
            headerType={headerType}
            isLogin={loggedIn}
            windowWidth={width}
            user={user}
            logo={
              <>
                <Logo withLink className='header-navbar-logo' linkTo={`${match.url}`} src={logoSrc} />
              </>
            }
            profileMenu={<ProfileMenu />}
            authMenu={<AuthMenu />}
            myTripsMenu={
              <MyTripsMenu
                loggedIn={loggedIn}
                user={user}
                onAuth={() => setAuthVisible(true)}
              />
            }
            searchComponent={<NavbarSearch />}
            guestProfile={<GuestProfile />}
            popeyeMenu={
              <PopeyeMenu
                loggedIn={loggedIn}
                user={user}
                onAuth={() => setAuthVisible(true)}
              />
            }
            languageMenu={<LanguageMenu />}
            notificationsComponent={
              <Notifications onAuth={() => setAuthVisible(true)} />
            }
            onAuth={() => setAuthVisible(true)}
          />
        )}
        <EmailVerificationBanner headerType={headerType} />
      </Sticky>
      <AuthModal
        visible={authVisible}
        title={t('POPUP_SIGNUP_HEADER')}
        onClose={() => setAuthVisible(false)}
      />
      <HMVpopup
        visible={!!homeMarinaVerified}
        marinaName={homeMarinaVerified}
        history={history}
        locale={match.params.locale}
        onClose={() => setHomeMarinaVerified('')}
      />
    </HeaderWrapper>
  );
});
