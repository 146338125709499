import React, { useContext, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import i18n from 'i18n';
import * as Cookies from 'js-cookie';
import { FiChevronDown } from 'react-icons/fi';
import { AuthContext } from 'context/AuthProvider';
import Menu from 'components/UI/Antd/Menu/Menu';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import boater from 'services/boater.js';
import locales, { localeOptions } from 'settings/locales';
import { LanguageTrigger, Label } from './LanguageMenu.style';

const LanguageMenu = ({ match, location, history }) => {
  const [opened, setOpened] = useState(false);
  const { loggedIn, refreshData } = useContext(AuthContext);

  const toggleLanguages = () => setOpened(!opened);
  const closeLanguages = () => setOpened(false);

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setOpened(false));

  const changeLanguage = lng => {
    if (loggedIn) {
      boater
        .update({ boater: { language: lng } })
        .then(res => {
          refreshData({ parameters: ['boater'] });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const acceptCookies = Cookies.get('accepts-cookies') === 'accepted';
      if (acceptCookies) {
        Cookies.set('guest-language', lng, { expires: 365 * 10 });
      }
    }
    // change language
    i18n.changeLanguage(lng);
    // change route
    const ret = location.pathname.replace(`/${match.params.locale}`, '');
    const newPathname = `/${lng}${ret}${location.hash}`;
    // when pushing an object the rendering doesn't happen why
    // history.push({
    //   pathname: newPathname,
    //   search: location.search
    // });
    history.push(newPathname)
  };

  return (
    <div
      className='dropdown-menu-item languages-dropdown-menu'
      ref={dropdownRef}
    >
      <LanguageTrigger
        className={`additional-menu-trigger ${opened ? 'active' : 'hide'}`}
        onClick={toggleLanguages}
      >
        <Label>{i18n.language}</Label>
        <FiChevronDown size={16} />
      </LanguageTrigger>

      {/* ToDo: selected menu indicate */}
      <Menu className={`dropdown-menu ${opened ? 'active' : 'hide'}`}>
        {localeOptions.map((option, index) => (
          <Menu.Item key={index}>
            <a
              className='languages-dropdown-menu-item'
              onClick={e => {
                e.preventDefault();
                changeLanguage(option);
                closeLanguages();
              }}
            >
              {locales[option] ? locales[option].label : locales.en.label}
            </a>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
};

export default withRouter(LanguageMenu);
