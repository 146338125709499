import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import i18n from 'i18n';
import * as Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import Menu from 'components/UI/Antd/Menu/Menu';
import boater from 'services/boater.js';
import locales, { localeOptions } from 'settings/locales';
import { AuthContext } from 'context/AuthProvider';

const MobileLanguageMenu = ({ onClose, match, location, history }) => {
  const { loggedIn, refreshData } = useContext(AuthContext);
  const changeLanguage = lng => {
    if (loggedIn) {
      boater
        .update({ boater: { language: lng } })
        .then(res => {
          refreshData({ parameters: ['boater'] });
          onClose();
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      const acceptCookies = Cookies.get('accepts-cookies') === 'accepted';
      if (acceptCookies) {
        Cookies.set('guest-language', lng, { expires: 365 * 10 });
      }
    }
    i18n.changeLanguage(lng);
    // change route
    const ret = location.pathname.replace(`/${match.params.locale}`, '');
    const newPathname = `/${lng}${ret}`;
    history.push({
      pathname: newPathname,
      search: location.search
    });
  };
  return (
    <Menu
      defaultSelectedKeys={
        locales[i18n.language] ? locales[i18n.language].label : 0
      }
      className='mobile-submenu'
      mode='inline'
      inlineIndent={0}
    >
      {localeOptions.map((option, index) => {
        return (
          <Menu.Item key={locales[option] ? locales[option].label : index}>
            <a
              className='mobile-language-menu-item'
              href='#'
              onClick={e => {
                e.preventDefault();
                changeLanguage(option);
              }}
            >
              <img
                className='flag'
                src={locales[option] ? locales[option].icon : locales.en.icon}
                alt='Language pic'
              />
              <span>
                {locales[option] ? locales[option].label : locales.en.label}
              </span>
            </a>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default withRouter(MobileLanguageMenu);
