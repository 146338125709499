let amplitude;
const getAmplitude = async () => {
  if (!amplitude) {
    await import('amplitude-js').then(amp => {
      amplitude = amp;
      amplitude.getInstance().init(
        process.env.REACT_APP_AMPLITUDE_API_KEY, null,
        {
          includeUtm: true,
          includeReferrer: true,
          includeGclid: true
        });
    });
  }
  return amplitude;
};

export { getAmplitude };
