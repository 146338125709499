import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CookieConcentBody = styled.div`
  z-index: 999999;
  width: 70%;
  padding: 20px 63px 20px 34px;
  position: absolute;
  border-radius: 8px;
  right: 15%;
  background-color: #F0F5FF;
  box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.75);
  transition: all 0.3s ease;
  ${({ visible }) =>
    visible
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
  @media only screen and (max-width: 767px) {
    padding: 10px 40px;
  }
  .cookie_decline_button {
    position: absolute;
    top: 5px;
    right: 5px;
    border: 0;
    background-color: #F0F5FF;
    outline: none;
    padding: 0;
    &:hover,
    &:active,
    &:focus {
      color: ${themeGet('text.0', '#2C2C2C')};
    }
    &:after {
      animation: none !important;
    }
  }
`;

export const CookieConcentContent = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
  p {
    margin-bottom: 0;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #121A2C;
    line-height: 16.8px;
    @media only screen and (max-width: 480px) {
      margin-bottom: 10px;
    }
  }
  a {
    text-decoration: underline;
    color: ${themeGet('primary.0', '#194AB9')};
  }
  button {
    margin-left: 20px;
    font-weight: bold;
    @media only screen and (max-width: 480px) {
      margin-left: 0;
    }
  }
`;
