import React from 'react';

import map from 'lodash/map';
import {
  DatePicker,
  Form,
  Input,
  TimePicker,
  Select,
  Rate,
  Switch,
  Checkbox,
  AutoComplete,
  InputNumber
} from 'antd';

const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const { Option } = Select;

const CreateAntField =
  Component =>
  ({
    field,
    form,
    hasFeedback,
    label,
    selectOptions,
    submitCount,
    type,
    renderRight,
    innerRef,
    onBlur = () => {},
    labelCol = { span: 24 }, // label at the top by default
    ...props
  }) => {
    const touched = form.touched[field.name];
    const submitted = submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({ target: { value } }) => {
      form.setFieldValue(field.name, value);
      if (props.onInputChange) {
        props.onInputChange(value);
      }
    };
    const onChange = value => {
      form.setFieldValue(field.name, value) &&
        props.onChange &&
        props.onChange(value);
    };
    const onBlurHandler = () => {
      form.setFieldTouched(field.name, true);
      onBlur();
    };

    return (
      <div className='field-container'>
        <FormItem
          labelCol={labelCol}
          label={label}
          colon={false}
          hasFeedback={
            (hasFeedback && submitted) || (hasFeedback && touched)
              ? true
              : false
          }
          help={submittedError || touchedError ? hasError : false}
          validateStatus={submittedError || touchedError ? 'error' : 'success'}
        >
          <Component
            {...field}
            {...props}
            ref={innerRef}
            type={type}
            onBlur={onBlurHandler}
            onChange={type ? onInputChange : onChange}
          >
            {selectOptions &&
              (selectOptions.length
                ? map(selectOptions, name => {
                    if (typeof name === 'object') {
                      const { value, label } = name;
                      return (
                        <Option key={value} value={value}>
                          {label}
                        </Option>
                      );
                    } else {
                      return (
                        <Option key={name} value={name}>
                          {name}
                        </Option>
                      );
                    }
                  })
                : map(Object.keys(selectOptions), value => (
                    <Option key={value} value={value}>
                      {selectOptions[value]}
                    </Option>
                  )))}
          </Component>
        </FormItem>
      </div>
    );
  };

export const AntSelect = CreateAntField(Select);
export const AntDatePicker = CreateAntField(DatePicker);
export const AntInput = CreateAntField(Input);
export const AntInputPassword = CreateAntField(Input.Password);
export const AntInputNumber = CreateAntField(InputNumber);
export const AntTextArea = CreateAntField(Input.TextArea);
export const AntTimePicker = CreateAntField(TimePicker);
export const AntRate = CreateAntField(Rate);
export const AntSwitch = CreateAntField(Switch);
export const AntRange = CreateAntField(RangePicker);
export const AntCheckbox = CreateAntField(Checkbox);
export const AntAutoComplete = CreateAntField(AutoComplete);
