import { post, get } from './api';

export default {
  getall: async function () {
    return await get('/absence_report/getall');
  },
  get: async function (id) {
    return await get('/absence_report/get/' + id);
  },
  create: async function (data) {
    return await post('/absence_report/create', data);
  },
  update: async function (data) {
    return await post('/absence_report/edit', data);
  },
  delete: async function (data) {
    return await post('/absence_report/delete', data);
  },
  activate_auto_absence: async function (data) {
    return await post('/absence_report/activate_auto_absence', data);
  }
};
