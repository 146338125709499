import * as Yup from 'yup';
import i18n from 'i18next';
import moment from 'moment';
import configForFE from 'common-pickapier/configForFE';
import {formatLocalizedDate} from "./dates";
const maxDate = moment();
const date = new Date(maxDate);

const nameRexp = /^[a-zA-Z][^{0-9}#&@<=>+_\"(|)\\\/ˆ˜'*~.,;:$^%{}±§!?\[\]]+$/;
const phoneRexp = /^(\+{0,1}\d(\d|-){4,})$/;
const emailRexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getEmailSchemaObject = i18n => {
  return Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('INVALID_EMAIL'))
      .matches(emailRexp, i18n.t('INVALID_EMAIL'))
      .required(i18n.t('EMAIL_REQUIRED'))
  });
};

const getEmailSchemaForPartnersObject = i18n => {
  return Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('INVALID_EMAIL'))
      .matches(emailRexp, i18n.t('INVALID_EMAIL'))
  });
};

const getPersonalInfoSchemaObject = i18n => {
  return Yup.object().shape({
    country_of_residence: Yup.string().required(i18n.t('COUNTRY_REQUIRED')),
    first_name: Yup.string()
      .matches(nameRexp, i18n.t('FIRST_NAME_INCORRECT'))
      .required(i18n.t('FIRST_NAME_REQUIRED')),
    last_name: Yup.string()
      .matches(nameRexp, i18n.t('LAST_NAME_INCORRECT'))
      .required(i18n.t('LAST_NAME_REQUIRED')),
    phone: Yup.string()
      .matches(phoneRexp, i18n.t('PHONE_NUMBER_ERROR'))
      .required(i18n.t('PHONE_NUMBER_REQUIRED')),
    date_of_birth: Yup.date()
      .nullable()
      .max(
        maxDate,
        i18n.t('DATE_OF_BIRTH_SHOULD_NOT_BE_LATER', {
          max_date: `${formatLocalizedDate(new Date())}`
        })
      )
  });
};

const getVesselFeaturesSchemaObject = (i18n, hull_type, maxVesselLengths = { max_vessel_length:250, max_vessel_draft:20, max_vessel_beam:40 }) => {
  return Yup.object().shape({

    hull_type: hull_type? Yup.string()
      .typeError(i18n.t('VESSEL_HULL_TYPE_REQUIRED'))
      .required(i18n.t('VESSEL_HULL_TYPE_REQUIRED'))
      .oneOf(configForFE.VESSEL_HULL_TYPES)
      .nullable(): '',
    length: Yup.number()
      .transform(v => (isNaN(v) ? undefined : v))
      .required(i18n.t('VESSEL_LOA_REQUIRED'))
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_LENGTH_ERROR'))
      .max(maxVesselLengths.max_vessel_length, i18n.t('VESSEL_MAX_LENGTH_ERROR', {max_size: maxVesselLengths.max_vessel_length})),
    draft: Yup.number()
      .transform(v => (isNaN(v) ? undefined : v))
      .required(i18n.t('VESSEL_DRAFT_REQUIRED'))
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_DRAFT_ERROR'))
      .max(maxVesselLengths.max_vessel_draft, i18n.t('VESSEL_MAX_DRAFT_ERROR', {max_size: maxVesselLengths.max_vessel_draft})),
    beam: Yup.number()
      .transform(v => (isNaN(v) ? undefined : v))
      .required(i18n.t('VESSEL_BEAM_REQUIRED'))
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_BEAM_ERROR'))
      .max(maxVesselLengths.max_vessel_beam, i18n.t('VESSEL_MAX_BEAM_ERROR', {max_size: maxVesselLengths.max_vessel_beam}))
  });
};

const getVesselProfileSchemaObject = i18n => {
  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .required(i18n.t('VESSEL_NAME_REQUIRED'))
      .min(3, i18n.t('VESSEL_NAME_MIN_LETTERS')),
    length: Yup.number()
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_LENGTH_ERROR'))
      .max(configForFE.MAX_RESERVATION_VESSEL_LOA, i18n.t('VESSEL_MAX_LENGTH_ERROR', {max_size: configForFE.MAX_RESERVATION_VESSEL_LOA})),
    draft: Yup.number()
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_DRAFT_ERROR'))
      .max(configForFE.MAX_RESERVATION_VESSEL_DRAFT, i18n.t('VESSEL_MAX_DRAFT_ERROR', {max_size: configForFE.MAX_RESERVATION_VESSEL_DRAFT})),
    beam: Yup.number()
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_BEAM_ERROR'))
      .max(configForFE.MAX_RESERVATION_VESSEL_BEAM, i18n.t('VESSEL_MAX_BEAM_ERROR', {max_size: configForFE.MAX_RESERVATION_VESSEL_BEAM})),
    type: Yup.string()
      .oneOf(configForFE.VESSEL_TYPES)
      .required(i18n.t('VESSEL_TYPE_REQUIRED')),
    hull_type: Yup.string()
      .oneOf(configForFE.VESSEL_HULL_TYPES)
      .required(i18n.t('VESSEL_HULL_TYPE_REQUIRED'))
  });
};

const getConnectMembershipSchemaObject = i18n => {
  return Yup.object().shape({
    card_number: Yup.string()
      .trim()
      .required(i18n.t('CARD_CODE_REQUIRED'))
      .matches(/^[A-Z]{3}\d{4,7}$/, i18n.t('CARD_CODE_FORMAT'))
  });
};

const getConnectBFGMembershipSchemaObject = i18n => {
  return Yup.object().shape({
    card_number: Yup.string()
      .trim()
      .required(i18n.t('CARD_CODE_REQUIRED'))
      .matches(/^[A-Z]{3}\d{4,9}[A-Z]{3}$/, i18n.t('CARD_CODE_FORMAT_BFG'))
  });
};

const getConnectIGYMembershipSchemaObject = i18n => {
  return Yup.object().shape({
    card_number: Yup.string()
      .trim()
      .required(i18n.t('CARD_CODE_REQUIRED'))
      .matches(/^[A-Z]{3}\d{4,9}[A-Z]{3}$/, i18n.t('CARD_CODE_FORMAT_IGY'))
  });
};

const getConnectSTXMembershipSchemaObject = i18n => {
  return Yup.object().shape({
    card_number: Yup.string()
      .trim()
      .required(i18n.t('CARD_CODE_REQUIRED'))
      .matches(/^[A-Z]{3}\d{4,9}[A-Z]{3}$/, i18n.t('CARD_CODE_FORMAT_STX'))
  });
};

const getRequestMembershipSchemaObject = i18n => {
  return Yup.object().shape({
    home_marina_id: Yup.string()
      .trim()
      .required(i18n.t('HOME_MARINA_REQUIRED')),
    vessel_id: Yup.string()
      .trim()
      .nullable()
      .required(i18n.t('VESSEL_NAME_REQUIRED'))
  });
};

const getRequestMembershipSchemaExtendedObject = i18n => {
  return Yup.object().shape({
    boat_name: Yup.string()
      .trim()
      .required(i18n.t('VESSEL_NAME_REQUIRED'))
      .min(3, i18n.t('VESSEL_NAME_MIN_LETTERS')),
    home_marina_id: Yup.string()
      .trim()
      .required(i18n.t('HOME_MARINA_REQUIRED')),
    boat_length: Yup.number()
      .typeError(i18n.t('NUMBER_TYPE_ERROR'))
      .min(0.1, i18n.t('VESSEL_LENGTH_ERROR'))
      .max(1000, i18n.t('VESSEL_MAX_LENGTH_ERROR'))
      .required(i18n.t('VESSEL_LOA_REQUIRED'))
  });
};

const getLoginSchemaObject = i18n => {
  return Yup.object().shape({
    email: Yup.string()
      .email(i18n.t('INVALID_EMAIL'))
      .matches(emailRexp, i18n.t('INVALID_EMAIL'))
      .required(i18n.t('EMAIL_REQUIRED')),
    password: Yup.string()
      .min(6, i18n.t('PASSWORD_LENGTH'))
      .max(20, i18n.t('PASSWORD_TOO_LONG'))
      .required(i18n.t('PASSWORD_REQUIRED'))
  });
};

const getExtendedSignupSchemaObject = i18n => {
  return Yup.object().shape({
    first_name: Yup.string()
      .matches(nameRexp, i18n.t('FIRST_NAME_INCORRECT'))
      .required(i18n.t('FIRST_NAME_REQUIRED'))
      .min(2, i18n.t('FIRST_NAME_LENGTH')),
    last_name: Yup.string()
      .matches(nameRexp, i18n.t('LAST_NAME_INCORRECT'))
      .required(i18n.t('LAST_NAME_REQUIRED'))
      .min(2, i18n.t('LAST_NAME_LENGTH')),
    email: Yup.string()
      .email(i18n.t('INVALID_EMAIL'))
      .matches(emailRexp, i18n.t('INVALID_EMAIL'))
      .required(i18n.t('EMAIL_REQUIRED')),
    password: Yup.string()
      .min(6, i18n.t('PASSWORD_LENGTH'))
      .max(20, i18n.t('PASSWORD_TOO_LONG'))
      .required(i18n.t('PASSWORD_REQUIRED')),
    termsAndConditions: Yup.bool().oneOf(
      [true],
      i18n.t('SIGN_UP_TERMS_REQUIRED')
    )
  });
};

const getResidencySchemaObject = i18n => {
  return Yup.object().shape({
    home_marina_name: Yup.string()
      .nullable()
      .trim()
      .required(i18n.t('HOME_MARINA_REQUIRED'))
  });
};

const getAbsenceReportSchemaObject = i18n => {
  return Yup.object().shape({
    vessel_id: Yup.string()
      .trim()
      .nullable()
      .required(i18n.t('VESSEL_NAME_REQUIRED'))
  });
};

const getPopeyeSchemaObject = i18n => {
  return Yup.object().shape({
    invited_email: Yup.string()
      .email(i18n.t('INVALID_EMAIL'))
      .matches(emailRexp, i18n.t('INVALID_EMAIL'))
      .required(i18n.t('EMAIL_REQUIRED'))
  });
};

// ToDo: provide validation and translations
const getPopeyeWelcomeSchemaObject = i18n => {
  return Yup.object().shape({
    address: Yup.string().required(i18n.t('REQUIRED_FIELD_ERROR')),
    address_line_two: Yup.string(), // Shouldn't be required
    city: Yup.string().required(i18n.t('REQUIRED_FIELD_ERROR')),
    country: Yup.string().required(i18n.t('REQUIRED_FIELD_ERROR')),
    postal_code: Yup.string().required(i18n.t('REQUIRED_FIELD_ERROR')),
  });
}

export const getLoginSchema = i18n => {
  return getLoginSchemaObject(i18n);
};

export const getExtendedSignupSchema = i18n => {
  return getExtendedSignupSchemaObject(i18n);
};

export const getPersonalProfileSchema = i18n => {
  return getPersonalInfoSchemaObject(i18n);
};

export const getPersonalProfileWithEmailSchema = i18n => {
  return getPersonalInfoSchemaObject(i18n).concat(getEmailSchemaObject(i18n));
};

export const getEmailSchemaForPartners = i18n => {
  return getEmailSchemaForPartnersObject(i18n);
};

export const getVesselReserveSchema = (i18n, hull_type, maxVesselLengths) => {
  return getVesselFeaturesSchemaObject(i18n, hull_type, maxVesselLengths);
};

export const getVesselReserveWithNameSchema = (i18n, hull_type, maxVesselLengths) => {
  return getVesselFeaturesSchemaObject(i18n, hull_type, maxVesselLengths).concat(
    Yup.object().shape({
      vessel_name: Yup.string()
        .trim()
        .required(i18n.t('VESSEL_NAME_REQUIRED'))
        .min(3, i18n.t('VESSEL_NAME_MIN_LETTERS'))
    })
  );
};

export const getVesselProfileSchema = i18n => {
  return getVesselProfileSchemaObject(i18n);
};

export const getResidencySchema = i18n => {
  return getResidencySchemaObject(i18n);
};

export const getConnectMembershipSchema = i18n => {
  return getConnectMembershipSchemaObject(i18n);
};

export const getConnectBFGMembershipSchema = i18n => {
  return getConnectBFGMembershipSchemaObject(i18n);
};

export const getConnectIGYMembershipSchema = i18n => {
  return getConnectIGYMembershipSchemaObject(i18n);
};

export const getConnectSTXMembershipSchema = i18n => {
  return getConnectSTXMembershipSchemaObject(i18n);
};

export const getRequestMembershipSchema = i18n => {
  return getRequestMembershipSchemaObject(i18n);
};

export const getRequestMembershipSchemaExtended = i18n => {
  return getRequestMembershipSchemaExtendedObject(i18n);
};

export const getAbsenceReportSchema = i18n => {
  return getAbsenceReportSchemaObject(i18n).concat(
    Yup.object().shape({
      home_marina_id: Yup.string()
        .nullable()
        .required(i18n.t('HOME_MARINA_REQUIRED'))
    })
  );
};

export const getAbsenceReportSchemaEdit = i18n => {
  return getAbsenceReportSchemaObject(i18n);
};

export const getGuestReservationSchema = i18n => {
  return getEmailSchemaObject(i18n).concat(
    Yup.object().shape({
      termsAndConditions: Yup.bool()
        .test(
          'consent',
          i18n.t('SIGN_UP_TERMS_REQUIRED'),
          value => value === true
        )
        .required(i18n.t('SIGN_UP_TERMS_REQUIRED'))
    })
  );
};

export const getPopeyeSchema = i18n => {
  return getPopeyeSchemaObject(i18n);
};

export const getPopeyeWelcomeSchema = i18n => {
  return getPopeyeWelcomeSchemaObject(i18n);
};

export const getSubscriptionSchema = i18n => {
  return getEmailSchemaObject(i18n);
};
