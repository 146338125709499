import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import Tooltip from 'components/UI/Antd/Tooltip/Tooltip';
import Wrapper from './CustomDay.style';

import { isBlockedDayInAdvance } from 'library/helpers/utils';

import PointsIcon from 'assets/images/promotion_points_datepicker_icon.svg';
import PercentIcon from 'assets/images/promotion_percent_datepicker_icon.svg';
import {isBlockedMinDaysInAdvance} from "../../../library/helpers/utils";

export default function CustomDay({ day, limits, promotionType }) {
  const { t } = useTranslation();

  const getTooltip = () => {
    if (isBlockedDayInAdvance(day, limits)) {
      return limits.max_days_before === 1
        ? t('DAY_IN_ADVANCE_TOOL_TIP')
        : t('DAYS_IN_ADVANCE_TOOL_TIP', {
            number_of_days: limits.max_days_before
          });
    } else if (isBlockedMinDaysInAdvance(day, limits)) {
      return limits.min_days_before === 1
          ? t('MIN_DAY_IN_ADVANCE_TOOL_TIP')
          : t('MIN_DAYS_IN_ADVANCE_TOOL_TIP', {
            number_of_days: limits.min_days_before
          });
    } else {
      return t('LENGTH_TOOL_TIP', {
        number_of_days: limits.max_nights
      });
    }
  };

  const promotionIcons = {
    'POINTS_MULTIPLIER': PointsIcon,
    'DISCOUNT':          PercentIcon
  }

  return (
    <Wrapper lineThrough={ Boolean(limits) }>
      {limits ? (
        <Tooltip
          overlayClassName='date-picker-tooltip'
          placement='top'
          title={getTooltip()}
        >
          {day.format('D')}
        </Tooltip>
      ) : (
        day.format('D')
      )}
      {Boolean(promotionType) && <ReactSVG className="promotion-icon" src={promotionIcons[promotionType]}/>}
    </Wrapper>
  );
}
