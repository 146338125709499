import {call, put, takeLatest} from "redux-saga/effects";
import {authActions} from "./authSlice";
import {reservationActions} from "../reservation/reservationSlice"
// import {initializeApp} from 'firebase/app';
// import {
//     signInWithEmailAndPassword,
//     signInWithPopup,
//     FacebookAuthProvider,
//     GoogleAuthProvider,
//     getAuth
// } from 'firebase/auth';
// import config from './firebaseConfig';
import boaterService from "../../../services/boater";
import {appActions} from "../app/appSlice";

function* initializeFirebase() {
    // initializeApp(config);
    yield put(authActions.initSuccess({}));
}

function* loginWithUsername(action) {
    // yield put(appActions.setAppLoading(true));
    // try {
    //     // @ts-ignore
    //     const res = yield call(signInWithEmailAndPassword, getAuth(), action.payload.username, action.payload.password);
    //     // @ts-ignore
    //     const boaterResponse = yield call(boaterService.get_boater,res.user.accessToken);
    //
    //     yield put(authActions.loginSuccess({
    //         user: {
    //             displayName: res.user.displayName,
    //             email: res.user.email,
    //             imageUrl: res.user.photoUrl,
    //             accessToken: res.user.accessToken,
    //             idToken: res.user.idToken
    //         },
    //         boater: boaterResponse.data
    //     }));
    //     yield put(reservationActions.setMail(boaterResponse.data.email));
    //     if(action.payload.onSuccess) {
    //         yield call(action.payload.onSuccess);
    //     }
    // } catch (e) {
    //     yield put(authActions.loginFailure('EMAIL_AND_OR_PASSWORD_ARE_INCORRECT'));
    // }
    // yield put(appActions.setAppLoading(false));
}

function* loginWithGoogle(action) {
    // yield put(appActions.setAppLoading(true));
    // try {
    //     const provider = new GoogleAuthProvider();
    //     provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    //     // @ts-ignore
    //     const res = yield call(signInWithPopup, getAuth(), provider);
    //     // @ts-ignore
    //     const boaterResponse = yield call(boaterService.get_boater,res.user.accessToken);
    //
    //     yield put(authActions.loginSuccess({
    //         user: {
    //             displayName: res.user.displayName,
    //             email: res.user.email,
    //             imageUrl: res.user.photoUrl,
    //             accessToken: res.user.accessToken,
    //             idToken: res.user.idToken
    //         },
    //         boater: boaterResponse.data
    //     }));
    //     if(action.payload.onSuccess) {
    //         yield call(action.payload.onSuccess);
    //     }
    // } catch (e) {
    //     yield put(authActions.loginFailure('LOGIN_FAILED'));
    // }
    // yield put(appActions.setAppLoading(false));
}

function* loginWithFacebook(action) {
    // yield put(appActions.setAppLoading(true));
    // try {
    //     // @ts-ignore
    //     const provider = new FacebookAuthProvider();
    //     provider.addScope('email');
    //     // @ts-ignore
    //     const res = yield call(signInWithPopup, getAuth(), provider);
    //     console.log('res', res);
    //     // @ts-ignore
    //     const boaterResponse = yield call(boaterService.get_boater,res.user.accessToken);
    //
    //     yield put(authActions.loginSuccess({
    //         user: {
    //             displayName: res.user.displayName,
    //             email: res.user.email,
    //             imageUrl: res.user.photoUrl,
    //             accessToken: res.user.accessToken,
    //             idToken: res.user.idToken
    //         },
    //         boater: boaterResponse.data
    //     }));
    //     if(action.payload.onSuccess) {
    //         yield call(action.payload.onSuccess);
    //     }
    // } catch (e) {
    //     yield put(authActions.loginFailure('LOGIN_FAILED'));
    // }
    // yield put(appActions.setAppLoading(false));
}

export default function* authSaga() {
    yield takeLatest(authActions.init.type, initializeFirebase);
    yield takeLatest(authActions.loginWithUsername.type, loginWithUsername);
    yield takeLatest(authActions.loginWithGoogle.type, loginWithGoogle);
    yield takeLatest(authActions.loginWithFacebook.type, loginWithFacebook);
}
