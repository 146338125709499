import * as Cookies from 'js-cookie';
import { useCallback } from 'react';

const useCookie = () => {
  const getCookie = useCallback(cookieName => {
    return Cookies.get(cookieName);
  }, []);

  const setCookie = useCallback((cookieName, cookieValue) => {
    Cookies.set(cookieName, cookieValue);
  }, []);

  const removeCookie = useCallback((cookieName, cookieValue) => {
    Cookies.remove(cookieName);
  }, []);

  return { getCookie, setCookie, removeCookie };
};

export default useCookie;
