import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const SpinnerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  background-color: rgba(255, 255, 255, 0.8);
  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }
  .ant-spin-spinning {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .ant-spin-text {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: ${themeGet('primary.0', '#194AB9')};
  }
`;
