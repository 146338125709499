import React, { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { LayoutContext } from 'context/LayoutProvider';
import SearchForm from 'components/Search/SearchForm';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { FiSearch } from 'react-icons/fi'
import {
  SearchDropdown,
  SearchHeadline,
  SearchDescription
} from './NavbarSearch.style';

const NavbarSearch = ({ location, match, history }) => {
  const { t } = useTranslation();
  const [{ searchVisibility }, dispatch] = useContext(LayoutContext);
  const [mobileDatePickerOpened, setMobileDatePickerOpened] = useState(false);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleSearch = () =>
      dispatch({
        type: searchVisibility ? 'HIDE_TOP_SEARCHBAR' : 'SHOW_TOP_SEARCHBAR'
      });

  useEffect(() => {
    if (location.pathname === match.url && searchVisibility) {
      toggleSearch();
    }
  }, [location, match.url, searchVisibility, toggleSearch]);

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () => {
    if (!mobileDatePickerOpened) {
      dispatch({ type: 'HIDE_TOP_SEARCHBAR' });
    }
  });

  const onClickHandler = e => {
    e.preventDefault();
    if (location.pathname === match.url) {
      dispatch({ type: 'ACTIVATE_HOME_SEARCHBAR' });
    } else {
      toggleSearch();
    }
  };

  return (
    <div className='navbar-search-item' ref={searchRef}>
      <a className='nav-link' onClick={onClickHandler}>
        <FiSearch />
        <span className='search-label'> {t('RESERVE')} </span>
      </a>
      <SearchDropdown
        className={`dropdown-menu ${searchVisibility ? 'active' : 'hide'}`}
      >
        <SearchHeadline>{t('HOME_SEARCH_TITLE')}</SearchHeadline>
        <SearchDescription>{t('HOME_SEARCH_DESC')}</SearchDescription>
        <SearchForm
          datePickerDirection='down'
          setMobileDatePickerOpened={setMobileDatePickerOpened}
        />
      </SearchDropdown>
    </div>
  );
};

export default withRouter(NavbarSearch);
