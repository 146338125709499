import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdClose } from 'react-icons/md';

import Modal from 'components/UI/Antd/Modal/Modal';
import { Button } from 'components/common/Button';

import { Headline, Body, Actions } from './ConfirmDialog.style';

const ConfirmDialog = ({
  visible,
  onClose,
  title,
  confirmLabel,
  cancelLabel,
  onConfirm,
  description
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      wrapClassName='confirm-modal'
      maxWidth='md'
      width={401}
      title={<Headline>{ title }</Headline>}
      bodyStyle={{ padding: '0px' }}
      centered={true}
      onCancel={onClose}
      closeIcon={<MdClose size={24} />}
      footer={<Actions>
        <Button
          adaptive={false}
          label={confirmLabel}
          onClick={onConfirm}
        />
        <Button
          adaptive={false}
          variant='link'
          label={cancelLabel}
          onClick={onClose}
        />
      </Actions>}
      zIndex={10002}
    >
      <Body>
        { description }
      </Body>
    </Modal>
  );
};

export default ConfirmDialog;
