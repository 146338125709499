import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const LanguageTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
  padding: 4px 12px;
  border-radius: 5px;
  height: 44px;
  @media only screen and (max-width: 991px) {
    margin-right: 13px;
  }
  @media only screen and (max-width: 480px) {
    margin-right: 0;
  }
  svg {
    margin-top: 1px;
  }
  &:hover {
    background-color: rgba(242, 242, 242);
  }
  &.active {
    svg {
      margin-top: -2px;
      transform: rotate(-180deg);
    }
  }

`;

export const Label = styled.div`
  font-size: 16px;
  text-transform: uppercase;
  margin-right: 6px;
`;
