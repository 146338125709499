import { post, get } from './api';

export default {
  getAll: async function () {
    return await get(`/message/getall`);
  },
  getAllReservation: async function (id) {
    return await get(`/message/reservation/${id}`);
  },
  getAllMarina: async function (id) {
    return await get(`/message/marina/${id}`);
  },
  create: async function (data) {
    return await post('/message/create', data);
  },
  createAttachmentMessage: async function (data) {
    return await post('/message/createAttachmentMessage', data);
  },
  view: async function (data) {
    return await post('/message/view', data);
  },
  read: async function (data) {
    return await post('/message/read', data);
  },
  unread: async function (data) {
    return await post('/message/unread', data);
  },
  edit: async function (data) {
    return await post('/message/edit', data);
  }
};
