import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import publicData from 'services/publicData';
import guestService from 'services/guest';

// guest/boater_list/subscribe  post
import i18n, { availableLanguages } from 'i18n';
import { localeOptions } from 'settings/locales';
import * as Cookies from 'js-cookie';
import { HOME_PAGE } from 'settings/constant';
import { isDemoMode } from 'library/helpers/demoInstanceHelpers';
import { regionsList as regions } from "../settings/regions";
import {ReservationRoutes} from "../container/Reservation/routes";

export const GlobalContext = React.createContext({});
export const getMarinaTextId = m => {
  if (!m || !m.name) return null;
  return encodeURIComponent(
    m.name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .split(' ')
      .join('-')
  );
};

const GlobalProvider = ({ children, location, match, history }) => {
  const { waitingForAuth, user, loggedIn } = useContext(AuthContext);

  const [marinas, setMarinas] = useState([]);
  const [hiddenMarinas, setHiddenMarinas] = useState([]);
  const [topMarinas, setTopMarinas] = useState([]);
  const [homeMarinas, setHomeMarinas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [mapLocation, setMapLocation] = useState({});

  // Global Links
  const [termsOfServiceLink, setTermsOfServiceLink] = useState(null);
  const [privacyPolicyLink, setPrivacyPolicy] = useState(null);
  const [cookiePolicyLink, setCookiePolicyLink] = useState(null);

  const [numOfNotifs, setNumOfNotifs] = useState(0);
  const [subSectionTitle, setSubSectionTitle] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [reviewSchema, setReviewSchema] = useState(null);
  const [filtered, setFiltered] = useState(false);

  const getSingleMarina = id => {
    if (isNaN(id)) {
      return (marinas.find(element => getMarinaTextId(element) === id)) ||
          (hiddenMarinas.find(element => getMarinaTextId(element) === id));
    } else {
      return (marinas.find(element => element.id === parseInt(id)) ||
          hiddenMarinas.find(element => element.id === parseInt(id)));
    }
  };

  const getMarinasByCountry = country =>
    marinas.filter(marina => marina.country === country.name);

  const searchMarinasByParams = searchParams => {
    let filteredMarinas = marinas;
    for (let prop in searchParams) {
      let param;
      switch (prop) {
        case 'memberships':
          param = searchParams[prop].map(item => item.toLowerCase());
          filteredMarinas = filteredMarinas.filter(marina => {
            return param.includes(marina.association.name.toLowerCase());
          });
          break;
        case 'ratings':
          filteredMarinas = filteredMarinas.filter(marina => {
            return searchParams[prop].includes(
              marina.number_of_stars_rating && marina.number_of_stars_rating[0]
            );
          });
          break;
        // TODO
        case 'amenities':
          break;
        case 'price':
          break;
        default:
          break;
      }
    }
    return filteredMarinas;
  };

  const loadAllMarinas = () => {
    setLoading(true);
    const jsonPromise = new Promise((resolve, reject) => {
        publicData.
            getMarinasData()
            .then(data => resolve(data))
            .catch(err => reject(err));
    });
    const marinasPromise = new Promise((resolve, reject) => {
      publicData
        .getMarinas()
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
    const countriesPromise = new Promise((resolve, reject) => {
      publicData
        .getCountries()
        .then(data => resolve(data))
        .catch(err => reject(err));
    });

    Promise.all([jsonPromise, marinasPromise, countriesPromise])
      .then(values => {
        const respMarinas = values[1].map(marina => ({
          ...marina,
          ...values[0][marina.code]
        }));
        const countries = values[2].map(country => ({
          name: country,
          image: values[0].countries[country]
        }));

        // Top marinas defined as Marinas with payments
        const topMarinasFiltered = respMarinas.filter(marina =>
          marina.marina_settings.accepts_online_payments &&
          marina.marina_settings.stripe_account_details?.capabilities?.card_payments === "active");

        topMarinasFiltered.sort((a,b) => a.id === 98 ? -1: 1);

        setMarinas(respMarinas.filter(marina=> !marina.hide_marina));
        setHiddenMarinas(respMarinas.filter(marina=> marina.hide_marina));
        setTopMarinas(topMarinasFiltered);
        setCountries(countries);
        setTermsOfServiceLink(values[0].terms_of_service);
        setPrivacyPolicy(values[0].privacy_policy);
        setCookiePolicyLink(values[0].cookie_policy);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  };

  const loadHomeMarinas = async () => {
    const response = await publicData.getHomeMarinas();
    setHomeMarinas(response);
  };

  useEffect(() => {
    loadAllMarinas();
    loadHomeMarinas();
  }, []);

  useEffect(() => {
    if (!waitingForAuth) {
      if (
        marinas.length &&
        process.env.REACT_APP_FIREBASE_ENV === 'PRODUCTION' &&
        !filtered
      ) {
        const prodMarinas = marinas.filter(
          m => !(m.mail && m.mail.includes('@pickapier.com'))
        );
        const allMarinas = [...marinas];
        setFiltered(true);
        if (user && loggedIn) {
          const { email } = user;
          if (email && !email.includes('pickapier')) {
            // if this is NOT the test pickapier user
            setMarinas(prodMarinas);
          } else {
            // if this IS the test pickapier user
            setMarinas(allMarinas);
          }
        } else if (!loggedIn) {
          setMarinas(prodMarinas);
        }
      }
    }
  }, [user, loggedIn, marinas.length, filtered, waitingForAuth]);

  // Re-set language for guest mode based on cookie
  useEffect(() => {
    if (!loggedIn) {
      const language =
        match.params.locale || Cookies.get('guest-language') || 'en';
      if (language && availableLanguages.includes(language)) {
        i18n.changeLanguage(language);
      }
      if(!location.hash.includes(ReservationRoutes.Finished)) {
          // change route
          const ret =
              location.pathname === HOME_PAGE
                  ? ''
                  : location.pathname.replace(`/${match.params.locale}`, '');
          const newPathname = `/${language}${ret}`;
          setTimeout(() => {
              history.replace({
                  pathname: newPathname,
                  search: location.search
              });
          }, 0);
      }
    }
  }, [loggedIn]);

  const subscribeGuest = async data => {
    try {
      const res = await guestService.subscribeGuest(data);
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        regions,
        marinas,
        hiddenMarinas,
        homeMarinas,
        getSingleMarina,
        getMarinasByCountry,
        searchMarinasByParams,
        countries,
        loading,
        termsOfServiceLink,
        privacyPolicyLink,
        cookiePolicyLink,
        setNumOfNotifs,
        numOfNotifs,
        subSectionTitle,
        setSubSectionTitle,
        metaDescription,
        setMetaDescription,
        topMarinas,
        loadAllMarinas,
        loadHomeMarinas,
        setReviewSchema,
        reviewSchema,
        mapLocation,
        setMapLocation,
        subscribeGuest
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default withRouter(GlobalProvider);
