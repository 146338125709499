const defaultTheme = {
  breakpoints: ['481px', '768px', '992px', '1201px', '1441px'],
  primary: [
    '#194AB9', // 0: Default
    '#399C9F', // 1: Chart
    '#0036B1'  // 2: hover
  ],
  color: [
    '#000000', // 0: Black
    '#ffffff', // 1: White
    '#F7F7F7', // 2: Dropdown Hover
    '#E9E8E8', // 3: Bullet bg
    '#FC5C63', // 4: Active Favorite
    'rgba(0, 0, 0, 0.25)', // 5: Inactive Favorite
    '#273343', // 6: Agent Social Icon
    '#3b5998', // 7: Facebook
    '#55ADEE', // 8: Twitter
    '#FFF900', // 9: Snapchat
    '#dd4b39', // 10: Google Plus
    '#F61C0D', // 11: Youtube
    '#e4405f', // 12: Instagram
    '#E2E2E2', // 13: Range Color
    '#00ACEE', // 14: Github Color
    '#FFCB2B', // 15: Firebase Color
    '#484848', // 16: Link button
    '#ff495c', // 17: Watermelon
    '#005ca3', // 18: Blue
    '#bfbfbf', // 19: Very Light Pink
    '#52c41a', // 20: Green
    '#eeeeee', // 21: Very light grey
    '#fafafa', // 22: White two
    '#66bdff', // 23: Light blue
    '#8d9aa4', // 24: Blue gray
    '#495e6e', // 25: Slate
    '#b3cbe1', // 26: Transparent navbar hover,
    '#194AB9', // 27: Blue that should be applied in all the system,
    '#e6f7ff', // 28: Alert light blue background
    '#cccccc', // 29: line through light gray
    '#393939', // 30: Active tab
    '#2eb7a9', // 31: Email verification code
    '#f2f2f2', // 32: Popeye menu background
    '#f8f8f8', // 33: Popeye menu background hovered
    '#e4e7e7', // 34: Date picker background hovered
    '#F0F5FF', // 35: discover card background hovered and Marina manager background
    '#121A2C', // 36: Popeye home section background
    '#009FFD', // 37: Regions map selected stroke
    '#E0E0E0', // 38: datepicker navigation button background
    '#0036B1', // 39: primary button hover background
    '#F2F2F2', // 40: header mobile sticky background
    '#fff7dd', // 41: light orange
    '#1C48B7', // 42: light orange
    '#1D4EB6', // 43: card label bg
  ],
  default: [
    '#e8eaec' // 0: Default
  ],
  warning: [
    '#F29100', // 0: Warning
    '#EB5757' // 1: discover Warning
  ],
  success: [
    '#00BB5D' // 0: Success
  ],
  error: [
    '#F9503D', // 0: Error
    'rgba(249, 80, 61, 0.08)', // 1: Error Light Bg
    '#ff3030' // 2: Error bright
  ],
  text: [
    '#2C2C2C', // 0: Heading
    '#909090', // 1: Meta Text
    '#777777', // 2: Text Info
    '#8d8d8d', // 3: Link button color
    '#323232', // 4: Dark title color
    '#f5222d', // 5: Error text
    '#727272', // 6: Description text
    '#444444', // 7: Bottom Mobile menu text
    '#6a6a6a', // 8: Result dialog title color
    '#4a4a4a', // 9: greyish-brown
    '#1a1a1b', // 10: Dark color
    '#333333', // 11: Popeye titles and menu text color
    '#828282', // 12: Popeye menu and titles color
    '#121A2C', // 13: navbar links and other text color
    '#4f4f4f', // 14: discover toolbar serach title color
    '#ffffff', // 15: white text
    '#000000', // 16: black text
    '#0634AF', // 17: tab text active
    '#152331', // 18: tab text
  ],
  border: [
    '#EBEBEB', // 0: Light border
    '#E8E8E8', // 1: Default Border
    'rgba(57, 151, 247, 0.35)', // 2: Active Light Border
    '#E6E6E6', // 3: Pricing Border
    '#dfdfdf', // 4: Light gray border
    '#e8eaec', // 5: Very light blue border
    '#f0f0f0', // 6: Very light gray border
    '#dddddd', // 7: mobile bottom menu item border
    '#a0a1a2', // 8: popeye form field border
    '#e0e0e0', // 9: popeye page border
    '#828282', // 10: popeye request input border
    '#bdbdbd', // 11: Popeye earning box border
    '#f2bb06', // 12: orange
    '#121A2C', // 13: main color border
  ],
  boxShadow: [
    'rgba(26, 68, 116, 0.16)', // 0: Card hover
    'rgba(0, 0, 0, 0.16)' // 1: Carousel Button shadow
  ],
  fonts: {
    primary: 'Lato, sans-serif'
  },
  popeyeColors: {
    button: [
      '#194AB9', // 0 Active background color
      '#345cc0', // 1 Hover background color
      '#2ec4b6', // 2 Active background color mobile
      '#697EB0' //  3 dark blue+grey
    ]
  }
};

export default defaultTheme;
