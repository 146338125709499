export const InstantBookingRequirement = {
    VesselLOA: 'vessel_loa',
    VesselBeam: 'vessel_beam',
    VesselDraft: 'vessel_draft',
    VesselHullType: 'vessel_hull_type',
    VesselType: 'vessel_type',
    PrivateOrCommercial: 'private_or_commercial',
    LiveAboard: 'live_aboard',
    HomeMarinaName: 'home_marina_name',
    WaterElectricity: 'water_electricity',
    PasseportEscales: 'passeport_escales',
    Country: 'country_of_residence'
}
