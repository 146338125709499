import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const SearchDropdown = styled.div`
  width: 100%;
  background-color: ${themeGet('color.1', '#ffffff')};
  padding: 25px 80px 30px;
  @media only screen and (max-width: 991px) {
    padding: 25px 30px 30px;
    .search-form-wrapper {
      max-width: 414px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 13px 27px 17px;
  }
`;

export const SearchHeadline = styled.h2`
  font-weight: bold;
  color: ${themeGet('text.0', '#2C2C2C')};
  line-height: 34px;
  font-size: 28px;
  margin-bottom: 8px;
  @media only screen and (max-width: 767px) {
    line-height: 30px;
    font-size: 24px;
  }
  @media only screen and (max-width: 480px) {
    line-height: 28px;
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

export const SearchDescription = styled.p`
  color: ${themeGet('text.0', '#2C2C2C')};
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 30px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;
