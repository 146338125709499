import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const BannerBodyWrapper = styled.div`
  position: relative;
  top: -20px;
  text-align: center;
  padding: 0 33px 20px;

  .submit-button {
    min-width: 175px;
    height: 47px;
    color: ${themeGet('color.1', '#fff')};
    font-weight: bold;
    font-size: 15px;
    margin-top: 25px;
  }

  @media only screen and (max-width: 991px) {
    padding: 20px 26px 42px;
    top: 0;
  }
  a {
    margin-top: 26px;
    height: 47px;
    font-weight: 700;
    min-width: 170px;
    font-size: 15px;
    line-height: 47px;
    background-color: ${themeGet('primary.0', '#194AB9')};
    border: 0;
    &:hover {
      background-color: ${themeGet('primary.0', '#194AB9')};
      opacity: 0.8;
    }
    @media only screen and (max-width: 991px) {
      margin-top: 37px;
      width: 100%;
      line-height: 38px;
      height: 38px;
      font-size: 14px;
    }
  }
`;

export const BannerImage = styled.div`
  position: relative;
  top: -21px;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

export const BannerHeadline = styled.h2`
  font-weight: bold;
  color: ${themeGet('text.4', '#323232')};
  font-size: 20px;
  margin-bottom: 5px;
  @media only screen and (max-width: 991px) {
    font-size: 30px;
    line-height: 36px;
    padding: 0 22px;
    margin-bottom: 16px;
  }
`;

export const BannerDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  color: ${themeGet('text.3', '#8d8d8d')};
  margin-bottom: 0;
  &.mobile-description {
    line-height: 1.29;
    color: ${themeGet('text.4', '#323232')};
    display: none;
  }
  @media only screen and (max-width: 991px) {
    line-height: 1.6;
    font-size: 20px;
    font-weight: normal;
    &.mobile-description {
      display: block;
    }
  }
`;

export const BannerIcon = styled.div`
  display: none;
  margin-top: 34px;
  @media only screen and (max-width: 991px) {
    display: block;
  }
`;

export const TriangleWrapper = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: block;
  }
  position: absolute;
  bottom: -16px;
  left: 129px;
`;

export const Triangle = styled.div`
  position: relative;
  background-color: ${themeGet('color.1', '#ffffff')};
  text-align: left;
  width: 2em;
  height: 2em;
  border-top-right-radius: 20%;
  transform: rotate(270deg) skewX(-9deg) scale(1, 0.866);

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 2em;
    height: 2em;
    border-top-right-radius: 20%;
  }
  &:before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
      translate(0, -50%);
  }
  &:after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;
