import { get } from './api';

export default {
  getMarinas: async function () {
    return await get('/public/marinas');
  },
  getMarinasData: async function () {
    return await get('/public/marinas_data?language=all');
  },
  getCountries: async function () {
    return await get('/public/countries');
  },
  getHomeMarinas: async function () {
    return await get('/public/home_marinas');
  }
};
