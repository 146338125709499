import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context/GlobalProvider';

import Logo from 'components/UI/Logo/Logo';
import Footers from 'components/Footer/Footer';

import useAmplitude from 'library/hooks/useAmplitude';
import { getMenuColumns, socialMenuItems } from './footerMenus';
import smallLogo from 'assets/images/pickapier/logo_vertical.png';

const Footer = ({ match }) => {
  const { t } = useTranslation();
  const { sendToAmplitude } = useAmplitude();
  const { termsOfServiceLink, privacyPolicyLink } = useContext(GlobalContext);
  const menuColumns = getMenuColumns(match.params.locale);
  const bottomMenu = [
    {
      title: t('FOOTER_TERMS'),
      link: termsOfServiceLink,
      amplitude: {
        eventProperties: { type: 'terms' },
        eventName: 'footer_link_clicked'
      }
    },
    {
      title: t('FOOTER_PRIVACY'),
      link: privacyPolicyLink,
      amplitude: {
        eventProperties: { type: 'privacy' },
        eventName: 'footer_link_clicked'
      }
    },
    {
      title: t('FOOTER_GDPR'),
      link: 'https://gdpr-rep.eu/q/17740657',
      amplitude: {
        eventProperties: { type: 'gdpr' },
        eventName: 'footer_link_clicked'
      }
    }
  ];

  return (
    <Footers
      logo={
        <Logo
          withLink
          linkTo={`${match.url}`}
          src={smallLogo}
          className='footer-logo'
        />
      }
      menu={ menuColumns }
      social={ socialMenuItems }
      bottomMenu={ bottomMenu }
      onLinkClick={ amplitude => sendToAmplitude(amplitude) }
      copyright={`@ ${new Date().getFullYear()} PICK A PIER LTD. All rights reserved.`}
    />
  );
};

export default withRouter(Footer);
