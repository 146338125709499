import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import i18n from '../../i18n';
import { GlobalContext } from 'context/GlobalProvider';
import Row from 'components/UI/Antd/Grid/Row';
import Col from 'components/UI/Antd/Grid/Col';
import Button from 'components/UI/Antd/Button/Button';
import Divider from 'components/UI/Antd/Divider/Divider';
import { AntInput } from 'components/UI/Antd/AntdInputWithFormik';
import ButtonWithAsync from 'components/UI/ButtonWithAsync/ButtonWithAsync';
import { getLoginSchema } from 'library/helpers/formUtils';
import { FORGET_PASSWORD_PAGE } from 'settings/constant';
import {
  Wrapper,
  AuthButtons,
  FieldWrapper,
  ErrorLabel,
  BottomInfo
} from './AuthForms.style';
// icons
import googleIcon from 'assets/images/google-icon.svg';
import facebookIcon from 'assets/images/facebook-icon.svg';

const AsyncButton = ButtonWithAsync(Button);

const initialValues = {
  email: '',
  password: ''
};

const RenderSignInForm = props => {
  const { t } = useTranslation();
  const { termsOfServiceLink } = useContext(GlobalContext);
  const { values, submitCount, submitForm, error, forgetPasswordLink } = props;
  return (
    <Form>
      <Field
        component={AntInput}
        className='input-field'
        name='email'
        type='email'
        size='large'
        placeholder={t('YOUR_EMAIL_PLACEHOLDER')}
        defaultValue={values.email}
        submitCount={submitCount}
        hasFeedback
      />
      <Field
        component={AntInput}
        className='input-field'
        name='password'
        type='password'
        size='large'
        placeholder={t('SIGN_IN_PASSWORD_PLACEHOLDER')}
        submitCount={submitCount}
        value={values.password}
        hasFeedback
      />

      <FieldWrapper className='forgot-password'>
        <Link to={forgetPasswordLink}>{t('FORGOT_PASSWORD')}</Link>
      </FieldWrapper>

      {error && <ErrorLabel>{error.message}</ErrorLabel>}

      <AsyncButton
        asyncAction={submitForm}
        className='signin-btn'
        htmlType='button'
        type='primary'
        size='large'
        label={t('LOGIN')}
      />
    </Form>
  );
};

const SignInForm = ({
  handleSubmit,
  error,
  googleBtnLoading,
  googleAuthAction,
  facebookBtnLoading,
  facebookAuthAction,
  onSignUpLinkClick,
  match
}) => {
  const { t } = useTranslation();
  const loginSchema = getLoginSchema(i18n);

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={loginSchema}
      >
        {props => (
          <RenderSignInForm
            {...{ ...props, error }}
            forgetPasswordLink={`/${match.params.locale}${FORGET_PASSWORD_PAGE}`}
          />
        )}
      </Formik>
      <Divider>{t('OR_CONTINUE_WITH')}</Divider>
      <AuthButtons>
        <Row gutter={20}>
          <Col xs={12}>
            <Button
              loading={googleBtnLoading}
              className='google-btn'
              type='primary'
              size='large'
              onClick={googleAuthAction}
            >
              <img src={googleIcon} /> Google
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              loading={facebookBtnLoading}
              className='facebook-btn'
              type='primary'
              size='large'
              onClick={facebookAuthAction}
            >
              <img src={facebookIcon} /> Facebook
            </Button>
          </Col>
        </Row>
      </AuthButtons>
      <BottomInfo>
        {t('DONT_HAVE_BOATER_ACCOUNT_YET')}
        {'  '}
        <span style={{ cursor: 'pointer' }}>
          <Link className='bottom-link' to={'#'} onClick={onSignUpLinkClick}>
            {t('SIGN_UP_BUTTON')}
          </Link>
        </span>
      </BottomInfo>
    </Wrapper>
  );
};

export default withRouter(SignInForm);
