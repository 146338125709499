import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiMailCheckLine, RiMailUnreadLine } from 'react-icons/ri';
import { AuthContext } from 'context/AuthProvider';
import { EmailVerificationContext } from 'context/EmailVerificationProvider';
import EmailVerificationForm from 'container/EmailVerification/EmailVerificationForm';
import Button from 'components/UI/Antd/Button/Button';
import ConfirmEmailDialog from 'components/UI/ConfirmEmailDialog/ConfirmEmailDialog';
import {
  EmailVerificationBody,
  EmailVerificationContent,
  EmailVerificationActions
} from './EmailVerificationBanner.style';

const EmailVerificationBanner = ({ history, location, headerType }) => {
  const { t } = useTranslation();
  const { loggedIn, user } = useContext(AuthContext);
  const {
    onVerify,
    onDecline,
    onCloseBanner,
    onCloseModal,
    bannerVisible,
    modalVisible,
    emailSent,
    emailConfirmed
  } = useContext(EmailVerificationContext);

  if (!loggedIn || !user) {
    return null;
  }
  return (
    <EmailVerificationBody
      headerType={headerType}
      visible={bannerVisible}
      success={emailConfirmed}
    >
      {emailConfirmed ? (
        <EmailVerificationContent>
          <p>
            <RiMailCheckLine size={20} />
            <span>
              {t('EMAIL_VERIFICATION_SUCCESS_TEXT', {
                boater_name: user.first_name
                  ? `${user.first_name} ${user.last_name || ''}`
                  : ''
              })}
            </span>
          </p>
          <EmailVerificationActions>
            <Button
              className='confirm_email_button'
              type='link'
              onClick={onCloseBanner}
            >
              {t('CLOSE')}
            </Button>
          </EmailVerificationActions>
        </EmailVerificationContent>
      ) : (
        <EmailVerificationContent>
          <p>
            <RiMailUnreadLine size={20} />
            {emailSent ? (
              <span>
                {t('EMAIL_VERIFICATION_PENDING_TEXT', {
                  boater_name: user.first_name
                    ? `${user.first_name} ${user.last_name || ''}`
                    : ''
                })}
              </span>
            ) : (
              <span>
                {t('EMAIL_VERIFICATION_TEXT', {
                  boater_name: user.first_name
                    ? `${user.first_name} ${user.last_name || ''}`
                    : ''
                })}
              </span>
            )}
          </p>
          <EmailVerificationActions>
            <Button
              className='confirm_email_button'
              type='link'
              onClick={() => onVerify({ force: true })}
            >
              {emailSent ? t('RESEND_EMAIL') : t('CONFIRM_EMAIL')}
            </Button>
            &middot;
            <Button
              className='remind_later_button'
              type='link'
              onClick={onDecline}
            >
              {t('REMIND_ME_LATER')}
            </Button>
          </EmailVerificationActions>
        </EmailVerificationContent>
      )}
      <ConfirmEmailDialog
        visible={modalVisible}
        title={t('SIGN_UP_MODAL_TITLE')}
        onCancel={onCloseModal}
        subtitle={
          <Fragment>
            <p>
              {t('SIGN_UP_VERIFICATION_CODE_DESC', {
                boater_email: user.email
              })}
            </p>
          </Fragment>
        }
        actions={null}
      >
        <EmailVerificationForm
          history={history}
          location={location}
          onCloseModal={onCloseModal}
        />
      </ConfirmEmailDialog>
    </EmailVerificationBody>
  );
};

export default withRouter(EmailVerificationBanner);
