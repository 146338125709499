import React, { useContext } from 'react';
import { FixedBottom } from 'react-fixed-bottom';
import { useTranslation } from 'react-i18next';
import { CookieContext } from 'context/CookieProvider';
import { GlobalContext } from 'context/GlobalProvider';
import { MdClose } from 'react-icons/md';
import Button from 'components/UI/Antd/Button/Button';
import { CookieConcentBody, CookieConcentContent } from './CookieConcent.style';

export default function CookieConcent({ visible }) {
  const { t } = useTranslation();
  const { onAccept, onDecline } = useContext(CookieContext);
  const { cookiePolicyLink } = useContext(GlobalContext);
  if (navigator.userAgent === 'ReactSnap') {
    return <></>;
  }
  return (
    <FixedBottom offset={11}>
      <CookieConcentBody visible={visible}>
        <CookieConcentContent>
          <p>
            {t('COOKIES_TEXT')}{' '}
            <a
              className='cookie-link'
              href={cookiePolicyLink || '#'}
              target='_blank'
            >
              {t('COOKIES_LINK')}
            </a>
          </p>
          <Button size='large' htmlType='submit' onClick={onAccept} type='primary'>
            {t('I_AGREE')}
          </Button>
        </CookieConcentContent>
        <Button
          className='cookie_decline_button'
          htmlType='button'
          onClick={onDecline}
        >
          <MdClose size={24} />
        </Button>
      </CookieConcentBody>
    </FixedBottom>
  );
}
