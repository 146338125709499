import React, { useState, useContext, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import Menu from 'components/UI/Antd/Menu/Menu';
import {
  RESERVATIONS_PAGE,
  ABSENCE_REPORTS_PAGE,
  COMPLETED,
  PAST_REPORTS
} from 'settings/constant';
// icons
import reservationsIcon from 'assets/images/pickapier/reservations.svg';
import absenceIcon from 'assets/images/pickapier/absence.svg';

const MyTripsMenu = ({ match, location, loggedIn, user, onAuth }) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const dropdownRef = useRef(null);

  const toggleMenu = () => setOpened(!opened);
  const closemenu = () => setOpened(false);

  useOnClickOutside(dropdownRef, () => setOpened(false));

  const onClickHandler = (e, prevent = false) => {
    if (prevent) {
      e.preventDefault();
    }
    if (!loggedIn || !user) {
      onAuth();
    } else {
      toggleMenu();
    }
  };

  return (
    <div className='dropdown-menu-item' ref={dropdownRef}>
      <a
        className={
          location.pathname === `${match.url}${RESERVATIONS_PAGE}` ||
          location.pathname ===
            `${match.url}${RESERVATIONS_PAGE}${COMPLETED}` ||
          location.pathname === `${match.url}${ABSENCE_REPORTS_PAGE}` ||
          location.pathname ===
            `${match.url}${ABSENCE_REPORTS_PAGE}${PAST_REPORTS}`
            ? 'nav-link active'
            : 'nav-link'
        }
        onClick={e => onClickHandler(e, true)}
        data-test-id='test-my-trips'
      >
        {t('MY_TRIPS_MENU_ITEM')}
      </a>
      <Menu
        selectable={false}
        className={`dropdown-menu with-icons ${opened ? 'active' : 'hide'}`}
      >
        <Menu.Item
          className={
            location.pathname === `${match.url}${RESERVATIONS_PAGE}` ||
            location.pathname === `${match.url}${RESERVATIONS_PAGE}${COMPLETED}`
              ? 'ant-menu-item-selected'
              : ''
          }
          key='3'
        >
          <NavLink
            isActive={() => false}
            to={`${match.url}${RESERVATIONS_PAGE}`}
            onClick={closemenu}
          >
            <ReactSVG
              src={reservationsIcon}
              className='dropdown-menu-icon reservations'
            />
            {t('RESERVATIONS_MENU_ITEM')}
          </NavLink>
        </Menu.Item>

        <Menu.Item
          className={
            location.pathname === `${match.url}${ABSENCE_REPORTS_PAGE}` ||
            location.pathname ===
              `${match.url}${ABSENCE_REPORTS_PAGE}${PAST_REPORTS}`
              ? 'ant-menu-item-selected'
              : ''
          }
          key='4'
        >
          <NavLink
            isActive={() => false}
            to={`${match.url}${ABSENCE_REPORTS_PAGE}`}
            onClick={closemenu}
          >
            <ReactSVG
              src={absenceIcon}
              className='dropdown-menu-icon absence'
            />
            {t('ABSENCE_REPORTS_MENU_ITEM')}
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default withRouter(MyTripsMenu);
