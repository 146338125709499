import moment, { isMoment } from 'moment';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import { getAmplitude } from 'services/amplitude';
import firebaseService from 'services/firebaseService.js';

const FULL_DATE_FORMAT = 'DD/MM/YYYY HH:mm';

export const getVesselType = details => {
  try {
    const { vessel } = details;
    if (
      !isEmpty(vessel.memberships) &&
      find(vessel.memberships, { type: 'TEM' })
    ) {
      return 'transeurope member';
    } else if (vessel.home_marina_verified) {
      return 'home marina verified';
    } else {
      return 'boater';
    }
  } catch (error) {
    console.log('Amplitude: error in get vessel type function');
    console.log(error);
  }
};

export const getBoaterType = details => {
  try {
    const { boater, allMemberships } = details;
    // either search boater_type due to all its vessels or due to specific one if given.

    if (boater.is_guest) {
      return 'guest';
    } else if (allMemberships.length && find(allMemberships, { type: 'TEM' })) {
      return 'transeurope member';
    } else if (
      boater.vessels.filter(({ home_marina_verified }) => home_marina_verified)
        .length
    ) {
      return 'home marina verified';
    } else {
      return 'boater';
    }
  } catch (error) {
    console.log('Amplitude: error in get boater type function');
  }
};

export const getBoaterProperties = details => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    const { initialize_properties, boater, statistics } = details;

    if (initialize_properties) {
      return {
        user_id: firebaseService.auth.currentUser.uid,
        full_name: boater.full_name
          ? boater.full_name
          : `${boater.first_name} ${boater.last_name}`,
        email: boater.email,
        first_seen: moment(boater.created_at).format(FULL_DATE_FORMAT),
        number_of_boats: 1,
        language: boater.language || 'en',
        campaign: boater.campaign
      };
    }
    if (isNil(boater)) return {};

    const allMemberships = [].concat.apply(
      [],
      boater.vessels.map(({ memberships }) => memberships || [])
    );

    let allCustomers = [];
    let allVisits = [];
    if (allMemberships.length) {
      allCustomers = [].concat.apply(
        [],
        allMemberships.map(({ customer }) => customer || [])
      );

      allVisits = [].concat.apply(
        [],
        allCustomers.map(({ visits }) => visits || [])
      );
    }
    let number_of_marinas_redeemed = 0;
    let number_of_redeemed_nights = 0;
    if (allVisits.length) {
      const prev_marinas_id = [];
      for (let visit of allVisits) {
        if (
          !prev_marinas_id.length ||
          (prev_marinas_id.length > 0 &&
            !prev_marinas_id.includes(visit.marina_id))
        ) {
          number_of_marinas_redeemed += 1;
          prev_marinas_id.push(visit.marina_id);
        }
        number_of_redeemed_nights += moment(visit.end_date).diff(
          visit.start_date,
          'days'
        );
      }
    }

    let boater_type = getBoaterType({ boater, allMemberships });

    return {
      user_id: firebaseService.auth.currentUser.uid,
      full_name: `${boater.first_name} ${boater.last_name}`,
      email: boater.email,
      boater_type,
      home_marina: boater.vessels[0].home_marina_name,
      language: boater.language || 'en',
      number_of_boats: boater.vessels && boater.vessels.length,
      first_seen: moment(boater.created_at).format(FULL_DATE_FORMAT),
      ...statistics,
      home_marina_country:
        statistics && statistics.home_marina_country
          ? statistics.home_marina_country
          : '',
      screen_size:
        window.screen.width * window.devicePixelRatio +
        'x' +
        window.screen.height * window.devicePixelRatio,
      total_nights_redeemed: number_of_redeemed_nights,
      total_marinas_that_redeemed: number_of_marinas_redeemed
    };
  } catch (error) {
    console.log('Amplitude: error in get boater properties: ');
    console.log(error);
  }
};

export const amplitudeLogEvent = async (eventName, params = {}) => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;
  try {
    (await getAmplitude()).getInstance().logEvent(eventName, params);
  } catch (error) {
    console.log('Amplitude create log event error: ');
    console.log(error);
  }
};

export const updateUserProperties = async details => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    const { boater, statistics, initialize_properties = false } = details;
    const properties = getBoaterProperties({
      boater,
      statistics,
      initialize_properties
    });

    const identify = new (await getAmplitude()).Identify();
    for (const property of Object.keys(properties)) {
      const value = properties[property];
      identify.set(property, value);
    }
    (await getAmplitude()).getInstance().identify(identify);
  } catch (error) {
    console.log('Amplitude update user properties error: ');
    console.log(error);
  }
};

export const getFormattedSourcePath = details => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    const { source } = details;
    let formatted_source = '';
    switch (source) {
      case 'RESERVATION':
        formatted_source = 'online_reservation';
        break;
      case 'request':
        formatted_source = 'request_membership';
        break;
      case 'connect':
        formatted_source = 'connect_membership';
        break;
    }

    return formatted_source;
  } catch (error) {
    console.log('Amplitude: error in function getFormattedSourcePath');
    console.log(error);
  }
};

export const getFormattedReservationEvent = details => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    const {
      payment,
      reservation,
      marina,
      data,
      location,
      type,
      reservationVessel,
      initialValues,
      unmet_required_fields,
      marinas_viewed_before,
      popeye_discount
    } = details;

    let source = '';
    let origin = '';
    if (location) {
      if (location.search && location.search.includes('ref=email')) {
        source = 'email';
      } else if (!location.state) {
        source = 'direct_link';
      } else if (location.state.ref) {
        source = location.state.ref;
      }
      if (location.state && location.state.from === 'top_marinas') {
        origin = 'top_marinas';
      }
    }

    let total_messages =
      reservation.messages && reservation.messages.length
        ? reservation.messages.length
        : 0;
    if (type === 'chat') {
      total_messages += 1;
    }

    let redeem_nights = 'n/a';
    if (payment) {
      const { nights_to_redeem } = payment;
      if (!isNil(nights_to_redeem)) {
        redeem_nights = nights_to_redeem;
      }
    } else if (!isNil(reservation.requested_redeem_nights_amount)) {
      redeem_nights = reservation.requested_redeem_nights_amount;
    }

    let updatedFields = [];
    if (data && data.method === 'UPDATE') {
      updatedFields = getUpdatedFieldsLabels({
        data,
        action: 'reservation_required_fields',
        initialValues
      });
    }

    let popeye_promotion = 'n/a';
    if (!isEmpty(popeye_discount)) {
      const { total_discount_amount, total_points_to_redeem } = popeye_discount;
      popeye_promotion = getFormattedPopeyePromotionType({
        total_discount_amount,
        total_points_to_redeem
      });
    }

    return {
      reservation_id: reservation.id,
      marina_id: marina.id,
      marina_name: marina.name,
      marina_country: marina.country,
      marina_association: marina.association.name,
      number_of_nights: moment(reservation.end_date).diff(
        moment(reservation.start_date),
        'days'
      ),
      boat_loa: reservationVessel.length,
      boat_beam: reservationVessel.beam,
      boat_draft: reservationVessel.draft,
      total_messages,
      price: payment && payment.total_to_pay ? payment.total_to_pay : '-',
      type,
      updatedFields,
      source,
      origin,
      redeem_nights,
      boater_type: getVesselType({
        vessel: reservationVessel
      }),
      unmet_required_fields,
      marinas_viewed_before:
        marinas_viewed_before !== -1 ? marinas_viewed_before : 0,
      popeye_promotion
    };
  } catch (error) {
    console.log('Amplitude: error in send amplitude update res event function');
    console.log(error);
  }
};

export const getUpdatedFieldsLabels = details => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    const { data, initialValues, action } = details;

    let result = [];
    switch (action) {
      case 'updated_absence_report':
        const {
          departure_date,
          destination,
          return_date,
          reason,
          vessel_name,
          home_marina_id
        } = data;
        if (initialValues.departure_date !== departure_date) {
          result.push('departure_date');
        }
        if (initialValues.destination !== destination) {
          result.push('destination');
        }
        if (initialValues.home_marina_id !== home_marina_id) {
          result.push('marina_name');
        }
        if (initialValues.reason !== reason) {
          result.push('reason');
        }
        if (initialValues.return_date !== return_date) {
          result.push('return_date');
        }
        if (initialValues.vessel_name !== vessel_name) {
          result.push('vessel_name');
        }
        return sortBy(result);

      case 'reservation_required_fields':
        if (data.boater) {
          for (const field of Object.keys(data.boater)) {
            if (field === 'id') continue;
            result.push(field);
          }
        }
        if (data.vessel) {
          for (const field of Object.keys(data.vessel)) {
            if (field === 'id') continue;
            result.push(field);
          }
        }
        return sortBy(result);

      case 'reservation_updated_profile':
        for (const field of Object.keys(data.boater)) {
          let comparedField = field;
          if (field === 'id') continue;
          if (field === 'email') comparedField = 'email_address';
          else if (field === 'date_of_birth') {
            const initial_date_of_birth = initialValues[comparedField];
            if (isMoment(initial_date_of_birth)) {
              const initial_date_of_birth_formatted =
                initial_date_of_birth.format('YYYY-MM-DD');
              const new_date_of_berth = data.boater[field];
              if (new_date_of_berth !== initial_date_of_birth_formatted) {
                result.push(comparedField);
              }
            }
            continue;
          }
          if (initialValues[comparedField] !== data.boater[field]) {
            result.push(comparedField);
          }
        }
        for (const field of Object.keys(data.vessel)) {
          let comparedField = field;
          if (field === 'home_marina_id' || field === 'id') continue;
          else if (field === 'length') comparedField = 'vessel_loa';
          else if (field === 'beam') comparedField = 'vessel_beam';
          else if (field === 'draft') comparedField = 'vessel_draft';
          else if (field === 'name') comparedField = 'vessel_name';
          else if (field === 'type') comparedField = 'vessel_type';

          if (initialValues[comparedField] !== data.vessel[field]) {
            result.push(comparedField);
          }
        }
        return sortBy(result);
    }
  } catch (error) {
    console.log('Amplitude: error in get updated fields labels function');
    console.log(error);
  }
};
export const removeIdentification = async () => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    (await getAmplitude()).getInstance().setUserId(null);
    (await getAmplitude()).getInstance().setDeviceId(null);
  } catch (error) {
    console.log('Amplitude remove user and device identification error: ');
    console.log(error);
  }
};

export const addIdentification = async details => {
  if (
    !process.env.REACT_APP_TESTING_AMPLITUDE &&
    process.env.REACT_APP_FIREBASE_ENV !== 'TEST' &&
    process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION'
  )
    return;

  try {
    const { boater, device_id } = details;
    let userId;

    /**
     * This is used in case of a guest user is going to signed up
     * and have previous events related to that user as guest
     * the connection will be the device id => by convention it will be the boater id.
     */
    if (!isNil(device_id)) {
      (await getAmplitude()).getInstance().setDeviceId(device_id);
    }

    if (!isNil(boater)) {
      userId = boater.user ? boater.user.uid : boater.uid;
    }

    (await getAmplitude()).getInstance().setUserId(userId);
  } catch (error) {
    console.log('Amplitude add user identification error: ');
    console.log(error);
  }
};

export const getFormattedCheckPriceEvent = details => {
  try {
    const {
      location,
      marina,
      hull_type,
      length,
      beam,
      draft,
      start_date,
      end_date,
      requested_redeem_nights_amount,
      calculatedPrice,
      error,
      marinas_viewed_before,
      duplicate = false,
      promotions
    } = details;
    let origin = '';

    if (!location.state) {
      origin = 'direct_link';
    } else if (location.state.ref) {
      origin = location.state.ref;
    } else if (location.state.from === 'top_marinas') {
      origin = 'top_marinas';
    }

    const popeye_promotion = getFormattedPopeyePromotionType({
      promotions,
      start_date,
      end_date
    });

    const today = moment().startOf('day');
    return {
      marina_id: marina.id || '-',
      marina_name: marina.name || '-',
      marina_country: marina.country || '-',
      marina_association: marina.association.name || '-',
      hull_type: hull_type || '-',
      boat_loa: length || '-',
      boat_beam: beam || '-',
      boat_draft: draft || '-',
      marinas_viewed_before,
      number_of_nights:
        moment(end_date).diff(moment(start_date), 'days') || '-',
      origin,
      redeem_nights: !isNil(requested_redeem_nights_amount)
        ? requested_redeem_nights_amount
        : 'n/a',
      price: !isEmpty(calculatedPrice) ? calculatedPrice.total_to_pay : '**',
      error,
      days_before: moment(start_date).diff(today, 'days'),
      duplicate,
      popeye_promotion
    };
  } catch (error) {
    console.log(
      'Amplitude: error in send reservation check price event function'
    );
    console.log(error);
  }
};
export const getFormattedPopeyePromotionType = details => {
  /**
   * 1. Promotions, start_date, end_date => when reservation wasn't initiated
   * 2. After creation => total_discount_amount, total_discount_for_points;
   */
  const {
    promotions,
    start_date,
    end_date,
    total_discount_amount,
    total_discount_for_points
  } = details;
  let popeye_promotion = 'n/a';
  if (!isEmpty(promotions)) {
    const promotionsWithinReservation = promotions.filter(promotion => {
      const { starts_discount_at, ends_discount_at } = promotion;
      const momentPromotionStartsAt = moment(starts_discount_at);
      const momentPromotionEndsAt = moment(ends_discount_at);
      const reservationStartDate = moment(start_date);
      const reservationEndDate = moment(end_date);
      if (
        reservationStartDate.isBetween(
          momentPromotionStartsAt,
          momentPromotionEndsAt,
          'days',
          []
        ) &&
        reservationEndDate.isBetween(
          momentPromotionStartsAt,
          momentPromotionEndsAt,
          'days',
          []
        )
      )
        return promotion;
    });

    if (!isEmpty(promotionsWithinReservation)) {
      if (promotionsWithinReservation.length === 1)
        popeye_promotion =
          promotionsWithinReservation[0].promotion_type.toLowerCase();
      else {
        const pointsType = promotionsWithinReservation.filter(
          promotion => promotion.promotion_type === 'POINTS'
        );
        const discountType = promotionsWithinReservation.filter(
          promotion => promotion.promotion_type === 'DISCOUNT'
        );
        if (!isEmpty(pointsType) && !isEmpty(discountType))
          popeye_promotion = 'both';
        else if (!isEmpty(discountType)) popeye_promotion = 'discount';
        else if (!isEmpty(pointsType)) popeye_promotion = 'points_multiplier';
      }
    }
  } else if (total_discount_amount > 0 && total_discount_for_points > 0)
    popeye_promotion = 'both';
  else if (total_discount_amount > 0) popeye_promotion = 'discount';
  else if (total_discount_for_points > 0)
    popeye_promotion = 'points_multiplier';

  return popeye_promotion;
};
