import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  input.ant-input {
    height: 47px;
    line-height: 47px;
    padding-left: 20px;
    border-radius: 3px;
    border: 1px solid ${themeGet('border.3', '#E6E6E6')};
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 15px;
    &::placeholder {
      color: ${themeGet('text.2', '#777777')};
    }
    &:focus {
      box-shadow: none;
    }
  }
  .ant-form-item-explain {
    position: absolute;
    top: 100%;
  }
`;

export const FieldWrapper = styled.div`
  .ant-form-item-control {
    .ant-form-item-control-input {
      min-height: auto;
    }
    line-height: 18px;
  }
  &.forgot-password {
    text-align: right;
    margin-bottom: 30px;
    a {
      font-size: 12px;
      line-height: 15px;
      color: ${themeGet('text.0', '#323232')};
      &:hover {
        color: ${themeGet('primary.0', '#194AB9')};
      }
    }
  }0036B1
`;

export const SwitchWrapper = styled.div`
  .terms-field {
    line-height: 18px;
    a {
      color: ${themeGet('primary.0', '#194AB9')};
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Label = styled.span`
  font-size: 12px;
  a {
    margin-left: 3px;
  }
`;

export const ErrorLabel = styled.span`
  font-size: 15px;
  font-weight: 700;
  color: ${themeGet('text.5', '#f5222d')};
  line-height: 1;
`;

export const AuthButtons = styled.div`
  padding-bottom: 20px;
  .ant-btn {
    &.facebook-btn,
    &.google-btn {
      height: 47px;
      border-radius: 2px;
      font-size: 15px;
      font-weight: bold;
      box-shadow: none;
      width: 100%;
    }
    &.facebook-btn {
      border-color: ${themeGet('color.7', '#3b5998')};
      background-color: ${themeGet('color.7', '#3b5998')};
      > img {
        margin-right: 10px;
        margin-bottom: 2px;
      }
    }
    &.google-btn {
      border-color: ${themeGet('color.10', '#dd4b39')};
      background-color: ${themeGet('color.1', '#ffffff')};
      color: ${themeGet('color.0', '#000000')};
      border: 1.5px solid ${themeGet('color.19', '#bfbfbf')};
      > img {
        margin-right: 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const BottomInfo = styled.p`
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  color: ${themeGet('text.2', '#777777')};
  margin-bottom: 0;
  a {
    color: ${themeGet('primary.0', '#194AB9')};
    font-weight: bold;
    &:hover,
    &:focus {
      outline: none;
      color: ${themeGet('primary.0', '#194AB9')};
      text-decoration: none;
    }
  }
  .bottom-link {
    white-space: nowrap;
  }
`;
