import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 565px;
  max-width: 100%;
  .ant-menu {
    border-right: none;
    &.ant-menu-vertical {
      .ant-menu-item {
        margin-bottom: 6px;
      }
    }
  }
  .ant-menu-item {
    font-size: 14px;
    line-height: 22px;
    padding: 0;
    &.ant-menu-item-selected {
      background-color: transparent;
    }
    a {
      color: ${themeGet('primary.0')};
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    width: 465px;
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
    margin-bottom: 14px;
  }
  @media only screen and (max-width: 767px) {
    .ant-menu-item {
      height: 31px;
      font-size: 11px;
      line-height: 13px;
      a {
        color: ${themeGet('text.13')};
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

export const Column = styled.div``;

export const Title = styled.h5`
  color: ${themeGet('text.13')};
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: 991px) {
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
`;
