import UKFlag from 'assets/images/united-kingdom-flag-icon-32.png';
import FranceFlag from 'assets/images/france-flag-icon-32.png';
import GermanyFlag from 'assets/images/germany-flag-icon-32.png';
import SpainFlag from 'assets/images/spain-flag-icon-32.png';
import ItalyFlag from 'assets/images/italy-flag-icon-32.png';
import filler from 'assets/images/banner/1.jpg';

const locales = {
  en: {
    label: 'English',
    icon: UKFlag
  },
  fr: {
    label: 'Français',
    icon: FranceFlag
  },
  es: {
    label: 'Español',
    icon: SpainFlag
  },
  de: {
    label: 'Deutsch',
    icon: GermanyFlag
  },
  it: {
    label: 'Italiano',
    icon: ItalyFlag
  }
};

if (process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION') {
  locales['xx'] = {
    label: 'dictkeys',
    icon: filler
  }
}

export const localeOptions = Object.entries(locales).map(([key]) => key);

export default locales;
