import 'react-dates/initialize';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactDatesStyleWrapper } from './ReactDates.style';

import { getOutsideRangeByLimits } from 'library/helpers/utils';
import CustomDay from 'components/UI/CustomDay/CustomDay';

/*
 * For Localization enable this code [example : French language as "fr"]
 */
// import moment from 'moment';
// import 'moment/locale/fr';

const DateRangePickerBox = props => {
  /*
   * For Localization enable this code [example : French language as "fr"]
   */
  // moment.locale('fr');

  const [focusedInput, setFocusedInput] = useState(
    props.focusedInput ? props.focusedInput : null
  );
  const [startDate, setStartDate] = useState(
    props.startDate ? props.startDate : null
  );
  const [endDate, setEndDate] = useState(props.endDate ? props.endDate : null);
  const [separator, setSeparator] = useState(
    props.item && props.item.separator ? props.item.separator : '/'
  );
  const [dateFormat, setDateFormat] = useState(
    props.item && props.item.format ? props.item.format : 'llll'
  );

  useEffect(() => {
    setFocusedInput(props.focusedInput);
  }, [props.focusedInput]);

  useEffect(() => {
    if (updatable && props.startDate && props.endDate) {
      setStartDate(moment(props.startDate, 'DD/MM/YYYY'));
      setEndDate(moment(props.endDate, 'DD/MM/YYYY'));
    }
    if (clearable && props.startDate === null && props.endDate === null) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [props.startDate, props.endDate]);

  const onDateChangeFunc = ({ startDate, endDate }) => {
    setStartDate(startDate && stateDateWrapper(startDate));
    setEndDate(endDate && stateDateWrapper(endDate));

    props.updateSearchData({
      setStartDate: startDate ? startDate.format(dateFormat) : null,
      setEndDate: endDate ? endDate.format(dateFormat) : null
    });
  };

  // DateRangePickerBox props list
  const {
    className,
    startDateId,
    endDateId,
    startDatePlaceholderText,
    endDatePlaceholderText,
    disabled,
    showClearDates,
    isRTL,
    orientation,
    anchorDirection,
    withPortal,
    withFullScreenPortal,
    small,
    block,
    numberOfMonths,
    regular,
    noBorder,
    stateDateWrapper = date => date,
    updatable,
    clearable,
    customInputIcon,
    appendToBody,
    readOnly,
    openDirection,
    onFocus = () => {},
    onClose,
    promotions = []
  } = props;

  const onFocusChangeFunc = focusedInput => {
    onFocus();
    setFocusedInput(focusedInput);
  };

  // Add all classs to an array **************
  const addAllClasses = ['date_picker'];
  // className prop checking **************
  if (className) {
    addAllClasses.push(className);
  }

  // React-Dates DateRangePicker Props List
  const defaultCalenderProps = {
    displayFormat: dateFormat,
    startDateId: startDateId ? startDateId : 'start_unique_id',
    endDateId: endDateId ? endDateId : 'end_date_unique_id',
    startDate,
    endDate,
    focusedInput,
    onFocusChange: onFocusChangeFunc,
    onDatesChange: onDateChangeFunc,
    startDatePlaceholderText,
    endDatePlaceholderText,
    disabled,
    isRTL,
    showClearDates: showClearDates ? showClearDates : false,
    orientation,
    anchorDirection,
    withPortal,
    withFullScreenPortal,
    small,
    numberOfMonths: numberOfMonths ? numberOfMonths : 2,
    block,
    regular,
    noBorder,
    customInputIcon: customInputIcon && customInputIcon,
    appendToBody,
    readOnly,
    openDirection,
    onClose
  };

  return (
    <ReactDatesStyleWrapper className={addAllClasses.join(' ')}>
      <DateRangePicker
        {...defaultCalenderProps}
        isOutsideRange={day =>
          getOutsideRangeByLimits(
            day,
            props.limits,
            startDate,
            endDate,
            true,
            focusedInput
          )
        }
        renderDayContents={day => {

          let isThisPromotionDay = false;
          let promotionType = null;

          promotions.forEach(({ valid_between_start_date, valid_between_end_date, promotion_type }) => {
            const VSDayMoment = moment(valid_between_start_date).startOf('day');
            const VEDayMoment = moment(valid_between_end_date).startOf('day');
            if(moment(day).isBetween(VSDayMoment, VEDayMoment, 'day', '[]')) {
              isThisPromotionDay = true;
              promotionType = promotion_type;
            }
          })

          const outside = getOutsideRangeByLimits(
              day,
              props.limits,
              startDate,
              endDate,
              false,
              focusedInput
            );

          if(isThisPromotionDay && outside) {
            return <CustomDay day={day} limits={props.limits} promotionType={promotionType} />;
          } else if(isThisPromotionDay) {
            return <CustomDay day={day} promotionType={promotionType} />;
          } else if(outside) {
            return <CustomDay day={day} limits={props.limits} />;
          } else {
            return day.format('D');
          }
        }}
      />
    </ReactDatesStyleWrapper>
  );
};

DateRangePickerBox.propTypes = {
  /** startDateId of the date-picker field */
  startDateId: PropTypes.string.isRequired,
  /** endDateId of the date-picker field */
  endDateId: PropTypes.string.isRequired, //
  /** startDatePlaceholderText of the date-picker field */
  startDatePlaceholderText: PropTypes.string,
  /** endDatePlaceholderText of the date-picker field */
  endDatePlaceholderText: PropTypes.string,
  /** disabled of the date-picker field */
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.oneOf(['START_DATE', 'END_DATE'])
  ]),

  /** showClearDates of the date-picker field */
  showClearDates: PropTypes.bool,
  /** isRTL of the date-picker field */
  isRTL: PropTypes.bool,
  /** orientation of the date-picker field */
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  /** anchorDirection of the date-picker field */
  anchorDirection: PropTypes.oneOf(['left', 'right']),
  /** withPortal of the date-picker field */
  withPortal: PropTypes.bool,
  /** withFullScreenPortal of the date-picker field */
  withFullScreenPortal: PropTypes.bool,
  /** small of the date-picker field */
  small: PropTypes.bool,
  /** numberOfMonths of the date-picker field */
  numberOfMonths: PropTypes.number,
  /** block of the date-picker field */
  block: PropTypes.bool,
  /** regular of the date-picker field */
  regular: PropTypes.bool,
  /** noBorder of the date-picker field */
  noBorder: PropTypes.bool,
  /** updateSearchData of the date-picker field to send component data to parent component */
  updateSearchData: PropTypes.func,
  /** limits to disable some dates */
  limits: PropTypes.object
};

export default DateRangePickerBox;
