import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const FormWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${themeGet('color.1', '#ffffff')};
  border-radius: 24px;
  min-height: 55px;
  @media only screen and (max-width: 991px) {
    min-height: 40px;
  }

  /* submit button style */
  button[type='submit'].ant-btn {
    min-width: 140px;
    height: 54px;
    color: ${themeGet('color.1', '#ffffff')};
    background-color: ${themeGet('primary.0', '#194AB9')};
    border-radius: 27px;
    border: 2px solid;
    border-color: #ffffff;
    outline: 2px solid ${themeGet('primary.0', '#194AB9')};
    box-shadow: none;
    font-size: 17px;
    line-height: 24px;
    font-weight: 600;
    text-shadow: none;

    @media only screen and (max-width: 991px) {
      display: none;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
  ${({ mobileMode }) => mobileMode && css`
    padding: 8px 12px 8px 0;
    border-radius: 0;
    background-color: ${themeGet('color.40', '#F2F2F2')};
    .ant-select {
      .ant-select-selector {
        height: 34px;
      }
    }
  `}
`;

export const OrtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ort-type {
    margin-top: 8px;
    font-size: 14px;
    font-wight: 400;
    lint-height: 22.4px;
    color: #828282; 
  }
  .ort-label {
    margin-top: 4px;
    font-size: 17px;
    font-wight: 400;
    lint-height: 23.8px;
    color: #000000; 
  }
`

export const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  .target {
    right: 10px;
    left: auto;

    @media only screen and (max-width: 480px) {
      right: 25px;
    }
  }

  .ant-select {
    width: 100%;
    height: 30px;
    .ant-select-selector {
      padding: 0;
      border: none;
      background: ${themeGet('color.1', '#ffffff')};
      .ant-select-selection-search {
        height: 30px;
        left: 0;
      }
      .ant-select-selection-search,
      .ant-select-selection-placeholder {
        padding: 0 30px 0 25px;
        color: ${themeGet('text.12')};
        font-size: 17px;
        @media only screen and (max-width: 991px) {
          font-size: 14px;
          padding: 0 30px 0 38px;
        }
      }
      .ant-select-selection-search-input,
      .ant-select-selection-placeholder {
        height: 30px;
      }
      .ant-select-selection-placeholder {
        line-height: 30px;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: none !important;
      }
    }
  }

  /* icon style */
  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    &.search-icon {
      left: 15px;
      right: auto;
      stroke: ${themeGet('text.12', '#828282')};
      @media only screen and (min-width: 992px) {
        display: none;
      }
    }
  }

  ${({ mobileMode }) => mobileMode && css`
    border: 1px solid ${({ searchOpened }) => searchOpened ? themeGet('border.10') : themeGet('border.9') };
    border-radius: 4px;
    .ant-select {
      height: 34px;
      .ant-select-selector {
        .ant-select-selection-search {
          height: 34px;
        }
        .ant-select-selection-search,
        .ant-select-selection-placeholder {
          padding-top: 2px;
          padding-bottom: 3px;
        }
        .ant-select-selection-search {
          color: ${themeGet('text.13', '#121A2C')};
        }
        .ant-select-selection-placeholder {
          color: ${themeGet('text.12', '#828282')};
        }
      }
      .ant-select-clear {
        top: 35%;
        right: 18px;
      }
      &.ant-select-open {
        .ant-select-selector {
          border-radius: 4px;
        }
      }
    }
  `}
`;
