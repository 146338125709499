import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Trigger = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 44px;
  border-radius: 5px;
  margin-top: 4px;
  padding: 4px 8px;
  margin-right: 16px;
  @media only screen and (max-width: 991px) {
    margin-right: 0;
  }
  .ant-badge-count {
    top: -1px;
    right: 2px;
    min-width: 17px;
    height: 17px;
    padding: 0 5px;
    border-radius: 8px;
    box-shadow: none;
    line-height: 17px;
    font-weight: bold;
  }
  &:hover {
    .additional-menu-trigger-icon {
      svg {
        .affect-class {
          &.fill {
            fill: ${themeGet('primary.0', '#194AB9')};
          }
          &.stroke {
            stroke: ${themeGet('primary.0', '#194AB9')};
          }
        }
      }
    }
  }
  .additional-menu-trigger-icon {
    svg {
      .affect-class {
        &.fill {
          fill: ${themeGet('text.13', '#121a2c')};
        }
        &.stroke {
          stroke: ${themeGet('text.13', '#121a2c')};
        }
      }
    }
  }
`;

export const NotificationsDropdown = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${themeGet('color.1', '#ffffff')};
  border: 1px solid ${themeGet('border.3', '#e6e6e6')};
  min-width: 300px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  &.active {
    opacity: 1;
    visibility: visible;
    @media only screen and (max-width: 991px) {
      top: 52px;
      position: fixed;
    }
  }
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const NotificationsListHeader = styled.div`
  height: 45px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${themeGet('border.3', '#e6e6e6')};
  padding: 0 20px;
  &.unviewed {
    background-color: #e6f7ff;
  }
  .read_all {
    padding: 0;
    color: #4a4a4a;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: ${themeGet('primary.0', '#194AB9')};
    }
  }
`;

export const NewMessagesCounter = styled.p`
  margin-bottom: 0;
  font-weight: bold;
  color: ${themeGet('text.0', '#2c2c2c')};
`;

export const NotificationsListBody = styled.div`
  max-height: ${({ displayedAll }) =>
    displayedAll ? 'calc(100vh - 195px)' : 'calc(100vh - 237px)'};
  overflow-y: scroll;
  @media only screen and (max-width: 991px) {
    ${({ displayedAll, isEmpty }) =>
    displayedAll
      ? css`
            max-height: calc(100vh - 260px);
          `
      : css`
            max-height: ${isEmpty
          ? 'calc(100vh - 106px)'
          : 'calc(100vh - 260px)'};
            overflow: hidden;
          `}
`;

export const NotificationsList = styled.ul`
  max-height: 100%;
`;

export const NotificationItem = styled.li`
  width: 100%;
  border-bottom: 1px solid ${themeGet('border.3', '#e6e6e6')};
  &:last-child {
    border-bottom: 0;
  }
`;

export const NotificationBody = styled.div`
  padding: 11px 22px 14px 16px;
  min-height: 85px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  border-left: 3px solid ${themeGet('color.1', '#ffffff')};
  &:hover {
    border-left: 3px solid ${themeGet('primary.0', '#194AB9')};
  }
  &.readed {
    border-left: 3px solid ${themeGet('color.2', '#f7f7f7')};
    background-color: ${themeGet('color.2', '#f7f7f7')};
    &:hover {
      border-left: 3px solid ${themeGet('primary.0', '#194AB9')};
      .actions_button {
        display: block;
      }
    }
    .title {
      font-weight: normal;
    }
  }
  &.unviewed {
    background-color: #e6f7ff;
    border-left: 3px solid #e6f7ff;
  }
  .actions_button {
    position: absolute;
    right: 0;
    top: 4px;
    display: none;
    font-size: 24px;
    padding: 0 5px;
    color: ${themeGet('text.2', '#777777')};
    &:hover {
      color: ${themeGet('text.2', '#777777')};
    }
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  .anticon {
    margin-top: 2px;
    font-size: 17px;
  }
`;

export const NotificationSubject = styled.h4`
  font-weight: bold;
  line-height: 19px;
  padding-left: 9px;
  padding-right: 8px;
  margin-bottom: 6px;
`;

export const NotificationDate = styled.p`
  margin-bottom: 0;
  text-align: right;
  font-size: 12px;
  color: #778796;
`;

export const NotificationsListFooter = styled.div`
  border-top: 1px solid ${themeGet('border.3', '#e6e6e6')};
  padding: 10px;
  text-align: center;
  .link {
    font-weight: bold;
  }
  @media only screen and (max-width: 991px) {
    bottom: 52px;
    left: 0;
    width: 100%;
    background-color: ${themeGet('color.1', '#ffffff')};
  }
  a.link {
    color: ${themeGet('primary.0', '#194AB9')};
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const EmptyNotifications = styled.div`
  padding: 30px 16px;
  text-align: center;
  font-size: 15px;
`;

export const ActionDropdownOuter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  &.opened {
    display: block;
  }
`;

export const ActionsDropdown = styled.div`
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  top: 0;
  right: 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px 17px;
  background-color: ${themeGet('color.1', '#ffffff')};
  display: none;
  &.opened {
    display: flex;
  }
  .action_button {
    height: 25px;
    padding: 0;
    color: #4a4a4a;
    &:hover {
      color: ${themeGet('primary.0', '#194AB9')};
    }
  }
`;
