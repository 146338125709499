import eastMediterraneanImg from 'assets/images/map/east_mediterranean.png';
import westMediterraneanImg from 'assets/images/map/west_mediterranean.png';
import adriaticImg from 'assets/images/map/adriatic.png';
import atlanticImg from 'assets/images/map/atlantic.png';
import biscayImg from 'assets/images/map/biscay.png';
import westChannelImg from 'assets/images/map/west_channel.png';
import eastChannelImg from 'assets/images/map/east_channel.png';
import irishSeaImg from 'assets/images/map/irish_sea.png';
import balticSeaImg from 'assets/images/map/baltic_sea.png';

const regions = {
    east_mediterranean: {
        title: 'HOMEPAGE_REGION_MAP_EAST_MED',
        frame: [
            {lng: 33.9291576, lat: 30.4443571},
            {lng: 36.1264232, lat: 30.9356296},
            {lng: 37.8842357, lat: 38.4754749},
            {lng: 29.0512279, lat: 42.1922592},
            {lng: 18.5922436, lat: 41.4060204},
            {lng: 18.372517,  lat: 37.1563261},
            {lng: 20.8334545, lat: 31.4618828},
            {lng: 29.4467357, lat: 29.6455171},
            {lng: 33.9291576, lat: 30.4443571},
        ],
        mobileImg: eastMediterraneanImg
    },
    west_mediterranean: {
        title: 'HOMEPAGE_REGION_MAP_WEST_MED',
        frame: [
            {lng: 10.7676807, lat: 44.8562184},
            {lng: 3.3409229,  lat: 44.1983126},
            {lng: -5.6678662, lat: 37.0029466},
            {lng: -5.6239209, lat: 34.6516909},
            {lng: 11.8223682, lat: 34.6155326},
            {lng: 16.7002979, lat: 35.2639646},
            {lng: 17.8868213, lat: 39.1133963},
            {lng: 10.7676807, lat: 44.8562184},
        ],
        mobileImg: westMediterraneanImg
    },
    adriatic: {
        title: 'HOMEPAGE_REGION_MAP_ADRIATIC',
        frame: [
            {lng: 12.1739307, lat: 46.2409931},
            {lng: 10.7676807, lat: 44.8562184},
            {lng: 15.3379932, lat: 41.0962838},
            {lng: 16.8321338, lat: 38.8743125},
            {lng: 20.2598682, lat: 38.7030442},
            {lng: 20.9190479, lat: 41.5911657},
            {lng: 14.7667041, lat: 46.2409931},
            {lng: 12.1739307, lat: 46.2409931},
        ],
        mobileImg: adriaticImg
    },
    atlantic: {
        title: 'HOMEPAGE_REGION_MAP_ATLANTIC',
        frame: [
            {lng: -8.8630701,  lat: 43.2871927},
            {lng: -20.0251794, lat: 40.0044653},
            {lng: -20.0251794, lat: 26.3057141},
            {lng: -12.8181482, lat: 26.6989866},
            {lng: -6.4021326,  lat: 33.057007},
            {lng: -5.479281,   lat: 35.9535421},
            {lng: -7.5447107,  lat: 42.092098},
            {lng: -8.8630701,  lat: 43.2871927},
        ],
        mobileImg: atlanticImg
    },
    biscay: {
        title: 'HOMEPAGE_REGION_MAP_BISCAY',
        frame: [
            {lng: -4.6443201, lat: 48.5983996},
            {lng: -9.0827966, lat: 42.9986111},
            {lng: -0.6892419, lat: 42.6763678},
            {lng: -0.0740076, lat: 46.7040763},
            {lng: -4.6443201, lat: 48.5983996},
        ],
        mobileImg: biscayImg
    },
    west_channel: {
        title: 'HOMEPAGE_REGION_MAP_WEST_CHANNEL',
        frame: [
            {lng: -0.1671866, lat: 51.4983751},
            {lng: -5.8800773, lat: 51.2514914},
            {lng: -6.0119132, lat: 49.9687762},
            {lng: -4.4738273, lat: 48.3013498},
            {lng: 0.0525399,  lat: 48.3889734},
            {lng: 2.6892587,  lat: 50.5587031},
            {lng: -0.1671866, lat: 51.4983751},
        ],
        mobileImg: westChannelImg
    },
    east_channel: {
        title: 'HOMEPAGE_REGION_MAP_EAST_CHANNEL',
        frame: [
            {lng: -4.0343741, lat: 56.0872644},
            {lng: -2.6281241, lat: 57.3649575},
            {lng: 9.293631,   lat: 55.1552681},
            {lng: 9.3689462,  lat: 53.4519734},
            {lng: 2.3376962,  lat: 49.8272394},
            {lng: -0.5187491, lat: 51.3064695},
            {lng: -4.0343741, lat: 56.0872644},
        ],
        mobileImg: eastChannelImg
    },
    irish_sea: {
        title: 'HOMEPAGE_REGION_MAP_IRISH',
        frame: [
            {lng: -2.5402335,  lat: 51.0861621},
            {lng: -1.5734366,  lat: 53.6347635},
            {lng: -4.3859366,  lat: 56.2585111},
            {lng: -10.626171,  lat: 55.5937681},
            {lng: -10.8019523, lat: 50.7815174},
            {lng: -2.5402335,  lat: 51.0861621},
        ],
        mobileImg: irishSeaImg
    },
    baltic_sea: {
        title: 'HOMEPAGE_REGION_MAP_BALTIC',
        frame: [
            {lng: 9.3452361,  lat: 54.0238033},
            {lng: 14.2231658, lat: 52.8988563},
            {lng: 21.6938689, lat: 53.8945248},
            {lng: 22.3969939, lat: 57.3412508},
            {lng: 9.0376189,  lat: 57.9293407},
            {lng: 9.3452361,  lat: 54.0238033},
        ],
        mobileImg: balticSeaImg
    }
};

export const regionsList = Object.keys(regions).map(name => ({
    name,
    ...regions[name]
}))

export default regions;
