import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { MdClose } from 'react-icons/md';
import { FiChevronDown } from 'react-icons/fi';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { LayoutContext } from 'context/LayoutProvider';
import Button from 'components/UI/Antd/Button/Button';
import useAmplitude from 'library/hooks/useAmplitude';
import NavbarWrapper, {
  NavbarInnerContainer,
  NavbarLeftItemsContainer,
  NavbarRightItemsContainer,
  LogoArea,
  MainMenu,
  MainMenuItem,
  AdditionalMenu,
  AdditionalMenuWrapper,
  AuthenticaionMenu,
  ProfileMenuWrapper,
  AuthMenuWrapper,
  Trigger,
  IconWrapper,
  EnvBanner,
  LoadingWrapper,
  HeaderSearchBarContainer
} from './Navbar.style';
// icons
import crownIcon from 'assets/images/pickapier/membership-crown.svg';
import { DISCOVER_PAGE, ABSENCE_REPORTS_PAGE } from 'settings/constant';
import { AuthContext } from 'context/AuthProvider';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import SearchForm from 'components/Search/SearchForm';
import { setStateToUrl } from 'library/helpers/url_handler';

const Navbar = ({
  headerType,
  isLogin,
  user,
  logo,
  authMenu,
  profileMenu,
  myTripsMenu,
  popeyeMenu,
  guestProfile,
  searchComponent,
  languageMenu,
  notificationsComponent,
  windowWidth,
  onAuth,
  location,
  history,
  match
}) => {
  const { t } = useTranslation();
  const { sendToAmplitude } = useAmplitude();
  const [{ searchVisibility }, dispatch] = useContext(LayoutContext);
  const { waitingForAuth } = useContext(AuthContext);
  const [showSearchnavbar, setShowSearchnavbar] = useState(true);
  const { pathname } = location;
  const onSearchClose = () => dispatch({ type: 'HIDE_TOP_SEARCHBAR' });
  const handleExternalLink = (e, eventName, link) => {
    e.preventDefault();

    // Amplitude event
    const eventProperties = {
      source: 'Header',
      origin: pathname
    };

    sendToAmplitude({
      eventProperties,
      eventName
    });

    setTimeout(() => {
      window.location.assign(link);
    }, 2000);
  };
  const loggedUser = isLogin && user;

  useScrollPosition(({ currPos }) => {
    if (windowWidth < 992) {
      if (currPos.y < -400) {
        if (!showSearchnavbar) {
          setShowSearchnavbar(true);
        }
      } else {
        if (showSearchnavbar) {
          setShowSearchnavbar(false);
        }
      }
    }
  });

  return (
    <NavbarWrapper className={headerType}>
      <NavbarInnerContainer>
        <NavbarLeftItemsContainer>
          {!!logo && <LogoArea>{logo}</LogoArea>}

          {process.env.REACT_APP_FIREBASE_ENV !== 'PRODUCTION' && (
            <EnvBanner className='env-banner'>
              {process.env.REACT_APP_FIREBASE_ENV === 'TEST' && <span>'TEST'</span>}
              {process.env.REACT_APP_FIREBASE_ENV === 'DEVELOPMENT' && <span>'DEV'</span>}
              {process.env.REACT_APP_FIREBASE_ENV === 'DEMO' && <span>'DEMO'</span>}
            </EnvBanner>
          )}
        </NavbarLeftItemsContainer>

        {windowWidth < 992 && showSearchnavbar && (
          <HeaderSearchBarContainer className="header-search-Bar-container">
            <SearchForm mobileMode={true} />
          </HeaderSearchBarContainer>
        )}

        <NavbarRightItemsContainer>
          <MainMenu className='MainMenu'>
            {loggedUser && (
              <MainMenuItem className={headerType}>
                <NavLink
                  className='nav-link vacancy'
                  to={`${match.url}${ABSENCE_REPORTS_PAGE}${setStateToUrl({
                    open_report: true
                  })}`}
                >
                  {t('HEADER_VACANCY')}
                </NavLink>
              </MainMenuItem>
            )}

            {(match.url !== DISCOVER_PAGE) && (
              <MainMenuItem className={headerType}>
                {searchComponent}
              </MainMenuItem>
            )}

            <MainMenuItem className={headerType}>{popeyeMenu}</MainMenuItem>

            {loggedUser && (<MainMenuItem className={headerType}>{myTripsMenu}</MainMenuItem>)}

            {!loggedUser && (<MainMenuItem className={headerType}>
              <a
                className='nav-link'
                onClick={(e) => handleExternalLink(e, 'for_ocean_clicked', `${process.env.REACT_APP_MARKET_URL}/for-the-ocean/`)}
              >
                {t('HEADER_FOR_OCEAN')}
              </a>
            </MainMenuItem>
            )}

            {!loggedUser && (<MainMenuItem className={headerType}>
              <a
                className='nav-link'
                onClick={(e) => handleExternalLink(e, 'for_marinas_clicked', `${process.env.REACT_APP_MARKET_URL}/for-marinas/`)}
              >
                {t('HEADER_FOR_MARINAS')}
              </a>
            </MainMenuItem>
            )}

            {!loggedUser && (<MainMenuItem className={headerType}>
              <a
                className='nav-link'
                onClick={(e) => handleExternalLink(e, 'for_boaters_clicked', `${process.env.REACT_APP_MARKET_URL}/for-boaters/`)}
              >
                {t('HEADER_FOR_BOATER')}
              </a>
            </MainMenuItem>
            )}
          </MainMenu>

          {waitingForAuth ? (
            <LoadingWrapper className={headerType}>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: 'inherit' }}
                    spin
                  />
                }
              />
            </LoadingWrapper>
          ) : (
            <>
              <AdditionalMenu
                className={headerType}
                searchVisibility={searchVisibility}>
                <AdditionalMenuWrapper className='additional-menu-wrapper'>
                  {loggedUser && notificationsComponent}
                  {(windowWidth > 992 || (!loggedUser && windowWidth < 992)) && languageMenu}
                  {windowWidth < 992 && !loggedUser && guestProfile}
                </AdditionalMenuWrapper>
              </AdditionalMenu>
              <AuthenticaionMenu>
                {loggedUser ? (
                  <ProfileMenuWrapper>{profileMenu}</ProfileMenuWrapper>
                ) : (
                  <AuthMenuWrapper className={`auth-menu-wrapper ${headerType}`}>
                    {authMenu}
                  </AuthMenuWrapper>
                )}
              </AuthenticaionMenu>
            </>
          )}

          {searchVisibility && headerType === 'default' && (
            <Button
              className='search_close_button'
              htmlType='button'
              type='link'
              onClick={onSearchClose}
            >
              <MdClose size={24} />
            </Button>
          )}
        </NavbarRightItemsContainer>
      </NavbarInnerContainer>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  authMenu: PropTypes.element,
  isLogin: PropTypes.bool,
  headerType: PropTypes.oneOf(['transparent', 'default'])
};

export default withRouter(Navbar);
