import React from 'react';

import { Copyright } from './PopeyeCopyright.style';

const PopeyeCopyright = ({
  mobileOnly=false,
  paddingTop=0,
  mode='dark',
  align={ desktop: "center", mobile: "center" }
}) => {
  return (
    <Copyright
      mobileOnly={ mobileOnly }
      paddingTop={ paddingTop }
      mode={ mode }
      align={ align }
    >
      © 2022 King Features Syndicate
    </Copyright>
  );
};

export default PopeyeCopyright;
