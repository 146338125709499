import React, { Fragment, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { AuthContext } from 'context/AuthProvider';
import { LayoutContext } from 'context/LayoutProvider';

import LayoutWrapper from 'components/UI/Antd/Layout/Layout';
import PopeyeRequestDrawer from 'components/UI/PopeyeRequestDrawer/PopeyeRequestDrawer';

import Header from './Header/Header';
import Footer from './Footer/Footer';
import MobileMenu from './MobileMenu/MobileMenu';

import {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  PROFILE_PAGE,
  SETTINGS_PAGE,
  MARINA_PAGE,
  CHANGE_PASSWORD_PAGE,
  FORGET_PASSWORD_PAGE,
  EMAIL_VERIFICATION_PAGE,
  EMAIL_PREFERENCES,
  DISCOVER_PAGE, IFRAME
} from 'settings/constant';

import useWindowSize from 'library/hooks/useWindowSize';

const { Content } = LayoutWrapper;

export default withRouter(function Layout({ children, location, match }) {
  const { width } = useWindowSize();
  const { loggedIn, user } = useContext(AuthContext);
  const [state, dispatch] = useContext(LayoutContext);
  const singlePageUrlFromConst = `${match.url}${MARINA_PAGE}`.split('/');
  const singlePageUrlFormLocation = location.pathname.split('/');
  const isSinglePage = singlePageUrlFormLocation[2] === singlePageUrlFromConst[2];
  const isEmailPreferencesPage = location.pathname === `${match.url + SETTINGS_PAGE + EMAIL_PREFERENCES}`;
  const isProfilePage = location.pathname === `${match.url}${PROFILE_PAGE}`;
  const isDiscoverMobilePage = location.pathname.includes(DISCOVER_PAGE) && width < 992;
  const loggedUser = loggedIn && user;

  return (
    <Fragment>
      {location.pathname === `${match.url}${LOGIN_PAGE}` ||
      isDiscoverMobilePage ||
      location.pathname === `${match.url}${CHANGE_PASSWORD_PAGE}` ||
      location.pathname.includes(IFRAME) ||
      location.pathname === `${match.url}${FORGET_PASSWORD_PAGE}` ||
      location.pathname === `${match.url}${EMAIL_VERIFICATION_PAGE}` ||
      location.pathname === `${match.url}${REGISTRATION_PAGE}` ? (
        <Fragment>
          <Content>{children}</Content>
          {width < 992 && !isDiscoverMobilePage && <div style={{ height: '52px' }} />}
        </Fragment>
      ) : (
        <Fragment>
          <Header />
          <Content>{children}</Content>
          {!location.pathname.includes(DISCOVER_PAGE) && <Footer />}
          {(isSinglePage || isEmailPreferencesPage || isProfilePage) && (
            <Fragment>
              {width < 992 && <div style={{ height: '74px' }} />}
            </Fragment>
          )}
          {width < 992 && <div style={{ height: '52px' }} />}
        </Fragment>
      )}
      {width < 992 && loggedUser && !isDiscoverMobilePage && !isSinglePage && <MobileMenu />}
      <PopeyeRequestDrawer
        opened={ state.popeyeRequestDrawerOpened }
        onClose={ () => dispatch({ type: 'HIDE_POPEYE_REQUEST_DRAWER' })}
      />
    </Fragment>
  );
});
