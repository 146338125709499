import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import isEmpty from 'lodash/isEmpty';
import { AuthContext } from 'context/AuthProvider';
import Drawer from 'components/UI/Antd/Drawer/Drawer';
import Menu from 'components/UI/Antd/Menu/Menu';
import { getStateFromUrl } from 'library/helpers/url_handler';
import { isItemActive } from 'library/helpers/bottomNavigation';
import { RESERVATIONS_PAGE, ABSENCE_REPORTS_PAGE } from 'settings/constant';
import { MY_TRIPS } from './MobileMenu';
import {
  MobileMenuTitle,
  DrawerBodyWrapper,
  DrawerFooter
} from './MobileMenu.style';
// icons
import myTripsIcon from 'assets/images/pickapier/my-trips.svg';
import myTripsHoverIcon from 'assets/images/pickapier/my-trips-hovered.svg';
import reservationsIcon from 'assets/images/pickapier/reservations.svg';
import absenceIcon from 'assets/images/pickapier/absence.svg';

const MyTripsMobileMenu = ({
  location,
  match,
  state,
  dispatch,
  isARModalShown,
  activeGuestItem,
  setActiveGuestItem
}) => {
  const { pathname } = location;
  const searchParams = getStateFromUrl(location);
  const { t } = useTranslation();
  const { loggedIn, user } = useContext(AuthContext);

  const { tripsDrawerOpened, authDrawerOpened } = state;

  const toggleBottomMenu = (e, prevent) => {
    if (prevent) {
      e.preventDefault();
    }
    dispatch({ type: 'HIDE_HOME_DRAWER' });
    dispatch({ type: 'HIDE_MORE_DRAWER' });
    dispatch({ type: 'HIDE_POPEYE_REQUEST_DRAWER' });
    if (!loggedIn || !user) {
      dispatch({
        type: authDrawerOpened ? 'HIDE_AUTH_DRAWER' : 'OPEN_AUTH_DRAWER'
      });
      if (!authDrawerOpened) {
        setActiveGuestItem(MY_TRIPS);
      } else {
        setActiveGuestItem(null);
      }
    } else {
      dispatch({
        type: tripsDrawerOpened ? 'HIDE_TRIPS_DRAWER' : 'OPEN_TRIPS_DRAWER'
      });
    }
  };

  const activeBottomItem = isItemActive(MY_TRIPS, state, pathname, match, {
    activeGuestItem,
    isARModalShown,
    searchParams
  });

  return (
    <Fragment>
      <a
        onClick={e => toggleBottomMenu(e, true)}
        className={
          activeBottomItem ? 'mobile-nav-link active' : 'mobile-nav-link'
        }
      >
        <ReactSVG
          className={`bottom-nav-icon ${activeBottomItem ? '' : 'hidden'}`}
          src={myTripsHoverIcon}
        />
        <ReactSVG
          className={`bottom-nav-icon ${activeBottomItem ? 'hidden' : ''}`}
          src={myTripsIcon}
        />
        <MobileMenuTitle className='mobile-menu-title'>
          {t('BOTTOM_NAV_MY_TRIPS')}
        </MobileMenuTitle>
      </a>
      <Drawer
        placement='bottom'
        closable={false}
        onClose={toggleBottomMenu}
        zIndex={999998}
        className='mobile-bottom-menu'
        visible={tripsDrawerOpened}
        height={196}
        bodyStyle={{ padding: 0 }}
      >
        <DrawerBodyWrapper>
          <Menu selectable={false}>
            <Menu.Item
              className={
                pathname.includes(`${RESERVATIONS_PAGE}`)
                  ? 'ant-menu-item-selected'
                  : ''
              }
              key='1'
            >
              <NavLink
                isActive={() => false}
                to={`${match.url}${RESERVATIONS_PAGE}`}
                onClick={toggleBottomMenu}
              >
                <ReactSVG src={reservationsIcon} className='mobile-menu-icon' />
                {t('RESERVATIONS_MENU_ITEM')}
              </NavLink>
            </Menu.Item>
            <Menu.Item
              className={
                pathname.includes(`${ABSENCE_REPORTS_PAGE}`) &&
                !(!isEmpty(searchParams) && searchParams.open_report)
                  ? 'ant-menu-item-selected'
                  : ''
              }
              key='2'
            >
              <NavLink
                isActive={() => false}
                to={`${match.url}${ABSENCE_REPORTS_PAGE}`}
                onClick={toggleBottomMenu}
              >
                <ReactSVG src={absenceIcon} className='mobile-menu-icon' />
                {t('ABSENCE_REPORTS_MENU_ITEM')}
              </NavLink>
            </Menu.Item>
          </Menu>
        </DrawerBodyWrapper>
        <DrawerFooter />
      </Drawer>
    </Fragment>
  );
};

export default MyTripsMobileMenu;
