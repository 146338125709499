import React, {useContext} from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Antd/Button/Button';
import Drawer from 'components/UI/Antd/Drawer/Drawer';
import { MarinaMobileHeaderWrapper, BackButtonTitle } from './Header.style';
import MobieSignupBanner from '../MobileMenu/MobieSignupBanner/MobieSignupBanner';
import { LayoutContext } from 'context/LayoutProvider';

const MarinaMobileHeader = ({ history, match }) => {
  const { t } = useTranslation();
  const [state, dispatch] = useContext(LayoutContext)
  const closeSignupBanner = () => {
    dispatch({ type: 'HIDE_AUTH_DRAWER' });
  };

  return (
    <>
    <MarinaMobileHeaderWrapper>
      <Button
        className='back_button'
        htmlType='button'
        onClick={() => {
          history.push({ pathname: match.url });
        }}
        type='link'
      >
        <FiArrowLeft />
        <BackButtonTitle>{t('BACK_TO_ALL_MARINAS')}</BackButtonTitle>
      </Button>
    </MarinaMobileHeaderWrapper>
    <Drawer
        placement='bottom'
        closable={false}
        onClose={closeSignupBanner}
        zIndex={999998}
        className='mobile-bottom-menu'
        visible={state.authDrawerOpened}
        height={224}
        bodyStyle={{ padding: 0 }}
      >
        <MobieSignupBanner closeDrawer={closeSignupBanner} />
      </Drawer>
    </>
  );
};

export default MarinaMobileHeader;
