import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 15px 97px;
  position: relative;
  .ant-btn.ant-btn-link.popeye-request-back-button {
    display: flex;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 2px;
    color: ${themeGet('text.11', '#333333')};
    padding: 4px;
  }
`;

export const Label = styled.span`
  font-size: 15px;
  line-height: 1.2;
  margin-bottom: 2px;
`
