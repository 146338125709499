import styled, { css } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Wrapper = styled.div`
  .ant-menu {
    border-bottom: none;
    .ant-menu-item {
      padding: 0;
      margin: 0;
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      display: flex;
      align-items: center;
      &.ant-menu-item-selected {
        background-color: transparent;
      }
      a {
        color: ${themeGet('text.12')};
        &:hover {
          opacity: 0.8;
        }
      }
      &:after {
        display: none;
      }
    }
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    color: ${themeGet('text.12')};
    &:hover {
      opacity: 0.8;
    }
  }
`;


export const Divider = styled.div`
  background-color: ${themeGet('text.12')};
  height: 14px;
  width: 1px;
  margin: 0 3px;
  @media only screen and (max-width: 991px) {
    ${({ first }) => first && css`display: none;` }
  }
`;

