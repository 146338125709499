import React, { Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, withRouter } from 'react-router-dom';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import Modal from 'components/UI/Antd/Modal/Modal';
import Button from 'components/UI/Antd/Button/Button';
import { ABSENCE_REPORTS_PAGE } from 'settings/constant';
import useWindowSize from 'library/hooks/useWindowSize';
import { setStateToUrl, getStateFromUrl } from 'library/helpers/url_handler';
import {
  BannerBodyWrapper,
  BannerImage,
  BannerHeadline,
  BannerDescription,
  BannerIcon,
  TriangleWrapper,
  Triangle
} from './AbsenceReportBanner.style';
// icons
import ARbanner from 'assets/images/pickapier/ar-banner.png';
import ARIcon from 'assets/images/pickapier/notify-absence-icon.svg';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const AbsenceReportBanner = ({
  visible,
  onClose,
  onClick,
  match,
  details,
  noLink,
  location
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  let link = `/${match.params.locale}${ABSENCE_REPORTS_PAGE}${setStateToUrl({
    open_report: true
  })}`;
  let translations;

  if (details) {
    const {
      title,
      headline,
      cta_title,
      cta_link,
      description_first,
      description_first_mobile,
      description_second,
      description_second_mobile
    } = details;
    if (cta_link) link = cta_link;
    translations = {
      title: title ? t(title) : t('ABSENCE_BANNER_TITLE'),
      headline: headline ? t(headline) : t('ABSENCE_BANNER_HEADLINE'),
      cta_title: cta_title ? t(cta_title) : t('ABSENCE_BANNER_BUTTON_LABEL'),
      description_first: description_first
        ? t(description_first)
        : t('ABSENCE_BANNER_DESCRIPTION_FIRST'),
      description_first_mobile: description_first_mobile
        ? t(description_first_mobile)
        : t('ABSENCE_BANNER_DESCRIPTION_FIRST_MOBILE'),
      description_second: description_second
        ? t(description_second)
        : t('ABSENCE_BANNER_DESCRIPTION_SECOND'),
      description_second_mobile: description_second_mobile
        ? t(description_second_mobile)
        : t('ABSENCE_BANNER_DESCRIPTION_MOBILE')
    };
  } else {
    translations = {
      title: t('ABSENCE_BANNER_TITLE'),
      headline: t('ABSENCE_BANNER_HEADLINE'),
      cta_title: t('ABSENCE_BANNER_BUTTON_LABEL'),
      description_first: t('ABSENCE_BANNER_DESCRIPTION_FIRST'),
      description_first_mobile: t('ABSENCE_BANNER_DESCRIPTION_FIRST_MOBILE'),
      description_second: t('ABSENCE_BANNER_DESCRIPTION_SECOND'),
      description_second_mobile: t('ABSENCE_BANNER_DESCRIPTION_MOBILE')
    };
  }

  const handleClick = () => {
    const searchParams = getStateFromUrl(location);
    if (!isEmpty(searchParams) && !isNil(searchParams.reservation_id)) {
      onClick(searchParams.reservation_id);
    } else {
      onClick();
    }
  };

  return (
    <Fragment>
      <Modal
        wrapClassName='absence-report-banner'
        width={width > 991 ? 644 : 343}
        title={translations.title}
        visible={visible}
        bodyStyle={{ padding: '0px' }}
        centered={true}
        onCancel={onClose}
        footer={null}
        zIndex={10001}
        closeIcon={
          <MdClose
            size={24}
            style={{ color: width > 991 ? '#ffffff' : '#323232' }}
          />
        }
      >
        <BannerImage>
          <img src={ARbanner} alt='Absence report' />
        </BannerImage>
        <BannerBodyWrapper>
          <BannerHeadline>{translations.headline}</BannerHeadline>
          <BannerDescription>
            {width > 991
              ? translations.description_first
              : translations.description_first_mobile}
          </BannerDescription>
          {width > 991 ? (
            <BannerDescription>
              {translations.description_second}
            </BannerDescription>
          ) : (
            <BannerIcon>
              <ReactSVG className='absence-report-icon' src={ARIcon} />
              <BannerDescription className='mobile-description'>
                {translations.description_second_mobile}
              </BannerDescription>
            </BannerIcon>
          )}
          {noLink ? (
            <Button
              className='submit-button'
              type='primary'
              onClick={handleClick}
            >
              {translations.cta_title}
            </Button>
          ) : (
            <Link
              className='ant-btn ant-btn-primary'
              onClick={onClose}
              to={link}
            >
              {translations.cta_title}
            </Link>
          )}
          <TriangleWrapper>
            <Triangle />
          </TriangleWrapper>
        </BannerBodyWrapper>
      </Modal>
    </Fragment>
  );
};

export default withRouter(AbsenceReportBanner);
